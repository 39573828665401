import { useEffect, useState } from "react";
import FooterContainer from "src/components/footer/footer";
import SEOContents from "src/components/SEOContents";
import brandDomains from 'src/config/hostConfig';
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";


const ExclusionPolicy = (props) => {
    const { app: { brandId }, theme } = props;
    let findDomain = brandDomains.find(elm => elm.id === brandId)?.domain;
    const website = `${findDomain}`;
    const name = website.toUpperCase().split('.')[1]
    const [mailId, setMailId] = useState('')
    const { config: { showSkypeId } } = theme;

    useEffect(() => {
        switch (brandId) {
            case 31:
                setMailId('support@yolo247.co')
                break;
            case 32:
                setMailId('support@yolo247.club')
                break;
            case 33:
                setMailId('support@baaziadda.com')
                break;
            case 34:
                setMailId('support@betdaily.club')
                break;
            case 39:
                setMailId('support@fomo7.net')
                break;
            case 46:
                setMailId('support@fun88.site')
                break;
            case 47:
                setMailId('support@gofun88.in')
                break;
            default:
                setMailId('')
                break;
        }
    }, [])

    
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : (brandId === 46 || brandId === 47) ? 'Fun88' : "Baazi Adda";

    return (
        <>
        <SEOContents pageName={"exclusionPolicy"}/>
            <ContentWrapper>
                <h1>Self Exclusion Policy of {domain}</h1>

                <h2>What is a self-exclusion?</h2>
                <p>
                    Self-exclusion is a facility that the Site offers to help those customers who feel that their gambling is out of
                    control and want us to help them stop. By entering into a self-exclusion agreement with the Site, you will be
                    prevented from using your Account (as defined in the terms and conditions) for a specific period, as determined
                    by you, of between 6 months and 5 years.
                </p>
                {/* <h2>How to self-exclude from the Site?</h2>
                <p>
                    Please inform us of the period for which you wish to self-exclude. The minimum is 6 months and the maximum
                    is 5 years. If you request self-exclusion but do not specify a period, we will exclude you for the minimum
                    period of three months ("Minimum Period").
                    by you, of between {brandId && brandId === 31 ? "3 months" : "6 months"} and 5 years.
                </p> */}
                <h2>How to self-exclude from the Site?</h2>
                <p>
                    Please inform us of the period for which you wish to self-exclude. The minimum is {brandId && brandId === 31 ? "3 months" : "6 months"} and the maximum
                    is 5 years. If you request self-exclusion but do not specify a period, we will exclude you for the minimum
                    period of {brandId && brandId === 31 ? "three months" : "six months"} ("Minimum Period").
                </p>
                <h2>How soon after requesting a self-exclusion will it be activated?</h2>
                <p>
                    We will endeavour to apply your exclusion as soon as practically possible. Normally, we will be able to reset
                    your password to prevent you accessing the Site within 24 hours of your request.
                </p>
                <h2>What happens if I self-exclude?</h2>
                <p>
                    We will make all reasonable efforts to:
                    <ul>
                        <li>Prevent any marketing material being forwarded to you.</li>
                        <li>Remove you from any marketing databases operated by us.</li>
                        <li>Suspend your activity by cancelling your ability to access the Site for the period requested by you, or if
                            no period is requested by you, for the Minimum Period.
                        </li>
                        <li>Permanently close your Customer Account if instructed to do so by you, and return all funds owed to
                            you to your nominated bank account.
                        </li>
                    </ul>
                </p>
                <h2>Can I re-activate my Account or open a new Account during the self-exclusion period?</h2>
                <p>
                    Accounts that have been self-excluded cannot be reactivated under any circumstances until the expiry of the
                    self-exclusion period.
                </p>
                <p>
                    During the period of your exclusion, you must not attempt to re-open any existing Account(s), seek to open any
                    new Accounts or seek to place bets through any other customer&apos;s Account.
                </p>
                <h2>If I would like to re-activate my Account, is this possible?</h2>
                <p>
                    Accounts that have been self-excluded cannot be reactivated under any circumstances until the expiry of the
                    self-exclusion period.
                </p>
                <p>At the end of the self-exclusion period, you must contact us in person and confirm such intention in writing. If it
                    is decided (in the Site&apos;s absolute discretion) to permit you to re-open your Account/open a new Account, you
                    should be aware that a 24-hour waiting period will be imposed prior to the Account being available for use.
                </p>

                { showSkypeId ? 
                <><p>Our Support will help you via email and skype at all time without any additional costs for you&#58;</p>
                    <ul>
                        <li><a href={`mailto:http://www.${mailId}`} style={{ textTransform: 'none' }}>{mailId}</a></li>
                        <li><a href={brandId && (brandId === 39 || brandId === 40) ? `https://join.skype.com/invite/spvuzktaqDbU` : 
                        `https://join.skype.com/invite/r4wa0JbdaMPD` }target="_blank"> {name} support skype</a></li>
                    </ul></> : null }
                {showSkypeId ? 
                    <><p>Our Support will help you via email and skype at all time without any additional costs for you&#58;</p>
                    <ul>
                        <li><a href={`mailto:http://www.${mailId}`} style={{ textTransform: 'none' }}>{mailId}</a></li>
                        <li><a href="https://join.skype.com/invite/r4wa0JbdaMPD" target="_blank"> {name} support skype</a></li>
                    </ul></> : null
                }
            </ContentWrapper>
            <FooterContainer />
        </>
    )

}

export default withAppContext(withTheme(ExclusionPolicy));

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
    color:${theme('palette.nametext')};
	font-size: 12px;
	padding: 1.5em 1.25em;
    text-transform: capitalize;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
        /* color: #f4a322; */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
	}

	h2 {
		font-size: 16px;
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		margin: 0.75em 0;
	}

	a {
		/* color: #e9d967; */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
		text-decoration: underline;
        cursor: pointer;
	}
`;