import { Suspense, lazy } from "react";
// import MainContent from "src/components/fine_print/tnc31";
import FooterContainer from "src/components/footer/footer";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { theme } from "styled-tools";
import getSchemaData from "src/components/schema";

const structuredData = (brandId) => {
	let domainURL = 'https://www.yolo247.co';
  
	if (brandId === 32) {
	  domainURL = 'https://www.yolo247.club';
	}
	else if (brandId === 33) {
	  domainURL = 'https://www.betdaily.com';
	}
	else if (brandId === 34) {
	  domainURL = 'https://www.betdaily.club';
	}   else if (brandId === 39) {
        domainURL = 'https://www.fomo7.net';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.pro';
      }
	else if (brandId === 46) {
		domainURL = 'www.fun88.site';
	}
	else if (brandId === 47) {
		domainURL = 'www.gofun88.in';
	}

	const otherschemaData = getSchemaData('terms-conditions');

  
	const data = [{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement":
			[
				{
					"@type": "ListItem",
					"item": `${domainURL}`,
					"name": "Homepage",
					"position": 1
				},
				{
					"@type": "ListItem",
					"item": `${domainURL}/terms-conditions`,
					"name": "T&C",
					"position": 2
				}
			],
		"name": "Breadcrumbs"
	},
	...otherschemaData
	];
  
	return JSON.stringify(data)
}

interface IProps {
    app: {
        brandId: number;
        type: any;
    };
    theme: { image: any; view: string };
    brandName: any
}

const terms: React.SFC<IProps> = props => {
    const {
        app: { brandId },
    } = props;

    // const Contents = brandId && brandId === 32 ? lazy(() => import("src/components/fine_print/tnc32")) : lazy(() => import("src/components/fine_print/tnc31"))

    const Contents = brandId && brandId === 32 ? lazy(() => import("src/components/fine_print/tnc32")) : 
	brandId && (brandId === 39 || brandId === 40) ? lazy(() => import("src/components/fine_print/tnc39")) :
	brandId && (brandId === 46 || brandId === 47) ? lazy(() => import("src/components/fine_print/tnc46")) :
	lazy(() => import("src/components/fine_print/tnc31"))

    return (<>
	<Helmet>
        <script type="application/ld+json">
          {
            structuredData(brandId)
          }

        </script>
      </Helmet>
        <ContentWrapper>
			<SEOContents pageName={"termsandconditions"}/>
            <Suspense fallback={<div>Loading ... </div>}>
                <Contents />
            </Suspense>
        </ContentWrapper>
        <FooterContainer />
    </>
    );
};

export default withAppContext(terms);

const ContentWrapper = styled.div`
 	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 1.5em 1.25em;
    color: white;
    text-transform: capitalize;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
	}

	h2 {
		font-size: 16px;
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
        list-style-type:none;
	}

	li {
		margin: 0.75em 0;
	}

	a {
		/* color: #e9d967; */
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
		text-decoration: underline;
        cursor: pointer;
	}
	.policyInternWrap {
		position: relative;
		color: ${theme('palette.nametext')};
	}

	.addColor {
		font-size: 18px;
		/* color: #f4a322; */
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
	}

	
	.policyInternWrap {
		position: relative;
		color: ${theme('palette.nametext')};
	}

	.downloadAction {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		right: 0;
		top: 0;
	}

	.downloadAction a {
		width: 92px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		padding: 0;
		border-radius: 5px;
		font-size: 12px;
		color: #fff;
		text-decoration: none;
		background: linear-gradient(180deg, #FFCF84 0%, #AC6700 100%);
	}

	.downloadAction a:hover {
		background: linear-gradient(180deg, #FFCF84 0%, #000000 100%);
	}

	.downloadAction svg {
		width: 12.353px;
		height: 11.25px;
	}

	.brandColor {
		color: #8998F4;
	}

	.bgColor {
		background: #f5f5f5;
		padding: 2rem;
		margin: 0;
		border-radius: 1rem;
	}
	`;
