import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { Link, useNavigate } from 'react-router-dom';

function CrazyInstantChallenge(props) {
    const { app: {
        brandId
    }, theme } = props;

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const gamesList = [
        { image: "brand/6/image/CICGameList01.png", url: "/instant-games/aviator" },
        { image: "brand/6/image/CICGameList02.png", url: "/instant-games/plinkox" },
        { image: "brand/6/image/CICGameList03.png", url: "/instant-games/rocketon" },
        { image: "brand/6/image/CICGameList04.png", url: "/instant-games/cricketx" },
        { image: "brand/6/image/CICGameList05.png", url: "/instant-games/helicopterx" },
        { image: "brand/6/image/CICGameList06.png", url: "/instant-games/cappadocia" },
        { image: "brand/6/image/CICGameList07.png", url: "/instant-games/taxi" },
        { image: "brand/6/image/CICGameList08.png", url: "/instant-games/comet-crash" },
        { image: "brand/6/image/CICGameList09.png", url: "/instant-games/mines" },
        { image: "brand/6/image/CICGameList10.png", url: "/instant-games/penalty-shootout" },
        { image: "brand/6/image/CICGameList11.png", url: "/instant-games/maestro" },
        { image: "brand/6/image/CICGameList12.png", url: "/instant-games/footballx" },
        { image: "brand/6/image/CICGameList13.png", url: "/instant-games/balloon" },
        { image: "brand/6/image/CICGameList14.png", url: "/instant-games/daredevil" },
        { image: "brand/6/image/CICGameList15.png", url: "/instant-games/archeo" },
        { image: "brand/6/image/CICGameList16.png", url: "/instant-games/gold-rush" }
    ];

    const redirectToGame = (e, url) => {
        e.preventDefault();
       if(url){
        navigate(url);
       } else {
        return;
       }
    }
    

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';

    return (
        <>
            <InformationWrapper>
                <CICFirstSecWrap>
                    <CICFirstSecContent>
                      <h2>{brandId == 31 || brandId == 32 ? "CRAZY INSTANT CHALLENGE 2025" : brandId == 33 || brandId == 34 ? "Baazi Instant Challenge" : brandId  == 39 || brandId == 40 ? "FOMO Instant Challenge 2025" : "Instant Challenge 2025"}</h2>
                      <ul>
                        <li>Win a pool prize of INR {brandId == 33 || brandId == 34 ? "28" : "46"} Lakhs.</li>
                        <li>All You Need To Do Is Play Any Of Your Favourite Instant Games <br />from 15th JAN - 15th MAR 2025 to enter this challenge.</li>
                        <li>140 lucky players stand a chance to win!</li>
                        <li>Winners will be declared weekly on {domain} social media handles.</li>
                        <li>Crazy bonus will be credited as bonus in your {domain} wallet.</li>
                      </ul>
                      </CICFirstSecContent>
                </CICFirstSecWrap>
                <CICGamesSecWrap>
                     <h2>Games <span>(16)</span></h2>
                     <CICGamesList>
                            {gamesList.map((game, i) => (
                                    <LazyLoadImage
                                        key={i}
                                        src={`${config.imageV3Host}/static/cashsite/${game.image}`}
                                        alt="bigBashBigCashImage"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        onClick={(e) => redirectToGame(e, game.url)}
                                    />
                            ))}
                     </CICGamesList>
                </CICGamesSecWrap>

                <CICFeaturesWrap>
                    <CICFeaturesBox>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps01}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>{brandId === 33 || brandId === 34 ? "Instant Leaderboard" : "Championship Leaderboard"}</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Get ready for an adrenaline-packed competition! Each week marks a Crazy Round in the Championship Leaderboard, with a total of <span>4 Crazy Rounds</span> throughout the campaign.</li>
                                <li>At the end of each week, the prize pool is displayed on promo page with <span>top players</span> current standings.</li>
                                <li>A total of <span>140 players</span> have a chance to win crazy bonuses during these rounds.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps02}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Telegram Bonus Drop</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Stay connected and win big with our <span>Telegram Bonus Drops!</span> </li>
                                <li><span>Weekly Promotions: </span> Exclusive promo bonuses for the top 10 ranks delivered straight to our Telegram community.</li>
                                <li><span>Weekly Free Bets:</span> Don't miss your chance to grab free bets every week and enhance your gameplay.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps03}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Turbo Bonus Leaderboard</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Take your betting to the next level with the <span>Turbo Bonus Leaderboard!</span></li>
                                <li>10 Consecutive Winning Bets: Earn a special bonus worth <span>500 INR</span>.</li>
                                <li>20 Consecutive Winning Bets: Unlock a special bonus worth <span>1,000 INR</span>.</li>
                                <li>30 Consecutive Winning Bets: Claim a special bonus worth <span>3,000 INR</span>.</li>
                                <li>This feature encourages consistent play and adds an <span>exciting layer</span> of quick rewards for <span>our players.</span></li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps04}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Weekly Cashback Offer</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Enjoy a <span>10% cashback</span> on your weekly losses!</li>
                                <li>Deposit <span>every day and play</span> to your heart's content.</li>
                                <li>If your net profit as of Sunday 12:00 AM is negative, you'll receive up to <span>10% cashback</span> on your losses.</li>
                                <li>This offer ensures you always have something to look forward to on <span>{domain}</span>, even during a tough week.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>
                </CICFeaturesWrap>

                <CICPrizeDetailsWrap>
                    <h2>Championship Leaderboard <br />  {brandId == 31 || brandId == 32 ? "CRAZY INSTANT CHALLENGE" : brandId == 33 || brandId == 34 ? "Baazi Instant Challenge" : brandId  == 39 || brandId == 40 ? "FOMO Instant Challenge" : "Instant Challenge 2025"} {brandId == 33 || brandId == 34 ? "28" : "46"} Lakhs</h2>
                    <p>Aviator, Mines, PlinkoX, penalty shoot out, Rocket on, Masetro, CricketX, Footballx, HelicopterX, Balloon, Cappadocia, daredevil, taxiride.</p>
                    <ImageWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICPrizeTable}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ImageWrapper>
                </CICPrizeDetailsWrap>


                
             
                {(brandId == 33 || brandId == 34) ?
                    <CICTermsAndCondition>
                        <CICMainHeadWrap>
                            <h2>Terms & Conditions</h2>
                        </CICMainHeadWrap>
                        <ol>
                            <li>This promotion is valid for all new and existing players.</li>
                            <li>
                                Baazi Instant Challenge is valid from <strong>15th JAN to 15th MAR 2025</strong>. Leaders will be announced weekly on the Baaziadda Instagram and Telegram channels.
                            </li>
                            <li>For a bet to be valid, a minimum cash-out of 1.30 is required.</li>
                            <li>
                                Void, cancelled, or draw bets, as well as cashed-out bets, do not count towards the deposit or bonus rollover requirements.
                            </li>
                            <li>
                                Multiple accounts will not be considered for promotion eligibility/criteria. All players will be subject to duplicate checking.
                               <br /> Baaziadda reserves the right to disqualify players if collusion or any foul play is detected in the promotion.
                            </li>
                            <li>This promotion cannot be used in conjunction with, or contribute to the eligibility for, any other promotion.</li>
                            <li>Baaziadda reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>Baaziadda General Terms & Conditions apply.</li>
                        </ol> 

                    
                    </CICTermsAndCondition> :
                    <CICTermsAndCondition>
                        <CICMainHeadWrap>
                            <h2>Terms & Conditions</h2>
                        </CICMainHeadWrap>
                        <ol>
                            <li>This promotion is valid for all new and existing players.</li>
                            <li>
                                Crazy Instant Challenge is valid from <strong>15th JAN to 15th MAR 2025</strong>. Leaders will be announced weekly on the {domain} Telegram channel.
                            </li>
                            <li>For a bet to be valid, a minimum cash-out of 1.30 is required.</li>
                            <li>
                                Void, cancelled, or draw bets, as well as cashed-out bets, do not count towards the deposit or bonus rollover requirements.
                            </li>
                            <li>Multiple accounts will not be considered for promotion eligibility/criteria. All players will be subject to duplicate checking.
                               <br /> {domain} reserves the right to disqualify players if collusion or any foul play is detected in the promotion.
                            </li>
                            <li>This promotion cannot be used in conjunction with, or contribute to the eligibility for, any other promotion.</li>
                            <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ol>
                    </CICTermsAndCondition>
                }
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(CrazyInstantChallenge));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.CICInnerBgYolo")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        color: #E6FF00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 3rem;

    img {
        width: 75%;
    }
`;

const CICMainHeadWrap = styled.div`
    width: 85%;
    background: linear-gradient(90deg, #C50C0A 0%, rgba(167, 40, 34, 0.00) 100%);
    margin-left: 4%;

    & h2 {
        font-size: 25.431px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFFF8E 30.32%, #FB9700 109.84%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;  
        position:relative;
        margin: 0px 20px;

        ::before {
            content: '';
            width: 4px;
            height: 24px;
            border-radius: 45.041px;
            background: #FFF;
            box-shadow: 0px 0px 13.441px 0px #F00;
            position: absolute;
            left: -23px;
            top: 3px;
        }
    }
`

const CICTermsAndCondition = styled.div`
    width: 80%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
    border-radius: 22.706px;
    border: 0.908px solid #FFF;
    background: radial-gradient(1165.69% 282.76% at -64.11% -58.63%, rgba(121, 89, 41, 0.72) 0%, rgba(19, 19, 18, 0.72) 39.94%, rgba(19, 18, 17, 0.72) 66.87%, rgba(64, 50, 29, 0.72) 100%);

    & ol {
        padding-left: 15px;
    }

    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 1rem 0;
    }

    & h4 {
        color: #E6FF00;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    & ${CICMainHeadWrap} {
      margin-left: 0%;
      width: 40%;
    }
`;

const CICFirstSecWrap = styled.div`
    width: 1048px;
    height: 404px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme("image.CICFirstSecBGYolo")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 3em;
`

const CICFirstSecContent = styled.div`
    width: 50%;
    height: auto;

    & h2 {
        font-size: 25.431px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFFF8E 30.32%, #FB9700 109.84%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 3px 0px;
        display: flex;
        flex-direction: column;

        ::after {
            content: '';
            width: 15%;
            height: 2px;
            border-radius: 58.041px;
            background: #FFF;
            box-shadow: 0px 0px 13.441px 0px #F00;
            margin-top: 8px;
        }
    }

    & ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            padding-left: 15px;
            width: 80%;

        li {
            color: #FFF;
            font-size: 14.243px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: capitalize;
            margin: 3px 0px;
        }

        @media only screen and (max-width: 1600px){
            
            & h2 {
                font-size: 22px;
            }

            & li {
                font-size: 12px;
            }
        }
}
`

const CICGamesSecWrap = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 5px;
        align-items: center;
        margin-top: 4em;

        & h2 {
            font-size: 25.431px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            background: linear-gradient(180deg, #FFFF8E 30.32%, #FB9700 109.84%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 3px 0px;
            position: relative;


        & span {
            font-size: 18px;
        }

        ::after {
            content: '';
            width: 80%;
            height: 2px;
            border-radius: 58.041px;
            background: #FFF;
            box-shadow: 0px 0px 13.441px 0px #F00;
            position: absolute;
            bottom: -11px;
            left: 12px;
        }
    }
`

const CICGamesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    height: auto;
    justify-content: center;
    gap: 3px;
    margin-top: 20px;

    & img {
        width: 100%;
        height: 140px;
        object-fit: contain;
        cursor: pointer;
    }
`

const CICFeaturesWrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 3em;
    justify-content: center;
`

const CICFeaturesBox = styled.div`
    border-radius: 22.706px;
    border: 0.908px solid #FFF;
    background: radial-gradient(1165.69% 282.76% at -64.11% -58.63%, rgba(121, 89, 41, 0.52) 0%, rgba(19, 19, 18, 0.52) 39.94%, rgba(19, 18, 17, 0.52) 66.87%, rgba(64, 50, 29, 0.52) 100%);
    backdrop-filter: blur(8.199999809265137px);
    padding: 0% 1% 2% 1%;
    width: 45%;
    margin-top: 7em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    ::before {
            content: '';
            width: 80%;
            height:4px;
            border-radius: 58.041px;
            background: #FFF;
            box-shadow: 0px 0px 13.441px 0px #F00;
            position: absolute;
            left: 60px;
            top: 0px;
    }

    & img {
        width: 80%;
        height: 300px;
        z-index: 999;
        position: relative;
        object-fit: contain;
        margin: -115px auto 0px auto;
    }

   

    & ul li {
        color: #FFF;
        font-size: 14.243px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21.365px */
        text-transform: capitalize;
        margin: 15px 0px;

        & span {
            color: #FEDB5D;
            font-family: 700;
        }
    }
`



const CICFeaturesBoxContent = styled.div`
    
`

const CICPrizeDetailsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;

    & h2 {
        font-size: 25.431px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFFF8E 30.32%, #FB9700 109.84%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 3px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        text-align: center;

        ::after {
            content: '';
            width: 15%;
            height: 2px;
            border-radius: 58.041px;
            background: #FFF;
            box-shadow: 0px 0px 13.441px 0px #F00;
            margin-top: 8px;
        }
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 14.243px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21.365px */
        text-transform: capitalize;
        margin: 6px 0px 15px 0px;
    }
`