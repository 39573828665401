import React from "react";
import fetch from "isomorphic-fetch";
import util from "../util/util";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import { getBrandId } from "src/store/initAppContext";

!(global as any).langObj && ((global as any).langObj = {});

interface IProps {
  lang: string;
  showkey: string;
  langVersion: string;
  brandDomains: any[];
  brandId: number;
  app: any;
}

//  tslint:disable-next-line
const withSystem: any = (Child: any) =>
  class WithSystem extends React.Component<IProps, any> {
    static langVersion = "";
    constructor(props: IProps) {
      super(props);
      WithSystem.langVersion = props.langVersion || Date.now().toString();
      const _brandId = getBrandId();
      geti18n("en-us", props.langVersion, _brandId);
      this.state = {
        i18n: (key: string): string | JSX.Element => {
          const lang = "en-us";
          // util.getValue((global as any).langObj, `${lang}.${key}`);
          return util.getValue((global as any).langObj, `${lang}.${key}`);
        },
      };
    }

    render(): JSX.Element {
      return <Child {...this.props} {...this.state} />;
    }
  };

function geti18n(
  lang: string = "en-us",
  prefix = new Date().toISOString(),
  brandId: number
): Promise<any> | undefined {
  const baseUrl = "";
  const url = "";
  const langSelected = cookieUtil.get(ECookieName.COOKIE_LANG) || "en-us";
  if (util.isClient) {
    if (!(global as any).langObj[lang]) {
      const baseData: any = geti18nClient(langSelected, brandId);
      const data: any = geti18nClient(langSelected, brandId);
      (global as any).langObj[lang] = {
        ...baseData,
        ...Object.keys(data).reduce((result: any, key: string) => {
          if (!!data[key]) {
            result[key] = data[key];
          }
          return result;
        }, {}),
      };
    }
  } else {
    return fetch(baseUrl)
      .then((a: any) => a.json())
      .then((baseData: any) => {
        return fetch(url)
          .then((a: any) => a.json())
          .then((data: any) => {
            (global as any).langObj[lang] = {
              ...baseData,
              ...Object.keys(data).reduce((res: any, key: string) => {
                if (!!data[key]) {
                  res[key] = data[key];
                }
                return res;
              }, {}),
            };
            return (global as any).langObj[lang];
          })
          .catch((e: any) => {
            console.log("get i18n data error.", e);
          });
      })
      .catch((e: any) => {
        console.log("get i18n data error.", e);
      });
  }
  return;
}

function geti18nClient(url: string, brandId: number = 31): any {
  let obj = {};
  if (url === "en-us") {
    let _defaultEObj = {
      "Deposit@ReloadBonus": "Refill bonus",
      "NoteSpecialChannelWarning": "Don’t Miss Out On The",
      "NoteSpecialChannelWarningText": "Redeposit Bonus",
      "NoteSpecialChannelWarningEnd": "On The Recommended Channel",
      "DASHBOARD@WithdrawalNoteDisclaimer3":
        "Maximum withdrawal allowed per transaction = ₹ 200,000",
        "MAX@WD":'Maximum withdrawal allowed per transaction : as Discussed',
      "TITLE@paperplane": "Paper Plane",
      "NOTE@ELITE":'Yolo247 reserves the right to change/modify these conditions at any time without prior notice',
      "TIER@ALLPLATINUMNOTE":
        "Congratulations, on being a platinum level member this World Cup ! Enjoy your royalty privileges, meet the turnover and stay Platinum afterwards too",
      "PROMOTIONS@Platinumrush": "Platinum Rush",
      "TITLE@wcbetboost": "world cup bet boost",
      "TITLE@EvoCrazyPachinko": "EVOLUTION CRAZY PACHINKO: BET & WIN",
      "PROMOTIONS@psl": "PAISA SUPER LEAGUE",
      jetx: "JETX TOURNAMENT",
      "TITLE@ninjacrush": "Ninja Crash",
      "PROMOTIONS@funkytime": "FUNKY TIME : BET & WIN !",
      "PROMOTIONS@WCCBONAZA": "CASHBACK BONANZA",
      "GAME@KINGMAKER": "KINGMAKER",
      "PROMOTIONS@jetx1":
        "Its time to win big in “Smartsoft JETX” tournament. All you need to do is complete your turnover and multiplier missions, spin the leaderboard wheel and win the exciting prizes!",
      "SLIDERIMAGE@TEENPATTI1": "/static/media/teenpattiNormal.a4fc5d89.jpg",
      "SLIDERIMAGE@TEENPATTI2": "/static/media/TeenpattiNew.c4574ca0.jpg",
      "SLIDERIMAGE@TEENPATTI3": "/static/media/Andar-bahar.a799f4f1.jpg",
      "COLFIRST@GAME1": "/static/media/wheelGamenew.bf16a049.jpg",
      "COLFIRST@GAME2": "/static/media/liveDicegameNew.0b06be9d.jpg",
      "COLFIRST@GAME3": "/static/media/lucky7new.115d62aa.jpg",
      "HOMEPAGE@GAME1": "/static/media/CasinoJokerBet.f0a2db90.jpg",
      "HOMEPAGE@GAME2": "/static/media/casinoPoker.0d088bc3.jpg",
      "HOMEPAGE@GAME3": "/static/media/casinoFruitRace1.586e4844.jpg",
      "HOMEPAGE@GAME4": "/static/media/andar-bahar.png",
      "HOMEPAGE@GAME5": "/static/media/casinoRoulette.e34bdf9a.jpg",
      "HOMEPAGE@GAME6": "/static/media/wheelBetCasinoNew.77871d3a.jpg",
      "HOMEPAGE@GAME7": "/static/media/casinoBetNumber.ac5c3400.jpg",
      "HOMEPAGE@GAME8": "/static/media/casinoliveDice.0645bd9f.jpg",
      "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGame.aecdf10f.jpg",
      "HOMEPAGE@GAME10": "/static/media/32casinoCards.00093f2f.jpg",
      SEARCH: "SEARCH",
      "AGENT_UPDATE@TRANSACTION_LOCK": "Transaction Processing, Wait a moment.",
      "BETFAIR_API_NG_EXCEPTION@ACCESS_DENIED": "Technical Failure",
      "BETFAIR_API_NG_EXCEPTION@INVALID_APP_KEY": "Technical Failure",
      "BETFAIR_API_NG_EXCEPTION@INVALID_INPUT_DATA": "Invalid data",
      "BETFAIR_API_NG_EXCEPTION@INVALID_SESSION_INFORMATION":
        "Expired Session Data",
      "BETFAIR_API_NG_EXCEPTION@NO_APP_KEY": "Technical Failure",
      "BETFAIR_API_NG_EXCEPTION@NO_SESSION": "Technical Failure",
      "BETFAIR_API_NG_EXCEPTION@REQUEST_SIZE_EXCEEDS_LIMIT":
        "Technical Failure",
      "BETFAIR_API_NG_EXCEPTION@SERVICE_BUSY":
        "We are processing your information",
      "BETFAIR_API_NG_EXCEPTION@TIMEOUT_ERROR":
        "We are processing your information",
      "BETFAIR_API_NG_EXCEPTION@TOO_MANY_REQUESTS":
        "We are processing your information",
      "BETFAIR_API_NG_EXCEPTION@TOO_MUCH_DATA":
        "We are processing your information",
      "BETFAIR_API_NG_EXCEPTION@UNEXPECTED_ERROR": "Technical Failure",
      "BETFAIR_API_NG@BET_ACTION_ERROR": "Technical Failure",
      "BETFAIR_API_NG@BET_IN_PROGRESS": "Processing your bet",
      "BETFAIR_API_NG@BET_TAKEN_OR_LAPSED":
        "Bet cannot be cancelled. Please refresh your page or login again",
      "BETFAIR_API_NG@CANCELLED_NOT_PLACED": "Bet cancellation - processing",
      "BETFAIR_API_NG@DUPLICATE_BETIDS": "Technical Failure",
      "BETFAIR_API_NG@DUPLICATE_TRANSACTION": "Technical Failure",
      "BETFAIR_API_NG@ERROR_IN_MATCHER": "Technical Failure",
      "BETFAIR_API_NG@ERROR_IN_ORDER": "Unable to place bet",
      "BETFAIR_API_NG@INSUFFICIENT_FUNDS": "Not enough balance",
      "BETFAIR_API_NG@INVALID_ACCOUNT_STATE":
        "No bet permissions. Please contact up-line",
      "BETFAIR_API_NG@INVALID_BACK_LAY_COMBINATION": "Invalid bets",
      "BETFAIR_API_NG@INVALID_BET_ID": "Technical Failure",
      "BETFAIR_API_NG@INVALID_BID_TYPE": "Invalid Bet",
      "BETFAIR_API_NG@INVALID_CUSTOMER_ORDER_REF": "Invalid Bet",
      "BETFAIR_API_NG@INVALID_MARKET_ID": "Market doesn't exist",
      "BETFAIR_API_NG@INVALID_MARKET_VERSION": "Invalid Market",
      "BETFAIR_API_NG@INVALID_MIN_FILL_SIZE": "Technical Failure",
      "BETFAIR_API_NG@INVALID_ODDS": "Invalid odds.",
      "BETFAIR_API_NG@INVALID_ORDER": "Invalid bet",
      "BETFAIR_API_NG@INVALID_ORDER_TYPE": "Invalid bet",
      "BETFAIR_API_NG@INVALID_PERSISTENCE_TYPE": "Invalid bet",
      "BETFAIR_API_NG@INVALID_PRICE_EDIT": "Invalid price",
      "BETFAIR_API_NG@INVALID_PROFIT_RATIO": "Invalid bet",
      "BETFAIR_API_NG@INVALID_RUNNER": "Runner doesn't exist",
      "BETFAIR_API_NG@INVALID_WALLET_STATUS": "Not enough balance",
      "BETFAIR_API_NG@LOSS_LIMIT_EXCEEDED": "Technical Failure",
      "BETFAIR_API_NG@MARKET_NOT_OPEN_FOR_BETTING":
        "Market not open for betting",
      "BETFAIR_API_NG@MARKET_NOT_OPEN_FOR_BSP_BETTING":
        "Market not open for betting",
      "BETFAIR_API_NG@MARKET_SUSPENDED": "Market is suspended",
      "BETFAIR_API_NG@NO_ACTION_REQUIRED": "No action needed",
      "BETFAIR_API_NG@NO_CHASING": "Technical Failure",
      "BETFAIR_API_NG@PERMISSION_DENIED": "Permission denied",
      "BETFAIR_API_NG@PROCESSED_WITH_ERRORS": "Technical Failure",
      "BETFAIR_API_NG@REGULATOR_IS_NOT_AVAILABLE": "Service unavailable",
      "BETFAIR_API_NG@REJECTED_BY_REGULATOR": "Bet failure",
      "BETFAIR_API_NG@RELATED_ACTION_FAILED": "Technical Failure",
      "BETFAIR_API_NG@RUNNER_REMOVED": "Runner has been removed from the event",
      "BETFAIR_API_NG@SERVICE_UNAVAILABLE":
        "The requested service is unavailable",
      "BETFAIR_API_NG@TIME_IN_FORCE_CONFLICT": "Technical Failure",
      "BETFAIR_API_NG@TOO_MANY_INSTRUCTIONS": "Processing",
      "BETFAIR_API_NG@UNEXPECTED_MIN_FILL_SIZE": "Technical Failure",
      "BETFAIR_API_NG@UNEXPECTED_PERSISTENCE_TYPE": "Technical Failure",
      "CASINO_STATEMENT_TABLE@COMMISION": "Commision",
      "CASINO_STATEMENT_TABLE@CHARGES": "Charges",
      "CASINO_STATEMENT_TABLE@COMMISSION": "Commision",
      "CUSTOMER_MEMBER_GET_TRANSACTION@FAILURE": "Get transaction failure",
      "CUSTOMER_MEMBER_GET_TRANSACTION@SUCCESS": "Get transaction success",
      "CUSTOMER_MEMBER@MEMBER_CREATE_FAILURE": "Account create failure",
      "CUSTOMER_MEMBER@MEMBER_CREATE_SUCCESS": "Member create success",
      "CUSTOMER_MEMBER@MEMBER_CREATE_USERNAME_DUPLICATE": "Username duplicate",
      "CUSTOMER_MEMBER@MEMBER_CREDIT_INIT_FAILURE":
        "Member credit init failure",
      "EXCHANGE_CANCEL_ORDER@ORDER_BF_CANCELORDER_FAILURE":
        "Unable to cancel bet",
      "EXCHANGE_CANCEL_ORDER@ORDER_CHECK_ORDER_UNFOUND": "Unable to cancel bet",
      "EXCHANGE_CANCEL_ORDER@SUCCESS": "Bet cancelled successfully",
      "EXCHANGE_CHECKOUT_ORDER@BODY_PARAM_ERROR": "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_CREATE_TRANSACTIONS_EXCEPTION":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_CREATE_TRANSACTIONS_FAILURE":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_DELETE_CLEARED_ORDER_FAILURE":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_INSERT_CLEARED_ORDER_FAILURE":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_INSERT_SETTLE_CLOSED_FAILURE":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_MOVE_CLEARED_ORDER_EXCEPTION":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_MOVE_SETTLE_EXCEPTION":
        "Technical failure",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_NOT_FIND_ORDER":
        "Could not find bet",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_UPDATE_ORDER_FAILURE":
        "Unable to update",
      "EXCHANGE_CHECKOUT_ORDER@SUCCESS": "Success",
      "EXCHANGE_PLACE_ORDER@EXCHANGE_UPPERLINES_ERROR":
        "Unable to place bet. Please contact your up-line",
      "EXCHANGE_PLACE_ORDER@ORDER_BETFAIR_IN_BLACKLIST":
        "Betting has been disabled. Plz contact upline.",
      "EXCHANGE_PLACE_ORDER@ORDER_BETFAIR_IS_INVALID": "Unable to place bet.",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_API_TIMEOUT":
        "Unable to confirm bet. Please check again in 30 seconds to see if bet accepted.",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_EXCEPTION_TIMEOUT": "Technical Failure.",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_PT_FAILURE": "Technical Failure.",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_INSUFFICIENT": "Not enough balance",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_LESS_THAN_PER_ORDER_LIMIT":
        "Bet limit is less than minimum bet limit",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_OVER_PER_MARKET_LIMIT":
        "Bet size is greater than max market bet limit",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_OVER_PER_ORDER_LIMIT":
        "Bet size is greater than max bet limit",
      "EXCHANGE_PLACE_ORDER@ORDER_IS_INVALID":
        "Unable to bet, please contact to upperline.",
      "EXCHANGE_PLACE_ORDER@ORDER_IS_UNDER_PROCESSING":
        "Your order is under processing, please wait.",
      "EXCHANGE_PLACE_ORDER@ORDER_PRICE_IS_UNMATCHED":
        "Odds have changed. Please choose the new odds from the event list, or type them in.",
      "EXCHANGE_PLACE_ORDER@ORDER_SETTING_FAILURE": "Technical failure",
      "EXCHANGE_PLACE_ORDER@ORDER_SNK_IS_INVALID": "Unable to place bet.",
      "EXCHANGE_PLACE_ORDER@SELECTION_SIZE_ZERO": "There's no selection stock.",
      "EXCHANGE_PLACE_ORDER@SUCCESS": "Bet placed successfully",
      "EXCHANGE_RESETTLE@ORDER_MARKET_RESETTLE_ERROR": "NO ORDER",
      "EXCHANGE_RESETTLE@ORDER_RESETTLE_GET_TRANSACTION_ERROR": "NO ORDER",
      "EXCHANGE_UPDATE_ORDER@ORDER_BETFAIR_IN_BLACKLIST":
        "Betting has been disabled. Plz contact upline.",
      "EXCHANGE_UPDATE_ORDER@ORDER_BETFAIR_IS_INVALID": "Unable to update bet.",
      "EXCHANGE_UPDATE_ORDER@ORDER_SNK_IS_INVALID": "Unable to update bet.",
      "EXCHANGE_UPDATE_ORDER@ORDER_UPDATEORDER_WRONG_SOURCE": "Invalid market",
      "EXCHANGE_UPDATE_ORDER@SUCCESS": "Bet updated successfully",
      "GAME@JACKPOT": "Jackpot",
      "GQL_AGENT_MEMBER@ACCOUNT_PASSWORD_INVALID":
        "Account/Password has incorrect format or too short",
      "GQL_AGENT_MEMBER@CREATE_ACCOUNT_DUPLICATE_ERROR":
        "Account has been used",
      "GQL_AGENT_MEMBER@CREATE_ACCOUNT_ERROR":
        "Error occurs when creating account",
      "GQL_AGENT_MEMBER@UPDATE_ACCOUNT_ERROR":
        "Error occurs when updating account",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_CANCELORDER_FAILURE":
        "Technical Failure",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_PLACEORDER_FAILURE":
        "Technical Failure",
      "GQL_EXCHANGE_ORDER@ORDER_REOPEN_BF_NOT_ALLOWED": "",
      "GQL_EXCHANGE_ORDER@ORDER_UPDATE_PENDING_NO_PERMISSION": "No permission",
      "GQL_GAME_LOGIN@GAME_SERVICE_LOGIN_FAILURE":
        "Game Service is busy, please try again later.",
      "GQL_GAME_LOGIN@GAME_SERVICE_USER_POOL_OVER_LIMIT":
        "Demo system is busy. Please try again later.",
      "GQL_GAME_LOGIN@GAME_SETTING_DISABLED":
        "Platform currently disabled, Please contact your upline.",
      "GQL_GAME_TRANSFER@GAME_SERVICE_TRANSFER_FAILURE": "Transfer failed.",
      "GQL_GAME_TRANSFER@GAME_SERVICE_TRANSFER_TIMEOUT":
        "Please wait more seconds and refresh for the result.",
      "GQL_GAME_TRANSFER@MEMBER_TRANSATION_CREDIT_INSUFFICIENT":
        "Credit insufficient",
      "GQL_GAME_TRANSFER@PT_TRANSFER_FAILURE": "Casino credit transfer fail",
      "GQL_MEMBER_LOGIN@MEMBER_IP_BLACKLISTED":
        "Your IP is disabled, please contact your upperline.",
      "GQL_MEMBER_LOGIN@MEMBER_LOGIN_EUTOKEN_FAILURE": "Unable to log in",
      "GQL_MEMBER_LOGIN@MEMBER_LOGIN_FAILURE":
        "Username or Password is incorrect",
      "GQL_MEMBER_LOGIN@MEMBER_PERMISSIONS_FAILURE":
        "Log in disabled. Please contact upline",
      "GQL_MEMBER_LOGIN@MEMBER_UPPERLINES_PERMISSIONS_FAILURE":
        "Your upperlines had been INACTIVE/CLOSED, please check.",
      "GQL_MEMBER_LOGIN@SUCCESS": "Welcome",
      "GQL_MEMBER_LOGOUT@MEMBER_LOGOUT_FAILURE": "Unable to log out",
      "GQL_MEMBER_LOGOUT@SUCCESS": "Thank you",
      "GQL_MEMBER_TOGGLE_FAVORITE@SUCCESS": "Event added to favorites",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_NEW_PASSWORD_NOT_CORRECT":
        "New password is not correct.",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_OLD_PASSWORD_NOT_CORRECT":
        "Old Password is incorrect",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_PASSWORD_DUPLICATE":
        "New password is duplicated with old password",
      "GQL_MEMBER_UPDATE_PASSWORD@SUCCESS": "Password updated successfully",
      "GQL_MEMBER_VALIDATE@MEMBER_TOKEN_VALIDATE_FAILURE": "Technical Failure",
      "GQL_MEMBER_VALIDATE@SUCCESS": "Validate success",
      "MEMBER_CANCEL_TRANSACTION@MEMBER_TRANSACTION_HAD_BEEN_CANCELLED":
        "The bet has been cancelled successfully",
      "MEMBER_CANCEL_TRANSACTION@MEMBER_TRANSACTION_NOT_FOUND":
        "Technical Failure",
      "MEMBER_CANCEL_TRANSACTION@SUCCESS": "Cancelled successfully",
      "MEMBER_CANCEL_TRANSACTION@URL_PARAM_ERROR": "Technical Failure",
      "MEMBER_CREATE_SETTLE_TRANSACTION@SUCCESS": "Success",
      "MEMBER_CREATE_TRANSACTION@SUCCESS": "Success",
      "MEMBER_DEPOSIT@SUCCESS": "Member deposit success",
      "MEMBER_GET_BALANCE@SUCCESS": "Get avalibleBalacne success",
      "MEMBER_TRANSACTION@TRANSACTION_CHECK_BALANCE_FAILED":
        "Check credit balance failed.",
      "MEMBER_TRANSACTION@TRANSACTION_CREDIT_INSUFFICIENT":
        "The transaction credit is insufficient.",
      "MEMBER_TRANSACTION@TRANSACTION_UPDATE_CREDIT_FAILED":
        "Update credit failed.",
      "MEMBER_WITHDRAW@SUCCESS": "Member withdraw success",
      "MEMBER@EXIST": "Member exist",
      "MEMBER@NOT_EXIST": "Member not exist",
      "ORDER@ORDER@REMIND": "Your order is unmatched and cancelled.",
      "TABLE@FANCY1": "Fancy 1",
      "TABLE@FANCY2": "Fancy 2",
      "TABLE@FANCY3": "Fancy 3",
      "TABLE@FANCY4": "Fancy 4",
      "THIRDPARTY_BETFAIR@ORDER_BF_GET_SSID_FAILURE": "Technical Failure",
      "THIRDPARTY_CHECKOUT_ORDER@SUCCESS": "Checkout Order Success.",
      "THIRDPARTY_CHECKOUT_ORDER@URL_PARAM_ERROR": "The parameters are wrong.",
      "THIRDPARTY_CHECKOUT_ORDER@WAGERID_EXIST":
        "This wagerId has been checked out.",
      "TITLE@IPL_ELECTION": "INDIAN PREMIER LEAGUE 2023",
      "TITLE@ELECTION_2024": "US ELECTIONS 2024",
      "TITLE@HARYANA_ELECTION_2024": "HARYANA ELECTIONS 2024",
      "TITLE@JACKPOT": "Jackpot",
      "TITLE@PSL_ELECTION": "PSL CUP",
      "TITLE@WB_ELECTION": "West Bengal Election",
      "TRIAL_MODE_LOGIN@SUCCESS": "Welcome Visitors",
      "UPDATE_AI_PT@PT_SETTING_EXCEED": "PT Setting Exceeed",
      "UPDATE_AI_PT@SUCCESS": "Success",
      "BETFAIR_API_NG@INVALID_BET_SIZE": "Invalid Bet Size",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_UPDATEORDER_FAILURE":
        "Technical Failure",
      "ACCOUNT_STATEMENT_TABLE@AMOUNT": "Amount",
      "ACCOUNT_STATEMENT_TABLE@BALANCE": "Balance",
      "ACCOUNT_STATEMENT_TABLE@CREDIT_LIMIT": "Credit Limit",
      "ACCOUNT_STATEMENT_TABLE@DESCRIPTION": "Description",
      "ACCOUNT_STATEMENT_TABLE@FILTER_CASINO_ORDER": "Orders Only",
      "ACCOUNT_STATEMENT_TABLE@P&L": "P&L",
      "ACCOUNT_STATEMENT_TABLE@TIME": "Time",
      "ACCOUNT_STATEMENT_TABLE@TYPE": "Type",
      "ACCOUNT_STATEMENT@ALL": "All",
      "ACCOUNT_STATEMENT@BETTING_P&L": "Betting P/L Exchange",
      "ACCOUNT_STATEMENT@SETTLING": "Settling Statement",
      "ACCOUNT@AVAILABLE_CREDIT": "Available Credit",
      "ACCOUNT@BALANCE": "Balance",
      "ACCOUNT@GOLDTIER": "Gold Tier",
      "ACCOUNT@CREDIT_LIMIT": "Credit Limit",
      "ACCOUNT@NET_EXPOSURE": "Net Exposure",
      "ACCOUNT@P&L": "P&L",
      "ACCOUNT@BONUS": "Bonus won",
      "AGENT_SIDEBAR@ACCOUNT": "Account",
      "AGENT_SIDEBAR@ACTIVE_USERS": "Active Users",
      "AGENT_SIDEBAR@ASSOCIATED_ACCOUNT": "Associate New Account",
      "AGENT_SIDEBAR@CASINO_STATEMENT_AGENT": "Casino Statement",
      "AGENT_SIDEBAR@COMMUNICATION": "Communication",
      "AGENT_SIDEBAR@DASHBOARD": "Dashboard",
      "AGENT_SIDEBAR@DOWNLINE_SETTLE": "Settle",
      "AGENT_SIDEBAR@DOWNLINE_VIEW": "Manage",
      "AGENT_SIDEBAR@LIVE_BET_LIST": "Live Bet List",
      "AGENT_SIDEBAR@MANAGE_DOWNLINE": "Downline",
      "AGENT_SIDEBAR@MANAGE_EVENT": "Manage Event",
      "AGENT_SIDEBAR@MY_ACTIVITY": "My Activity",
      "AGENT_SIDEBAR@MY_REPORT": "My Report",
      "AGENT_SIDEBAR@NET_EXPOSURE": "Net Exposure",
      "AGENT_SIDEBAR@NOTIFICATION": "Notification",
      "AGENT_SIDEBAR@ORDER_MANAGEMENT": "Bet Report ",
      "AGENT_SIDEBAR@PL_REPORT_AGENT": "P/L Exchange Report - Agent",
      "AGENT_SIDEBAR@PL_REPORT_MARKET": "P/L Exchange Report - Market",
      "AGENT_SIDEBAR@PT_LISTING": "Position Taking - List",
      "AGENT_SIDEBAR@RISK_MANAGEMENT": "Risk Management",
      "AGENT_SIDEBAR@SETTLING_STATEMENT_AGENT": "Settling Statement",
      "AGENT_SIDEBAR@SPORT_STATEMENT_AGENT": "Sport Statement",
      "AGENT_SIDEBAR@SUB_ACCOUNT": "Sub Account",
      // added new dashboard label by ambu
      "AGENT_SIDEBAR@LANGUAGE_SETTLE": "Language Settle",
      "AGENT_SIDEBAR@ADMINISTRATOR": "Administrator",
      "AGENT_SIDEBAR@SUSPEND_PLATFORM": "Suspend Platform",
      "AGENT_SIDEBAR@IP_BLACKLIST": "IP Blacklist",
      // added new dashboard label by ambu
      "AGENT_SIDEBAR@ACCOUNT_MANAGEMENT": "Account Management",
      "AGENT_SIDEBAR@BETFAIR_PTSETTING": "Betfair PT Setting",
      // added new dashboard label by John

      "AGENT_SIDEBAR@CURRENCY_SETTING": "Currency Setting",
      // code chnaged/ added by - Malyaban
      "AGENT@ACCOUNT@AVAILABLE": "Available Credit",
      "AGENT@ACCOUNT@DESCRIPTION": "Description",
      "AGENT@ACCOUNT@EVENT": "Event",
      "AGENT@ACCOUNT@TIMESTAMP": "Timestamp",
      "AGENT@ACCOUNT@TRANSACTION": "Transaction Credit",
      EXAMPLE: "For Example :",
      withdrwalfooter:
        "……….. & 1% extra charges will be levied on subsequent withdrawals",
      "AGENTMEMBER_UPDATE_FAILED@UPPERLINE_DISABLED":
        "You do not have sufficient permissions.",
      "APP@ANDROID@APK": "https://file.zaw2cdr4.com/1/app/bbb-2020102001.apk",
      "APP@ANDROID@DOWNLOAD": "Download APK",
      DEFAULT_APK: "http://",
      MPC_APK:
        "https://app-mpc-prod.s3-eu-west-1.amazonaws.com/MPC-release.apk",
      "9BETS_APK": "https://app-9bets-prod.s3-eu-west-1.amazonaws.com/9BET.apk",
      BBB_APK: "https://app-bbb-prod.s3.eu-west-1.amazonaws.com/BBB-Games.apk",
      MAHA_APK:
        "https://app-mahadev-prod.s3-eu-west-1.amazonaws.com/Mahadevbet.apk",
      PARI_MATCH_APK:
        "https://app-parimatch-prod.s3.eu-west-1.amazonaws.com/pari_match.apk",
      "APP@BRAND@NAME": "BBB",
      "APP@DOWNLOAD@NAME": "33EXCH APP",
      "APP@DOWNLOAD@SLOGAN": "Exchange, Casino, Slot games all in one.",
      "APP@IOS@DOWNLOAD": "Download IPA",
      "APP@IOS@IPAID": "5e439d19159b1",
      "BRAND_NAME@RULES": "BBB",
      "CASINO_STATEMENT_TABLE@ACCOUNT": "ACCOUNT",
      "CASINO_STATEMENT_TABLE@AGENT": "AGENT",
      "CASINO_STATEMENT_TABLE@EVENT": "EVENT",
      "CASINO_STATEMENT_TABLE@GAME": "Game",
      "CASINO_STATEMENT_TABLE@PLACEDTIME": "Placed Time",
      "CASINO_STATEMENT_TABLE@PLATFORM": "Platform",
      "CASINO_STATEMENT_TABLE@PROVIDER": "Provider",
      "CASINO_STATEMENT_TABLE@REFID": "Ref ID",
      "CASINO_STATEMENT_TABLE@SETTLEDTIME": "Settled Time",
      "CASINO_STATEMENT_TABLE@STAKE": "Stake",
      "CASINO_STATEMENT_TABLE@STATUS": "Status",
      "CASINO_STATEMENT_TABLE@firstbet": "Initial Bet",
      "CASINO_STATEMENT_TABLE@cashout": "cashout",
      "CASINO_STATEMENT_TABLE@TURNOVER": "Turnover",
      "CASINO_STATEMENT_TABLE@WINLOSS": "Win/Loss",
      "CASINO@COIN_BALANCE": "Coin Balance: ",
      "CASINO@NOTE":
        "The currency displayed in the casino holds no value as the value of the coin  is the same as the value pre determined between you and your upline.",
      "CASINO@TITLE": "LIVE CASINO",
      "CASINO@TRANSFER_BUTTON": "Transfer",
      "CHANGE_PASSWORD@LENGTH_ERROR": "Password - between 6 and 20 characters",
      "CHANGE_PASSWORD@NEW_PASSWORD@DEFAULT_MESSAGE": "Enter New Password",
      "CHANGE_PASSWORD@NEW_PASSWORD@LABEL": "New Password",
      "CHANGE_PASSWORD@OLD_PASSWORD@DEFAULT_MESSAGE": "Type Old Password",
      "CHANGE_PASSWORD@OLD_PASSWORD@LABEL": "Old Password",
      "CHANGE_PASSWORD@CONFIRM_PASSWORD@LABEL": "Confirm Password",
      "CHANGE_PASSWORD@MINIMUM": "Min 8 Characters",
      "CHANGE_PASSWORD@REPEAD_PASSWORD@DEFAULT_MESSAGE": "Match new password",
      "CHANGE_PASSWORD@REPEAD_PASSWORD@LABEL": "Repeat Password",
      "CHANGE_PASSWORD@SUBMIT": "Change Password",
      "CHANGE_PASSWORD@SUCCESS": "Your Password has Successfully Changed",
      "UPDATE_PASSWORD@FAIL": "Failed to update password !",
      "CHANGE_PASSWORD@FAIL": "Change password fail!",
      "NOTIFY@PRICE": "PRICE",
      "NOTIFY@MATCH": "MATCH",
      "NOTIFY@UNMATCH": "UNMATCH",
      "DATE_PICKER@END": "End Date",
      "DATE_PICKER@FROM": "From",
      "DATE_PICKER@SEARCH": "Search",
      "DATE_PICKER@START": "Start Date",
      "DATE_PICKER@TITLE": "Pick Date",
      "DATE_PICKER@TO": "To",
      "DETAIL_TABLE@BET_ID": "Bet ID",
      "DETAIL_TABLE@FOOTER@BACK_SUBTOTAL": "Back subtotal",
      "DETAIL_TABLE@FOOTER@COMMISSION": "Commission",
      "DETAIL_TABLE@FOOTER@LAY_SUBTOTAL": "Lay Subtotal",
      "DETAIL_TABLE@FOOTER@NET": "Net Market Total",
      "DETAIL_TABLE@FOOTER@NO_SUBTOTAL": "No Subtotal",
      "DETAIL_TABLE@FOOTER@NOT_AVAILABLE": "Not Available",
      "DETAIL_TABLE@FOOTER@YES_SUBTOTAL": "Yes Subtotal",
      "DETAIL_TABLE@ODDS": "Odds",
      "DETAIL_TABLE@P&L": "P/L Exchange",
      "DETAIL_TABLE@PLACED": "Placed",
      "DETAIL_TABLE@SELECTION": "Selection",
      "DETAIL_TABLE@STAKE": "Stake",
      "DETAIL_TABLE@STATUS": "Status",
      "DETAIL_TABLE@TRANSACTION_NUMBER": "Transaction Number",
      "DETAIL_TABLE@TYPE": "Type",
      "DOWNLINE@SETTING@MARKET": "Market",
      "DOWNLINE@SETTING@SPORT": "Sport",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_DELETE_SETTLE_FAILURE":
        "Technical Failure",
      "FIXEDODDS@REMIND":
        "Please note that there is a conversion of coins when entering fixed odds.  So please check your balances and place your bets. Thank you.",
      "FOOT@COMPANYLICENSE": "Company License",
      "FOOT@DISCONNECTPOLICY": "Disconnection Policy",
      "FOOT@EXCLUSIONPOLICY": "Self Exclusion Policy",
      "FOOT@KYCPOLICY": "KYC Policy",
      "FOOT@AMLpolicy": "Anti-Money Laundering Program",
      "FOOT@LICENSEDETAILS": "", //"BBB Games is operated by SNK Entertainment N.V. a limited liability company incorporated under the laws of Curacao with company Registration number 148785 with registered office at Abraham de Veerstraat 7 , Curacao and is licensed and regulated by the Curacao authority as the regulatory body responsible holding a (Sub-license with License number 365/JAZ Sub-License GLH- OCCHKTW0704112019 granted on 11.04.2019). Players are requested not to contact any untrusted sources for BBB.Games accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.",
      "FOOT@PRIVACYPOLICY": "Privacy Policy",
      "FOOT@RESPONSIBLEGAMBLING": "Responsible Gambling",
      "FOOT@RESTRICTEDTERRITORIES": "Restricted Territories",
      "FOOT@RULES": "Rules & Regulations",
      "FOOT@TERMSANDPOLICY": " Terms and Policy",
      "FOOT@TERMSANDCONDITIONS": "Terms & Conditions",
      "FOOT@ABOUTUS": "About Us",
      "FOOT@FAQ": "FAQ",
      "FOOT@YEAR": "© 2019-2022", //code changed by John. Previous code was like "FOOT@YEAR": "Â© 2019-2021",
      "FOOTER@BEGAMBLEAWARE": "https://www.begambleaware.org/",
      "FOOTER@COPYRIGHT": "Copyright  2021-2025. All rights reserved",
      "FOOTER@GAMINGPARTNERS": "Gaming Partners",
      "FOOTER@PAYMENTMETHODS": "Payment Methods",
      "FOOTER@GAMECARE": "https://www.gamcare.org.uk/",
      "FOOTER@GAMSTOP": "https://www.gamstop.co.uk/",
      "FOOTER@CONNECTWITHUS": "Connect With Us",
      "FOOTER@PrincipalSponsors": "Principal Sponsors",
      "FOOTER@PrinciplePartner": "Principle Partner",
      "FOOTER@AssociatePartner": "Associate Partner",
      "FOOTER@Associatesponsors": "Associate sponsors",
      "FOOTER@AwardsAndRecognition": "Awards And Recognition",
      "FOOTER@EngagementPartner": "Engagement Partner",
      "FOOTER@OfficialOverseasBackSponsor": "Official Overseas Back Sponsor",
      "FOOTER@OficialTittleSponcer": "Official Title Sponsor",
      "FOOTER@PrincipleSponcerFomo": "Principal sponsor",
      "GAME@AGTEENPATTI": "TPT Plus",
      "GAME@ALL": "ALL GAMES",
      "GAME@SPRIBE": "SPRIBE",
      "GAME@AG": "AG",
      "GAME@STP": "STP",
      "GAME@SPINMATIC": "SPINMATIC",
      "GAME@NETENT": "NETENT",
      "GAME@BETSOFT": "BETSOFT",
      "GAME@PRAGMATICPLAY": "PRAGMATICPLAY",
      "GAME@TEENPATTI": "TEENPATTI",
      "GAME@SUPERSPADE": "SUPERSPADE",
      "GAME@SEXYGAMING": "SEXY GAMING",
      "GAME@EVOGAMES": "EVOLUTIONGAMING",
      "GAME@BETGAMES": "BETGAMES",
      "GAME@EZUGI": "EZUGI",
      "GAME@TVBET": "TV BET",
      "GAME@SUPERNOWA": "SUPERNOWA",
      "GAME@ONEXBET": "ONEXBET",
      "GAME@ASIA_GAMING": "AsiaGaming",
      "GAME@BBB_FIXED_OODS": "Fixed Odds",
      "GAME@SPORTBOOK": "Sportsbook",
      "GAME@ESPORTS": "E-Sports",
      "GAME@LIVE_CASINO": "Live Casino",
      "GAME@MICRO_GAMING": "MicroGaming",
      "GAME@MACAW": "MACAW",
      "GAME@AVIATRIX": "AVIATRIX",
      "GAME@PLAYTECH": "PlayTech",
      "GAME@GALAXYS": "GALAXSYS",
      "GAME@POKER": "Poker ",
      "GAME@POLITICS": "Politics",
      "GAME@SA_GAMING": "SAGaming",
      "GAME@SPECIAL_FANCY": "Special Fancy",
      "GAME@TEEN_PATTI": "Exchange Card Game",
      "GAME@VIRTUALSPORTS": "Virtual Sports",
      "GAME@WE_ARE_CASINO": "We Are Casino",
      "GLOBAL@BACK": "Back",
      "GLOBAL@FANCY": "Fancy",
      "GLOBAL@LAY": "Lay",
      "GLOBAL@LIABILITY": "Liability",
      "GLOBAL@LOSS": "Loss",
      "GLOBAL@MATCH_ODDS": "Match Odds",
      "GLOBAL@NO": "No",
      "GLOBAL@ODDS": "Odds",
      "GLOBAL@SELECTION": "Selection",
      "GLOBAL@PL": "P&L",
      "GLOBAL@PLACEHOLDER": "-",
      "GLOBAL@PROFIT": "Profit",
      "GLOBAL@RUNS": "Runs",
      "GLOBAL@STAKE": "Stake",
      "GLOBAL@YES": "Yes",
      "GQL_GAME_ADD@PARAM_ERROR": "Name and ImageUrl should be included.",
      "GQL_GAME_UPDATE@PARAM_ERROR": "Name and ImageUrl should be included.",
      "HEADER@BBBHOME": "Home",
      "HEADER@MULTITV": "Multi TV",
      "HEADER@LIVETV": "Live TV",
      "HEADER@LIVESCORE": "Live Score",
      "HISTORY_TABLE@BETID": "Bet ID",
      "HISTORY_TABLE@DESCRIPTION": "Description",
      "HISTORY_TABLE@LIABILITY": "Liability",
      "HISTORY_TABLE@MATCHED": "Matched",
      "HISTORY_TABLE@ODDS": "Odds",
      "HISTORY_TABLE@ORDER_NUMBER": "Bet Number",
      "HISTORY_TABLE@PLACED": "Placed",
      "HISTORY_TABLE@POTENTIAL_PROFIT": "Potential Profit",
      "HISTORY_TABLE@PROFIT_LOSS": "Profit/Loss",
      "HISTORY_TABLE@SETTLED": "Settled",
      "HISTORY_TABLE@STAKE": "Stake",
      "HISTORY_TABLE@STATUS": "Status",
      "HISTORY_TABLE@TIME": "Time",
      "HISTORY_TABLE@TRANSACTION_NUMBER": "Transaction Number",
      "HISTORY_TABLE@TYPE": "Type",
      "IOS@INSTALL@_OPT_STEP2":
        "If your screen pop up Profile Downloaded window, please close it and click allow button again.",
      "IOS@INSTALL@_OPT_STEP5":
        "Click install button to continue installation.",
      "IOS@INSTALL@STEP1":
        "Press download button,Install the file to your phone,and Click Allow to start downloading the profile.",
      "IOS@INSTALL@STEP2":
        'If not redirector to device setting, please follow "setting > General > Profile"',
      "IOS@INSTALL@STEP3": "Click Install to start installation.",
      "IOS@INSTALL@STEP4": "Please press open to continue.",
      "IOS@INSTALL@STEP5": "Install and proceed.",
      "IOS@INSTALL@STEP6": "You can find app on the desktop.",
      "IOVATION@INVALID": "Device or region invalid.",
      "LOGIN@CHECKBOX": "I am over 18, I agree to the ",
      "LOGIN@CHECKBOX2": "Terms and Condition ",
      "LOGIN@CHECKBOX3": " Privacy Policy",
      "LOGIN@DEFAULT_MESSAGE": "Welcome !",
      "MEMBER@SPORTBOOK_STATEMENT": "Sportsbook Statement",
      "MEMBER@VIRTUALSPORTS_STATEMENT": "VirtualSports Statement",
      "LOGIN@CLICKHERETO": "Click here to",
      "LOGIN@CLICKHERE": "Click here",
      "LOGIN@SIGNUP": "SIGNUP",
      "LOGIN@NOTABLE": "NOT ABLE TO LOGIN?",
      "LOGIN@LOGINID": "Login ID",
      "LOGIN@EXISTINGUSER": "Existing user?",
      "LOGIN@ENTEROTP": "ENTER OTP",
      "LOGIN@NEW_PASSWORD": "ENTER NEW PASSWORD",
      "LOGIN@CONFIRM_NEW_PASSWORD": "CONFIRM NEW PASSWORD",
      "BUTTON@RESETPASSWORD": "RESET PASSWORD",
      "BUTTON@SHOWUSERNAME": "SHOW USERNAME",
      // bbb changed to mpc by ambu
      // "LOGIN@EMAIL": "customercare@bbb.games",
      "LOGIN@DEFAULT": "customercare",
      "LOGIN@EMAIL": "customercare@mpc999.com",
      "LOGIN@MPCEMAIL": "customercare@mpc999.com",
      "LOGIN@9BETSEMAIL": "customercare@9bets.xyz",
      "LOGIN@BBBEMAIL": "customercare@bbb.games",
      "LOGIN@MAHADEVEMAIL": "customercare@mahadevbet.xyz",
      "LOGIN@PARIMATCH": "customercare@parimatch.exchange",
      "LOGIN@ERROR_MESSAGE": "Login Failed !",
      "LOGIN@18YEARS": "Am Over 18 Years Old",
      "LOGIN@ENTERUSERNAME": "Please Enter Your UserName",
      "LOGIN@ENTERPASSWORD": "Please Enter Your Password",
      "LOGIN@LOGGING_IN_MESSAGE": "Logging in...",
      "LOGIN@PASSWORD@LABEL": "Password",
      "LOGIN@PASSWORD@PLACEHOLDER": "Enter Password",
      "LOGIN@PHONE": "+380 932450228",
      "LOGIN@REMEMBER_ACCOUNT": "Remember me",
      "LOGIN@RESTRICTED": "Restricted Territories",
      "LOGIN@SUBMIT": "Login",
      "LOGIN@SUBMIT@UNCHECKED":
        "You cannot proceed until you verify that you are over 18 years old.",
      "LOGIN@TITLE": "Login",
      "LOGIN@ID": "LOGIN ID",
      "LOGIN@MOBILE": "Mobile Number",
      "LOGIN@EMAILID": "Enter Email Id",
      "LOGIN@USERNAME": "LOGIN ID",
      "LOGIN@PASSWORD": "PASSWORD",
      "LOGIN@DEPOSIT": "Deposit",
      "FORGOT@USERNAME": "Forgot Username?",
      "FORGOT@PASSWORD": "Forgot Password?",
      "PASSWORD@BUTTON": "RESET PASSWORD",
      "USERNAME@BUTTON": "SHOW USERNAME",
      "USERNAME@LOGIN": "CONTINUE TO LOGIN",
      "AUTH@OTP": "OTP",
      "AUTH@SENT": "SENT",
      "AUTH@SENDING": "SENDING...",
      "AUTH@VERIFY": "VERIFY",
      "AUTH@VERIFIED": "VERIFIED",
      "AUTH@VERIFYING": "VERIFYING...",
      "AUTH@RESENDOTP": "Not Received OTP?",
      "AUTH@RESEND": "Resend",
      "AUTH@BACK": "BACK",
      "SIGNUP@TITLE": "SIGNUP",
      "SIGNUP@FIRSTNAME": "Firstname",
      "SIGNUP@LASTNAME": "Lastname",
      "SIGNUP@REFERRERCODE": "Promo or referrer code (Optional)",
      "AUTH@EXISTINGUSER": "Existing User?",
      "AUTH@CLICKHERETO": "Click here to",
      "AUTH@CLICKHERE": "CLICK HERE",
      "AUTH@SUBMIT": "SUBMIT",
      "AUTH@NEXT": "NEXT",
      "SIGNUP@ENTERNAME": "ENTER NAME",
      "SIGNUP@ENTERPASSWORD": "ENTER PASSWORD",
      "SIGNUP@ENTERNUMBER": "ENTER MOBILE NUMBER",
      "LOGIN@USERNAME@LABEL": "Username",
      "LOGIN@BALANCE@LABEL": "Bal",
      "LOGIN@USERNAME@PLACEHOLDER": "Enter Username",
      "BONUS@FILTERS1": "All bonus",
      "BONUS@FILTERS2": "first time deposit",
      "BONUS@FILTERS3": "Re-deposit bonus",
      "BONUS@FILTERS4": "Live casino",
      "BONUS@FILTERS5": "Refer a friend",
      "BONUS@FILTERS6": "Slot games",
      "BONUS@FILTERS7": "Sportsbook",
      "BONUS@FILTERS8": "Exchange",
      "MATCHED_ORDER@AVERAGE": "Average",
      "MATCHED_ORDER@CONSOLIDATE": "Consolidated",
      "MATCHED_ORDER@EVENT_DATE": "Event Date",
      "MATCHED_ORDER@TITLE": "Matched Bet",
      "MEMBER@ACCOUNT_STATEMENT": "Account Statement",
      "MEMBER@CASINO_STATEMENT": "Casino Statement",
      "MEMBER@CHANGE_PASSWORD": "Change Password",
      "MEMBER@GOOGLE_AUTHENTICATION": "Authenticator",
      "MEMBER@LOG_OUT": "Log Out",
      "MEMBER@MY_ORDER": "My Bets",
      "MEMBER@P2P_PROFIT_LOSS": "Profit Loss",
      "MEMBER@P2P_STATEMENT": "Casino Statement",
      "MEMBER@PROFIT_LOSS": "Profit and Loss",
      "MEMBER@SPORT_STATEMENT": "Sport Statement",
      "MEMBER@TRANSFER": "Transfer",
      "MEMBER@SECURITY": "Security",
      "MEMBER@MEMBER": "Member",
      "MENU@BACK": "Back",
      "MENU@BETS": "My Bets",
      "MENU@HOME": "Home",
      "MENU@INPLAY": "In Play",
      "LOGIN@INPLAYGAMES": "IN PLAY GAMES",
      "MENU@MARKET": "My Markets",
      "MENU@FAVORITES": "Favorites",
      "MENU@MENU": "Menu",
      "MENU@TEENPATTI": "Exchange Card Games",
      "ONE_CLICK@SAVE": "Save ",
      "ONE_CLICK@TITLE": "1-Click Bet",
      "ONE_CLICK@EDIT": "Edit Stakes",
      ACCEPT_BOOKMAKER_ODDS: "ACCEPT ALL ODDS FOR BM",
      "ORDER@ORDERSLIP":
        "Odds have changed from {requestPrice} to {bestPrice}. Do you wish to continue at the new price?",
      "ORDERSLIP@CANCEL": "Cancel Editing",
      "ORDERSLIP@CANCEL_ALL": "Cancel All",
      "ORDERSLIP@CANCEL_ORDER": "Cancel Order",
      "ORDERSLIP@CLEAR": "Clear",
      "ORDERSLIP@EDIT": "Edit Stakes",
      "ORDERSLIP@EMPTY": "No Bets",
      "ORDERSLIP@PLACE": "Place Bet",
      "ORDERSLIP@PLACE_ALL": "Place All",
      "ORDERSLIP@SAVE": "Save",
      "ORDERSLIP@TITLE": "Bet Slip",
      "PASSWORD@DEFAULT_PASSWORD":
        "For your own safety , please change your password.",
      "PENDING_ORDER@TITLE": "Pending Order",
      "PLATFORM_DESCRIPTION@TEXT":
        "We offer multiple sports and multiple markets. Get the best returns on your bets here!",
      "PLATFORM_INDEX@BUTTON1": "BBB SPORT",
      "PLATFORM_INDEX@BUTTON2": "",
      "PLATFORM_SUBTITLE@SUBTITLE1": "Bet now on multiple sports!",
      "PLATFORM_SUBTITLE@SUBTITLE2": "New markets to bet",
      "PLATFORM_SUBTITLE@SUBTITLE3":
        "Now play in the best casinos available! A newer experience",
      "PLATFORM_TITLE@TITLE1": "fastest odds",
      "PLATFORM_TITLE@TITLE2": "New Betting Experience",
      "PLATFORM_TITLE@TITLE3": "CASINO",
      "PLATFORM_TITLE@TITLE4": "Live Casino",
      "PROFIT_LOSS_TABLE@COMMISSION": "Commission",
      "PROFIT_LOSS_TABLE@MARKET": "Market",
      "PROFIT_LOSS_TABLE@GAMES": "Games",
      "PROFIT_LOSS_TABLE@NET": "Net",
      "PROFIT_LOSS_TABLE@SETTLE_TIME": "Settle Time",
      "PROFIT_LOSS_TABLE@START_TIME": "Start Time",
      "RESOURCEFORM@BETFAIR": "BETFAIR",
      "RESOURCEFORM@BOOKMAKER": "BOOKMAKER",
      "RESOURCEFORM@PARKER": "PARKER",
      "RESOURCEFORM@WICKET": "Wicket",
      "SEARCH@NO_MATCH": "No events found ",
      "SEARCH@PLACEHOLDER": "Search events",
      "SIDEBAR@BACK_TO_BETTING": "Back to Betting",
      "SIDEBAR@FOOTNOTE": "Rules & Regulations © 2019 - 2021",
      "SIDEBAR@FOOTNOTE_KYC": "KYC",
      "SIDEBAR@FOOTNOTE_UNDERAGE": "Underage gambling is an offense",
      "SIDEBAR@QUICK_ACCESS": "Quick Access",
      "SIDEBAR@SPORTS": "Sports",
      "SPORT_STATEMENT_TABLE@ACCOUNT": "ACCOUNT",
      "SPORT_STATEMENT_TABLE@AGENT": "AGENT",
      "SPORT_STATEMENT_TABLE@EVENT": "Event",
      "SPORT_STATEMENT_TABLE@MARKET": "Market",
      "SPORT_STATEMENT_TABLE@PLACEDTIME": "Placed Time  ",
      "SPORT_STATEMENT_TABLE@PLATFORM": "Platform",
      "SPORT_STATEMENT_TABLE@REFID": "Ref Id",
      "SPORT_STATEMENT_TABLE@SELECTION": "Selection ",
      "SPORT_STATEMENT_TABLE@SETTLEDTIME": "Settled Time ",
      "SPORT_STATEMENT_TABLE@STAKE": "Stake: MW/FOW",
      "SPORT_STATEMENT_TABLE@STATUS": "Status",
      "SPORT_STATEMENT_TABLE@WINLOSS": "Win/Loss: MW/FOW",
      "LOGIN@TOPGAMES": "TOP GAMES",
      "SPORT@WORLDCUPFANCY": " WORLD CUP FANCY",
      "SPORT@ALL": "ALL",
      "SPORT@CRICKET": "Cricket",
      "SPORT@BTI": "BTI-Sports",
      "SPORT@FOOTBALL": "Football",
      "SPORT@GREYHOUND_RACING": "Greyhounds",
      "SPORT@HORSE_RACING": "Horses",
      "SPORT@POLITICS": "Politics",
      "SPORT@TENNIS": "Tennis",
      "SPORT@VolleyBall": "Volleyball",
      "SPORT@BASKETBALL": "Basketball",
      "STATEMENT@STATUS@All": "All",
      "STATEMENT@STATUS@BET": "Bet",
      "STATEMENT@STATUS@CANCEL": "Cancel",
      "STATEMENT@STATUS@CONFIRM": "Confirm",
      "STATEMENT@STATUS@DELETE": "Delete",
      "STATEMENT@STATUS@SETTLE": "Settled",
      "STATEMENT@WALLET@FOW": "FOW= Fixed Odds Wallet",
      "STATEMENT@WALLET@MW": "MW= Main Wallet",
      "TRANSFER@MAINWALLET": "Main Wallet",
      "TRANSFER@WACS": "WACS",
      "TRANSFER@AGTEENPATTI": "AgTeenpatti",
      "TRANSFER@SLOTTERY": "SLottery",
      "SECURITY@VERIFICATIONCODE": "Verification Code",
      "BUTTON@VERIFY": "Verify",
      "BUTTON@DISABLEVERIFY": "Disable Two-factor authentication",
      "BUTTON@BACK": "Back",
      "BETSLIP@MIN": "Min:",
      "TABLE@BALL_RUNNING": "Ball Running",
      "TABLE@COMING_SOON": "Coming Soon!",
      "TABLE@IN_PLAY": "In-Play",
      "TABLE@MATCH": "Match",
      "TABLE@PROVIDER": "PROVIDER",
      "TABLE@MAX_BET": "Max Bet",
      "TABLE@MAX_EXPOSURE": "Max Exposure",
      "TABLE@MAX_MARKET": "Max Market",
      "TABLE@NO_CONTENT": "No Content",
      "TABLE@NO_EVENT": "No Event/Game",
      "TABLE@NO_MARKET": "No Market",
      "TABLE@SESSION_RUNS": "Fancy",
      "TABLE@STATE": "State",
      "TABLE@SUSPENDED": "Suspended",
      "TEST@TEST": null,
      "THIRD_PARTY@NOTIFICATION":
        "Please ignore INR in casino. Value of coin is as per the upline.",
      "THIRD_PARTY@NOTIFICATION_HINDI":
        "कृपया कैसीनो में INR को अनदेखा करें। सिक्के का मूल्य आपके द्वारा प्राप्त मूल्य के अनुसार रहेगा।",
      "THIRD_PARTY@NOTIFICATION_NO": "No",
      "THIRD_PARTY@NOTIFICATION_YES": "Yes",
      "THIRD_PARTY@REMIND":
        "Please ignore INR in casino. Value of coin is as per the upline.",
      "THIRD_PARTY@REMIND_YES": "OK , I understand.",
      "THIRD_PARTY@REMIND@INDIAN":
        "à¤à¥à¤ªà¤¯à¤¾ à¤à¥à¤¸à¥à¤¨à¥ à¤®à¥à¤ INR à¤à¥ à¤à¤¨à¤¦à¥à¤à¤¾ à¤à¤°à¥à¤à¥¤ à¤¸à¤¿à¤à¥à¤à¥ à¤à¤¾ à¤®à¥à¤²à¥à¤¯ à¤à¤ªà¤à¥ à¤¦à¥à¤µà¤¾à¤°à¤¾ à¤ªà¥à¤°à¤¾à¤ªà¥à¤¤ à¤®à¥à¤²à¥à¤¯ à¤à¥ à¤à¤¨à¥à¤¸à¤¾à¤° à¤°à¤¹à¥à¤à¤¾à¥¤",
      "TITLE@BBBSPORT": "SPORT",
      "TITLE@BIG_BOSS_2020": "BigBoss 2020",
      "TITLE@BIHAR_ELECTIONS": "Bihar Elections",
      "TITLE@UPELECTION": "UP ELECTION",
      "TITLE@PUNJABELECTION": "PUNJAB ELECTION",
      "TITLE@PSL": "PSL CUP",
      "TITLE@populargames": "POPULAR GAMES",
      "TITLE@SLOTBINGO": "SLOT & BINGO",
      "TITLE@smartsoft": "SMARTSOFT",
        "GAME@VIVO" :"VIVO",
        "GAME@EVOPLAY" :"EVOPLAY",
        "GAME@JILI" :"JILI",
        "GAME@TURBOGAMES" :"TURBOGAMES",
      //

      "SIGNUPOTP@SUCCESS": "OTP sent Successfully",
      "SIGNUPOTP@FAILED": "PhoneNumber already exists",

      // "TITLE@BINGO": "Bingo",
      // terms and policy
      "TITLE@PRIVACY_POLICY": "Privacy Policy",
      "TITLE@DISCONNECTION_POLICY": "Disconnection Policy",
      "TITLE@RESPONSIBLE_GAMBLING": "Responsible Gambling",
      "TITLE@RULES_&_REGULATION": "Rules and Regulation",
      "TITLE@EXCLUSION_POLICY": " Exclusion Policy",
      "TITLE@RESTRICTED_TERRITORIES": "Restricted Territories",
      "TITLE@TERMSPOLICY": "TERMS AND POLICY",
      "TABLE@TODAY": "Today",
      "TABLE@TOMORROW": "Tomorrow",
      "TABLE@UPCOMING": "Upcoming",
      "TABLE@STATUS": "Status",
      "TABLE@CLICK_ON_ODDS_TO_BET": "Click on Odds to BET",
      "TABLE@OPTIONS": "Options",
      "TABLE@BOOST_YOUR_BET": "Boost your BET",
      "TABLE@USE_BOOKMAKER_AND_FANCY": "Use Bookmaker And Fancy",
      "TABLE@TEAMS": "Teams",
      "TITLE@LIVE_DEALER":
        "LIVE DEALER CASINOS BROUGHT TO YOU BY WORLD'S BEST PROVIDERS",
      "TITLE@DISCOVER_SUPER7S": "DISCOVER THE SUPER7S EDGE",
      "TITLE@DISCOVER_IEXCH": "DISCOVER THE IEXCH EDGE",
      "TITLE@DISCOVER_STRIKERS": "DISCOVER THE STRIKERS 365 EDGE",
      "TITLE@DISCOVER_BBBEDGE": "DISCOVER THE YOLO247 EDGE",
      "TITLE@BETDAILYEDGE": "DISCOVER THE BETDAILY EDGE",
      "TITLE@COME_PLAY_RULE": "Come . Play . Rule",
      "TITLE@PLAY": "Play",
      "TITLE@DEMO": "Demo",
      "PROMOTIONS@IPLPOWERPLAY":"IPL Power Play Bonus",
      "PROMOTIONS@IPLPOWERPLAY1":"IPL Betting dhamaka Power Play Bonus",
      "TITLE@PLAY_NOW": "Play Now",
      "TITLE@EXPLORE_NOW": "Explore Now",
      "TITLE@SPORTS": "Sports",
      "TITLE@TOP": "Top",
      "TITLE@BLACKJACK": "Blackjack",
      "TITLE@ROULETTE": "Roulette",
      "TITLE@BACCARAT": "Baccarat",
      "TITLE@DRAGON_TIGER": "Dragon Tiger",
      "TITLE@SIDE_BET_CITY": "Side Bet City",
      "TITLE@INDIAN_GAMES": "Indian Games",
      "TITLE@ANDAR_BAHAR": "Andar Bahar",
      "TITLE@7UP": "7Up And Down",
      "TITLE@DICE_GAMES": "Dice Games",
      "TITLE@SPECIALS": "Specials",
      "TITLE@MY_ACCOUNT": "My Account",
      "TITLE@ACCOUNT_STATEMENT": "Account Statement",
      "TITLE@MY_BETS": "My Bets",
      "TITLE@CASINO_STATEMENT": "Casino Statement",
      "EXCHANGE@INPLAYGAMES": "IN PLAY GAMES",
      "EXCHANGE@CRICKET": "CRICKET",
      "EXCHANGE@FOOTBALL": "FOOTBALL",
      "EXCHANGE@TENNIS": "TENNIS",
      "EXCHANGE@WORLDCUPFANCY": "WORLD CUP FANCY",
      "EVENTS@NO_EVENT": "No Event",
      "EVENTS@EVENT_INVALID": "The event is invalid or closed",
      "EVENTS@GOTO_INPLAY": "Go to In-Play",
      "EVENTS@GOTO_HOME": "Go to Home Page",
      "GLOBAL@SEARCHBYGAMES": "SEARCH BY GAMES",
      "GLOBAL@PROVIDERS": "PROVIDERS",
      "TITLE@DEVICE_TIME": "Device Time",
      "TITLE@PLATFORMBALANCE": "Platform Balance",
      "TITLE@BALANCE": "Balance",
      // "TITLE@DEVICE_TIME":"Device Time",
      "TITLE@STAKES": "Stakes",
      "TITLE@PRESS_HERE": "Press here",
      "TITLE@STEP1": "Step 1 :",
      "TITLE@STEP2": "Step 2 :",
      "TITLE@STEP3": "Step 3 :",
      "TITLE@SETP1_INFO":
        "Download Google Authenticator on your phone directly from AppStore or GooglePlay.",
      "TITLE@SETP2_INFO": "Setup account within Google Authenticator.",
      "TITLE@SETP3_INFO":
        "To get a token in Google Authenticator and enter the verification code down below ",
      "TITLE@NO": "NO",
      "TITLE@YES": "YES",
      "TITLE@MAXBET": "MAX BET : ",
      "TITLE@MAXMARKET": "MAX MKT : ",
      "TITLE@SORTBY": "SORT BY",
      "TITLE@PROVIDER": "PROVIDER",
      "TITLE@REFRESH": "Refresh",
      "TITLE@TRANSFER_TO_MAINWALLET": "Transfer All to Main Wallet",
      "ORDERLIST@SELECTED": "Selected Event:",
      "ORDERLIST@ALL": "All",
      "BETRADAR@FLOAT": "Float",
      "BETRADAR@CLOSE": "Close",
      "APP@SCANQR": "Scan QR code to download the app or click the button",
      "TITLE@ENTER_GOOGLE_AUTHENTICATOR_CODE":
        "Please Enter your Google Authenticator Code",
      "TITLE@SUBMIT": "SUBMIT",
      "TITLE@ENTER_CODE_HERE": "Enter Code Here",
      "FOOT@blog": "Blog",
      "FOOT@news": "News",
      "FOOT@Press": "Press",

      //
      "TITLE@LIGHTTHEME": "LIGHT THEME",
      "TITLE@DARKTHEME": "DARK THEME",
      "TITLE@BIGBASH": "Big Bash",
      "TITLE@BOOKMAKER": "Bookmaker",
      "TITLE@POKER": "Poker",
      "TITLE@SPECIALMARKET": "Spl Market",
      "TITLE@PROMOTIONS": "Promotions",
      "TITLE@Bonus": "Bonus",
      "TITLE@LOYALTY": "Loyalty Plan",
      "TITLE@CASINO": "Casino",
      "TITLE@DELHI": "Delhi Election",
      "TITLE@ESPORTS": "E-Sports",
      "TITLE@EXCHANGE": "Exchange",
      "TITLE@InstantGames": "Instant Games",
      "TITLE@FAVORITE": "Favorites",
      "TITLE@HARYANA": "Haryana Election",
      "TITLE@INPLAY": "In-Play",
      "TITLE@IPL_WINNER_2020": "IPL 2020",
      "TITLE@WORLDCUP": "WORLD CUP WINNER",
      "TITLE@LIVEGAMES": "Live Games",
      "TITLE@VIRTUALGAMES": "Virtual Games",
      "TITLE@Tournaments": "Tournaments",
      "TITLE@SUPERNOWA": "SUPERNOWA",
      "TITLE@JHARKHAND": "Jharkhand Election",
      "TITLE@LIVECASINO": "Live Casino",
      "TITLE@SLOTGAME": "Slot Game",
      "TITLE@PREMIUM_SPORTSBOOK": "PREMIUM SPORTSBOOK",
      "TITLE@BINGO": "Bingo",
      "TITLE@BLACKJACKSIDEBAR": "Black Jack",
      "TITLE@ANDARBAHARSIDEBAR": "Andhar Bahar",
      "TITLE@ROULETTESIDEBAR": "Roulette",
      "TITLE@BACCARATSIDEBAR": "Baccarat",
      "TITLE@CARDGAME": "Card Game",
      "TITLE@SPORT": "SPORTS",
      "TITLE@MAHARASHTRA": "Maharashtra Election",
      "TITLE@P2P": "Poker",
      "TITLE@POLITICS": "Politics",
      "TITLE@POPULAR": "POPULAR",
      // "TITLE@JACKPOT": "jackpot",
      "TITLE@SPECIALEVENT": "Special Market",
      // "TITLE@SPECIALEVENT": "Election",
      "TITLE@TEENPATTI": "Teenpatti",
      "TITLE@TEENPATTIPLUS": "Teenpatti",
      "TITLE@HOTGAMES": "HOT GAMES",
      "TITLE@AVIATOR": "Aviator",
      "TITLE@Plinko": "Plinko X",
      "TITLE@Footballx": "Football X",
      "TITLE@Baloon": "Balloon",
      "TITLE@Mines": "Mines",
      
      "TITLE@LIGHTINGROULETTE": "LIGHTNING ROULETTE",
      "TITLE@TEENPATTI20-20": "TEENPATTI 20-20",
      "TITLE@MonopolyLive": "Monopoly Live",
      "TITLE@LightningDice": "Lightning Dice",
      "TITLE@JetX": "JetX",
      "TITLE@CashShow": "Cash Show",
      "TITLE@roketon": "Rocketon",
      "TITLE@FunkyTimes": "Funky Time",
      "TITLE@crazytime": "Crazy Time",
      "TITLE@DragonTiger": "Dragon Tiger",
      "TITLE@CricketX": "Cricket X",
      "TITLE@RPS": "ROCK PAPER SCISSORS",
      "TITLE@ANDARBAHAR": "AndarBahar",
      "TITLE@US_ELECTION": "US Election",
      "TITLE@VIRTUALSPORTS": "Virtual Sports",
      "TRANSFER@EXCHANAGE_INTO": "Transfer into",
      "TRANSFER@EXCHANGE_NOTE":
        "** Please note that conversion of coins occurs when transferring between {fromPlatform} and {toPlatform}.",
      "UNMATCHED_ORDER@CANCEL": "Cancel",
      "UNMATCHED_ORDER@PLACE_UPDATE": "Update Bet",
      "UNMATCHED_ORDER@TITLE": "Unmatched Bet",
      "UNMATCHED_ORDER@UPDATE": "Update",

      // for new dashboaed

      "TITLE@AccountInfo": "Account Info",
      "TITLE@Edit": "Edit",
      "TITLE@General": "General",
      "TITLE@UserName": "User Name",
      "TITLE@ContactDetails": "Contact Details",
      "TITLE@Email": "Email (Optional)",
      "TITLE@Apps": "Apps",
      "TITLE@TelegramUsername": "Telegram Username",
      "TITLE@SkypeID": "Skype ID",
      "TITLE@WhatsApp": "WhatsApp",
      "TITLE@Referral": "Referral",
      "TITLE@Code": "Code",
      "TITLE@Link": "Link",
      "TITLE@CompleteProfile": "Complete your profile",
      "TITLE@PendingItems": "Pending Items",
      "TITLE@SetWithdrawalPassword": "SetWithdrawalPassword",
      "TITLE@CompleteKYC": "Complete KYC",
      "TITLE@AddBankDetails": "Add Bank Details",
      "TITLE@AddAppDetails": "Add App details",
      "DASHBOARD@DEPOSIT": "Deposit",
      "SIDEBAR@MYDASHBOARD": "My Dashboard",
      "SIDEBAR@PROFILE": "Profile",
      "SIDEBAR@FINANCES": "Finances",
      "SIDEBAR@DEPOSIT": "Deposit",
      "SIDEBAR@ACCOUNTSTATEMENT": "Account Statement",
      "SIDEBAR@VIRTUALSPORTSTATEMENT": "Virtual Sports Statement",
      "TUNOVERStatement@TurnoverDetails": "Turn Over Details",
      "SIDEBAR@TurnoverStatement": "Turnover Statement",
      "TUNOVERStatement@EventName": "Event Name",
      "TUNOVERStatement@MarketName": "Market Name",
      "TUNOVERStatement@Market": "Market",
      "TUNOVERStatement@Winloss": "Win / Loss",
      "TUNOVERStatement@Turnover": "Turnover",
      "TUNOVERStatement@Date": "Date",
      "TUNOVERStatement@SettleTime": "Settle Time",
      "TUNOVERStatement@Platform": "Platform",
      "TUNOVERStatement@Provider": "Provider",
      "TUNOVERStatement@GameName": "Game Name",
      "TUNOVERStatement@BetAmount": "Bet Amount",
      "SIDEBAR@WITHDRAWAL": "Withdrawal",
      "SIDEBAR@HISTORY": "History",
      "SIDEBAR@MYBETS": "My Bets",
      "SIDEBAR@P&L": "Profit & Loss",
      "SIDEBAR@CASINOSTATEMENT": "Casino Statements",
      "SIDEBAR@BONUS": "Bonus",
      "SIDEBAR@BETDETAIL": "Bet Details",
      "SIDEBAR@BANKDETAIL": "Bank Details",
      "SIDEBAR@MESSAGES": "Messages",
      "SIDEBAR@ACCOUNTINFO": "Account Info",
      "SOCIALMEDIA@LinkSocialMedia": "Link Social Media",
      "SIDEBAR@PASSWORD": "Password",
      "SIDEBAR@KYCVERIFICATION": "KYC Verification",
      "DASHBOARD_CURRENTPROFILEPASSWORD@LABEL": "Current Profile Password",
      "DASHBOARD@PASSWORD": "Change Password",
      "DASHBOARD@WITHDRAWALPASSWORD": "Withdrawal",
      "DASHBOARD@BANKDETAILS": "Bank Details",
      "DASHBOARD@KYCVERIFICATION": "KYC Verification",
      "DASHBOARD@MY_ORDER": "My Bets",
      "DASHBOARD@PROFITLOSS": "Profit And Loss",
      "DASHBOARD@CASINOSTMT": "Casino Statement",
      "DASHBOARD@BONUS": "Bonus",
      "SIDEBAR@PROMOTIONS": "Promotions",
      "DASHBOARD@AFFILIATE": "Affiliate",
      "DASHBOARD@CHOOSEDEPOSITTYPE": "Choose Deposit Type",
      "DASHBOARD@MINONESECTION": "(Min One Selection Required)",
      "DASHBOARD@CARDDETAILS": "Card Details",
      "HistoryTable@ReferanceNo": "Reference No",
      "HistoryTable@TimeDate": "Time and Date",
      "Table@Type": "Type",
      "HistoryTable@Description": "Description",
      "HistoryTable@Amount": "Amount",
      "HistoryTable@Status": "Status",
      "HistoryTable@Balance": "Balance",
      "DASHBOARD@HISTORY": "History",
      "Quicklink@Top": "Quick Links",
      "QuickLink@Inplay": "Exchange",
      "QuickLink@Process": "Deposit",
      "QuickLink@History": "History",
      "QuickLink@Message": "Message",
      "DASHBOARD@Transfer": "Wallet Transfer",
      "TITLE@HUNDRED": "The Hundred",
      "ProBonus@Sports": "Sports",
      "ProBonus@Welcome": "welcome bonus",
      "ProBonus@Reload": "Refill bonus",
      "ProBonus@Pregmatic": "Cashback Bonus",
      "ProBonus@Referral": "Referral Bonus",
      "ProBonus@Sub1": "15% Cashback Bonus",
      "ProBonus@Sub3": "Get upto 5% Bonus On Every Deposit",
      "ProBonus@Sub": " Get a 400% First Time Deposit Bonus",
      "ProBonus@Sub4": " Upto 5% Cashback Instantly In Your Wallet",
      "ProBonus@Sub5": " Refer & get 20% of your friends deposit amount",
      "ProBonusButton@CheckStatus": "Check Your Bonus Progress",
      "Bonus@Top": "Bonus",
      "SIDEBAR@Deposit": "Deposit",
      "SIDEBAR@Withdrawal": "Withdrawal",
      "SIDEBAR@History": "History",
      "SIDEBAR@MyBets": "My Bets",
      "SIDEBR@Profit&Loss": "Profit & Loss",
      "SIDEBAR@Casino": "Casino Statement",
      "SIDEBAR@SPORTBOOK_STATEMENT": "Sportsbook Statement",
      "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "Premium Sportsbook Statement",
      "SIDEBAR@STP": "Split The Pot Statement",
      "SIDEBAR@GALAXY": "Galaxsys Statement",
      "SIDEBAR@AVIATRIX": "Aviatrix Statement",
      "SIDEBAR@EVOPLAY": "Evoplay Statement",
      "SIDEBAR@JILI": "Jili Statement",
      "SIDEBAR@TURBOGAMES": "Turbo Games Statement",
      "SIDEBAR@Bonus": "Bonus",
      "SIDEBAR@Msg": "Messages",
      "Messages@View": "View",
      "Messages@UNRead": "Unread",
      "Unread@Msg": "Unread Messages",
      "Support@support": "FAQ",
      "SOCIALMEDIA@LinkAcc": "Choose The Account to Link",
      "SOCIALMEDIA@LinkFast":
        "Link your social media accounts for faster login",
      "SIDEBAR@Linksocial": "Link Social Media",
      "DASHBOARD@ProfilePAssword": "Account Password",
      "DASHBOARD@Withdrwal": "Withdrawal Password",
      "DASHBOARD@reset": "Reset",
      "DASHBOARD@submit": "Submit",
      "DASHBOARD@DocReset": "Reset",
      "SIDEBAR@Bonusdetails": "Bonus",
      "BreadCrumb@Homepage": "Home Page",

      "SOCIALMEDIA@AddNewBank": " + Add New Bank Account",

      "QuickLink@livecasino": "Live Casino",

      "Progress@BasicDetails": "Basic Details",
      "Progress@Document": "Document Submission",
      "Progress@Review": "Review",
      "Progress@Approval": "Approval",

      "DASHBOARD@KYCThankMsg":
        "Thank you for applying! We have received your Documents and will be reviewing it shortly.",
      "DASHBOARD@KYCThankMsgApproved":
        "We reviewed your documents and now its verified! you can continue your withdrawal process.",
      "DASHBOARD@KYCThankMsgRejected": "Reason: Document is not matching.",
      "DASHBOARD@chooseDocs": "Choose any one document to upload ",
      "DASHBOARD@chooseMinimumOne": "(Min one document required)",
      "DASHBOARD@Docsdetail": "Document detail (No special character allowed)",
      "DASHBOARD@DocUpload": "Upload Document",
      "DASHBOARD@DocUploadAfter": "Uploaded Document",
      "DASHBOARD@DocSize": "(Maximum size of image 6 MB)",
      "DASHBOARD@DocSubmit": "Submit",
      "dashboard@toolotip": "For any quries contact 24/7 support",

      "Document@AadharCard": "Aadhar Card",
      "Document@PanCard": "Pan Card",
      "Document@DrivingLicense": "Driving License",
      "Document@Passport": "Passport",

      "Kyc@status": "KYC",
      "DASHBOARD@DefaultAcc": "Default Account",
      "DASHBOARD@DefaultUPI": "Default UPI",
      "DASHBOARD@Remarks": "Remarks",
      "DASHBOARD@edit": "Edit",
      "DASHBOARD@delete": "Delete",

      "DASHBOARD@Deleteconfirm": "Do You Really Want To “Delete” this Account?",
      "DASHBOARD@Deleteconfirm1": "This cannot be Undone.",

      "Confirm@heading": "Are You Sure?",
      "Confirm@Delete": "Delete",
      "Confirm@Cancel": "Cancel",
      "DASHBOARD@Messageshow":
        "For security reasons, withdrawal is only allowed account owner. If you have any issues, do not hesitate to contact our",
      "DASHBOARD@customrService": "Customer Service",
      "DASHBOARD@Reset": "Reset",
      "DASHBOARD@UpdateAcc": "Update Account",
      "DASHBOARD@AddAcc": "Add Account",
      "DASHBOARD@sendOtp": "Send OTP",

      "Payment@UpiPayment": "Regular UPI",
      "Payment@NetBanking": "Net Banking",
      "Paymnt@E-wallet": "E-Wallet",
      "Channel@ChannelA": "Channel A",
      "DASHBOARD@Transacton": "Transaction Only Between",
      "DASHBOARD@NoOfTransaction": "Times of transactions per day",
      "DASHBOARD@BankDetail": "Bank detail",
      "DASHBOARD@forgotPassword": "Forgot Password ?",
      "DASHBOARD@ChangeAddAcc": "Add Account",

      "DASHBOARD@reminder": "Note:",
      "DASHBOARD@reminder1": "Fields With ( * ) Are mandatory",
      "DASHBOARD@reminder2": "Withdrawal Limit:",
      "DASHBOARD@reminder3":
        "Once You Complete The Submission, Please Contact Customer Service For Approval Time.",
      "DASHBOARD@reminder4":
        "If You Have Any Issues, Do Not Hesitate To Contact Our Customer Service.",

      "HISTORY@All": "All",
      "HISTORY@deposit": "Deposit",
      "HISTORY@withdraw": "Withdrawal",
      "PROMOTIONS@ALL": "All",
      "PROMOTIONS@INPROCESS": "In Process",
      "PROMOTIONS@COMPLETED": "Completed",
      "Promotions@Amount": "Bonus Amount",
      "Promotions@Reqdate": "Request Date",
      "Promotions@Exdate": "Expire Date",
      "Promotions@status": "Status",
      "Promotions@Turnover": "Turnover Needed",
      "Promotions@Terms": "Terms",
      "Promotions@CANCEL": "CANCEL BONUS",
      "Promotions@COMPLETED": "COMPLETED",
      "Promotions@RELEASED": "RELEASED",
      "Promotions@EXPIRED": "EXPIRED",
      "Promotions@PROCESS": "IN PROGRESS",
      "Promotions@QUEUED": "QUEUED",
      "Promotions@Details": "Details",
      "Promotions@Close": "Close",
      "Promotions@1stReferral": "1st Deposit Referral bonus",
      "Promotions@2ndReferral": "2nd Deposit Referral bonus",
      "Promotions@3rdReferral": "3rd Deposit Referral bonus",
      "Promotions@TOTALAmnt": "You have won ₹",
      "Promotions@TOTALAmnts": "bonus so far !",
      "Promotions@WINBONUS": "Make a Deposit And win bonus instantly",
      "Promotions@RefereeName": "Referee Name",
      "Promotions@RefereeHurry": "HURRAY !",
      "Promotions@ReferralCode": "Your Referral Code",
      "Promotions@ReferralFriend": "Your Friend",
      "Promotions@DailyDepositMsg": "DAILY DEPOSIT BONUS OF",
      "Promotions@DailyDepositMsg2": "INCLUDED",
      "Promotions@IplDailyCashbackMsg": "PROMO CODE BONUS OF",
      "Promotions@IplDailyCashbackMsg2": "INCLUDED",
      "Promotions@TopChoiceCashbackMsg": "TOP CHOICE BONUS INCLUDED",
      "Promotions@TopChoiceCashbackMsg2": "TOP CHOICE BONUS INCLUDED,PROMO CODE BONUS OF",
      "Promotions@ReferralDesc":
        "Has signed up, We are waiting for Their first deposit to activate your 10% referral bonus and up to 400% welcome bonus for your friend",
      "Promotions@ReferralDescSTD":
        "Has signed up, We are waiting for Their second deposit to activate your 5% referral bonus and and get a redeposit bonus for your friend",
      "Promotions@ReferralDescTTD":
        "Has signed up, We are waiting for Their third deposit to activate your 5% referral bonus and and get a redeposit bonus for your friend",
      "Promotions@RefereMessageSecondStart": "The second deposit by User :  ",
      "Promotions@RefereMessageSecondEnd": "is pending",
      "Promotions@RefereMessageThirdStart": "The Third deposit by User :  ",
      "Promotions@RefereMessageThirdEnd": "is pending",
      "Promotions@ReferralMessageTooltipSecond":
        "We are awaiting your friend's second deposit in order to activate the 5% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
      "Promotions@ReferralMessageTooltipThird":
        "We are awaiting your friend's third deposit in order to activate the 5% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
      "DASHBOARD@status": "Status",
      "Pagination@Transaction": "Transaction",
      "SIDEBR@ProfitLoss": "Profit & Loss",

      "MY_ORDER@PAST": "Past Bet",
      "MY_ORDER@CURRENT": "Current Bet",
      "Withdrawal@Complete": "Complete Your KYC!",
      "Withdrawal@Completekyc":
        "KYC Is Required If You Have Any Questions, Contact Our Customer Service.",
      "Withdrawal@GoToKYC": "Go To KYC Page",
      "DASHBOARD@channelA": "Channel A",
      "DASHBOARD@channelB": "Channel B",
      "Dashboard@SubTxt": "Min/max: ", //₹ 500 / ₹ 1,000
      "Dashboard@SubTxt1": "Min/max: ",
      "MEMBER@Dashboard": "Member Dashboard",
      scanQRCode: "Scan QR Code",
      "Deposit@scanQRCode": "Scan & Pay",
      "MENU@GOONTURN": "Go On, it's your turn",
      "MENU@PLAYANDNEVER": "play and never grow old",
      "MENU@IPLCUP": "Make your trick, Hat-trick ",
      "MENU@ENJOY": "Enjoy never-ending fun",
      "MENU@ExploreALL": "Explore all our promotion bonus on sports & casino",
      __ts: 1612422167784,

      "CREATE@USERNAME": "Create Username",
      "CREATE@PASSWORD": "Create Password",
      "CREATE@PHONENUMBER": "Enter Mobile Number",
      "TITLE@instagram": "Instagram",
      "MENU@SPORTBOOK": "Sportsbook",
      "SUBMENU@IPLCUP2022": "IPL CUP 2022",
      "SUBMENU@TEENPATTI2020": "TEENPATTI 2020",
      "TopGames@DownloadAPP": "APP",
      "TITLE@BetSlipTittle": "Bet Slip",
      "Header@Affiliate": "Affiliate",
      setWithdrawalPassword: "Set Withdrawal Password",
      "TITLE@BLUETIER": "Blue Tier",
      "TITLE@SILVERTIER": "Silver Tier",
      "TITLE@GOLDTIER": "Gold Tier",
      "TITLE@PLATINUMTIER": "Platinum Tier",
      "TITLE@ELITETIER": "Elite Tier",
      "TEXT@turnover": "Turnover",
      "TEXT@NA": "NA",
      "LOYALTY@RULESREGULATIONS": "Rules And Regulations",
      "BUTTON@KnowMoreBtn": "Know More",
      "Aviator@KnowMore2": "Know More",
      "BONUS@WELCOMEBONUS": "WELCOME BONUS",

      "LOYALTY@RULESTEXT1":
        "*If Turnover is below the required amount over a period of 1 month, then the player will be automatically downgraded to the prior tier, for example, if you are a gold tier member and you are unable to maintain a turnover of 40L over a period of 1 month, your loyalty program tier will shift to Silver & so on.",
      "LOYALTY@RULESTEXT2":
        "*There will be no change in withdrawal amount terms & conditions",
      "LOYALTY@RULESTEXT3":
        "  *The company reserves the right to cancel the bonus and refuse to grant it to the client without explaining the reasons if during the check of the gaming account, there are suspicions of violation of the fairness of the game or the use of strategies that the company at its discretion considers to be malicious. In disputable situations, the decision of the authorized officials of the company is final.",
      "LOYALTY@RULESTEXT4":
        " *In case of violation of the rules, foul play or other fraudulent activities, all such accounts will be excluded from participation",
      "LOYALTY@RULESTEXT5":
        "*This offer is subject to the general rules of YOLO247",
      "LOYALTY@RULESTEXT6": " *2 % Bonus will be awarded on every redeposit",
      "LOYALTY@RULESTEXT7": " *2% cash back will be awarded on weekly losses",
      "LOYALTY@RULESTEXT8":
        " *Additional cashback will be awareded as per the chosen loyalty program tier",

      //VIRTUAL SPORTS MENU TITLE
      "VIRTUALSPORTSMENUTITLE@CRICKET": "Cricket",
      "VIRTUALSPORTSMENUTITLE@WC": "BUNDESLIGA",
      "VIRTUALSPORTSMENUTITLE@worldcup": "world cup",
      "VIRTUALSPORTSMENUTITLE@EL": "English League",
      "VIRTUALSPORTSMENUTITLE@EUROLEAGUE": "Euro League",
      "VIRTUALSPORTSMENUTITLE@TENNIS": "Tennis",
      "VIRTUALSPORTSMENUTITLE@BASKETBALL": "Basket Ball",
      "VIRTUALSPORTSMENUTITLE@ENGLISHLEAGUE": "English League",
      "VIRTUALSPORTSMENUTITLE@FOOTER": "Get Your Freedom Here",

      "PROMOTIONSTITLE@REFILL": "Refill Bonus",
      "PROMOTIONSTITLE@CASHBACK": "Cashback Bonus",
      "PROMOTIONSTITLE@WELCOME": "Welcome Bonus",
      "ACCOUNT@BLUETIER": "BLUE TIER",
     "MaxWDlimit": "maximum withdrawal limit per day: ",
     "MaxWDlimitAllowed":"Maximum withdrawal allowed per transaction: ",
     "MaxWDlimitAllowedMonth":"Maximum withdrawal transactions allowed per month: ",
      "SIDEBAR@UPIDETAIL": "UPI Details",

      //PROMOTIONS(BONUS)
      "PROMOTIONS@WELCOMEBONS": "WELCOME BONUS",
      "PROMOTIONS@REFILLBONUS": "REFILL BONUS",
      "PROMOTIONS@CASHBACKBONUS": "CASHBACK BONUS",
      "PROMOTIONSWELCOME@FIRSTLINE":
        "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
      "PROMOTIONSWELCOME@SECONDLINE": "WHAT IS WELCOME BONUS?",
      "PROMOTIONSWELCOME@SECONDLINEINFO":
        "You are eligible for a 400% welcome bonus on your 1st deposit amount, if you make your first deposit within 30 minutes of signing up. Even after 30 minutes, you are still eligible for a massive 300% welcome bonus. So how do you get to claim this bonus? Just follow this ONE simple step:",
      "PROMOTIONSWELCOME@FAQ": "FAQ",
      "PROMOTIONSWELCOME@HOWTOGET": "HOW TO GET THIS BONUS?",
      "PROMOTIONSWELCOME@STEPS":
        "Step 1: Complete your RO Requirement of 20X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well read further below to understand.",
      "PROMOTIONSWELCOME@EXAMPLE": "Here Is An Example",
      "PROMOTIONSWELCOME@LI": "a) Your first deposit amount = Rs. 1,000",
      "PROMOTIONSWELCOME@LI2": "b) Hence your bonus of 300% = Rs. 3,000",
      "PROMOTIONSWELCOME@LI3":
        "c) You will need to complete an RO requirement of 24X your bonus amount, which is Rs. 3,000 * 24 = Rs. 72,000. Once you have achieved a valid RO requirement of Rs. 72,000, the Rs. 3,000 bonus amount will be added to your wallet instantly.",
      "PROMOTIONSWELCOME@RO": "WHAT IS RO REQUIREMENT?",
      "PROMOTIONSWELCOME@ROINFO":
        "Well RO means Roll Over, which in essence means you need to complete a certain valid roll over to avail your bonus.The valid RO calculation is as follows:",
      "PROMOTIONSWELCOME@ROINFO1":
        "So here is an example: you placed a bet of 100 rs in roulette on black color. So your stake is: 100 Rs",
      "PROMOTIONSWELCOME@ROEXMPLE": "Now there are 3 scenarios:",
      "PROMOTIONSWELCOME@ROEXMPLE1":
        "a) You win Rs.100 So Profit & Loss = Rs.100 So valid RO = (|100|/100) = 100% Hence your valid RO = Rs. 100",
      "PROMOTIONSWELCOME@ROEXMPLE2":
        "b) You lose Rs.100 So Profit & Loss = Rs. -100 So valid RO = (|-100|/100) = 100% Hence your valid RO = Rs. 100",
      "PROMOTIONSWELCOME@ROEXMPLE3":
        "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 30%.",
      "PROMOTIONSWELCOME@TERMS": "TERMS AND CONDITIONS",

      "PROMOTIONSWELCOME@TERMS1":
        "This promotion is valid only for all new registered players for the First Successful Deposit.",
      "PROMOTIONSWELCOME@TERMS2":
        "Bonus amount will be issued into your Main wallet after the completion of turnover requirements.",
      "PROMOTIONSWELCOME@TERMS3":
        "This promotion is only available once per player.",
      "PROMOTIONSWELCOME@TERMS14":
        "Welcome bonus is capped at a maximum of 24,000 INR",
      "PROMOTIONSWELCOME@TERMS4":
        "The bonus amount must be rolled over 20 (Twenty) times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",
      "PROMOTIONSWELCOME@TERMS5":
        "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",
      "PROMOTIONSWELCOME@TERMS6":
        "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 30%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 30%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",
      "PROMOTIONSWELCOME@TERMS7":
        "Void/Canceled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus turnover requirements.",
      "PROMOTIONSWELCOME@TERMS8":
        " Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
      "PROMOTIONSWELCOME@TERMS9":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for,any other promotion.",
      "PROMOTIONSWELCOME@TERMS10":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
      "PROMOTIONSWELCOME@TERMS11":
        " Bonus amount cannot be withdrawn but the winnings from the bonus are withdrawable.",
      "PROMOTIONSWELCOME@TERMS12":
        " In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
      "PROMOTIONSWELCOME@TERMS13": " Yolo247 General Terms & Conditions apply.",
      "PROMOTIONSWELCOME@TERMSp2p":
        "Rollover is not valid for any of the P2P games",
      "PROMOTIONS@COMMON":
        "Only one bonus is allowed per customer, family, address, shared computer, shared IP address, any identical account details including e-mail address, bank account details, credit card infomation and payment system account. Any misuse of this bonus offer will lead to an account being closed",
      "DASHBOARD@PromotionsAndBonus": "Promotions and Bonus",
      "DASHBOARD@KNOWMORE": "Know More",

      // refill(promotions)

      "PROMOTIONSREFILL@REFILLBONUS": "REFILL BONUS",
      "PROMOTIONSREFILL@REFILLBONUS1":
        "The first and only platform in the country to now offer bonuses on every deposit ",
      "PROMOTIONSREFILL@REFILLBONUS2":
        "We at Yolo247 strongly value and care for our loyal customers and thus you deserve more! At Yolo247 now with every deposit earn a little more and gain an edge to win more, and thus with every new deposit is a new opportunity! Yolo247 wishes to welcome you back on every deposit with upto 5% extra bonus (for Platinum members)!!",
      "PROMOTIONSREFILL@REFILLBONUS3": "What is a Refill BONUS?",

      "PROMOTIONSREFILL@REFILLBONUS4":
        "You are eligible for a 5% refill bonus on every re-deposit that you do . So let's understand how it works …",
      "PROMOTIONSREFILL@REFILLSTEPS": "1.) Login to your Yolo247 account",
      "PROMOTIONSREFILL@REFILLSTEPS2":
        "2.) Apart from your first deposit, every deposit henceforth you will receive 5% extra balance in your main wallet instantly after you have successfully deposited.",
      "PROMOTIONSREFILL@REFILLSTEPS3":
        "3.) If you use Fast Bank for your deposits, you will get an additional 5% extra bonus across all tiers. So Platinum players get a 10% refill bonus!",
      "PROMOTIONSREFILL@REFILLSTEPSLETSPLAY": "Come play!",
      "PROMOTIONSREFILL@TERMS1":
        " Bonus amount will be issued into your Main wallet after your successful redeposit.",
      "PROMOTIONSREFILL@aded1": "Cashback bonus expires every 48 hours.",
      "PROMOTIONSREFILL@TERMS2":
        "This promotion is available on every re-deposit and every bonus amount received during the day shall expire within 24 hours.",
      "PROMOTIONSREFILL@EXAMPLE": "Example:",
      "PROMOTIONSREFILL@TERM":
        "a.) 1st Redeposit is done on 31st May at 10 am of 1000 INR so you will receive a 20 Rs Bonus,thus totaling to 1020 Rs in your account. Now let’s say you make another deposit of 2000 INR at 3PM on the same day so then you will receive a 40 Rs bonus totaling your wallet to 2040 Rs. On 1st June midnight at 11:59:59PM IST the bonus amount of 60 Rs which was given throughout the day will expire and will no longer be available in your main wallet.",
      "PROMOTIONSREFILL@2EXAMPLE":
        "b.) Another example: Say you re-deposited 100,000 on the 15th of September at 1:00 pm, the refill bonus of 2,000 INR (for blue tier), will expire at 11:59:59 pm of 16th September. So, this bonus will be active for a total of 35 hours in this case.",
      "PROMOTIONSREFILL@TERMS3":
        " Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "PROMOTIONSREFILL@TERMS4":
        " Yolo247 reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "PROMOTIONSREFILL@TERMS5":
        " Bonus amount cannot be withdrawn but the winnings from the bonus are withdrawable.",
      "PROMOTIONSREFILL@TERMS6": " Yolo247 General Terms & Conditions apply",
      "PROMOTIONSREFILL@TERMSp2p":
        "Rollover Is Not Valid For Any Of The P2P Games",

      "PROMOTIONS@RefillNewHead0": "Rejuvenate with Every Redeposit - 5% Refill Bonus on Yolo247",
      "PROMOTIONS@RefillNewHead1": "Redeposit and Get up to 5% Refill Bonus",
      "PROMOTIONSREFILL@RefillNewDesc1": "Special players need special attention, and that is why we are offering up to 5% refill bonus on every redeposit",
      "PROMOTIONS@RefillNewDesc101": "The first and only platform in the country to provide a bonus on every deposit.",
      "PROMOTIONS@RefillNewDesc102": "Users get a 5% refill bonus (Platinum members) on every deposit.",
      "PROMOTIONS@RefillNewDesc103": "The percentage of refill bonus for non-platinum members depends on their tier.",
      "PROMOTIONS@RefillNewHead2": "What is Refill Bonus and How to Get It?",
      "PROMOTIONS@RefillNewDesc2": "A refill bonus is a bonus that a user gets on every deposit after the first one. To understand how and when the bonus is credited to your main wallet, look at the following steps:",
      "PROMOTIONS@RefillNewDesc201": "Login to your Yolo247 account through a website or Yolo247 app.",
      "PROMOTIONS@RefillNewDesc202": "For every deposit after the first deposit, a user will get up to a 5% refill bonus that will be added in his/her main wallet instantly.",
      "PROMOTIONS@RefillNewHead3": "Who is eligible for the Yolo247 Refill Bonus?",
      "PROMOTIONS@RefillNewDesc301": "Yolo247 users who are redepositing using the “highlighted channels” will be eligible for the refill bonus. The percentage of the refill bonus will depend on the user's tier level.",
      "PROMOTIONS@RefillNewDesc302": "Users, who are using the non-highlighted channels, will not be eligible for the refill bonus.",
      "PROMOTIONS@RefillNewDesc303": "In the Fast UPI payment mode: All users will get the refill bonus.",
      "PROMOTIONS@RefillNewDesc304": "During your process of redepositing through Regular UPI payment mode, you can find the highlighted channels",
      "PROMOTIONS@RefillNewHead4": "Benefits of Yolo247 Refill Bonus",
      "PROMOTIONS@RefillNewDesc4": "A user will experience an array of benefits when it comes to a refill bonus, which are as follows:",
      "PROMOTIONS@RefillNewDesc401": "Users get extra motivation to keep playing as they get up to a 5% bonus on every redeposit.",
      "PROMOTIONS@RefillNewDesc402": "Using this bonus, players can try different games, sharpen their casino game skills, and increase their chances of winning.",
      "PROMOTIONS@RefillNewTermsHead": "Terms and Conditions of Refill Bonus on Yolo247",
      "PROMOTIONS@RefillNewTermsDesc1": "Only one bonus is allowed per customer, address, shared computer, shared IP address, and any identical account details that include e-mail address, bank account details, credit card information, and payment system account. Any misuse of this bonus will lead to an account being closed.",
      "PROMOTIONS@RefillNewTermsDesc2": "Only users, who are using the highlighted channels, will be eligible for the refill bonus.",
      "PROMOTIONS@RefillNewTermsDesc3": "Bonus amount is credited to the main wallet of a user after every successful redeposit.",
      "PROMOTIONS@RefillNewTermsDesc4": "This promotion is available on every redeposit, and every bonus amount received during the day will expire at 12 AM IST the next day.",
      "PROMOTIONS@RefillNewTermsDesc5": "Example: 1st redeposit was done by the user on 21st September at 9 AM of ₹1,000. So, now the user will receive a ₹20 bonus; thus, the total amount in a user's account will be ₹1020. Now, consider that a user makes another deposit of ₹2000 at 2 PM on the same day. So now, a user will receive a bonus of ₹40. On 22nd September midnight at 11:59:59 PM the bonus amount of ₹60 that was given to the user will expire.",
      "PROMOTIONS@RefillNewTermsDesc6": "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if foul play takes place.",
      "PROMOTIONS@RefillNewTermsDesc7": "Yolo247 reserves the right to amend, cancel, and reclaim or refuse any promotions at its own discretion.",
      "PROMOTIONS@RefillNewTermsDesc8": "Bonus amount cannot be withdrawn, but the winnings from the bonus are withdrawable.",
      "PROMOTIONS@RefillNewTermsDesc9": "Yolo247's general terms and conditions apply.",
      "PROMOTIONS@RefillNewTermsDesc10": "Rollover is not valid for any of the P2P games.",

      //PROMOTION CASHBACK
      "PROMOTIONSCASHBACK@1STLINE":
        "Yolo247 offers a 2% cashback bonus instantly in your wallet On Your weekly losses",
      "PROMOTIONSCASHBACK@2NDLINE": "What is Cash-Back Bonus?",
      "PROMOTIONSCASHBACK@3RDLINE":
        "Wouldn’t it be awesome if you could reduce your losses? Here at Yolo247, now get a 5% cashback bonus instantly in your wallet on your weekly losses.",
      "PROMOTIONSCASHBACK@4THLINE":
        "Why don’t I show you an example to make this easier.",
      "PROMOTIONSCASHBACK@5THLINE":
        "Say you lost Rs. 10,000 in the last week (every week is counted Monday 00:00:01 AM to Sunday 23:59:59 PM), you will be credited 5% of your weekly losses, which is Rs. 500 in this example to your main wallet instantly.",
      "PROMOTIONSCASHBACK@TERM1":
        "Bonus amount will be issued into your Main wallet on monday which will calculate your weekly losses.",
      "PROMOTIONSCASHBACK@TERM2":
        "  This promotion is available on your weekly losses and the bonus will be decided by one’s overall Profit & Loss on the platform to avail this bonus.",
      "PROMOTIONSCASHBACK@TERM7": "4) Cashback bonus expires every 7 days.",
      "PROMOTIONSCASHBACK@TERM3":
        "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "PROMOTIONSCASHBACK@TERM4":
        " Yolo247 reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "PROMOTIONSCASHBACK@TERM5":
        " Bonus amount cannot be withdrawn but the winnings from the bonus are withdrawable.",
      "PROMOTIONSCASHBACK@TERMS6": " Yolo247 General Terms & Conditions apply",
      "PROMOTIONSCASHBACK@TERMSp2p":
        "Rollover is not valid for any of the P2P games",
      "TITLE@Save": "Save",
      "TITLE@LOYALTYTAGLINE": "YOU ONLY LIVE ONCE. PLAY MORE, EARN MORE !",
      "TITLE@PROMOTIONSTAGLINE": "A LITTLE EXTRA NEVER HURTS!",
      "PROMOTIONSTITLE@SIGNUP": "Signup Bonus",
      "PROMOTIONS@REFERRl": "Referral Bonus",

      //referal bonus

      "PROMOTIONS@REFERRAL": "REFERRAL BONUS",
      "PROMOTIONS@REFERRALHEAD":
        "Introducing Yolo247 Refer-A-Friend Program: A Win-Win Opportunity!",
      "PROMOTIONS@SIGNUPBONUS": "SIGNUP BONUS",
      "PROMOTIONSREFERRAL@1st":
        "Imagine a world where everyone could have the privilege of being your friend",
      "PROMOTIONSREFERRAL@2":
        "Well, we believe that exceptional friendships deserve exceptional rewards. ",
      "PROMOTIONSREFERRAL@3":
        "That's why we've created our exciting Refer-A-Friend program, the best online betting & casino referral bonus, where you and your friends can benefit together.",
      "PROMOTIONSREFERRAL@4":
        "Yolo247 Referral Code is open to all users, both new and old. Get to know how Referral Bonus works. ",
      "PROMOTIONSREFERRAL@5":
        "You refer a friend he signs up with YOLO247 using your referral link and does a deposit of lets assume 10,000 INR then in that case you are eligible for 500 INR Bonus after your friend completes 3 times RO of his deposit account.",
      "PROMOTIONSREFERRAL@6":
        "How Yolo247 Referral Bonus works? | Yolo247 Referral Code",
      "PROMOTIONSREFERRAL@7":
        "1. Bring your friends on board and join us for an unforgettable gaming experience.",
      "PROMOTIONSREFERRAL@8":
        "2. When your friends sign up with YOLO247 using your unique referral link or code (Yolo247 Referral Code), the fun begins & you activate the Yolo247 Referral Bonus process.",
      "PROMOTIONSREFERRAL@9":
        "3. Each time your referred friend makes a deposit, you'll receive a bonus based on a generous percentage structure:",
      "PROMOTIONSREFERRAL@91": "10% bonus on their first deposit",
      "PROMOTIONSREFERRAL@92": "5% bonus on their second deposit",
      "PROMOTIONSREFERRAL@93": "5% bonus on their third deposit",
      PROMOTIONSExampleHead: "Let's break it down with an example:",
      "PROMOTIONSREFERRAL@10":
        "Suppose you refer a friend who decides to embark on this thrilling journey with YOLO247.",
      "PROMOTIONSREFERRAL@101":
        "Your friend signs up and makes a total deposit (first three deposits) of, let's say, 30,000 INR.",
      "PROMOTIONSREFERRAL@102":
        "Upon meeting the 3X RO requirement of their deposit amount, you become eligible for a fantastic 6,000 INR bonus!",
      "PROMOTIONS@Referafriend":
        "How to refer a friend & win the best online casino referral bonus? ",
      "PROMOTIONS@Referafriend01":
        "1.	Share your unique Referral Code/URL with your friend and encourage them to join YOLO247. ",
      "PROMOTIONS@Referafriend02":
        "2.	Your friend should register on YOLO247 using your Referral Code to ensure you receive credit for this Online Casino Referral Bonus.  ",
      "PROMOTIONS@Referafriend03":
        "3.	Once your friend completes their initial three deposits, the excitement builds up. ",
      "PROMOTIONS@Referafriend04":
        "4.	After your friend fulfills the 3X RO requirement, you will receive a referral bonus equivalent to 20% (10%, 5% & 5% respectively) ",
      "PROMOTIONS@Referafriend05":
        "5.	Join the YOLO247 community and take advantage of this incredible opportunity to share the fun and reap the rewards together. Start referring your friends today and unlock a world of thrilling experiences! ",
      "PROMOTIONSREFERRAL@11":
        "So, let’s assume your friend deposits 20,000 INR and he completes 3X RO of his deposits amount i.e., he successfully completes valid RO bets of 60,000 INR then you will receive 10% bonus of his first deposit amount i.e., 2,000 INR. ",
      "PROMOTIONSREFERRAL@RO1":
        "So, let’s assume your friend deposits 20,000 INR and he completes 3X RO of his deposits amount i.e., he successfully completes valid RO bets of 60,000 INR then you will receive 10% bonus of his first deposit amount i.e., 2,000 INR. ",
      "PROMOTIONSREFERRAL@R02":
        "Now Your Friend Deposits 10,000 INR For The Second Time And Successfully Completes Valid RO Bets Of 30,000 INR Then You Will Receive 5% Bonus Of His Second Deposit Amount I.E., 500 INR",
      "PROMOTIONSREFERRAL@R03":
        "Now your friend deposits 5,000 INR for the third time and successfully completes valid RO bets of 15,000 INR then you will receive 5% bonus of his third deposit amount i.e., 250 INR",
      "PROMOTIONSREFERRAL@R04":
        "Hence Totalling To A Bonus Amount Of Rs. 2,750. This Is How You Can Make Use Of This Best Online Betting Referral Bonus. ",
      "PROMOTIONSREFERRAL@R0END":
        "You may also check out our Yolo247 Welcome Bonus. ",
      "PROMOTIONSREFERRAL@RO": "What is RO Requirement for Referral Bonus?",
      "PROMOTIONSREFERRAL@TERM1":
        "Referral bonuses will be awarded for the first three deposits made by the referred user.",
      "PROMOTIONSREFERRAL@TERM2":
        "Only one referral bonus will be active at a time. For instance, if the referral bonus for the first deposit is active, the bonuses for the second and third deposits will be queued and processed subsequently.",
      "PROMOTIONSREFERRAL@TERM3":
        "Bonus amount will be issued into your Main wallet after the completion of first deposit and turnover requirements by the referee. Similarly for second & third deposit.",
      "PROMOTIONSREFERRAL@TERM4":
        "The deposit amount must be rolled over 3 (Three) times. Turnover generated anywhere across the platform will be calculated towards the turnover requirements. ",
      "PROMOTIONSREFERRAL@TERM5":
        "The referral bonus will expire after 7 days from the date of activation. The validity period for the referral bonus will be 3 months. If the user did not make any deposits within this period, the bonus scheme will automatically expire.",
      "PROMOTIONSREFERRAL@TERM6":
        "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%. The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So, you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%. Please note that in exchange, match odds provided by Betfair and multiple bookmakers offered in exchange will be considered as 1 market.",
      "PROMOTIONSREFERRAL@TERM7":
        "Void/Canceled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus turnover requirements.",
      "PROMOTIONSREFERRAL@TERM8":
        "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.  Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion. ",
      "PROMOTIONSREFERRAL@TERM9":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
      "PROMOTIONSREFERRAL@TERM":
        "Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",
      "PROMOTIONSREFERRAL@TERM10":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
      "PROMOTIONSREFERRAL@TERM11":
        "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
      "PROMOTIONSREFERRAL@TERM12": "Yolo247 General Terms & Conditions apply.",
      " PROMOTIONSIGNUPBONUS1":
        "Q. Can I withdraw sign up bonus and the winnings through it?",
      PROMOTIONSIGNUPBONUS2:
        "A. What a legend! Thank you for signing up! Sign up bonus or the winnings obtained through it cannot be withdrawn. Sign up is for you to explore the platform.",

      // LOYALTY COMPONENT
      "LOYALTY@BLUETIER1ST": "2% bonus on every redeposit",
      "LOYALTY@BLUETIER2ND": "2% cashback bonus on weekly loses",
      "LOYALTY@BLUETIER3RD":
        "No min turnover amount required to be in blue tier",
      "LOYALTY@BLUETIER4TH":
        "No Turnover Conditions, Direct Bonus In Your Wallet And No Maximum Limit",
      "LOYALTY@SILVERTIER1ST": "3% bonus on every redeposit",
      "LOYALTY@SILVERTIER2ND": "3% cashback bonus on weekly loses",
      "LOYALTY@SILVERTIER3RD":
        "Min turnover amount of 25L/per month required to be in silver tier",
      "LOYALTY@GOLDTIER1ST": "4% bonus on every redeposit",
      "LOYALTY@GOLDTIER2ND": "4% cashback bonus on weekly loses",
      "LOYALTY@GOLDTIER3D": "Two exclusive event invites",
      "LOYALTY@GOLDTIER3RD":
        "Min turnover amount of 40L required to be in gold tier",
      "LOYALTY@PLATIUNUM1ST": "5% bonus on every redeposit",
      "LOYALTY@PLATIUNUM2ND": "5% cashback bonus on weekly loses",
      "LOYALTY@PLATIUNUM3RD":
        "Celebrity dinner invite Five Exclusive event invites All inclusive foriegn trip for two Min turnover amount of 1CR required to be in platinum tier",
      "TEXT@INVITE": "By invitation only",

      "DASHBOARD@ChooseChannelText": "Choose one channel",
      "DASHBOARD@noChanners":
        "Our deposit channels are busy. Please try later, or contact customer service.",
      "DASHBOARD@CHOOSECHANNEL": "Choose Channel",

      "LOYALTY@GOLDTIER4":
        "Min Turnover Amount Of 40L/Per Month Required To Be In Gold Tier",
      "LOYALTY@PLATIUNUM3": "Celebrity Dinner Invite",
      "LOYALTY@PLATIUNUM4": "Five Exclusive Event Invites",
      "LOYALTY@PLATIUNUM5": "All Inclusive Foriegn Trip For Two",
      "LOYALTY@PLATIUNUM6":
      "Min Turnover Amount Of 1CR/Per Month Required To Be In Platinum Tier",
      "LOYALTY@Elite": "Elite users' terms will be",
      "LOYALTY@Elite1": "discussed by their",
      "LOYALTY@Elite2": "respective Relationship Manager",

      "MANUALDEPOSIT@Heading": "Easy 4 Steps to deposit",
      "MANUALDEPOSIT@YoloBank": " YOLO247 Bank Details",
      "MANUALDEPOSIT@YoloBankTransaction": " ENTER TRANSACTION DETAILS",
      "MANUALDEPOSIT@YoloBankNew": "Copy this bank details and make payment",
      "MANUALDEPOSIT@YoloBankAcc": "Account Number",
      "MANUALDEPOSIT@YoloBankAccHolder": "Account holder",
      "MANUALDEPOSIT@YoloBankName": "Bank name",
      "MANUALDEPOSIT@YoloIFSC": "IFSC Code",
      "Transaction ID": "Transaction ID",
      "MANUALDEPOSIT@YoloBankuploadRecipt": "upload payment reciept",
      "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
      "MANUALDEPOSIT@YoloBankVerifyPayment": "Verify Payment Details",
      "MANUALDEP@DocSubmit": "(Maximum Size Of The Image 5MB)",
      "MANUALDEPOSIT@MinDeposit": "Min Deposit",
      "MANUALDEPOSIT@MaxDeposit": "Max Deposit",
      "Payment@MD": "Fast Bank",
      "DEPOSIT@NOTE":
        "NOTE: In case there’s a technical bank error on one channel, please try another channel.",
      "deposit@tips":
        "Our customers details including money are always safe. For any assistance, please reach out to our efficient customer care team",
      "DEPOSIT@BONUSNOTE":
        "NOTE: Bonus Will Be Credited After Deposit Is processed Into Your Account",
      "Paymnt@Fast-UPI": "Fast UPI",
      "MANUALDEPOSIT@PleaseVerify":
        "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. YOLO247  WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",
      "MANUALDEPOSIT@ThedailyDepositBonusIsApplicable":
        "IT IS PREFERRED TO USE IMPS/RTGS/NEFT FOR FAST BANK TRANSFERS",
      "MANUALDEPOSIT@IfYourDeposit":
        "IF YOUR DEPOSIT AMOUNT DOES NOT MATCH THE REQUESTED AMOUNT, IT IS CONSIDERED CHEATING AND THE FUNDS WILL BE WITHHELD. EG: IF YOU DEPOSIT ₹500 AND WRITE ₹1000 IN THE DEPOSIT REQUEST, THE DEPOSIT AMOUNT WILL NOT BE CREDITED TO YOUR WALLET OR REVERSED TO YOUR ACCOUNT.",
      "MANUALDEPOSIT@UPINEW": "Payment UPI Details",
      "MANUALDEPOSIT@UPIName": "UPI NAME : ",
      "MANUALDEPOSIT@UPIOption": "Click here for qR code",
      "MANUALDEPOSIT@UPIID": "YOLO247 UPI IDs (Select One)",
      "MANUALDEPOSIT@UPI": "UPI ID",
      //AccountInfoTierComponent
      "TIER@CurrentTurnOver": "Your turnover for this month is",
      "TIER@TierName": "Name",
      "TIER@TierContactNumber": "Contact Number",
      "TIER@TierEmail": "Email",
      "TIER@TierStartedText": "Started Blue",
      "TIER@TierEndText": "₹5L to Silver",
      "TIER@FinishedBlue": "Finished Blue",
      "TIER@FinishedSilver": "Finished Silver",
      "TIER@PlatinumLevel": "You have reached platinum level!",
      "TIER@₹25LtoGold": "₹25L to Gold",
      "TIER@₹1CrtoPlatinum": "₹1Cr to Platinum",
      "DASHBOARD@WITHDRAWALADDBANK": "Add Bank",
      "DASHBOARD@WITHDRAWALVIEWBANK": "View Bank",
      "HEADER@VIEWALL": "View All",
      "DASHBOARD@WITHDRAWALFAQ": "FAQ",
      "DASHBOARD@ADDUPI": "Add UPI",
      "DASHBOARD@ADDUPIACC": "+ ADD NEW UPI ID",
      "DASHBOARD@VIEWUPI": "View UPI",
      "DASHBOARD@WithdrawPROMOTIONS": "Promotions",
      "DASHBOARD@WithdrawalNote": "Note:",
      "DASHBOARD@WithdrawalNote1":
        "to withdraw your remaining balance, turnover of ₹",
      "DASHBOARD@WithdrawalNote13":
        "Maximum withdrawal allowed Transactions per Month = ₹ 25,00,000",
      "DASHBOARD@WithdrawalNote11": "has to be completed",
      "Select@speeed": "Select Speed",
      "Insta@WD": "Insta Withdrawal",
      "Normal@WD": "Normal Withdrawal",
      "Avilable@WD": "Available Withdrawal",
      "Bonus@WD": "withdrawal blocked due to bonus",
      "Pending@WD": "Withdrawal Blocked Due to pending turnover",
      "PendingTooltip@WD":
        "This RO requirment has to completed to withdraw full balance",
      "BonusTooltip@WD":
        "The winnings earned from the bonus can be withdrawn, but the bonus itself is for playing purposes only and cannot be withdrawn.",
      "PendingDesc@WD":
        "this RO requirment has to completed to withdraw full balance",
      "DASHBOARD@WithdrawalNoteNormalW": "Withdrawal Limit: ",
      "asdiscussed":'as Discussed',
      "DASHBOARD@MWithdrawalNoteNormalW": "Minimum Withdrawal Limit: ",
      "DASHBOARD@WithdrawalNoteInstaW": "Withdrawal Limit: ",
      "DASHBOARD@WithdrawalNoteInstaUPIW": "Insta Withdrawal UPI Limit: ",
      "DASHBOARD@WithdrawalNote4":
        "Members are allowed One free withdrawal per day. A nominal service charge will be applied starting from the  Second withdrawal onwards, based on your Tier.",
      "TIER@Finished": "Finished",
      "DASHBOARD@WithdrawalNoteExtra": "Withdrawal fees per day:",
      "ELITE@NOTE":'The discussed T&C’s will be sent on your inbox message as a confirmation',
      "TIER@MostValuableCustomer": "YOU ARE OUR MOST VALUABLE CUSTOMER!",
      "TITLE@ASIACUP": "ASIA CUP",
      "TITLE@bigbash": "BIG BASH",
      "TITLE@WORLDCUPT20": "WORLD CUP T20",
      "PROMOTIONSWB@WELCOMEB":
        "In other words, bets must be with odds of at least Decimal 1.30 to be contributed towards bonus rollover requirements.",
      "PROMOTIONS@CATCH99": "CATCH99 S2",
      "PROMOTIONS@CATCH99Qoute": "fortune favors",
      "PROMOTIONS@CATCH99Qoute1": "the bold",
      "PROMOTIONS@CATCH99Qoute2": "go bold",
      "PROMOTIONS@CATCH99Qoute3": "with gold",
      "PROMOTIONS@JetxCelestial": "JetX Celestial Rush",
      "PROMOTIONS@JetSetWithJetX": "Jet Set with JetX",
      "PROMOTIONS@WomensWorldCup": "Cricket World Cup - Women's",
      "PROMOTIONS@CricketCashbackMarathon": "Cricket cashback marathon",
      "PROMOTIONS@CrazyTimeEpicBonanza": "Crazy Time Epic Bonanza",
      "PROMOTIONS@AviatrixDoubleDhamaka": "AVIATRIX DOUBLE DHAMAKA",
      "PROMOTIONS@AviatorHighFlyer": "Aviator High Flyer",
      "PROMOTIONS@Smartsoft": "Smart soft : Spin & Win Fest",
      "PROMOTIONS@SmartSoftJetxCelestial": "Smart soft : Spin & Win",
      "PROMOTIONS@MinesLegendTrophy": "Mines Legend Trophy",
      "PROMOTIONS@CaribbeanLeagueBonus": "Caribbean Cricket Dhamaka",
      "PROMOTIONS@EvolutionRouletteCup": "Evolution Roulette Cup",
      "PROMOTIONS@AviatrixFlyAndPlay": "Aviatrix – Fly & PLAY",
      "PROMOTIONS@AviatrixJetToCrash": "Aviatrix – JET TO CASH, MAKE A SPLASH",
      "PROMOTIONS@BigBashBigCash": "Big Bash, Big Cash",
      "PROMOTIONS@SuperSlotsChallenge": "Super Slots Challenge",
      "PROMOTIONS@AviatorRainPromo": "Aviator Rain",
      "PROMOTIONS@AviatorSkyBoundMiles": "Aviator : Sky Bound Miles",
      "PROMOTIONS@FomoWorldCupLossBackExch": "100% Loss back for WC Exchange Bets",
      "PROMOTIONS@FomoWorldCupGoIndiaGoJackpot": "Go India Go Jackpot: INR 50 Lacs!",
      "PROMOTIONS@FomoAviatorWingsOfAdventure": "Aviator – Wings of Adventure!",
      "PROMOTIONS@FomoEvolutionCasinoChallenge": "Evoution Casino Challenge!",
      "PROMOTIONS@FomoAviatrixFlyHigh": "Aviatrix – Fly High & Win!",
      "PROMOTIONS@FomoWorldCupDailyDesposit": "Daily deposit bonus",
      "PROMOTIONS@FomoLossBackBonus": "Lossback bonus",
      "PROMOTIONS@WorldCupLossbackBonus": "100% lossback for WC Exchange Bets",
      "PROMOTIONS@WorldCupDoubleCashbackBonus": "Double Cashback Bonus!",
      "PROMOTIONS@WorldCupDailyDepositBonus": "DAILY DEPOSIT Bonus!",
      "PROMOTIONS@WorldCupEvolutionCasinoCupBonus": "Evolution casino cup",
      "PROMOTIONS@WorldCupDoubleCashbackBonusBazzi": "10% Double Cashback",
      "PROMOTIONS@EvolutionCasinoTourneyBazzi": "Evolution Casino Tourney!",
      "PROMOTIONS@DailyRewardsBazzi": "Daily Rewards",
      "PROMOTIONS@AviatrixFBE": "Aviatrix – Free Bets Extravaganza",
      "PROMOTIONS@IndianGalaxsysLeague": "Indian Galaxsys League",
      "PROMOTIONS@EvolutionCasionCup": "Evolution Casino Cup",
      "PROMOTIONS@DoubleCashback": "Ipl: Free Hit Cashback",
      "Catch99@Howtoenter": "How to enter",
      "Catch99@thejackpot": "the jackpot",
      "Catch99@Step1": "Step1: REGISTER",
      "Catch99@Step1TextContent":
        "Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.",
      "Catch99@Step2":
        "Step2: Deposit using any method and start playing your favourite games!",
      "Catch99@Step2TextContent":
        "Deposit & play each bet worth Rs.990/- or more from 25th September till 25th October’ 22 and stand a chance to win the jackpot prize of 990 grams Gold/-",
      "Catch99@TermsTittle": "Terms and Conditions:",
      "Catch99@TermsHeadContent":
        "The More you play with Rs.990 and above, more the chances to win the Jackpot. Winners will be notified on their inbox of Yolo247 and on Yolo247’s social media pages. Leader board will be updated every week and the lucky draw will be on 29 th October via live session on Instagram page!",
      "Catch99@TermsConditionPointsFirst":
        "1. Jackpot – 990 grams Gold: 1 winner",
      "Catch99@TermsConditionPointsFirstSub":
        "1 lucky winner will get a chance to go home with 990 grams of Gold! The player needs to earn 1,000 Chips to enter this Jackpot. He/she will earn 1,000 chips only if they meet the requirement of betting more than or equal to Rs. 990,000 on or before the 25th of October.",
      "Catch99@TermsConditionPointsSecond": "2. Win 99 grams Gold: 10 winners",
      "Catch99@TermsConditionPointsSecondSub":
        "The Player needs to earn 750 chips to enter the Jackpot. The player will earn 750 chips only if he/she meets the requirement of betting more than or equal to Rs.742,500 on or before the 25th of October.",
      "Catch99@TermsConditionPointsThird": "3. 9 grams Gold: 25 winners",
      "Catch99@TermsConditionPointsThirdSub":
        "The player needs to earn 500 Chips to enter the Jackpot. The player will earn 500 chips only if he/she meets the requirement of betting more than or equal to Rs.4,95,000 on or before the 25th of October.",
      "Catch99@TermsConditionPointsFourth": "4. Win Rs.9,900: 99 winners",
      "Catch99@TermsConditionPointsFourthSub":
        "The player needs to earn 250 Chips to enter the Jackpot. The player will earn 250 chips only if he/she meets the requirement of betting more than or equal to Rs.2,47,500 on or before the 25th of October. This amount will be credited to the players wallet at the end of the campaign.",
      "Catch99@TermsConditionPointsFifth": "5. Win Rs.990: 990 winners",
      "Catch99@TermsConditionPointsFifthSub":
        "The player needs to earn 100 Chips to enter the Jackpot. The player will earn 100 chips only if he/she meets the requirement of betting more than or equal to Rs.99,000 on or before the 25th of October. This amount will be credited to the players wallet at the end of the campaign.",
      "Catch99@GeneralTermsConditionTittle": "General Terms and conditions:",
      "Catch99@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "Catch99@GeneralTermsConditionSecond":
        "Catch99 is valid from 25th september'22 till 25th October'22 as per GMT+5:30. Winners will be announced on 29th October'22",
      "Catch99@GeneralTermsConditionThird":
        "Bets must be with odds of at least Decimal 1.30 to be considered as valid",
      "Catch99@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "Catch99@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "Catch99@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
      "Catch99@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "Catch99@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      "Catch99@GeneralTermsConditionNinth":
        "Only one bonus is allowed per customer, family, address, shared computer, shared IP address, any identical account details including e-mail address, bank account details, credit card infomation and payment system account. Any misuse of this bonus offer will lead to an account being closed.",
      "Whatsapp@whatsappText": "Get an ID instantly",
      "Whatsapp@whatsappTextsub": "Click here now",
      "Deposit@FastUpiDeposit": "Fast UPI Deposit",
      "Deposit@FastBankDeposit": "Fast Bank Deposit",
      "Deposit@RegularUpiDeposit": "Regular UPI Deposit",
      "Deposit@NetBankingDeposit": "Net Banking Deposit",
      "bigboss@bb": "BIGG BOSS",
      "DEPOSIT@Attention": "Attention Users",
      "DEPOSIT@Attentionnote":
        "Our bank and UPI DETAILS MAY NOT BE THE SAME on your next deposit. ALWAYS, make payment to the bank and upi accounts shown on our deposit page",
      "MANUALDEPOSIT@SelectOption": "Select Option",
      "GAME@MPLAY": "MPLAY",
      "GAME@7MOJO": "7MOJOS",
      "GAME@BTG": "BTG",
      "GAME@NLCGAMES": "NO LIMIT CITY",
      "GAME@REDTIGERGAES": "RED TIGER",
      "FIFA@WC": "FIFA WORLD CUP",
      "PROMOTIONS@AviatorBonus": "AVIATOR SKYBOUND MILES",
      "PROMOTIONS@BBLBonus": "BBL: BIG BASH !",
      "PROMOTIONS@AviatorQoute":
        "Its time to Get High & hence we are here with Aviator Bonanza Pool of INR 250,000 !!",
      "PROMOTIONS@AviatorQoute1": "Hence we are here with",
      "PROMOTIONS@AviatorQoute2": "Aviator Bonanza",
      "PROMOTIONS@AviatorQoute3": "Pool of",
      "PROMOTIONS@AviatorQoute4": "INR 250,000 !!",
      "Aviator@KnowMore": "Know More",
      "Aviator@Allyouneedtodo":
        "All you need to do is  place minimum 5,000 bets on Aviator from ",
      "Aviator@15thNov": "18th Nov-18th Dec",
      "Aviator@AndYoucanStand":
        "and you can stand a chance to win exciting benefits!",
      "Aviator@TheAviatorbonusisvalid":
        "The Aviator bonus is valid for minimum 1.30 cashout,",
      "Aviator@uptoasHigh": "up to as High as you can go!",
      "Aviator@StartplayingAviatornow":
        "Start playing Aviator now And start flying! Keep an eye on the in-game leader boards to track your progress & 247 lucky players stand a chance to WIN! Results will be declared on YOLO247 website & social media on",
      "Aviator@18thDEC2022": "20th DEC 2022!",
      "Aviator@Howtoenter": "How to Enter the Get High with Aviator program?",
      "Aviator@Step1": "Register",
      "Aviator@Step1TextContent":
        "Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.",
      "Aviator@Step2":
        "Deposit using any method and start playing your favourite games!",
      "Aviator@Step2TextContent":
        "Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 250,000 pool!",
      "Aviator@ReadMoreAbout": "Read more about the offer below:",
      "Aviator@20LuckyWinner":
        "20 lucky winner will get a chance to win INR 5,000 each and free loyalty tier upgrade at YOLO247 which will get you more benefits like additional reload & cashback while playing at YOLO247. The player needs to place minimum of 15,001 or more bets  to enter this offer held during 18th Nov-18th Dec 2022.",
      "Aviator@Aviator2ndPriceText":
        "40 lucky winner will get a chance to win INR 2,500 each and free loyalty tier upgrade at YOLO247 which will get you more benefits like additional reload & cashback while playing at YOLO247. The player needs to place minimum of 10,001 -15,000 bets to enter this offer held during 18th Nov-18th Dec 2022",
      "Aviator@Aviator3rdPriceText":
        "70 lucky winner will get a chance to win INR 1,250 each and free loyalty tier upgrade at YOLO 247 which will get you more benefits like additional reload & cashback while playing at YOLO247. The player needs to place minimum of 5,001-10,000 bets to enter this offer held during 18th Nov-18th Dec 2022.",
      "Aviator@Aviator4thPriceText":
        "117 lucky winner will get a chance to win INR 250 each and free loyalty tier upgrade at YOLO247 which will get you  more benefits like additional reload & cashback while playing at YOLO247. The player needs to place minimum of 5,000 bets  to enter this offer held during 18th Nov-18th Dec 2022.",
      "Aviator@GeneralTermsConditionTittle": "Terms & Conditions:",
      "Aviator@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "jetx@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "Aviator@GeneralTermsConditionSecond":
        "‘Fly High with Aviator’ is valid from",
      "Aviator@GeneralTermsConditionSecondValidFrom":
        "18th November to 18th December 22.",
      "Aviator@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on",
      "Aviator@GeneralTermsConditionSecondValidOn": "20th December 22",
      "Aviator@GeneralTermsConditionThird":
        "The Aviator bonus is valid for minimum 1.30 cash out",
      "Aviator@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "Aviator@GeneralTermsConditionFifth":
        " Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "Aviator@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
      "Aviator@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "Aviator@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      "TITLE@MARQUE": "Yolo247 - Beyond Games",
      "TITLE@betdailyMarquee": "Betdaily - ",
     "TITLE@MARQUE1":
        "Your Bonuses, Auto activated - Explore your entertainment partner now !!Check out our bonus page for exciting rewards.",
      "FifaFever@Step1": "Register",
      "jetx@Step1": "Register",
      "FifaFever@Step2":
        "Deposit using any method and start playing your favourite games!",
      "PROMOTIONS@FIFAFEVER": "FIFA FEVER",
      "PROMOTIONS@FIFADESC":
        "F.I.F.A 2022 is here & we are ready to roll at YOLO247, Bringing to you the",
      "PROMOTIONS@FIFADESC1": "FIFA world cup pool of ₹ 9,900,000 !",
      "PROMOTIONS@BBLBonusDesc":
        "Big Bash League is here & its time to make big bonus only at YOLO247!! You can win big bonus pool prize of",
      "PROMOTIONS@BBLBonusDesc1": "INR 1,729,000 at YOLO247 SPECIAL MARKET!!",
      "PROMOTIONS@FIFAINNERFIRSTHEAD": "How to Enter the FIFA FEVER?",
      "PROMOTIONS@jetxt":
        "Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.",
      "PROMOTIONS@FIFAINNERFIRSTStep1":
        "Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.",
      "PROMOTIONS@FIFAINNERFIRSTStep2":
        "Deposit & play each bet worth Rs.1,000/- or more to win the FIFA pool prize of INR 9,900,000",
      "PROMOTIONS@FIFAINNERFIRSTFoot":
        "All you need to do is place minimum bets of INR 1,000 on 2022 FIFA World Cup matches at min odds of 1.30 from 20th NOV to 18th DEC 2022.",
      "PROMOTIONS@FIFAINNERSECONDHEAD": "Read more about the offer below:",
      "PROMOTIONS@FIFAINNERSECONDTABLETITLE":
        "Each placed bet of INR 1,000 or above will help you to earn 100 jackpot coins. Keep an eye on the boards (Instagram URL) to track your progress & 115 lucky players stand a chance to WIN INR 9,900,000. Results will be declared on YOLO247 website & social media on 20th DEC 2022!",
      "FifaFever@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "FifaFever@GeneralTermsConditionSecond": "FIFA FEVER is valid from ",
      "FifaFever@GeneralTermsConditionSecondValidFrom":
        "20th November to 18th December 22. ",
      "FifaFever@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on ",
      "FifaFever@GeneralTermsConditionSecondValidOn": "20th December’22",
      "FifaFever@GeneralTermsConditionThird":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "FifaFever@GeneralTermsConditionFourth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "FifaFever@GeneralTermsConditionFifth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
      "FifaFever@GeneralTermsConditionSixth":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "FifaFever@GeneralTermsConditionSeventh":
        "Yolo247 General Terms & Conditions apply.",
      "GAME@KA-GAMES": "KA GAMING",
      "PROMOTIONS@1LuckyWinner":
        "1 lucky winner will get a chance to win 15 lakh Indian rupees.The player(s) needs to earn 10,000 coins to enter this Jackpot  during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help  you to earn 100 jackpot coins",
      "PROMOTIONS@2LuckyWinner":
        "2 lucky winner will get a chance to win 10 lakh Indian rupees each. The player(s) needs to earn 9,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@3LuckyWinner":
        "3 lucky winner will get a chance to win 5 lakh Indian rupees each. The player(s) needs to earn 8,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@4LuckyWinner":
        "4 lucky winner will get a chance to win 2.5 lakh Indian rupees each. The player(s) needs to earn 7,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@5LuckyWinner":
        "5 lucky winner will get a chance to win 1.5 lakh Indian rupees each. The player(s) needs to earn 6,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@10LuckyWinner":
        "10 lucky winner will get a chance to win 1 lakh Indian rupees each. The player(s) needs to earn 5,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@15LuckyWinner":
        "15 lucky winner will get a chance to win 50 thousand Indian rupees each. The player(s) needs to earn 4,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@20LuckyWinner":
        "20 lucky winner will get a chance to win 50 thousand Indian rupees each. The player(s) needs to earn 3,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@25LuckyWinner":
        "25 lucky winner will get a chance to win 25 thousand Indian rupees each. The player(s) needs to earn 2,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "PROMOTIONS@30LuckyWinner":
        "30 lucky winner will get a chance to win 10 thousand Indian rupees each. The player(s) needs to earn 1,000 coins to enter this Jackpot during the FIFA world cup held from 20th NOV to 18th DEC 2022. Each placed bet of INR 1,000 or higher amount will help you to earn 100 jackpot coins",
      "page@yoloAppDownload": "Yolo App Download",
      "YoloAppDwnld@DownloadApp": "Download",
      "YoloAppDwnld@App": "App",
      "YoloAppDwnld@for": "For",
      "YoloAppDwnld@BrandName": "YOLO",
      "YoloAppDwnld@BrandNameLast": "247",
      "YoloAppDwnld@Android": "Android",
      "YoloAppDwnld@TheYoloAppIsAvailable":
        "The YOLO App is available as a free download for Android devices. The app supports English and Hindi. Download the latest version of the App, register your account.",
      "YoloAppDwnld@Download": "Download",
      "YoloAppDwnld@ScreenShot": "Screenshots",
      "YoloAppDwnld@TakeALookAtTheScreenshot":
        "Take a look at the screenshots of the Yolo App interface below.",
      "YoloAppDwnld@ScanTheQr": "Scan the QR",
      "YoloAppDwnld@AndDownloadTheApp": "and download the app or click",
      "YoloAppDwnld@AppBenefitsFor": "Yolo App Benefits For",
      "YoloAppDwnld@MobileUsers": "Mobile Users",
      "YoloAppDwnld@InOrderForYouBet":
        "In order for you to bet not only at home but also in any place where there is the Internet, the YOLO betting app has developed a convenient application. The app works very well for all Android systems. Consider the main advantages of the application",
      "YoloAppDwnld@BenefitsStep1":
        "The application can be used wherever there is a stable Internet",
      "YoloAppDwnld@BenefitsStep2":
        "The application is already downloaded on your device, so it loads faster and requires less data.",
      "YoloAppDwnld@BenefitsStep3":
        "The application has a minimalistic and simple interface, so you can quickly figure out how it works.",
      "YoloAppDwnld@BenefitsStep4":
        "For users of the application, various bonuses and promotions have been invented.",
      "YoloAppDwnld@BenefitsStep5":
        "You can control the application with just one finger.",
      "YoloAppDwnld@BenefitsStep6":
        "All data is synchronized with all versions of the site.",
      "YoloAppDwnld@BenefitsStep7":
        "The application is always available and does not interrupt your activities during maintenance.",
      "YoloAppDwnld@HowTo": "How To ",
      "YoloAppDwnld@DownloadInHowTo": "Download",
      "YoloAppDwnld@AndInstall": "And Install?",
      "YoloAppDwnld@TechnicallyTheYolo247Apk":
        "Technically, the YOLO247 APK completely copies the official website and repeats its design and features. You can download and install it directly through our site. Just follow a few steps described below.",
      "YoloAppDwnld@ClickOn": "Click On The ",
      "YoloAppDwnld@ClickOnMenu": "Menu",
      "YoloAppDwnld@ClickOnMenuOptions": "Click On The Menu Option",
      "YoloAppDwnld@ClickTheMenu":
        "Click the “Menu” button at the bottom of the screen.",
      "YoloAppDwnld@DownloadTheAppFile": "Download The App File",
      "YoloAppDwnld@ClickTheDownldAndroid":
        "Click the “Download Android App” button at the bottom of the screen.",
      "YoloAppDwnld@WaitForTheFile": "Wait For The Yolo App To Download",
      "YoloAppDwnld@OnceTheProcessIs":
        "Once the process is complete, start the installation.",
      "YoloAppDwnld@InstallTheYoloApp":
        "Install The Yolo App On Your Smartphone",
      "YoloAppDwnld@WaitForTheInsatalationprocess":
        "Wait for the installation process to complete, then open it via a shortcut on your Mobile.",
      "BBL@InnertextLeft":
        "is here & its time to make big bonus only at YOLO247!! You can win big bonus pool prize of INR 1,729,000 at YOLO247 SPECIAL MARKET & also stand a chance to win special cashback bonus on your losses during all 8 weeks of this action-packed T20 tournament!",
      "BBl@InnerTextLeft1":
        ": Earn jackpot pool bonus of INR 1,729,000 by simply predicting the team you feel will win BBL. Results will be declared on YOLO247 social media Instagram handle on 5TH FEB 2023!",
      "BBl@InnertextLeft2":
        "  : Earn upto 100% special cashback bonus on your weekly losses for the bets placed in this tournament. Results will be declared on YOLO247 social media Instagram handle every week for cashback winners.",
      "Bbl@InnerTextLeftHead": "Big Bash League",
      "Bbl@InnerTextLeftHead1": "Big bash big bonus ",
      "Bbl@InnerTextLeftHead2": "Big bash big cashback",
      "BBl@MiddleHead": "How to Enter",
      "BBl@MiddleHead2": "BBBB contest?",
      "BBL@MiddleDepositStepHead": "Deposit",
      "BBl@MiddleDepositStep":
        "Deposit using any method and start playing BBL!",
      "BBL@CardsTitle1": "Big bonus Special market",
      "BBL@CardsTitle2": "Big bash big cashback",
      "BBL@CardsTitle3": "24 lucky",
      "BBL@CardsTitle4": "47 lucky",
      "BBL@CardsTitle5": "247 lucky",
      "BBL@CardsSubText1": "1,729,000 INR :",
      "BBL@CardsSubText11": "7 winners",
      "BBL@CardsSubText2": "318 winners : ",
      "BBL@CardsSubText21": "upto 100% cashback",
      "BBL@CardsSubText3": "upto 100% cashback :",
      "BBL@CardsSubText31": "24 winners",
      "BBL@CardsSubText4": "upto 50% cashback :",
      "BBL@CardsSubText41": "47 winners",
      "BBL@CardsSubText5": "surprize cashback :",
      "BBL@CardsSubText51": "247 winners",
      "BBL@CardsDescMain1":
        "7 lucky winner will get a chance to win INR 247,000 each !!",
      "BBL@CardsDescSub1":
        " All you need to do is place bet on the team you feel will win BBL at YOLO247 Special markets. Minimum bet to enter this contest is 2470 INR. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on YOLO247 social media Instagram handle on 5TH FEB 2023!",
      "BBL@CardsDescMain2":
        "318 winners can earn upto 100% cashback bonus every week from 13th Dec’22 to 4th Feb’23 !!",
      "BBL@CardsDescSub2":
        " All you need to do is place bet on the team you feel will win BBL at YOLO247 Special markets. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on YOLO247 social media Instagram handle on 5TH FEB 2023!",
      "BBL@CardsDescMain3":
        "winners will stand a chance to win 100% cash back bonus every week!!!!",
      "BBL@CardsDescSub3":
        " All you need to do is place 10 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday . Results will also be declared on YOLO247 social media Instagram handle every week.",
      "BBL@CardsDescMain4":
        "winners will get a chance to win 50% cash back bonus every week!",
      "BBL@CardsDescSub4":
        "All you need to do is place 5 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday. Results will also be declared on YOLO247 social media Instagram handle every week.",
      "BBL@CardsDescMain5":
        "winners will get a chance to win surprize cashback bonuses every week!",
      "BBl@CardsDescSub5":
        "All you need to do is place bets worth minimum INR 500 in BBL tournament every week to enter this contest. Lucky winners will receive this surprize bonus amount in their wallet every Monday.",
      "BBL@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "BBL@GeneralTermsConditionSecond": "This promotion is valid during the ",
      "BBL@GeneralTermsCOnditionsSecondEnd": "Big Bash League season",
      BBLGeneralTermsCOnditionsSecondValidFromYear: "2022/2023",
      "BBL@GeneralTermsConditionSecondValidFrom": "(13th DEC’22 - 4th FEB’23).",
      "BBL@GeneralTermsConditionThird":
        "Bets must be with odds of at least Decimal 1.30 to be considered as valid",
      "BBL@GeneralTermsConditionFourth":
        " Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements",
      "BBL@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion",
      "BBL@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
      "BBL@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion",
      "BBL@GeneralTermsConditionEightth":
        " Yolo247 General Terms & Conditions apply",
      "Deposit@promo": "Deposit Now",
      "arjith@promohead":
        "Play & Win Tickets to ARIJIT SINGH’S concert & Sing along!",
      "arjith@promoheadP":
        "Now every deposit on YOLO247 will not only get you additional redeposit bonus (as per loyalty program) but now you and your special one will also stand a chance to win tickets for the Arijit Singh’s concert on the 27th January 2023 in Pune!",
      "arjith@list1":
        "The top depositors in each category will stand a chance to win tickets as per the given slab",
      "arjith@list2":
        "To enter the contest, please email your username & location to ",
      "arjith@extra": " with subject: Yolo247 concert",
      "arjith@list3":
        "Results will be declared on 21st JAN 2023 in your Yolo247 inbox and your email!",
      "arjith@term1": "This promotion is valid for all new & existing players",
      "arjith@term2": "This promotion is valid from 28th DEC – 18th JAN 2023",
      "arjith@term3":
        "Multiple accounts will not be considered for this promotion eligibility/criteria.All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion",
      "arjith@term4":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion",
      "arjith@term5": "Yolo247 General Terms & Conditions apply",
      "PROMOTIONS@singYolo": "SING WITH YOLO",
      "PROMOTIONS@singYolo1":
        " Win exciting 1+1: Diamond, Platinum, Yolo247 Gold,Silver & Bronze tickets for this concert by simply entering our CONTEST below from 28th Dec - 18th Jan 2023!",
      "Jetx@GeneralTermsConditionSecond":
        "This promotion is valid for all new & existing players Only bets placed using real money on the full website, mobile or mobile app versions are participating in the tournament.",
      "Jetx@GeneralTermsConditionThird":
        "  Bets must be collected at least on multiplier X2 or higher or must be lost.",
      "Jetx@GeneralTermsConditionFourth":
        "You can spin the points wheel for during 30 seconds after it appears. In the case of inactivity, after 30 seconds the wheel will automatically spin and the player will be rewarded on a random basis.",
      "Jetx@GeneralTermsConditionFifth":
        "Leaderboard points and progress will be displayed inside the participating games lobby.",
      "Jetx@GeneralTermsConditionSixth":
        "The player who earns the maximum number of points will win the tournament.",
      "Jetx@GeneralTermsConditionSeventh":
        "At the end of each stage, during 3 working days winner players will receive prizes.",
      "Jetx@GeneralTermsConditionEightth":
        "If a loss of connection or technical issue arises, all affected spins are considered invalid and may not be included in the tournament results.",
      "Jetx@GeneralTermsConditionnine":
        "Casino and Smartsoft receive the right to cancel any points earned or withhold prizes if they believe that all or part of the outcome comes as results of fraud or collusion with other players.",
      "Jetx@GeneralTermsConditionten":
        "Smartsoft reserves the right to change, suspend or cancel this offer at any time.",
      "Jetx@GeneralTermsConditioneleven":
        "By taking part in this offer, the customer agrees to these terms and conditions and to the general terms and conditions of Casino.",
      "Jetx@GeneralTermsConditiontwelve":
        "All decisions made by the Casino management team are final.",
      "Jetx@GeneralTermsCondition13":
        "The winning amount will be based on the latest currency exchange value",
      "jetx@deposit2":
        "In order to win, the players need to accomplish the given missions. Top 500 players on the leaderboard stand a chance to win a pool prize of INR 4,400,000",
      "jetx@deposit3":
        " The leaderboard is working on a points system. For receiving points, player has to spin a leaderboard wheel, which contains 6 different points:1,2,10,25,50,100.",
      "jetx@depnote": "Deposit using any method and start playing JETX!",
      PrePlay: "Pre-play",
      PrePlayNote:
        "Note: Zero Commission pre-play market & market will be suspended once the game begins.",
      "PROMOTIONS@crazyCrashPromo": "CRAZY CRASH CHALLENGE",
      "PROMOTIONS@crazyCrashPromoMainDesc1":
        "Its time you win Big & Get Crazy!",
      "PROMOTIONS@crazyCrashPromoMainDesc2":
        "So here we are  presenting to you the Crazy Crash Challenge ! Win pool prize of",
      "PROMOTIONS@crazyCrashPromoMainDesc3": "INR 1,200,000",
      "PROMOTIONS@crazyCrashPromoMainDesc4":
        "All you need to do is PLAY any of your favourite Crash game",
      "PROMOTIONS@crazyCrashPromoMainDesc5":
        "(JetX, CricketX, Ballon, FootballX, PlinkoX, jetX3 & Cappadocia)",
      "PROMOTIONS@crazyCrashPromoMainDesc6": "from",
      "PROMOTIONS@crazyCrashPromoMainDesc7": "1st Feb – 28th FEB 2023",
      "PROMOTIONS@crazyCrashPromoMainDesc8": "to enter this",
      "PROMOTIONS@crazyCrashPromoFIRSTHEAD": "How to Enter CCC?",
      "PROMOTIONS@crazyCrashPromoPrizeHEAD": "Prize Allocation",
      "crazyCrash@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "crazyCrash@GeneralTermsConditionSecond":
        "Crazy Crash Challenge is valid from ",
      "crazyCrash@GeneralTermsConditionSecondValidFrom":
        "1st - 28th FEB 2023. ",
      "crazyCrash@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on ",
      "crazyCrash@GeneralTermsConditionSecondValidOn":
        "5th June, 2023 on YOLO247 social media handles ( Instagram & telegram )",
      "crazyCrash@GeneralTermsConditionThird":
        "For a bet to be valid, minimum 1.30 cash out is required",
      "crazyCrash@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "crazyCrash@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "crazyCrash@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
      "crazyCrash@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "crazyCrash@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      "SPLMKT@PSL": "PAKISTAN SUPER LEAGUE",

      "ipl@GeneralTermsConditionFirst":
        "IPL Dhamaka Bonus is valid for all new & existing players.",
      "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
      "ipl@GeneralTermsConditionSecondValidFrom":
        " 31st march to 28th may, 2023",
      "ipl@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on ",
      "ipl@GeneralTermsConditionSecondValidOn":
        "YOLO247 social media handles ( Instagram & telegram )",
      "ipl@GeneralTermsConditionThird":
        "Maximum pay-out for Additional cashback bonus is INR 1,000",
      "ipl@GeneralTermsConditionThirdext":
        "Bet should be placed at minimum odds of 1.30",
      "ipl@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "ipl@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "ipl@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
      "ipl@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "ipl@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      iplinfo:
        "The most exciting time of the year for every cricket fan is when the Indian Premier League is underway. That’s why IPL lovers will receive a special",
      infospan: "IPL MATKA BONUS",
      iplinfo1: "on YOLO247 to enjoy this popular competition even more.",
      iplinfo2:
        " Results for IPL will be declared on YOLO247 social media (Instagram & Telegram handles) on 5 June, 2023",
      "PROMOTIONS@iplmatka": "IPL Betting Dhamaka",
      iplline1:
        "The Most Exciting Time of the Year For Every Cricket Fans is where the",
      iplline2: "INDIAN PREMIER LEAGUE",
      iplline3: "is Underway. Thats why IPL Lovers will recieve a special",
      iplline4: "IPL MATKA BONUS",
      iplline5: "on YOLO247 to enjoy this popular competition even more",
      "PROMOTIONS@iplCasinoCarnival": "IPL CASINO CARNIVAL",
      casinoCarnivaltextline1:
        "Join the biggest gaming event of the year and stand a chance to win whopping cash prize from a pool of",
      casinoCarnivaltextline2: "INR 1 Crore,",
      casinoCarnivaltextline3: "as well as exclusive",
      casinoCarnivaltextline4: "Merchandise",
      casinoCarnivaltextline5: "From Yolo247.",
      casinoCarnivaltextline6: "To qualify for the prizes, achieve",
      casinoCarnivaltextline7: "a minimum turnover of 10,000 INR",
      casinoCarnivaltextline8:
        "in any live dealer game(s) and get placed in a level based on your performance. Don't miss out on this incredible opportunity to join the Carnival and win big this IPL season!",
      casinoCarnivaltextline9:
        "So, what are you waiting for? Join us for the ultimate gaming experience and take your IPL season to the next level at YOLO247!",
      "CasinoCarnival@TermsConditionTittle": "Terms and Conditions:",
      "CasinoCarnival@TermsConditionFirst":
        "This promotion is valid for all new & existing players",
      "CasinoCarnival@TermsConditionSecond":
        "IPL Casino Carnival Bonus is valid from 1st April to 31st May 2023. Winners will be announced on 5th June on YOLO247 social media handles ( Instagram & Telegram)",
      "CasinoCarnival@TermsConditionThird":
        "No Minimum bet amount required to enter this contest",
      "CasinoCarnival@TermsConditionFourth":
        "Only Live dealer games are applicable for this promotion",
      "CasinoCarnival@TermsConditionFifth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements",
      "CasinoCarnival@TermsConditionSixth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion",
      "CasinoCarnival@TermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions",
      "CasinoCarnival@TermsConditionEighth":
        " Yolo247 General Terms & Conditions apply",
      "Deplost@NoChannel":
        "This channel is busy. Please try another payment method",
      "PROMOTIONSWELCOME@stakesamt":
        "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
      "Games@NoGamesMessage": "⚠ No games are available for this provider",
      "Games@NoGamesMessage2":
        "✖ Please remove any applied search filter to see other games",
      "DASHBOARD@WithdrawalNoteDisclaimer4":
        "Withdrawal requests submitted between 12:00AM and 6:00AM may take longer to process due to banking server issues that are commonly experienced during the night or non-banking hours.",
      "firstbet@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "firstbet@GeneralTermsConditionSecond":
        "The winners of each week will remain eligible for prizes in the following weeks as well.",
      "firstbet@GeneralTermsConditionThird":
        "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles( Instagram & telegram)",
      "firstbet@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "firstbet@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "firstbet@GeneralTermsConditionSixth":
        "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "firstbet@GeneralTermsConditionSeventh":
        "Yolo247 General Terms & Conditions apply",
      "PROMOTIONS@firstperson": "FIRST PERSON BET & WIN",

      "Funkytime@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "Funkytime@GeneralTermsConditionSecond":
        "The winners of each week will remain eligible for prizes in the following weeks as well.",
      "Funkytime@GeneralTermsConditionThird":
        "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
      "Funkytime@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "Funkytime@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "Funkytime@GeneralTermsConditionSixth":
        "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "Funkytime@GeneralTermsConditionSeventh":
        "Yolo247 General Terms & Conditions apply",

      "DailyDepositBonus@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing YOLO247 members.",
      "DailyDepositBonus@GeneralTermsConditionSecond":
        "This bonus amount will be capped at INR 7,000",
      "DailyDepositBonus@GeneralTermsConditionThird":
        "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
      "DailyDepositBonus@GeneralTermsConditionFourth":
        "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
      "DailyDepositBonus@GeneralTermsConditionFifth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
      "DailyDepositBonus@GeneralTermsConditionSixth":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
      "DailyDepositBonus@GeneralTermsConditionSeventh":
        "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
      "DailyDepositBonus@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",

      "PROMOTIONS@ACBPromo": "Asia Cup Bonanza",
      "PROMOTIONS@acbPromoRightText1":
        "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
      "PROMOTIONS@acbPromoRightText2":
        "This Asia cup immerse yourself with exhilarating cricket action only on YOLO247.",
      "PROMOTIONS@acbPromoRightText3":
        "Join the biggest gaming event of Asia and stand a chance to win",
      "PROMOTIONS@acbPromoRightText4":
        " Extra Bonuses and Tickets to the Cricket World Cup 2023",

      "asiaCup@GeneralTermsConditionSecond": "Asia Cup Bonanza is valid from ",
      "asiaCup@GeneralTermsConditionSecondTwo":
        "30th August to 17th September 2023. ",
      "asiaCup@GeneralTermsConditionSecondThree":
        "Winners will be announced on ",
      "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
      "asiaCup@GeneralTermsConditionSecondFive":
        "on YOLO247 social media handles ( Instagram & Telegram).",
      "asiaCup@GeneralTermsConditionThird":
        "No Minimum bet amount required to enter this contest.",
      "asiaCup@GeneralTermsConditionFourth":
        "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
      "asiaCup@GeneralTermsConditionFifth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "asiaCup@GeneralTermsConditionSixth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "asiaCup@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "asiaCup@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",

      "PROMOTIONS@EvoCasinoCupPromo": "BACCARAT : BET & WIN!",
      "evoCasinoPromo@RegisterText":
        "Open the door to endless thrills by creating your very own Yolo247 account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
      "evoCasinoPromo@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "evoCasinoPromo@GeneralTermsConditionSecond":
        "The winners of each week will remain eligible for prizes in the following weeks as well.",
      "evoCasinoPromo@GeneralTermsConditionThird":
        "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles ( Instagram, Telegram) and your respective inbox.",
      "evoCasinoPromo@GeneralTermsConditionFourth":
        "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
      "evoCasinoPromo@GeneralTermsConditionFifth":
        "Winnings will be added to wallets as bonus every week.",
      "evoCasinoPromo@GeneralTermsConditionSixth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "evoCasinoPromo@GeneralTermsConditionSeventh":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "evoCasinoPromo@GeneralTermsConditionEighth":
        "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "evoCasinoPromo@GeneralTermsConditionNinth":
        "Yolo247 General Terms & Conditions apply",
        "OTPWithDrawal": 'OTP authentication required to initiate transaction when selecting a new bank account.',
        "WithdrawalChannelMsg":"In the next page, submit your payment's UTR number to avoid delays",
        "depositbonuscode":"I Have Promo Code",
        "USERREGPOPUP@Text": "Following details are required to fill the Bank form",
        "Payment@Crypto": "Fast Crypto",
        "Deposit@CryptoDeposit": "Fast Crypto Deposit",
        "TITLE@FUN88EDGE": "Discover the FUN88 EDGE",
        "DEPOSIT@BONUSNOTEUnsuccessful": "Kindly try again if transaction is unsuccessful.",
        "DEPOSIT@MESSAGETRANSACID": "Transaction ID",
        "DEPOSIT@MESSAGEDATETIME": "Date & time",
        "DEPOSIT@MESSAGEBtnText": "Back to Homepage",
        "DEPOSIT@MESSAGEBtnText2": "View Transaction History",
        "FOOTER@TitleSponsorSeason10": "Title Sponsor Season 10",
        "FOOTER@OfficialGlobalAmbassador2023-25": "Official Global Ambassador 2023-25",
        "FOOTER@OfficialShirtSponsorEPLFrom2017-20": "Official Shirt Sponsor EPL from 2017-20",
        "FOOTER@GlobalBrandAmbassador2023-24": "Global Brand Ambassador 2023-24",
        "FOOTER@OfficialBettingPartnerSince2012": "Official Betting Partner, Since 2012",
        "FOOTER@OfficialShirtSponsorBurnleyFCin2015": "Official Shirt Sponsor, Burnley F.C. in 2015",
        "MEMBER@transactionHistory": "Past Transactions",
        "MEMBER@betHistory":"Past Bets",
        "DEPOSIT@FASTCryptoSUPPORTSText": "FAST Crypto SUPPORTS",
        "depositCryptoTerms@ContentFirst": "Before making deposits, kindly review your wallet charges to ensure total amount matches the platforms amount",
        "depositCryptoTerms@ContentSecond": "Purchasing crypto through a third-party provider indicates your agreement to their Terms of Service and adherence to the Operator's KYC policy",
        "depositCryptoTerms@ContentThired": "If your deposit doesn't match the requested amount, the funds could be withheld",
        "depositCryptoTerms@ContentFourth": "Kindly contact customer support for any queries",
         "FOOT@sponsorship":"Sponsorship",
         "PROMOTIONS@InstantChallengePromo":"CRAZY INSTANT CHALLENGE 2025",
         "FOOT@customercare":"Customer Care"

      };

    obj = {
      31: {
        ..._defaultEObj,


        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
        "PROMOTIONS@LiveJackPot2025": "The Live Jackpot",
      },

      33: {
        ..._defaultEObj,
      "PROMOTIONS@BigBashBigCash": "Bonus Big Bash",
        "PROMOTIONS@RefillNewHead0": "Triumph with Every Redeposit - 5% Refill Bonus on BaaziAdda",
        "PROMOTIONS@RefillNewHead1": "Redeposit and Receive up to 5% Refill Bonus",
        "PROMOTIONSREFILL@RefillNewDesc1": "You are special and deserve the best, so we're offering a 5% refill bonus on every redeposit.",
        "PROMOTIONS@RefillNewDesc101": "The only platform in the country to provide a bonus on every deposit.",
        "PROMOTIONS@RefillNewDesc102": "Users get a 5% refill bonus (Platinum members) on every redeposit.",
        "PROMOTIONS@RefillNewDesc103": "The percentage of refill bonus for non-platinum users depends on their tier.",
        "PROMOTIONS@RefillNewHead2": "What is Refill Bonus and How to Get It?",
        "PROMOTIONS@RefillNewDesc2": "A refill bonus is a bonus that a user gets on every redeposit. To understand how and when the refill bonus is credited to your main wallet, look at the following steps:",
        "PROMOTIONS@RefillNewDesc201": "Login to your BaaziAdda account through a website or the BaaziAdda app.",
        "PROMOTIONS@RefillNewDesc202": "For every redeposit, a user will get up to a 5% refill bonus that will be added to his/her main wallet instantly.",
        "PROMOTIONS@RefillNewHead3": "Who is eligible for the BaaziAdda Refill Bonus?",
        "PROMOTIONS@RefillNewDesc301": "BaaziAdda users who are redepositing using the “highlighted channels” will be eligible for the refill bonus. The percentage of the refill bonus will depend on the user's tier level.",
        "PROMOTIONS@RefillNewDesc302": "Users, who are using the non-highlighted channels, will not be eligible for the refill bonus.",
        "PROMOTIONS@RefillNewDesc303": "In the Fast UPI payment mode: All users will get the refill bonus.",
        "PROMOTIONS@RefillNewDesc304": "During your process of redepositing through Regular UPI payment mode, you can find the highlighted channels",
        "PROMOTIONS@RefillNewHead4": "Benefits of BaaziAdda Refill Bonus",
        "PROMOTIONS@RefillNewDesc4": "A user will experience multiple benefits when it comes to a refill bonus, which are as follows:",
        "PROMOTIONS@RefillNewDesc401": "Users get an added motivation to keep playing as they get up to a 5% bonus on every redeposit.",
        "PROMOTIONS@RefillNewDesc402": "Players can use this bonus to play different games, improve their casino game skills, and increase their chances of winning.",
        "PROMOTIONS@RefillNewTermsHead": "Terms and Conditions of Refill Bonus on BaaziAdda",
        "PROMOTIONS@RefillNewTermsDesc1": "Only one bonus is allowed per customer, address, shared computer, shared IP address, and any identical account details that include e-mail address, bank account details, credit card information, and payment system account. Any misuse of this bonus will lead to an account being closed.",
        "PROMOTIONS@RefillNewTermsDesc2": "Only users, who are using the highlighted channels, will be eligible for the refill bonus.",
        "PROMOTIONS@RefillNewTermsDesc3": "After every successful redeposit, the bonus amount is credited to the user's main wallet.",
        "PROMOTIONS@RefillNewTermsDesc4": "This promotion is available on every redeposit, and every bonus amount received during the day will expire at 12 AM IST the next day.",
        "PROMOTIONS@RefillNewTermsDesc5": "Example: 1st redeposit was done by the user on 21st September at 9 AM of ₹1,000. So, now the user will receive a ₹20 bonus; thus, the total amount in a user's account will be ₹1020. Now, consider that a user makes another deposit of ₹2000 at 2 PM on the same day. So now, a user will receive a bonus of ₹40. On 22nd September at midnight at 11:59:59 PM, the bonus amount of ₹60 that was given to the user will expire.",
        "PROMOTIONS@RefillNewTermsDesc6": "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. BaaziAdda reserves the right to disqualify players if foul play takes place.",
        "PROMOTIONS@RefillNewTermsDesc7": "BaaziAdda reserves the right to amend, cancel, reclaim, or refuse any promotions at its discretion.",
        "PROMOTIONS@RefillNewTermsDesc8": "The bonus amount cannot be withdrawn, but the winnings from the bonus are withdrawable.",
        "PROMOTIONS@RefillNewTermsDesc9": "BaaziAdda's general terms and conditions apply.",
        "PROMOTIONS@RefillNewTermsDesc10": "Rollover is not valid for any of the P2P games.",






        "NOTE@ELITE":'Baaziadda reserves the right to change/modify these conditions at any time without prior notice',
        "YoloAppDwnld@WaitForTheFile": "Wait For The BAAZI ADDA App To Download",
        "TITLE@BETDAILYEDGE": "DISCOVER THE BAAZI ADDA EDGE",
        "PROMOTIONSCASHBACK@1STLINE":
        "Baazi Adda offers a 2% cashback bonus instantly in your wallet On Your weekly losses",
        "PROMOTIONSCASHBACK@5THLINE":
          "Say you lost Rs. 10,000 in the last week (every week is counted Monday 00:00:01 AM to Sunday 23:59:59 PM), you will be credited 5% of your weekly losses, which is Rs. 500 in this example to your main wallet instantly.",

        "PROMOTIONSREFILL@REFILLSTEPS2":
          "2.) Apart from your first deposit, every deposit henceforth you will receive 5% extra balance in your main wallet instantly after you have successfully deposited.",

        "evoCasinoPromo@RegisterText":
          "Open the door to endless thrills by creating your very own Baazi Adda account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
        "PROMOTIONS@ACBPromo": "Asia Cup Bonanza",
        "PROMOTIONS@acbPromoRightText1":
          "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
        "PROMOTIONS@acbPromoRightText2":
          "This Asia cup immerse yourself with exhilarating cricket action only on Baazi Adda.",
        "PROMOTIONS@acbPromoRightText3":
          "Join the biggest gaming event of Asia and stand a chance to win",
        "PROMOTIONS@acbPromoRightText4":
          " Extra Bonuses and Tickets to the Cricket World Cup 2023",

        "evoCasinoPromo@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "evoCasinoPromo@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "evoCasinoPromo@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Baazi Adda social media handles ( Instagram, Telegram) and your respective inbox.",
        "evoCasinoPromo@GeneralTermsConditionFourth":
          "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
        "evoCasinoPromo@GeneralTermsConditionFifth":
          "Winnings will be added to wallets as bonus every week.",
        "evoCasinoPromo@GeneralTermsConditionSixth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "evoCasinoPromo@GeneralTermsConditionSeventh":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "evoCasinoPromo@GeneralTermsConditionEighth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "evoCasinoPromo@GeneralTermsConditionNinth":
          "Baazi Adda General Terms & Conditions apply",

        "asiaCup@GeneralTermsConditionSecond":
          "Asia Cup Bonanza is valid from ",
        "asiaCup@GeneralTermsConditionSecondTwo":
          "30th August to 17th September 2023. ",
        "asiaCup@GeneralTermsConditionSecondThree":
          "Winners will be announced on ",
        "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
        "asiaCup@GeneralTermsConditionSecondFive":
          "on Baazi Adda social media handles ( Instagram & Telegram).",
        "asiaCup@GeneralTermsConditionThird":
          "No Minimum bet amount required to enter this contest.",
        "asiaCup@GeneralTermsConditionFourth":
          "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
        "asiaCup@GeneralTermsConditionFifth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "asiaCup@GeneralTermsConditionSixth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "asiaCup@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "asiaCup@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Baazi Adda social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Baazi Adda General Terms & Conditions apply",

        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Baazi Adda social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Baazi Adda General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Baazi Adda members",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Baazi Adda reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Baazi Adda reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well read further below to understand.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONSWELCOME@TERMS4":
          " The bonus amount must be rolled over  24 (Twenty-four) times. Turnover generated any where across the platform will be calculated towards the turnover requirements.",
        "PROMOTIONSWELCOME@TERMS5":
          " The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",
        "PROMOTIONSWELCOME@TERMS6":
          " Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos.So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market",
        "PROMOTIONSREFILL@REFILLBONUS4":
          "On Baazi Adda, you are eligible for a 5% Refill Bonus on every deposit that you make. For Platinum users, it is a massive 5%.",

        "PROMOTIONS@iplmatka": "IPL Betting Dhamaka",


        iplline4: "IPL SATTA MATKA BONUS",
        "ipl@GeneralTermsConditionFirstBD":
          "The IPL Satta Matka Bonanza 2023 is open to all existing and new Baazi Adda users.",
        "ipl@GeneralTermsConditionSecondBD":
          "The IPL Satta Matka Bonanza 2023 is valid from 31 March, 2023, to 28 May, 2023. Winners will be announced through Baazi Adda social media platforms on",
        "ipl@GeneralTermsConditionThirdBD":
          "To be eligible to enter the IPL Satta Matka Bonanza 2023, a user should place a minimum bet of Rs. 1000 on every IPL match.",
        "ipl@GeneralTermsConditionThirdextBD":
          "The bets should be placed at minimum odds of 1.30.",
        "ipl@GeneralTermsConditionFourthBD":
          "Cashed Out, Void, Cancelled, or Draw bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifthBD":
          "Multiple accounts will not be considered for the IPL Satta Matka Bonanza 2023. All players will be subject to duplicate checking.",
        "ipl@GeneralTermsConditionSixthBD":
          "Baazi Adda reserves the right to disqualify players if collusion or any fairplay violation occurs in the IPL Satta Matka Bonanza 2023.",
        "ipl@GeneralTermsConditionSeventhBD":
          "The IPL Satta Matka Bonanza 2023 cannot be used in conjunction with any other existing promotions on Baazi Adda.",
        "ipl@GeneralTermsConditionEighthBD":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "ipl@GeneralTermsConditionNinethBD":
          "Baazi Adda General Terms & Conditions apply.",
        "ipl@GeneralTermsConditiontenthBD": "",
        "ipl@GeneralTermsConditionSecondValidFromBD":
          "31 March, 2023, to 28 May, 2023.",
        "ipl@GeneralTermsConditionSecondWinnersWillBeBD": "5 June, 2023",

        iplline5: "on Baazi Adda to enjoy this popular competition even now",

        iplinfo1: "on Baazi Adda to enjoy this popular competition even more.",
        iplinfo2:
          " Results for IPL will be declared on Baazi Adda social media (Instagram & Telegram handles) on _______ date TBD",

        "ipl@GeneralTermsConditionFirst":
          "IPL Dhamaka Bonus is valid for all new & existing players.",
        "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
        "ipl@GeneralTermsConditionSecondValidFrom":
          "______ to _____.  _______ on Baazi Adda social media handles (Instagram & telegram)",
        "ipl@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on ",
        "ipl@GeneralTermsConditionSecondValidOn":
          "Baazi Adda social media handles ( Instagram & telegram )",
        "ipl@GeneralTermsConditionThird":
          "Maximum pay-out for Additional cashback bonus is INR 1,000",
        "ipl@GeneralTermsConditionThirdext":
          "Bet should be placed at minimum odds of 1.30",
        "ipl@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "ipl@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
        "ipl@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "ipl@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        "PROMOTIONS@psl": "PSL 50:50 CRICKET DHAMAKA",
        "TITLE@LOYALTYTAGLINE": "WIN DAILY ON BAAZI ADDA,EARN MORE",

        "TITLE@betdailyMarquee": "Baazi Adda - Jeet ka Adda, BaaziAdda !! ",
        "PROMOTIONS@BBLBonusDesc1":
          "INR 1,729,000 at Baazi Adda SPECIAL MARKET!!",
        "FifaFever@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "FifaFever@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "FifaFever@GeneralTermsConditionSixth":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "FifaFever@GeneralTermsConditionSeventh":
          "Baazi Adda General Terms & Conditions apply.",

        "BBL@CardsDescSub3":
          " All you need to do is place 10 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday . Results will also be declared on Baazi Adda social media Instagram handle every week.",
        "BBL@CardsDescMain4":
          "winners will get a chance to win 50% cash back bonus every week!",
        "BBL@CardsDescSub4":
          "All you need to do is place 5 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday. Results will also be declared on Baazi Adda social media Instagram handle every week.",

        "BBL@CardsDescSub1":
          " All you need to do is place bet on the team you feel will win BBL at Baazi Adda Special markets. Minimum bet to enter this contest is 2470 INR. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on Baazi Adda social media Instagram handle on 5TH FEB 2023!",
        "BBL@CardsDescMain2":
          "318 winners can earn upto 100% cashback bonus every week from 13th Dec’22 to 4th Feb’23 !!",
        "BBL@CardsDescSub2":
          " All you need to do is place bet on the team you feel will win BBL at Baazi Adda Special markets. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on Baazi Adda social media Instagram handle on 5TH FEB 2023!",

        "PROMOTIONS@FIFAINNERFIRSTStep1":
          "Create a Baazi Adda account if you do not have one yet. Register with your name and mobile number.",

        "BBl@InnerTextLeft1":
          ": Earn jackpot pool bonus of INR 1,729,000 by simply predicting the team you feel will win BBL. Results will be declared on Baazi Adda social media Instagram handle on 5TH FEB 2023!",
        "BBl@InnertextLeft2":
          "  : Earn upto 100% special cashback bonus on your weekly losses for the bets placed in this tournament. Results will be declared on Baazi Adda social media Instagram handle every week for cashback winners.",

        "BBL@InnertextLeft":
          "is here & its time to make big bonus only at Baazi Adda!! You can win big bonus pool prize of INR 1,729,000 at Baazi Adda SPECIAL MARKET & also stand a chance to win special cashback bonus on your losses during all 8 weeks of this action-packed T20 tournament!",

        "LOYALTY@RULESTEXT5":
          "*This offer is subject to the general rules of Baazi Adda",
        "PROMOTIONS@BBLBonusDesc":
          "Big Bash League is here & its time to make big bonus only at Baazi Adda!! You can win big bonus pool prize of",

        "PROMOTIONSREFERRAL@TERM10":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",

        "PROMOTIONSREFERRAL@TERM11":
          "In The Event, Any Fraudulent Activity Of The Player(S) Has Been Identified, Baazi Adda Reserves The Right To Withhold Your Funds And Reject Your Withdrawals.",

        "PROMOTIONSREFERRAL@TERM12":
          "Baazi Adda General Terms & Conditions apply.",

        "PROMOTIONSREFERRAL@TERM9":
          " This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion..",
        "PROMOTIONSREFERRAL@7":
          "1. Share your  Referral Code/URL with your friend  and ask them to join Baazi Adda.",

        "PROMOTIONSCASHBACK@TERM3":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",

        "PROMOTIONSCASHBACK@TERM4":
          "Baazi Adda reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "PROMOTIONSCASHBACK@TERMS6":
          "Baazi Adda General Terms & Conditions apply",

        "PROMOTIONSCASHBACK@TERMSp2p":
          "Rollover is not valid for any of the P2P games",
        "PROMOTIONSCASHBACK@3RDLINE":
          "Wouldn’t it be awesome if you could reduce your losses? Here at Baazi Adda, now get a 5% cashback bonus instantly in your wallet on your weekly losses.",

        "PROMOTIONSREFILL@TERMS6": " Baazi Adda General Terms & Conditions apply",
        "PROMOTIONSREFILL@TERMSp2p":
          "Rollover Is Not Valid For Any Of The P2P Games",
        "PROMOTIONSREFILL@TERMS3":
          "  Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "PROMOTIONSREFILL@TERMS4":
          "  Baazi Adda reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "PROMOTIONSREFILL@REFILLSTEPS": "1.) Login to your Baazi Adda account",
        "PROMOTIONSREFILL@REFILLBONUS2":
          "We at Baazi Adda strongly value and care for our loyal customers and thus you deserve more! At Baazi Adda now with every deposit earn a little more and gain an edge to win more, and thus with every new deposit is a new opportunity! Baazi Adda wishes to welcome you back on every deposit with upto 5% extra bonus (for Platinum members)!!",
        "PROMOTIONSWELCOME@TERMS12":
          " In the event, any fraudulent activity of the player(s) has been identified, Baazi Adda reserves the right to withhold your funds and reject your withdrawals.",
        "PROMOTIONSWELCOME@TERMS13":
          " Baazi Adda General Terms & Conditions apply.",
        "PROMOTIONSWELCOME@TERMSp2p":
          " Rollover is not valid for any of the P2P games",
        "PROMOTIONSWELCOME@TERMS10":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "PROMOTIONSWELCOME@TERMS8":
          " Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Baazi Adda reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at Baazi Adda are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "MANUALDEPOSIT@YoloBank": " Baazi Adda Bank Details",
        "MANUALDEPOSIT@PleaseVerify":
          "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. Baazi Adda WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",
        "MANUALDEPOSIT@UPIID": "Baazi Adda UPI IDs (Select One)",
        "Aviator@Allyouneedtodo":
          "All you need to do is  place minimum 5,000 bets on Aviator from ",
        "Aviator@15thNov": "5th February - 1st March 2023",
        "Aviator@AndYoucanStand":
          "and you can stand a chance to win exciting benefits!",
        "Aviator@TheAviatorbonusisvalid":
          "The Aviator bonus is valid for minimum 1.30 cashout,",
        "Aviator@uptoasHigh": "up to as High as you can go!",
        "Aviator@StartplayingAviatornow":
          "Start playing Aviator now And start flying! Keep an eye on the in-game leader boards to track your progress & 230 lucky players stand a chance to WIN! Results will be declared on Baazi Adda website & social media on",
        "Aviator@18thDEC2022": "3rd March 2023!",
        "Aviator@Howtoenter": "How to Enter the Play Big with Aviator program?",
        "Aviator@Step1TextContent":
          "Create a Baazi Adda account if you do not have one yet. Register with your name and mobile number.",
        "Aviator@Step2TextContent":
          "Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 235,000 pool!",
        "Aviator@20LuckyWinner":
          "20 lucky winner will get a chance to win INR 3,000 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 10,001 or more bets  to enter this offer",
        "Aviator@Aviator2ndPriceText":
          "40 lucky winner will get a chance to win INR 2,000 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 5,001 -10,000 bets  to enter this offer",
        "Aviator@Aviator3rdPriceText":
          "70 lucky winner will get a chance to win INR 1,000 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 5,000 bets  to enter this offer",
        "Aviator@Aviator4thPriceText":
          "100 lucky winner will get a chance to win INR 250 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to play Aviator  to enter this offer",
        "Aviator@GeneralTermsConditionSecondValidFrom":
          "5th February to 1st March 2023.",
        "Aviator@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on",
        "Aviator@GeneralTermsConditionSecondValidOn": "3rd March 2023",
        "Aviator@GeneralTermsConditionSecond":
          "‘Play Big with Aviator’ is valid from",
        "Aviator@GeneralTermsConditionFifth":
          " Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Aviator@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "Aviator@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Aviator@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",
        "Aviator@ReadMoreAbout": "Read more about the offer below:",
        "PROMOTIONS@AviatorQoute":
          "Its time to Play Big & hence we are here with Aviator Bonanza Bonus of ",
        "PROMOTIONS@AviatorPrice": "INR 235,000 !!",
        "YoloAppDwnld@TechnicallyTheYolo247Apk":
          "Technically, the Baazi Adda APK completely copies the official website and repeats its design and features. You can download and install it directly through our site. Just follow a few steps described below.",
        "YoloAppDwnld@InstallTheYoloApp":
          "Install The Baazi Adda App On Your Smartphone",
        "YoloAppDwnld@AppBenefitsFor": "Baazi Adda App Benefits For",
        "YoloAppDwnld@InOrderForYouBet":
          "In order for you to bet not only at home but also in any place where there is the Internet, the Baazi Adda betting app has developed a convenient application. The app works very well for all Android systems. Consider the main advantages of the application",
        "YoloAppDwnld@TakeALookAtTheScreenshot":
          "Take a look at the screenshots of the Baazi Adda App interface below.",
        "YoloAppDwnld@TheYoloAppIsAvailable":
          "The Baazi Adda App is available as a free download for Android devices. The app supports English and Hindi. Download the latest version of the App, register your account.",
        "YoloAppDwnld@BrandName": "",
        "YoloAppDwnld@BrandNameLast": "Baazi Adda",
        "PSL@TableTittle": "PSL 2023 LEADER BOARD",
        "PROMOTIONS@REFERRALHEAD":
          "Introducing Baazi Adda Refer-A-Friend Program: A Win-Win Opportunity!",
        "PROMOTIONSREFERRAL@4":
          "Baazi Adda Referral Code is open to all users, both new and old. Get to know how Referral Bonus works. ",
        "PROMOTIONSREFERRAL@5":
          "You refer a friend he signs up with Baazi Adda using your referral link and does a deposit of lets assume 10,000 INR then in that case you are eligible for 500 INR Bonus after your friend completes 3 times RO of his deposit account.",
        "PROMOTIONSREFERRAL@6":
          "How Baazi Adda Referral Bonus works? | Baazi Adda Referral Code",
        "PROMOTIONSREFERRAL@8":
          "2. When your friends sign up with Baazi Adda using your unique referral link or code (Baazi Adda Referral Code), the fun begins & you activate the Baazi Adda Referral Bonus process.",
        "PROMOTIONSREFERRAL@10":
          "Suppose you refer a friend who decides to embark on this thrilling journey with Baazi Adda.",
        "PROMOTIONS@Referafriend01":
          "1.	Share your unique Referral Code/URL with your friend and encourage them to join Baazi Adda. ",
        "PROMOTIONS@Referafriend02":
          "2.	Your friend should register on Baazi Adda using your Referral Code to ensure you receive credit for this Online Casino Referral Bonus.  ",
        "PROMOTIONS@Referafriend05":
          "5.	Join the Baazi Adda community and take advantage of this incredible opportunity to share the fun and reap the rewards together. Start referring your friends today and unlock a world of thrilling experiences! ",
        "PROMOTIONSREFERRAL@R0END":
          "You may also check out our Baazi Adda Welcome Bonus. ",
        "PROMOTIONSREFERRAL@TERM8":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.  Baazi Adda reserves the right to disqualify players if any collusion or any foul play takes place in the promotion. ",
        "PROMOTIONS@dailyDepoBonusText":
          "Best Casino Daily Deposit Scheme brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Baazi Adda.",
          "PROMOTIONS@CaribbeanLeagueBonus": "Caribbean League Bonus",
          "PROMOTIONS@LiveJackPot2025": "Live Baazi Challenge",
          "PROMOTIONS@InstantChallengePromo":"Baazi Instant Challenge"
      },

      34: {
        ..._defaultEObj,
        "PROMOTIONS@BigBashBigCash": "Bonus Big Bash",
        "YoloAppDwnld@WaitForTheFile": "Wait For The Betdaily App To Download",
        "PROMOTIONSCASHBACK@1STLINE":
        "Betdaily offers a 2% cashback bonus instantly in your wallet On Your weekly losses",
        "PROMOTIONSCASHBACK@5THLINE":
          "Say you lost Rs. 10,000 in the last week (every week is counted Monday 00:00:01 AM to Sunday 23:59:59 PM), you will be credited 5% of your weekly losses, which is Rs. 500 in this example to your main wallet instantly.",

        "evoCasinoPromo@RegisterText":
          "Open the door to endless thrills by creating your very own Betdaily account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
        "evoCasinoPromo@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "evoCasinoPromo@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "evoCasinoPromo@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Betdaily social media handles ( Instagram, Telegram) and your respective inbox.",
        "evoCasinoPromo@GeneralTermsConditionFourth":
          "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
        "evoCasinoPromo@GeneralTermsConditionFifth":
          "Winnings will be added to wallets as bonus every week.",
        "evoCasinoPromo@GeneralTermsConditionSixth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "evoCasinoPromo@GeneralTermsConditionSeventh":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "evoCasinoPromo@GeneralTermsConditionEighth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "evoCasinoPromo@GeneralTermsConditionNinth":
          "Betdaily General Terms & Conditions apply",

        "PROMOTIONS@ACBPromo": "Asia Cup Bonanza",
        "PROMOTIONS@acbPromoRightText1":
          "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
        "PROMOTIONS@acbPromoRightText2":
          "This Asia cup immerse yourself with exhilarating cricket action only on BETDAILY.",
        "PROMOTIONS@acbPromoRightText3":
          "Join the biggest gaming event of Asia and stand a chance to win",
        "PROMOTIONS@acbPromoRightText4":
          " Extra Bonuses and Tickets to the Cricket World Cup 2023",
        "asiaCup@GeneralTermsConditionSecond":
          "Asia Cup Bonanza is valid from ",
        "asiaCup@GeneralTermsConditionSecondTwo":
          "30th August to 17th September 2023. ",
        "asiaCup@GeneralTermsConditionSecondThree":
          "Winners will be announced on ",
        "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
        "asiaCup@GeneralTermsConditionSecondFive":
          "on BETDAILY social media handles ( Instagram & Telegram).",
        "asiaCup@GeneralTermsConditionThird":
          "No Minimum bet amount required to enter this contest.",
        "asiaCup@GeneralTermsConditionFourth":
          "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
        "asiaCup@GeneralTermsConditionFifth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "asiaCup@GeneralTermsConditionSixth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. BETDAILY reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "asiaCup@GeneralTermsConditionSeventh":
          "BETDAILY reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "asiaCup@GeneralTermsConditionEighth":
          "BETDAILY General Terms & Conditions apply.",

        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Betdaily social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Betdaily General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Betdaily members",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Betdaily reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Betdaily reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on betdaily social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Betdaily General Terms & Conditions apply",

        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well read further below to understand.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over  24 (Twenty-four) times. Turnover generated any where across the platform will be calculated towards the turnover requirements.",
        "PROMOTIONSWELCOME@TERMS5":
          " The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",
        "PROMOTIONSREFILL@REFILLBONUS4":
          "On Betdaily, you are eligible for a 5% Refill Bonus on every deposit that you make. For Platinum users, it is a massive 5%.",
        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos.So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market",

        "PROMOTIONS@iplmatka": "IPL Betting Dhamaka",
        iplline4: "IPL SATTA MATKA BONUS",
        "ipl@GeneralTermsConditionFirstBD":
          "The IPL Satta Matka Bonanza 2023 is open to all existing and new Bet Daily users.",
        "ipl@GeneralTermsConditionSecondBD":
          "The IPL Satta Matka Bonanza 2023 is valid from 31 March, 2023, to 28 May, 2023. Winners will be announced through Bet Daily social media platforms on",
        "ipl@GeneralTermsConditionThirdBD":
          "To be eligible to enter the IPL Satta Matka Bonanza 2023, a user should place a minimum bet of Rs. 1000 on every IPL match.",
        "ipl@GeneralTermsConditionThirdextBD":
          "The bets should be placed at minimum odds of 1.30.",
        "ipl@GeneralTermsConditionFourthBD":
          "Cashed Out, Void, Cancelled, or Draw bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifthBD":
          "Multiple accounts will not be considered for the IPL Satta Matka Bonanza 2023. All players will be subject to duplicate checking.",
        "ipl@GeneralTermsConditionSixthBD":
          "Bet Daily reserves the right to disqualify players if collusion or any fairplay violation occurs in the IPL Satta Matka Bonanza 2023.",
        "ipl@GeneralTermsConditionSeventhBD":
          "The IPL Satta Matka Bonanza 2023 cannot be used in conjunction with any other existing promotions on Bet Daily.",
        "ipl@GeneralTermsConditionEighthBD":
          "Bet Daily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "ipl@GeneralTermsConditionNinethBD":
          "Bet Daily General Terms & Conditions apply.",
        "ipl@GeneralTermsConditiontenthBD": "",
        "ipl@GeneralTermsConditionSecondValidFromBD":
          "31 March, 2023, to 28 May, 2023.",
        "ipl@GeneralTermsConditionSecondWinnersWillBeBD": "5 June, 2023",
        iplline5: "on Betdaily to enjoy this popular competition even now",

        iplinfo1: "on BETDAILY to enjoy this popular competition even more.",
        iplinfo2:
          " Results for IPL will be declared on BETDAILY social media (Instagram & Telegram handles) on _______ date TBD",

        "ipl@GeneralTermsConditionFirst":
          "IPL Dhamaka Bonus is valid for all new & existing players.",
        "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
        "ipl@GeneralTermsConditionSecondValidFrom":
          "______ to _____.  _______ on Betdaily social media handles (Instagram & telegram)",
        "ipl@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on ",
        "ipl@GeneralTermsConditionSecondValidOn":
          "Betdaily social media handles ( Instagram & telegram )",
        "ipl@GeneralTermsConditionThird":
          "Maximum pay-out for Additional cashback bonus is INR 1,000",
        "ipl@GeneralTermsConditionThirdext":
          "Bet should be placed at minimum odds of 1.30",
        "ipl@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "ipl@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
        "ipl@GeneralTermsConditionSeventh":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "ipl@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",
        "PROMOTIONS@psl": "PSL 50:50 CRICKET DHAMAKA",
        "TITLE@LOYALTYTAGLINE": "WIN DAILY ON BET DAILY,EARN MORE",
        "TITLE@betdailyMarquee": "Betdaily -",
        "PROMOTIONS@BBLBonusDesc1":
          "INR 1,729,000 at BETDAILY SPECIAL MARKET!!",
        "FifaFever@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "FifaFever@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "FifaFever@GeneralTermsConditionSixth":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "FifaFever@GeneralTermsConditionSeventh":
          "Betdaily General Terms & Conditions apply.",

        "BBL@CardsDescSub3":
          " All you need to do is place 10 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday . Results will also be declared on Betdaily social media Instagram handle every week.",
        "BBL@CardsDescMain4":
          "winners will get a chance to win 50% cash back bonus every week!",
        "BBL@CardsDescSub4":
          "All you need to do is place 5 bets worth minimum INR 1,000 in BBL tournament every week to enter this contest. Cashback will be calculated on weekly losses & loaded in lucky winners wallet every Monday. Results will also be declared on Betdaily social media Instagram handle every week.",

        "BBL@CardsDescSub1":
          " All you need to do is place bet on the team you feel will win BBL at Betdaily Special markets. Minimum bet to enter this contest is 2470 INR. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on Betdaily social media Instagram handle on 5TH FEB 2023!",
        "BBL@CardsDescMain2":
          "318 winners can earn upto 100% cashback bonus every week from 13th Dec’22 to 4th Feb’23 !!",
        "BBL@CardsDescSub2":
          " All you need to do is place bet on the team you feel will win BBL at Betdaily Special markets. Time frame 13th Dec 2022 – 2nd Feb 2023! Results will be declared on Betdaily social media Instagram handle on 5TH FEB 2023!",

        "PROMOTIONS@FIFAINNERFIRSTStep1":
          "Create a Betdaily account if you do not have one yet. Register with your name and mobile number.",

        "BBl@InnerTextLeft1":
          ": Earn jackpot pool bonus of INR 1,729,000 by simply predicting the team you feel will win BBL. Results will be declared on Betdaily social media Instagram handle on 5TH FEB 2023!",
        "BBl@InnertextLeft2":
          "  : Earn upto 100% special cashback bonus on your weekly losses for the bets placed in this tournament. Results will be declared on Betdaily social media Instagram handle every week for cashback winners.",

        "BBL@InnertextLeft":
          "is here & its time to make big bonus only at Betdaily!! You can win big bonus pool prize of INR 1,729,000 at BETDAILY SPECIAL MARKET & also stand a chance to win special cashback bonus on your losses during all 8 weeks of this action-packed T20 tournament!",

        "LOYALTY@RULESTEXT5":
          "*This offer is subject to the general rules of Betdaily",
        "PROMOTIONS@BBLBonusDesc":
          "Big Bash League is here & its time to make big bonus only at Betdaily!! You can win big bonus pool prize of",

        "PROMOTIONSREFERRAL@TERM7":
          "8) Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.  Betdaily reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",

        "PROMOTIONSREFERRAL@TERM10":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",

        "PROMOTIONSREFERRAL@TERM11":
          "In The Event, Any Fraudulent Activity Of The Player(S) Has Been Identified, Betdaily Reserves The Right To Withhold Your Funds And Reject Your Withdrawals.",

        "PROMOTIONSREFERRAL@TERM12":
          "Betdaily General Terms & Conditions apply.",
        "PROMOTIONSREFERRAL@7":
          "1. Share your  Referral Code/URL with your friend  and ask them to join Betdaily.",

        "PROMOTIONSCASHBACK@TERM3":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",

        "PROMOTIONSCASHBACK@TERM4":
          " Betdaily reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "PROMOTIONSCASHBACK@TERMS6":
          "Betdaily General Terms & Conditions apply",
        "PROMOTIONSCASHBACK@TERMSp2p":
          "Rollover is not valid for any of the P2P games",

        "PROMOTIONSCASHBACK@3RDLINE":
          "Wouldn’t it be awesome if you could reduce your losses? Here at Betdaily, now get a 5% cashback bonus instantly in your wallet on your weekly losses.",

        "PROMOTIONSREFILL@TERMS6":
          "  Betdaily General Terms & Conditions apply",
        "PROMOTIONSREFILL@TERMSp2p":
          "Rollover Is Not Valid For Any Of The P2P Games",
        "PROMOTIONSREFILL@TERMS3":
          " Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "PROMOTIONSREFILL@TERMS4":
          " Betdaily reserve the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "PROMOTIONSREFILL@REFILLSTEPS": "1.) Login to your betdaily account",
        "PROMOTIONSREFILL@REFILLBONUS2":
          "We at Betdaily strongly value and care for our loyal customers and thus you deserve more! At Betdaily now with every deposit earn a little more and gain an edge to win more, and thus with every new deposit is a new opportunity! Betdaily wishes to welcome you back on every deposit with upto 5% extra bonus (for Platinum members)!!",
        "PROMOTIONSWELCOME@TERMS12":
          "In the event, any fraudulent activity of the player(s) has been identified, Betdaily reserves the right to withhold your funds and reject your withdrawals.",
        "PROMOTIONSWELCOME@TERMS13":
          " Betdaily General Terms & Conditions apply.",
        "PROMOTIONSWELCOME@TERMSp2p":
          " Rollover is not valid for any of the P2P games",
        "PROMOTIONSWELCOME@TERMS10":
          " Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "PROMOTIONSWELCOME@TERMS8":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Betdaily reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at Betdaily are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "MANUALDEPOSIT@YoloBank": " BETDAILY Bank Details",
        "MANUALDEPOSIT@PleaseVerify":
          "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. BETDAILY WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",
        "MANUALDEPOSIT@UPIID": "BETDAILY UPI IDs (Select One)",

        "Aviator@Allyouneedtodo":
          "All you need to do is  place minimum 5,000 bets on Aviator from ",
        "Aviator@15thNov": "5th February - 1st March 2023",
        "Aviator@AndYoucanStand":
          "and you can stand a chance to win exciting benefits!",
        "Aviator@TheAviatorbonusisvalid":
          "The Aviator bonus is valid for minimum 1.30 cashout,",
        "Aviator@uptoasHigh": "up to as High as you can go!",
        "Aviator@StartplayingAviatornow":
          "Start playing Aviator now And start flying! Keep an eye on the in-game leader boards to track your progress & 230 lucky players stand a chance to WIN! Results will be declared on Betdaily website & social media on",
        "Aviator@18thDEC2022": "3rd March 2023!",
        "Aviator@Howtoenter": "How to Enter the Play Big with Aviator program?",
        "Aviator@Step1TextContent":
          "Create a Betdaily account if you do not have one yet. Register with your name and mobile number.",
        "Aviator@Step2TextContent":
          "Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 235,000 pool!",
        "Aviator@20LuckyWinner":
          "20 lucky winner will get a chance to win INR 3,000 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 10,001 or more bets  to enter this offer",
        "Aviator@Aviator2ndPriceText":
          "40 lucky winner will get a chance to win INR 2,000 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 5,001 -10,000 bets  to enter this offer",
        "Aviator@Aviator3rdPriceText":
          "70 lucky winner will get a chance to win INR 1,000 each and free loyalty tier upgrade at BetDailywhich will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 5,000 bets  to enter this offer",
        "Aviator@Aviator4thPriceText":
          "100 lucky winner will get a chance to win INR 250 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to play Aviator  to enter this offer",
        "Aviator@GeneralTermsConditionSecondValidFrom":
          "5th February to 1st March 2023.",
        "Aviator@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on",
        "Aviator@GeneralTermsConditionSecondValidOn": "3rd March 2023",
        "Aviator@GeneralTermsConditionSecond":
          "‘Play Big with Aviator’ is valid from",
        "Aviator@GeneralTermsConditionFifth":
          " Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Aviator@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "Aviator@GeneralTermsConditionSeventh":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Aviator@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",
        "Aviator@ReadMoreAbout": "Read more about the offer below:",
        "PROMOTIONS@AviatorQoute":
          "Its time to Play Big & hence we are here with Aviator Bonanza Bonus of ",
        "PROMOTIONS@AviatorPrice": "INR 235,000 !!",
        "YoloAppDwnld@TechnicallyTheYolo247Apk":
          "Technically, the BETDAILY APK completely copies the official website and repeats its design and features. You can download and install it directly through our site. Just follow a few steps described below.",
        "YoloAppDwnld@InstallTheYoloApp":
          "Install The Betdaily App On Your Smartphone",
        "YoloAppDwnld@AppBenefitsFor": "Betdaily App Benefits For",
        "YoloAppDwnld@InOrderForYouBet":
          "In order for you to bet not only at home but also in any place where there is the Internet, the BETDAILY betting app has developed a convenient application. The app works very well for all Android systems. Consider the main advantages of the application",
        "YoloAppDwnld@TakeALookAtTheScreenshot":
          "Take a look at the screenshots of the Betdaily App interface below.",
        "YoloAppDwnld@TheYoloAppIsAvailable":
          "The Betdaily App is available as a free download for Android devices. The app supports English and Hindi. Download the latest version of the App, register your account.",
        "YoloAppDwnld@BrandName": "",
        "YoloAppDwnld@BrandNameLast": "Betdaily",
        "PSL@TableTittle": "PSL 2023 LEADER BOARD",
        "PROMOTIONS@REFERRALHEAD":
          "Introducing Betdaily Refer-A-Friend Program: A Win-Win Opportunity!",
        "PROMOTIONSREFERRAL@4":
          "Betdaily Referral Code is open to all users, both new and old. Get to know how Referral Bonus works. ",
        "PROMOTIONSREFERRAL@5":
          "You refer a friend he signs up with Betdaily using your referral link and does a deposit of lets assume 10,000 INR then in that case you are eligible for 500 INR Bonus after your friend completes 3 times RO of his deposit account.",
        "PROMOTIONSREFERRAL@6":
          "How Betdaily Referral Bonus works? | Betdaily Referral Code",
        "PROMOTIONSREFERRAL@8":
          "2. When your friends sign up with Betdaily using your unique referral link or code (Betdaily Referral Code), the fun begins & you activate the Betdaily Referral Bonus process.",
        "PROMOTIONSREFERRAL@10":
          "Suppose you refer a friend who decides to embark on this thrilling journey with Betdaily.",
        "PROMOTIONS@Referafriend01":
          "1.	Share your unique Referral Code/URL with your friend and encourage them to join Betdaily. ",
        "PROMOTIONS@Referafriend02":
          "2.	Your friend should register on Betdaily using your Referral Code to ensure you receive credit for this Online Casino Referral Bonus.  ",
        "PROMOTIONS@Referafriend05":
          "5.	Join the Betdaily community and take advantage of this incredible opportunity to share the fun and reap the rewards together. Start referring your friends today and unlock a world of thrilling experiences! ",
        "PROMOTIONSREFERRAL@R0END":
          "You may also check out our Betdaily Welcome Bonus. ",
        "PROMOTIONSREFILL@REFILLSTEPS2":
          "2.) Apart from your first deposit, every deposit henceforth you will receive 5% extra balance in your main wallet instantly after you have successfully deposited.",
        "PROMOTIONSREFERRAL@TERM8":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.  Betdaily reserves the right to disqualify players if any collusion or any foul play takes place in the promotion. ",
        "PROMOTIONS@dailyDepoBonusText":
          "Best Casino Daily Deposit Scheme brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Betdaily.",
        "PROMOTIONS@LiveJackPot2025": "Live Baazi Challenge",
      },

      32: {
        ..._defaultEObj,
        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
          "DASHBOARD@WithdrawalNoteExtra": "Max Withdrawal Limit/Day :",
          "DASHBOARD@WithdrawalNote4":
          "Upto ₹400,000",

        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing YOLO247 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Yolo247 General Terms & Conditions apply.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",

        "PROMOTIONSWELCOME@SECONDLINEINFO":
          "You are eligible for a 400% welcome bonus on your 1st deposit amount, if you make your first deposit within 30 minutes of signing up. Even after 30 minutes, you are still eligible for a massive 300% welcome bonus. So how do you get to claim this bonus? Just follow this ONE simple step:",

        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROINFO":
          "Well RO means Roll Over, which in essence means you need to complete a certain valid roll over to avail your bonus.The valid RO calculation is as follows:",

        "PROMOTIONSWELCOME@TERMS14":
          " Welcome bonus is capped at a maximum of 24,000 INR",

        // refill(promotions)

        "PROMOTIONSREFILL@REFILLBONUS4":
          "You are eligible for a 5% refill bonus on every re-deposit that you do . So let's understand how it works …",

        "PROMOTIONSREFILL@REFILLSTEPS2":
          "2.) Apart from your first deposit, for every deposit henceforth, you will receive a additional 5% redeposit bonus in your main wallet instantly after you have successfully deposited.",

        //PROMOTION CASHBACK
        "PROMOTIONSCASHBACK@3RDLINE":
          "Wouldn’t it be awesome if you could reduce your losses? Here at Yolo247, now get a 5% cashback bonus instantly in your wallet on your weekly losses.",

        "PROMOTIONSCASHBACK@5THLINE":
          "Say you lost Rs. 10,000 in the last week (every week is counted Monday 00:00:01 AM to Sunday 23:59:59 PM), you will be credited 5% of your weekly losses, which is Rs. 500 in this example to your main wallet instantly.",

        "PROMOTIONSCASHBACK@TERM7": "4) Cashback bonus expires every 7 days.",
        "PROMOTIONSCASHBACK@TERM3":
          " Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
        "PROMOTIONS@LiveJackPot2025": "The Live Jackpot",
      },

      39: {
        ..._defaultEObj,
        "Deposit@ReloadBonus": "Redeposit bonus",
        "PROMOTIONS@BigBashBigCash": "Big Bash Big Bonus",
        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "ProBonus@Welcome": "welcome bonus",
        "ProBonus@Reload": "Redeposit bonus",
        "ProBonus@Pregmatic": "Lossback Bonus",
        "ProBonus@Referral": "Referral Bonus",
        "ProBonus@Sub3": "Get upto 5% Bonus On Every Deposit",
        "ProBonus@Sub": " Get a 700% First Time Deposit Bonus",
        "ProBonus@Sub4": " Upto 5% Cashback Instantly In Your Wallet",
        "ProBonus@Sub5": " Refer & get 21% of your friends deposit amount",
        "PROMOTIONSTITLE@CASHBACK": "Lossback Bonus",
        "PROMOTIONS@CASHBACKBONUS": "Lossback bonus",
        "Promotions@ReferralMessageTooltipSecond":
        "We are awaiting your friend's second deposit in order to activate the 7% referral bonus for you and provide your friend with a redeposit bonus of up to 7%.",
      "Promotions@ReferralMessageTooltipThird":
        "We are awaiting your friend's third deposit in order to activate the 7% referral bonus for you and provide your friend with a redeposit bonus of up to 7%.",

        "Promotions@ReferralDesc":
        "Has signed up, We are waiting for Their first deposit to activate your 7% referral bonus and up to 700% welcome bonus for your friend",

        "FomoAppDwnld@BrandName": "Fomo7",
        "FomoApp@Online": "Online",
        "FomoApp@Betting": "Betting App",
        "FomoAppDwnld@BettingApk": "Best Casino & Sports Betting Apk",
        "FomoAppDwnld@TheFomoAppIsAvailable": "In the digital era, enjoy your favourite casino games and indulge in sports betting with the FOMO7 online betting app. With a user-friendly interface and seamless navigation, the FOMO7 app is among the top betting apps in India.",
        "FomoAppDwnld@App": "App",
        "FomoAppDwnld@Pages": "Pages",
        "FomoAppDwnld@ScreenshotTextOne": "Apart from over 700 casino games and sports betting, exciting bonuses, promotions, and top-notch customer",
        "FomoAppDwnld@ScreenshotTextTwo": "support await casino gaming enthusiasts on India's best online betting app.",
        "FomoAppDwnld@AppFeatures": "Key Features of ",
        "FomoAppDwnld@BrandNameFeature": "FOMO7",
        "FomoAppDwnld@AppOnlineApp": "Online Betting App",
        "FomoAppDwnld@KeyFeatureText": "While online betting apps in India are on the rise, there are no real money games apk betting apps that can match the FOMO7 app. The following are some of the key features of the FOMO7 online betting app",
        "FomoAppDwnld@BenefitsStep1": "An uncluttered interface and smooth navigation.",
        "FomoAppDwnld@BenefitsStep2": "Enjoy the best of both worlds - casino games and sports betting.",
        "FomoAppDwnld@BenefitsStep3": "The app runs smoothly and only takes up a little space on the smartphone device.",
        "FomoAppDwnld@BenefitsStep4": "Free to use with no subscription or hidden charges.",
        "FomoAppDwnld@BenefitsStep5": "One of the top legal betting apps in India that keeps users' data safe and secure.",
        "FomoAppDwnld@BenefitsStep6": "The customer support team is available 24x7 via the app.",
        "FomoAppDwnld@HowTo": "How to download ",
        "FomoAppDwnld@ApkFor": " Apk for",
        "FomoAppDwnld@Android": "Android?",
        "FomoAppDwnld@TechnicallyFomoApkOne": "The FOMO7 app has sports betting and a wide range of casino games for Android smartphones. The FOMO7 Android app is easy to",
        "FomoAppDwnld@TechnicallyFomoApkTwo": " download and install on your phone. Follow the below steps to download the FOMO7 app",
        "YoloAppDwnld@WaitForTheFile": "Wait for the file to download",
        "YoloAppDwnld@InstallTheYoloApp": "Install the FOMO7 App on your Smartphone",
        "FomoAppDwnld@SattaApp": " is the best online",
        "FomoAppDwnld@SattaAppwhy": "Why ",
        "FomoAppDwnld@SattaAppIndia": "satta app in India?",
        "FomoAppDwnld@SattaAppText": "We have listed the top 3 reasons for choosing FOMO7 as your online satta app.",
        "FomoAppDwnld@SattaTextOne": "Let’s find out how to download the best",
        "FomoAppDwnld@SattaTextTwo": "online satta app in India.",
        "FomoAppDwnld@NumberOne": "01",
        "FomoAppDwnld@NumberTwo": "02",
        "FomoAppDwnld@NumberOneText": "You can scan the QR code to download the FOMO7 app via the FOMO7 website from a PC or a laptop. The QR code is located at the top right corner of the FOMO7 homepage. Follow the instructions to complete the app's installation on your phone.",
        "FomoAppDwnld@NumberTwoText": "If you are browsing the FOMO7 website on your smartphone, tap the menu button on the FOMO7 homepage. Click on Download Android App and follow the steps to complete the installation of the bet apk download file.",
        "FomoAppDwnld@SignUp": " App Signup",
        "FomoAppDwnld@DepositText": "First Deposit on ",
        "FomoAppDwnld@SignUpTextOne": "Signing up on the FOMO7 app is instant. Before you can make the first FOMO7 login, you need to create a username and password and enter a few personal details like name, e-mail ID, mobile number, etc.",
        "FomoAppDwnld@SignUpTextTwo": "To make a deposit on the FOMO7 app, open the app and log in with your credentials. Tap on the green wallet icon and select one of the four options—Fast Bank, Fast UPI, Regular UPI, or Net Banking—on the app",
        "FomoAppDwnld@Bonus&Promo": "Bonuses & Promotions",
        "FomoAppDwnld@BonusOn": "On ",
        "FomoAppDwnld@PromotionTextOne": "There are plenty of bonuses and promotions available on the FOMO7 app. You can earn casino and sports betting bonuses on FOMO7 ",
        "FomoAppDwnld@PromotionTextTwo": "regularly. Here are the list of bonuses available on FOMO7",
        "FomoAppDwnld@PromotionTextThree": "Complete the FOMO7 online casino app download, keep depositing,",
        "FomoAppDwnld@PromotionTextFour": "and earn bonuses.",
        "FomoAppDwnld@CasinoApp": " Casino App",
        "FomoAppDwnld@CasinoLineTextOne": "The FOMO7 app is home to 700+ exciting and rewarding online casino games. There are different types of casino games, like slots, crash, dice, wheel games, and more. Among the many casino apps in India, FOMO7 casino stands out for its wide range of games. Here is a list of popular games available to download and play on the best online casino mobile app:",
        "FomoAppDwnld@CasinoLineTextFour": "The FOMO7 online casino app download option is ",
        "FomoAppDwnld@CasinoLineTextFive": "available on the homepage.",
        "FomoAppDwnld@SportsBet": "Sports Betting on ",
        "FomoAppDwnld@BettingApp": "Betting App",
        "FomoAppDwnld@SportsTextOne": "The FOMO7 betting app hosts sporting events happening worldwide. The cricket betting app covers various tournaments, such as the IPL, BBL, ODI World Cup, T20 World Cup, and more international and domestic tournaments. This best online cricket betting app in India has exciting betting markets and top betting odds. ",
        "FomoAppDwnld@SportsTextTwo": "The app caters to the needs of different cricket betting enthusiasts. With live cricket apk download, you can download the app instantly. As plenty of bonuses and engaging promotions are available. ",
        "FomoAppDwnld@SportsTextThree": "Among all the football betting apps in India, FOMO7 is the best football betting app. What makes FOMO7 the best football betting app in India is that it covers different popular leagues like EPL, La Liga, Bundesliga, UEFA Champions League, and more. The FOMO7 app also lets betting enthusiasts bet on popular football events like the FIFA World Cup, UEFA Euro Cup, Copa America, and more. It is the best tennis betting app as well, as it covers all grand slams and ATP events worldwide. ",
        "FomoAppDwnld@SportsTextFour": "The biggest advantage of sports betting is that you can do pre-match betting and in-play or betting on live matches. That’s why it stands as the best sports betting app in India.",
        "FomoAppDwnld@BettingTextOne": "No T20 event is more celebrated than the IPL. With over 70 matches played in the league, cricket fans have a gala time during the two-month-long tournament. The IPL online betting app offers the best odds, unique betting markets, exciting promotions, and bonuses, making it the best IPL satta app in India.",
        "FomoAppDwnld@BettingHeading": "Online IPL Betting App",
        "FomoAppDwnld@FAQquestionOne": "Is it easy to do FOMO7 apk download?",
        "FomoAppDwnld@FAQquestionTwo": "Which sports can I enjoy betting on after the FOMO7 online betting app download?",
        "FomoAppDwnld@FAQquestionThree": "Which tournaments can I bet on after the FOMO7 Indian cricket betting app download?",
        "FomoAppDwnld@FAQanswerOne": "Yes, you can do FOMO7 online betting app download with some quick and easy steps by visiting the brand website.",
        "FomoAppDwnld@FAQanswerTwo": "After the FOMO7 India betting app download, you can bet on cricket, football, tennis, and more.",
        "FomoAppDwnld@FAQanswerThree": "After FOMO7 Indian betting app download, you can access various cricket tournaments like the World Cup, Champions Trophy, ICC T20 World Cup, and popular T20 domestic leagues like IPL, BBL, PSL, and more.",
        "FomoAppDwnld@CasinoHeadTextOne": "Faster",
        "FomoAppDwnld@CasinoHeadTextTwo": "Safer",
        "FomoAppDwnld@CasinoHeadTextThree": "Better",
        "FomoAppDwnld@CasinoBodyTextOne": "When it comes to online real-money gambling apps, being fast is the foremost aspect to consider. FOMO7 apk is user-friendly and quick for navigation and loading.",
        "FomoAppDwnld@CasinoBodyTextTwo": "Your transactions are safe and secure on our platform. In addition to that, you have all the popular payment methods.",
        "FomoAppDwnld@CasinoBodyTextThree": "As you have both casino & sports betting on the same platform, your experience is undoubtedly better.",
        "FomoAppDwnld@BonusContentTitleOne": "Welcome Bonus",
        "FomoAppDwnld@BonusContentTitleTwo": "Referral Bonus",
        "FomoAppDwnld@BonusContentTitleThree": "Redeposit Bonus",
        "FomoAppDwnld@BonusContentBodyOne": "New users can claim a 700% Welcome Bonus upon completing the FOMO7 casino app download and making their first deposit. This is the biggest casino app download bonus in India.",
        "FomoAppDwnld@BonusContentBodyTwo": "The referral bonus is the most popular casino app bonus on FOMO7. When you refer your friends, and they join FOMO7, you will be eligible for a 21% Referral Bonus.",
        "FomoAppDwnld@BonusContentBodyThree": "On every deposit, except the first one, you will receive a 5% Redeposit Bonus. It is a recurring free casino bonus on successful deposits on FOMO7.",
        "FomoAppDwnld@OnlineTextOne": "Aviator game online download",
        "FomoAppDwnld@OnlineTextTwo": "Dragon Tiger game download",
        "FomoAppDwnld@OnlineTextThree": "Roulette game app download",
        "FomoAppDwnld@OnlineTextFour": "Lightning Roulette app download",
        "FomoAppDwnld@FAQHeading": "FAQs",
        "FomoAppDwnld@DownloadTextOne": "After successfully downloading the app, the next step is registration and ",
        "FomoAppDwnld@DownloadTextTwo": "making your first deposit on FOMO7.",
        "FomoAppDwnld@ClickOnMenu": "Click on the ",
        "FomoAppDwnld@Menu": "Menu ",
        "FomoAppDwnld@Options": "Option",
        "FomoAppDwnld@Download": "Download The ",
        "FomoAppDwnld@TheApp": "App File",
        "FomoAppDwnld@WaitForFile": "Wait For The File To ",
        "FomoAppDwnld@ToDownload": "Download",
        "FomoAppDwnld@InstallTheFomoApp": "Install The FOMO7 App On Your ",
        "FomoAppDwnld@SmartPhoneFomo": "Smartphone",

        "PROMOTIONS@REFILLBONUS": "Redeposit bonus",
        "PROMOTIONSTITLE@REFILL": "Redeposit bonus",
        "LOYALTY@RULESREGULATIONS":
          "Rules & Regulations",
        "LOYALTY@RULESTEXT1":
          " If Turnover is below the required amount over a period of 1 month, then the player will be automatically downgraded to the prior tier, for example, if you are a gold tier member and you are unable to maintain a turnover of 40L over a period of 1 month, your loyalty program tier will shift to Silver & so on.",
        "LOYALTY@RULESTEXT2":
          " There will be no change in withdrawal amount terms & conditions",
        "LOYALTY@RULESTEXT3":
          "  The company reserves the right to cancel the bonus and refuse to grant it to the client without explaining the reasons if during the check of the gaming account, there are suspicions of violation of the fairness of the game or the use of strategies that the company at its discretion considers to be malicious. In disputable situations, the decision of the authorized officials of the company is final.",
        "LOYALTY@RULESTEXT4":
          " In case of violation of the rules, foul play or other fraudulent activities, all such accounts will be excluded from participation",
        "LOYALTY@RULESTEXT5":
          " This offer is subject to the general rules of FOMO7",
        "LOYALTY@RULESTEXT6": " 2 % Bonus will be awarded on every redeposit",
        "LOYALTY@RULESTEXT7": " 2% cash back will be awarded on weekly losses",
        "LOYALTY@RULESTEXT8":
          " Additional cashback will be awareded as per the chosen loyalty program tier",
        "TITLE@MARQUE": "FOMO7 -",

        "TITLE@betdailyMarquee": "FOMO7 - ",
        "MANUALDEPOSIT@PleaseVerify":
          "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. FOMO7 WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",

        "TITLE@LOYALTYTAGLINE": "LOYALTY",
        "TITLE@FOMOEDGE": "Discover the FOMO7 EDGE",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
          "PROMOTIONS@CaribbeanLeagueBonus": "Caribbean Royal Bonus",
        "PROMOTIONS@EvolutionRouletteCup": "Evolution Crazy Cup",
        "PROMOTIONS@AviatorRainPromo": "Aviator shower",
        "NOTE@ELITE":'FOMO7 reserves the right to change/modify these conditions at any time without prior notice',
        "PROMOTIONS@LiveJackPot2025": "The Real Deal",
        "PROMOTIONS@InstantChallengePromo":"Instant Challenge 2025"
      },
      40: {
        ..._defaultEObj,
        "Deposit@ReloadBonus": "Redeposit bonus",
        "PROMOTIONS@BigBashBigCash": "Big Bash Big Bonus",
        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "ProBonus@Welcome": "welcome bonus",
        "ProBonus@Reload": "Redeposit bonus",
        "ProBonus@Pregmatic": "Lossback Bonus",
        "ProBonus@Referral": "Referral Bonus",
        "ProBonus@Sub3": "Get upto 5% Bonus On Every Deposit",
        "ProBonus@Sub": " Get a 700% First Time Deposit Bonus",
        "ProBonus@Sub4": " Upto 5% Cashback Instantly In Your Wallet",
        "ProBonus@Sub5": " Refer & get 21% of your friends deposit amount",
        "PROMOTIONSTITLE@CASHBACK": "Lossback Bonus",
        "PROMOTIONS@CASHBACKBONUS": "Lossback bonus",
        "Promotions@ReferralMessageTooltipSecond":
        "We are awaiting your friend's second deposit in order to activate the 7% referral bonus for you and provide your friend with a redeposit bonus of up to 7%.",
      "Promotions@ReferralMessageTooltipThird":
        "We are awaiting your friend's third deposit in order to activate the 7% referral bonus for you and provide your friend with a redeposit bonus of up to 7%.",

        "Promotions@ReferralDesc":
        "Has signed up, We are waiting for Their first deposit to activate your 7% referral bonus and up to 700% welcome bonus for your friend",

        "FomoAppDwnld@BrandName": "Fomo7",
        "FomoApp@Online": "Online",
        "FomoApp@Betting": "Betting App",
        "FomoAppDwnld@BettingApk": "Best Casino & Sports Betting Apk",
        "FomoAppDwnld@TheFomoAppIsAvailable": "In the digital era, enjoy your favourite casino games and indulge in sports betting with the FOMO7 online betting app. With a user-friendly interface and seamless navigation, the FOMO7 app is among the top betting apps in India.",
        "FomoAppDwnld@App": "App",
        "FomoAppDwnld@Pages": "Pages",
        "FomoAppDwnld@ScreenshotTextOne": "Apart from over 700 casino games and sports betting, exciting bonuses, promotions, and top-notch customer",
        "FomoAppDwnld@ScreenshotTextTwo": "support await casino gaming enthusiasts on India's best online betting app.",
        "FomoAppDwnld@AppFeatures": "Key Features of ",
        "FomoAppDwnld@BrandNameFeature": "FOMO7",
        "FomoAppDwnld@AppOnlineApp": "Online Betting App",
        "FomoAppDwnld@KeyFeatureText": "While online betting apps in India are on the rise, there are no real money games apk betting apps that can match the FOMO7 app. The following are some of the key features of the FOMO7 online betting app",
        "FomoAppDwnld@BenefitsStep1": "An uncluttered interface and smooth navigation.",
        "FomoAppDwnld@BenefitsStep2": "Enjoy the best of both worlds - casino games and sports betting.",
        "FomoAppDwnld@BenefitsStep3": "The app runs smoothly and only takes up a little space on the smartphone device.",
        "FomoAppDwnld@BenefitsStep4": "Free to use with no subscription or hidden charges.",
        "FomoAppDwnld@BenefitsStep5": "One of the top legal betting apps in India that keeps users' data safe and secure.",
        "FomoAppDwnld@BenefitsStep6": "The customer support team is available 24x7 via the app.",
        "FomoAppDwnld@HowTo": "How to download ",
        "FomoAppDwnld@ApkFor": " Apk for",
        "FomoAppDwnld@Android": "Android?",
        "FomoAppDwnld@TechnicallyFomoApkOne": "The FOMO7 app has sports betting and a wide range of casino games for Android smartphones. The FOMO7 Android app is easy to",
        "FomoAppDwnld@TechnicallyFomoApkTwo": " download and install on your phone. Follow the below steps to download the FOMO7 app",
        "YoloAppDwnld@WaitForTheFile": "Wait for the file to download",
        "YoloAppDwnld@InstallTheYoloApp": "Install the FOMO7 App on your Smartphone",
        "FomoAppDwnld@SattaApp": " is the best online",
        "FomoAppDwnld@SattaAppwhy": "Why ",
        "FomoAppDwnld@SattaAppIndia": "satta app in India?",
        "FomoAppDwnld@SattaAppText": "We have listed the top 3 reasons for choosing FOMO7 as your online satta app.",
        "FomoAppDwnld@SattaTextOne": "Let’s find out how to download the best",
        "FomoAppDwnld@SattaTextTwo": "online satta app in India.",
        "FomoAppDwnld@NumberOne": "01",
        "FomoAppDwnld@NumberTwo": "02",
        "FomoAppDwnld@NumberOneText": "You can scan the QR code to download the FOMO7 app via the FOMO7 website from a PC or a laptop. The QR code is located at the top right corner of the FOMO7 homepage. Follow the instructions to complete the app's installation on your phone.",
        "FomoAppDwnld@NumberTwoText": "If you are browsing the FOMO7 website on your smartphone, tap the menu button on the FOMO7 homepage. Click on Download Android App and follow the steps to complete the installation of the bet apk download file.",
        "FomoAppDwnld@SignUp": " App Signup",
        "FomoAppDwnld@DepositText": "First Deposit on ",
        "FomoAppDwnld@SignUpTextOne": "Signing up on the FOMO7 app is instant. Before you can make the first FOMO7 login, you need to create a username and password and enter a few personal details like name, e-mail ID, mobile number, etc.",
        "FomoAppDwnld@SignUpTextTwo": "To make a deposit on the FOMO7 app, open the app and log in with your credentials. Tap on the green wallet icon and select one of the four options—Fast Bank, Fast UPI, Regular UPI, or Net Banking—on the app",
        "FomoAppDwnld@Bonus&Promo": "Bonuses & Promotions",
        "FomoAppDwnld@BonusOn": "On ",
        "FomoAppDwnld@PromotionTextOne": "There are plenty of bonuses and promotions available on the FOMO7 app. You can earn casino and sports betting bonuses on FOMO7 ",
        "FomoAppDwnld@PromotionTextTwo": "regularly. Here are the list of bonuses available on FOMO7",
        "FomoAppDwnld@PromotionTextThree": "Complete the FOMO7 online casino app download, keep depositing,",
        "FomoAppDwnld@PromotionTextFour": "and earn bonuses.",
        "FomoAppDwnld@CasinoApp": " Casino App",
        "FomoAppDwnld@CasinoLineTextOne": "The FOMO7 app is home to 700+ exciting and rewarding online casino games. There are different types of casino games, like slots, crash, dice, wheel games, and more. Among the many casino apps in India, FOMO7 casino stands out for its wide range of games. Here is a list of popular games available to download and play on the best online casino mobile app:",
        "FomoAppDwnld@CasinoLineTextFour": "The FOMO7 online casino app download option is ",
        "FomoAppDwnld@CasinoLineTextFive": "available on the homepage.",
        "FomoAppDwnld@SportsBet": "Sports Betting on ",
        "FomoAppDwnld@BettingApp": "Betting App",
        "FomoAppDwnld@SportsTextOne": "The FOMO7 betting app hosts sporting events happening worldwide. The cricket betting app covers various tournaments, such as the IPL, BBL, ODI World Cup, T20 World Cup, and more international and domestic tournaments. This best online cricket betting app in India has exciting betting markets and top betting odds. ",
        "FomoAppDwnld@SportsTextTwo": "The app caters to the needs of different cricket betting enthusiasts. With live cricket apk download, you can download the app instantly. As plenty of bonuses and engaging promotions are available. ",
        "FomoAppDwnld@SportsTextThree": "Among all the football betting apps in India, FOMO7 is the best football betting app. What makes FOMO7 the best football betting app in India is that it covers different popular leagues like EPL, La Liga, Bundesliga, UEFA Champions League, and more. The FOMO7 app also lets betting enthusiasts bet on popular football events like the FIFA World Cup, UEFA Euro Cup, Copa America, and more. It is the best tennis betting app as well, as it covers all grand slams and ATP events worldwide. ",
        "FomoAppDwnld@SportsTextFour": "The biggest advantage of sports betting is that you can do pre-match betting and in-play or betting on live matches. That’s why it stands as the best sports betting app in India.",
        "FomoAppDwnld@BettingTextOne": "No T20 event is more celebrated than the IPL. With over 70 matches played in the league, cricket fans have a gala time during the two-month-long tournament. The IPL online betting app offers the best odds, unique betting markets, exciting promotions, and bonuses, making it the best IPL satta app in India.",
        "FomoAppDwnld@BettingHeading": "Online IPL Betting App",
        "FomoAppDwnld@FAQquestionOne": "Is it easy to do FOMO7 apk download?",
        "FomoAppDwnld@FAQquestionTwo": "Which sports can I enjoy betting on after the FOMO7 online betting app download?",
        "FomoAppDwnld@FAQquestionThree": "Which tournaments can I bet on after the FOMO7 Indian cricket betting app download?",
        "FomoAppDwnld@FAQanswerOne": "Yes, you can do FOMO7 online betting app download with some quick and easy steps by visiting the brand website.",
        "FomoAppDwnld@FAQanswerTwo": "After the FOMO7 India betting app download, you can bet on cricket, football, tennis, and more.",
        "FomoAppDwnld@FAQanswerThree": "After FOMO7 Indian betting app download, you can access various cricket tournaments like the World Cup, Champions Trophy, ICC T20 World Cup, and popular T20 domestic leagues like IPL, BBL, PSL, and more.",
        "FomoAppDwnld@CasinoHeadTextOne": "Faster",
        "FomoAppDwnld@CasinoHeadTextTwo": "Safer",
        "FomoAppDwnld@CasinoHeadTextThree": "Better",
        "FomoAppDwnld@CasinoBodyTextOne": "When it comes to online real-money gambling apps, being fast is the foremost aspect to consider. FOMO7 apk is user-friendly and quick for navigation and loading.",
        "FomoAppDwnld@CasinoBodyTextTwo": "Your transactions are safe and secure on our platform. In addition to that, you have all the popular payment methods.",
        "FomoAppDwnld@CasinoBodyTextThree": "As you have both casino & sports betting on the same platform, your experience is undoubtedly better.",
        "FomoAppDwnld@BonusContentTitleOne": "Welcome Bonus",
        "FomoAppDwnld@BonusContentTitleTwo": "Referral Bonus",
        "FomoAppDwnld@BonusContentTitleThree": "Redeposit Bonus",
        "FomoAppDwnld@BonusContentBodyOne": "New users can claim a 700% Welcome Bonus upon completing the FOMO7 casino app download and making their first deposit. This is the biggest casino app download bonus in India.",
        "FomoAppDwnld@BonusContentBodyTwo": "The referral bonus is the most popular casino app bonus on FOMO7. When you refer your friends, and they join FOMO7, you will be eligible for a 21% Referral Bonus.",
        "FomoAppDwnld@BonusContentBodyThree": "On every deposit, except the first one, you will receive a 5% Redeposit Bonus. It is a recurring free casino bonus on successful deposits on FOMO7.",
        "FomoAppDwnld@OnlineTextOne": "Aviator game online download",
        "FomoAppDwnld@OnlineTextTwo": "Dragon Tiger game download",
        "FomoAppDwnld@OnlineTextThree": "Roulette game app download",
        "FomoAppDwnld@OnlineTextFour": "Lightning Roulette app download",
        "FomoAppDwnld@FAQHeading": "FAQs",
        "FomoAppDwnld@DownloadTextOne": "After successfully downloading the app, the next step is registration and ",
        "FomoAppDwnld@DownloadTextTwo": "making your first deposit on FOMO7.",
        "FomoAppDwnld@ClickOnMenu": "Click on the ",
        "FomoAppDwnld@Menu": "Menu ",
        "FomoAppDwnld@Options": "Option",
        "FomoAppDwnld@Download": "Download The ",
        "FomoAppDwnld@TheApp": "App File",
        "FomoAppDwnld@WaitForFile": "Wait For The File To ",
        "FomoAppDwnld@ToDownload": "Download",
        "FomoAppDwnld@InstallTheFomoApp": "Install The FOMO7 App On Your ",
        "FomoAppDwnld@SmartPhoneFomo": "Smartphone",

        "PROMOTIONS@REFILLBONUS": "Redeposit bonus",
        "PROMOTIONSTITLE@REFILL": "Redeposit bonus",
        "USERREGPOPUP@Text": "Following details are required to fill the Bank form",
        "LOYALTY@RULESREGULATIONS":
          "Rules & Regulations",
        "LOYALTY@RULESTEXT1":
          " If Turnover is below the required amount over a period of 1 month, then the player will be automatically downgraded to the prior tier, for example, if you are a gold tier member and you are unable to maintain a turnover of 40L over a period of 1 month, your loyalty program tier will shift to Silver & so on.",
        "LOYALTY@RULESTEXT2":
          " There will be no change in withdrawal amount terms & conditions",
        "LOYALTY@RULESTEXT3":
          "  The company reserves the right to cancel the bonus and refuse to grant it to the client without explaining the reasons if during the check of the gaming account, there are suspicions of violation of the fairness of the game or the use of strategies that the company at its discretion considers to be malicious. In disputable situations, the decision of the authorized officials of the company is final.",
        "LOYALTY@RULESTEXT4":
          " In case of violation of the rules, foul play or other fraudulent activities, all such accounts will be excluded from participation",
        "LOYALTY@RULESTEXT5":
          " This offer is subject to the general rules of FOMO7",
        "LOYALTY@RULESTEXT6": " 2 % Bonus will be awarded on every redeposit",
        "LOYALTY@RULESTEXT7": " 2% cash back will be awarded on weekly losses",
        "LOYALTY@RULESTEXT8":
          " Additional cashback will be awareded as per the chosen loyalty program tier",
        "TITLE@MARQUE": "FOMO7 -",

        "TITLE@betdailyMarquee": "FOMO7 - ",
        "MANUALDEPOSIT@PleaseVerify":
          "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. FOMO7 WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",

        "TITLE@LOYALTYTAGLINE": "LOYALTY",
        "TITLE@FOMOEDGE": "Discover the FOMO7 EDGE",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of Profit & Loss to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total Profit & Loss to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONS@dailyDepoBonusText":
          "FOMO7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on FOMO7.",
          "NOTE@ELITE":'FOMO7 reserves the right to change/modify these conditions at any time without prior notice',
          "PROMOTIONS@CaribbeanLeagueBonus": "Caribbean Royal Bonus",
        "PROMOTIONS@EvolutionRouletteCup": "Evolution Crazy Cup",
        "PROMOTIONS@AviatorRainPromo": "Aviator shower",
        "PROMOTIONS@LiveJackPot2025": "The Real Deal",
        "PROMOTIONS@InstantChallengePromo":"Instant Challenge 2025"
      },
      46: {
        ..._defaultEObj,
        "FunAppDwnld@BrandName": "Fun88",
        "FunApp@Online": "Online",
        "FunApp@Betting": "Betting App",
        "FunAppDwnld@BettingApk": "Best Casino & Sports Betting Apk",
        "FunAppDwnld@TheFunAppIsAvailable": "In the digital era, enjoy your favourite casino games and indulge in sports betting with the Fun88 online betting app. With a user-friendly interface and seamless navigation, the Fun88 app is among the top betting apps in India.",
        "FunAppDwnld@App": "App",
        "FunAppDwnld@Pages": "Pages",
        "FunAppDwnld@ScreenshotTextOne": "Apart from over 700 casino games and sports betting, exciting bonuses, promotions, and top-notch customer",
        "FunAppDwnld@ScreenshotTextTwo": "support await casino gaming enthusiasts on India's best online betting app.",
        "FunAppDwnld@AppFeatures": "Key Features of ",
        "FunAppDwnld@BrandNameFeature": "Fun88",
        "FunAppDwnld@AppOnlineApp": "Online Betting App",
        "FunAppDwnld@KeyFeatureText": "While online betting apps in India are on the rise, there are no real money games apk betting apps that can match the Fun88 app. The following are some of the key features of the Fun88 online betting app",
        "FunAppDwnld@BenefitsStep1": "An uncluttered interface and smooth navigation.",
        "FunAppDwnld@BenefitsStep2": "Enjoy the best of both worlds - casino games and sports betting.",
        "FunAppDwnld@BenefitsStep3": "The app runs smoothly and only takes up a little space on the smartphone device.",
        "FunAppDwnld@BenefitsStep4": "Free to use with no subscription or hidden charges.",
        "FunAppDwnld@BenefitsStep5": "One of the top legal betting apps in India that keeps users' data safe and secure.",
        "FunAppDwnld@BenefitsStep6": "The customer support team is available 24x7 via the app.",
        "FunAppDwnld@HowTo": "How to download ",
        "FunAppDwnld@ApkFor": " Apk for",
        "FunAppDwnld@Android": "Android?",
        "FunAppDwnld@TechnicallyFunApkOne": "The Fun88 app has sports betting and a wide range of casino games for Android smartphones. The Fun88 Android app is easy to",
        "FunAppDwnld@TechnicallyFunApkTwo": " download and install on your phone. Follow the below steps to download the Fun88 app",
        "YoloAppDwnld@WaitForTheFile": "Wait for the file to download",
        "YoloAppDwnld@InstallTheYoloApp": "Install the Fun88 App on your Smartphone",
        "FunAppDwnld@SattaApp": " is the best online",
        "FunAppDwnld@SattaAppwhy": "Why ",
        "FunAppDwnld@SattaAppIndia": "satta app in India?",
        "FunAppDwnld@SattaAppText": "We have listed the top 3 reasons for choosing Fun88 as your online satta app.",
        "FunAppDwnld@SattaTextOne": "Let’s find out how to download the best",
        "FunAppDwnld@SattaTextTwo": "online satta app in India.",
        "FunAppDwnld@NumberOne": "01",
        "FunAppDwnld@NumberTwo": "02",
        "FunAppDwnld@NumberOneText": "You can scan the QR code to download the Fun88 app via the Fun88 website from a PC or a laptop. The QR code is located at the top right corner of the Fun88 homepage. Follow the instructions to complete the app's installation on your phone.",
        "FunAppDwnld@NumberTwoText": "If you are browsing the Fun88 website on your smartphone, tap the menu button on the Fun88 homepage. Click on Download Android App and follow the steps to complete the installation of the bet apk download file.",
        "FunAppDwnld@SignUp": " App Signup",
        "FunAppDwnld@DepositText": "First Deposit on ",
        "FunAppDwnld@SignUpTextOne": "Signing up on the Fun88 app is instant. Before you can make the first Fun88 login, you need to create a username and password and enter a few personal details like name, e-mail ID, mobile number, etc.",
        "FunAppDwnld@SignUpTextTwo": "To make a deposit on the Fun88 app, open the app and log in with your credentials. Tap on the green wallet icon and select one of the four options—Fast Bank, Fast UPI, Regular UPI, or Net Banking—on the app",
        "FunAppDwnld@Bonus&Promo": "Bonuses & Promotions",
        "FunAppDwnld@BonusOn": "On ",
        "FunAppDwnld@PromotionTextOne": "There are plenty of bonuses and promotions available on the Fun88 app. You can earn casino and sports betting bonuses on Fun88 ",
        "FunAppDwnld@PromotionTextTwo": "regularly. Here are the list of bonuses available on Fun88",
        "FunAppDwnld@PromotionTextThree": "Complete the Fun88 online casino app download, keep depositing,",
        "FunAppDwnld@PromotionTextFour": "and earn bonuses.",
        "FunAppDwnld@CasinoApp": " Casino App",
        "FunAppDwnld@CasinoLineTextOne": "The Fun88 app is home to 700+ exciting and rewarding online casino games. There are different types of casino games, like slots, crash, dice, wheel games, and more. Among the many casino apps in India, Fun88 casino stands out for its wide range of games. Here is a list of popular games available to download and play on the best online casino mobile app:",
        "FunAppDwnld@CasinoLineTextFour": "The Fun88 online casino app download option is ",
        "FunAppDwnld@CasinoLineTextFive": "available on the homepage.",
        "FunAppDwnld@SportsBet": "Sports Betting on ",
        "FunAppDwnld@BettingApp": "Betting App",
        "FunAppDwnld@SportsTextOne": "The Fun88 betting app hosts sporting events happening worldwide. The cricket betting app covers various tournaments, such as the IPL, BBL, ODI World Cup, T20 World Cup, and more international and domestic tournaments. This best online cricket betting app in India has exciting betting markets and top betting odds. ",
        "FunAppDwnld@SportsTextTwo": "The app caters to the needs of different cricket betting enthusiasts. With live cricket apk download, you can download the app instantly. As plenty of bonuses and engaging promotions are available. ",
        "FunAppDwnld@SportsTextThree": "Among all the football betting apps in India, Fun88 is the best football betting app. What makes Fun88 the best football betting app in India is that it covers different popular leagues like EPL, La Liga, Bundesliga, UEFA Champions League, and more. The Fun88 app also lets betting enthusiasts bet on popular football events like the FIFA World Cup, UEFA Euro Cup, Copa America, and more. It is the best tennis betting app as well, as it covers all grand slams and ATP events worldwide. ",
        "FunAppDwnld@SportsTextFour": "The biggest advantage of sports betting is that you can do pre-match betting and in-play or betting on live matches. That’s why it stands as the best sports betting app in India.",
        "FunAppDwnld@BettingTextOne": "No T20 event is more celebrated than the IPL. With over 70 matches played in the league, cricket fans have a gala time during the two-month-long tournament. The IPL online betting app offers the best odds, unique betting markets, exciting promotions, and bonuses, making it the best IPL satta app in India.",
        "FunAppDwnld@BettingHeading": "Online IPL Betting App",
        "FunAppDwnld@FAQquestionOne": "Is it easy to do Fun88 apk download?",
        "FunAppDwnld@FAQquestionTwo": "Which sports can I enjoy betting on after the Fun88 online betting app download?",
        "FunAppDwnld@FAQquestionThree": "Which tournaments can I bet on after the Fun88 Indian cricket betting app download?",
        "FunAppDwnld@FAQanswerOne": "Yes, you can do Fun88 online betting app download with some quick and easy steps by visiting the brand website.",
        "FunAppDwnld@FAQanswerTwo": "After the Fun88 India betting app download, you can bet on cricket, football, tennis, and more.",
        "FunAppDwnld@FAQanswerThree": "After Fun88 Indian betting app download, you can access various cricket tournaments like the World Cup, Champions Trophy, ICC T20 World Cup, and popular T20 domestic leagues like IPL, BBL, PSL, and more.",
        "FunAppDwnld@CasinoHeadTextOne": "Faster",
        "FunAppDwnld@CasinoHeadTextTwo": "Safer",
        "FunAppDwnld@CasinoHeadTextThree": "Better",
        "FunAppDwnld@CasinoBodyTextOne": "When it comes to online real-money gambling apps, being fast is the foremost aspect to consider. Fun88 apk is user-friendly and quick for navigation and loading.",
        "FunAppDwnld@CasinoBodyTextTwo": "Your transactions are safe and secure on our platform. In addition to that, you have all the popular payment methods.",
        "FunAppDwnld@CasinoBodyTextThree": "As you have both casino & sports betting on the same platform, your experience is undoubtedly better.",
        "FunAppDwnld@BonusContentTitleOne": "Welcome Bonus",
        "FunAppDwnld@BonusContentTitleTwo": "Referral Bonus",
        "FunAppDwnld@BonusContentTitleThree": "Refill Bonus",
        "FunAppDwnld@BonusContentBodyOne": "New users can claim a 388% Welcome Bonus upon completing the Fun88 casino app download and making their first deposit. This is the biggest casino app download bonus in India.",
        "FunAppDwnld@BonusContentBodyTwo": "The referral bonus is the most popular casino app bonus on Fun88. When you refer your friends, and they join Fun88, you will be eligible for a 24% Referral Bonus.",
        "FunAppDwnld@BonusContentBodyThree": "On every deposit, except the first one, you will receive a 5% Refill Bonus. It is a recurring free casino bonus on successful deposits on Fun88.",
        "FunAppDwnld@OnlineTextOne": "Aviator game online download",
        "FunAppDwnld@OnlineTextTwo": "Dragon Tiger game download",
        "FunAppDwnld@OnlineTextThree": "Roulette game app download",
        "FunAppDwnld@OnlineTextFour": "Lightning Roulette app download",
        "FunAppDwnld@FAQHeading": "FAQs",
        "FunAppDwnld@DownloadTextOne": "After successfully downloading the app, the next step is registration and ",
        "FunAppDwnld@DownloadTextTwo": "making your first deposit on Fun88.",
        "FunAppDwnld@ClickOnMenu": "Click on the ",
        "FunAppDwnld@Menu": "Menu ",
        "FunAppDwnld@Options": "Option",
        "FunAppDwnld@Download": "Download The ",
        "FunAppDwnld@TheApp": "App File",
        "FunAppDwnld@WaitForFile": "Wait For The File To ",
        "FunAppDwnld@ToDownload": "Download",
        "FunAppDwnld@InstallTheFunApp": "Install The Fun88 App On Your ",
        "FunAppDwnld@SmartPhoneFun": "Smartphone",
        "MANUALDEPOSIT@PleaseVerify":
        "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME.FUN88 WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",

        "TITLE@LOYALTYTAGLINE": "LOYALTY",
        "TITLE@MARQUE": "FUN88 - Lagao Shart. ",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of P&L to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total P&L to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
          "SLIDERIMAGE@TEENPATTI1": "/static/media/teenpattiHomeSliderFun88.jpg",
          "SLIDERIMAGE@TEENPATTI3": "/static/media/AndarBaharHPNormal.jpg",
          "SLIDERIMAGE@TEENPATTI2": "/static/media/teenpatti2020HPNormal.jpg",
          "COLFIRST@GAME1": "/static/media/wheelGameHPFun88.jpg",
          "COLFIRST@GAME2": "/static/media/liveDiceGameHPFun88.jpg",
          "COLFIRST@GAME3": "/static/media/lucky7HPFun88.jpg",
          "HOMEPAGE@GAME1": "/static/media/CasinoJokerBetHMFun88.jpg",
          "HOMEPAGE@GAME2": "/static/media/casinoPokerHMFun88.jpg",
          "HOMEPAGE@GAME3": "/static/media/casinoFruitRaceHMFun88.jpg",
          "HOMEPAGE@GAME4": "/static/media/andarBaharLiveHMFun88.jpg",
          "HOMEPAGE@GAME5": "/static/media/casinoRouletteHMFun88.jpg",
          "HOMEPAGE@GAME6": "/static/media/wheelBetCasinoNewHMFun88.jpg",
          "HOMEPAGE@GAME7": "/static/media/casinoBetNumberHMFun88.jpg",
          "HOMEPAGE@GAME8": "/static/media/casinoliveDiceHMFun88.jpg",
          "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGameHMFun88.jpg",
          "HOMEPAGE@GAME10": "/static/media/32casinoCardsHMFun88.jpg",
          "DASHBOARD@CHOOSECHANNEL": "Choose One Channel",
          "MANUALDEPOSIT@YoloBankNew": "Copy one of the Bank Details and make payment",
          "LOYALTY@RULESREGULATIONS":"Rules & Regulations",
          "LOYALTY@RULESTEXT1":" Users will be automatically downgraded to the next lowest tier if they fail to reach the required monthly turnover amount. For example, if a user is in the Gold tier and unable to maintain a turnover of 70L in one month, the user's FUN88 loyalty tier is downgraded to Silver.",
          "LOYALTY@RULESTEXT2":" FUN88 reserves the right to cancel the bonus if, during the check of the gaming account, there are suspicions of a violation of the game's fairness or the use of strategies that the company, at its discretion, considers malicious. In disputable situations, the decision of the company's authorized officials is final",
          "LOYALTY@RULESTEXT3":"  If there is any violation of the rules, foul play, or other fraudulent activities, all such accounts will be excluded from participating in this promotion.",
          "LOYALTY@RULESTEXT4":" This promotion is subject to the general rules of FUN88",
          "LOYALTY@RULESTEXT5": " Up to 5% Refill Bonus is given on every deposit",
          "LOYALTY@RULESTEXT6": " Up to 5% Cashback Bonus is given on weekly losses",
          "LOYALTY@RULESTEXT8":"",
          "ProBonus@Sub": " Get a 388% First Time Deposit Bonus",
          "ProBonus@Sub5": " Refer & get 24% of your friends deposit amount",
          "Promotions@ReferralMessageTooltipThird":
          "We are awaiting your friend's third deposit in order to activate the 8% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
          "Promotions@ReferralMessageTooltipSecond": "We are awaiting your friend's second deposit in order to activate the 8% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
          "Promotions@ReferralDesc": "Has signed up, We are waiting for Their first deposit to activate your 8% referral bonus and up to 388% welcome bonus for your friend",
          "NOTE@ELITE":'Fun88 reserves the right to change/modify these conditions at any time without prior notice',
          "PROMOTIONS@InstantChallengePromo":"FUN Instant Challenge 2025",
          "TITLE@PREMIUM_SPORTSBOOK": "B2B",
          "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "B2B Statement",
          "PROMOTIONS@LiveJackPot2025": "The FUN Jackpot",
          "LOYALTY@BLUETIER1ST": "MIN. TURNOVER 0",
          "LOYALTY@BLUETIER2ND": "2% Refill Bonus on Every Deposit",
          "LOYALTY@BLUETIER3RD": "2% Cashback Bonus on Weekly Losses",
          "LOYALTY@BLUETIER4TH": "",
          "LOYALTY@SILVERTIER1ST": "MIN. TURNOVER 25 LAKH",
          "LOYALTY@SILVERTIER2ND": "3% Refill Bonus on Every Deposit",
          "LOYALTY@SILVERTIER3RD": "3% Cashback Bonus on Weekly Losses",
          "LOYALTY@GOLDTIER1ST": "MIN. TURNOVER 70 LAKH",
          "LOYALTY@GOLDTIER2ND": "4% Refill Bonus on Every Deposit",
          "LOYALTY@GOLDTIER4": "4% Cashback Bonus on Weekly Losses",
          "LOYALTY@PLATIUNUM1ST": "MIN. TURNOVER 1 CRORE",
          "LOYALTY@PLATIUNUM2ND": "5% Refill Bonus on Every Deposit",
          "LOYALTY@PLATIUNUM6": "5% Cashback Bonus on Weekly Losses",
        
        },
      47: {
        ..._defaultEObj,
        "FunAppDwnld@BrandName": "Fun88",
        "FunApp@Online": "Online",
        "FunApp@Betting": "Betting App",
        "FunAppDwnld@BettingApk": "Best Casino & Sports Betting Apk",
        "FunAppDwnld@TheFunAppIsAvailable": "In the digital era, enjoy your favourite casino games and indulge in sports betting with the Fun88 online betting app. With a user-friendly interface and seamless navigation, the Fun88 app is among the top betting apps in India.",
        "FunAppDwnld@App": "App",
        "FunAppDwnld@Pages": "Pages",
        "FunAppDwnld@ScreenshotTextOne": "Apart from over 700 casino games and sports betting, exciting bonuses, promotions, and top-notch customer",
        "FunAppDwnld@ScreenshotTextTwo": "support await casino gaming enthusiasts on India's best online betting app.",
        "FunAppDwnld@AppFeatures": "Key Features of ",
        "FunAppDwnld@BrandNameFeature": "Fun88",
        "FunAppDwnld@AppOnlineApp": "Online Betting App",
        "FunAppDwnld@KeyFeatureText": "While online betting apps in India are on the rise, there are no real money games apk betting apps that can match the Fun88 app. The following are some of the key features of the Fun88 online betting app",
        "FunAppDwnld@BenefitsStep1": "An uncluttered interface and smooth navigation.",
        "FunAppDwnld@BenefitsStep2": "Enjoy the best of both worlds - casino games and sports betting.",
        "FunAppDwnld@BenefitsStep3": "The app runs smoothly and only takes up a little space on the smartphone device.",
        "FunAppDwnld@BenefitsStep4": "Free to use with no subscription or hidden charges.",
        "FunAppDwnld@BenefitsStep5": "One of the top legal betting apps in India that keeps users' data safe and secure.",
        "FunAppDwnld@BenefitsStep6": "The customer support team is available 24x7 via the app.",
        "FunAppDwnld@HowTo": "How to download ",
        "FunAppDwnld@ApkFor": " Apk for",
        "FunAppDwnld@Android": "Android?",
        "FunAppDwnld@TechnicallyFunApkOne": "The Fun88 app has sports betting and a wide range of casino games for Android smartphones. The Fun88 Android app is easy to",
        "FunAppDwnld@TechnicallyFunApkTwo": " download and install on your phone. Follow the below steps to download the Fun88 app",
        "YoloAppDwnld@WaitForTheFile": "Wait for the file to download",
        "YoloAppDwnld@InstallTheYoloApp": "Install the Fun88 App on your Smartphone",
        "FunAppDwnld@SattaApp": " is the best online",
        "FunAppDwnld@SattaAppwhy": "Why ",
        "FunAppDwnld@SattaAppIndia": "satta app in India?",
        "FunAppDwnld@SattaAppText": "We have listed the top 3 reasons for choosing Fun88 as your online satta app.",
        "FunAppDwnld@SattaTextOne": "Let’s find out how to download the best",
        "FunAppDwnld@SattaTextTwo": "online satta app in India.",
        "FunAppDwnld@NumberOne": "01",
        "FunAppDwnld@NumberTwo": "02",
        "FunAppDwnld@NumberOneText": "You can scan the QR code to download the Fun88 app via the Fun88 website from a PC or a laptop. The QR code is located at the top right corner of the Fun88 homepage. Follow the instructions to complete the app's installation on your phone.",
        "FunAppDwnld@NumberTwoText": "If you are browsing the Fun88 website on your smartphone, tap the menu button on the Fun88 homepage. Click on Download Android App and follow the steps to complete the installation of the bet apk download file.",
        "FunAppDwnld@SignUp": " App Signup",
        "FunAppDwnld@DepositText": "First Deposit on ",
        "FunAppDwnld@SignUpTextOne": "Signing up on the Fun88 app is instant. Before you can make the first Fun88 login, you need to create a username and password and enter a few personal details like name, e-mail ID, mobile number, etc.",
        "FunAppDwnld@SignUpTextTwo": "To make a deposit on the Fun88 app, open the app and log in with your credentials. Tap on the green wallet icon and select one of the four options—Fast Bank, Fast UPI, Regular UPI, or Net Banking—on the app",
        "FunAppDwnld@Bonus&Promo": "Bonuses & Promotions",
        "FunAppDwnld@BonusOn": "On ",
        "FunAppDwnld@PromotionTextOne": "There are plenty of bonuses and promotions available on the Fun88 app. You can earn casino and sports betting bonuses on Fun88 ",
        "FunAppDwnld@PromotionTextTwo": "regularly. Here are the list of bonuses available on Fun88",
        "FunAppDwnld@PromotionTextThree": "Complete the Fun88 online casino app download, keep depositing,",
        "FunAppDwnld@PromotionTextFour": "and earn bonuses.",
        "FunAppDwnld@CasinoApp": " Casino App",
        "FunAppDwnld@CasinoLineTextOne": "The Fun88 app is home to 700+ exciting and rewarding online casino games. There are different types of casino games, like slots, crash, dice, wheel games, and more. Among the many casino apps in India, Fun88 casino stands out for its wide range of games. Here is a list of popular games available to download and play on the best online casino mobile app:",
        "FunAppDwnld@CasinoLineTextFour": "The Fun88 online casino app download option is ",
        "FunAppDwnld@CasinoLineTextFive": "available on the homepage.",
        "FunAppDwnld@SportsBet": "Sports Betting on ",
        "FunAppDwnld@BettingApp": "Betting App",
        "FunAppDwnld@SportsTextOne": "The Fun88 betting app hosts sporting events happening worldwide. The cricket betting app covers various tournaments, such as the IPL, BBL, ODI World Cup, T20 World Cup, and more international and domestic tournaments. This best online cricket betting app in India has exciting betting markets and top betting odds. ",
        "FunAppDwnld@SportsTextTwo": "The app caters to the needs of different cricket betting enthusiasts. With live cricket apk download, you can download the app instantly. As plenty of bonuses and engaging promotions are available. ",
        "FunAppDwnld@SportsTextThree": "Among all the football betting apps in India, Fun88 is the best football betting app. What makes Fun88 the best football betting app in India is that it covers different popular leagues like EPL, La Liga, Bundesliga, UEFA Champions League, and more. The Fun88 app also lets betting enthusiasts bet on popular football events like the FIFA World Cup, UEFA Euro Cup, Copa America, and more. It is the best tennis betting app as well, as it covers all grand slams and ATP events worldwide. ",
        "FunAppDwnld@SportsTextFour": "The biggest advantage of sports betting is that you can do pre-match betting and in-play or betting on live matches. That’s why it stands as the best sports betting app in India.",
        "FunAppDwnld@BettingTextOne": "No T20 event is more celebrated than the IPL. With over 70 matches played in the league, cricket fans have a gala time during the two-month-long tournament. The IPL online betting app offers the best odds, unique betting markets, exciting promotions, and bonuses, making it the best IPL satta app in India.",
        "FunAppDwnld@BettingHeading": "Online IPL Betting App",
        "FunAppDwnld@FAQquestionOne": "Is it easy to do Fun88 apk download?",
        "FunAppDwnld@FAQquestionTwo": "Which sports can I enjoy betting on after the Fun88 online betting app download?",
        "FunAppDwnld@FAQquestionThree": "Which tournaments can I bet on after the Fun88 Indian cricket betting app download?",
        "FunAppDwnld@FAQanswerOne": "Yes, you can do Fun88 online betting app download with some quick and easy steps by visiting the brand website.",
        "FunAppDwnld@FAQanswerTwo": "After the Fun88 India betting app download, you can bet on cricket, football, tennis, and more.",
        "FunAppDwnld@FAQanswerThree": "After Fun88 Indian betting app download, you can access various cricket tournaments like the World Cup, Champions Trophy, ICC T20 World Cup, and popular T20 domestic leagues like IPL, BBL, PSL, and more.",
        "FunAppDwnld@CasinoHeadTextOne": "Faster",
        "FunAppDwnld@CasinoHeadTextTwo": "Safer",
        "FunAppDwnld@CasinoHeadTextThree": "Better",
        "FunAppDwnld@CasinoBodyTextOne": "When it comes to online real-money gambling apps, being fast is the foremost aspect to consider. Fun88 apk is user-friendly and quick for navigation and loading.",
        "FunAppDwnld@CasinoBodyTextTwo": "Your transactions are safe and secure on our platform. In addition to that, you have all the popular payment methods.",
        "FunAppDwnld@CasinoBodyTextThree": "As you have both casino & sports betting on the same platform, your experience is undoubtedly better.",
        "FunAppDwnld@BonusContentTitleOne": "Welcome Bonus",
        "FunAppDwnld@BonusContentTitleTwo": "Referral Bonus",
        "FunAppDwnld@BonusContentTitleThree": "Refill Bonus",
        "FunAppDwnld@BonusContentBodyOne": "New users can claim a 388% Welcome Bonus upon completing the Fun88 casino app download and making their first deposit. This is the biggest casino app download bonus in India.",
        "FunAppDwnld@BonusContentBodyTwo": "The referral bonus is the most popular casino app bonus on Fun88. When you refer your friends, and they join Fun88, you will be eligible for a 24% Referral Bonus.",
        "FunAppDwnld@BonusContentBodyThree": "On every deposit, except the first one, you will receive a 5% Refill Bonus. It is a recurring free casino bonus on successful deposits on Fun88.",
        "FunAppDwnld@OnlineTextOne": "Aviator game online download",
        "FunAppDwnld@OnlineTextTwo": "Dragon Tiger game download",
        "FunAppDwnld@OnlineTextThree": "Roulette game app download",
        "FunAppDwnld@OnlineTextFour": "Lightning Roulette app download",
        "FunAppDwnld@FAQHeading": "FAQs",
        "FunAppDwnld@DownloadTextOne": "After successfully downloading the app, the next step is registration and ",
        "FunAppDwnld@DownloadTextTwo": "making your first deposit on Fun88.",
        "FunAppDwnld@ClickOnMenu": "Click on the ",
        "FunAppDwnld@Menu": "Menu ",
        "FunAppDwnld@Options": "Option",
        "FunAppDwnld@Download": "Download The ",
        "FunAppDwnld@TheApp": "App File",
        "FunAppDwnld@WaitForFile": "Wait For The File To ",
        "FunAppDwnld@ToDownload": "Download",
        "FunAppDwnld@InstallTheFunApp": "Install The Fun88 App On Your ",
        "FunAppDwnld@SmartPhoneFun": "Smartphone",
        "MANUALDEPOSIT@PleaseVerify":
        "PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME.FUN88 WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.",

        "TITLE@LOYALTYTAGLINE": "LOYALTY",
        "TITLE@MARQUE": "FUN88 - Lagao Shart. ",
        "PROMOTIONSWELCOME@TERMS5":
          "The turnover requirements must be met within 7 days after your first deposit if the turnover requirement is not completed within 7 days then the bonus will expire.",

        "PROMOTIONSWELCOME@TERMS6":
          "Turnover amount is calculated as ‘Valid Turnover’ and the formula for valid turnover is the following: The ratio of your absolute value of P&L to the total stake must be greater than or equal to 50%.The valid turnover is calculated by ‘market’ for sports or by ‘round’ for casinos. So you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino, but the total P&L to the total stake ratio must be greater than or equal to 50%.Please note that in exchange, match odds provided by betfair and multiple bookmakers offered in exchange will be considered as 1 market.",

        "PROMOTIONSWELCOME@TERMS4":
          "The bonus amount must be rolled over 24  times.Turnover generated any where across the platform will be calculated towards the turnover requirements.",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "We here at YOLO 247 are elated that you decided to trust us and make your first deposit on our platform. We love to pamper our customers and hence you deserve a gift on making your first deposit here. Read below to learn how you can avail this bonus of 400% on your deposit amount with no maximum limit",
        "PROMOTIONSWELCOME@STEPS":
          "Step 1: Complete your RO Requirement of 24X your bonus amount and the bonus amount will be automatically credited to your wallet. Now you may wonder, what is a RO Requirement? Well, read further below to understand.",

        "PROMOTIONSWELCOME@ROREQ":
          "In other words, bets must be with odds of at least Decimal 1.50 to be contributed towards bonus rollover requirements.",
        "PROMOTIONSWELCOME@ROEXMPLE3":
          "c) You win Rs.5 and your stake was Rs.100 So valid RO = (|5|/100) = 5% Hence your valid RO = 0 as the 5% < 50%.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
          "SLIDERIMAGE@TEENPATTI1": "/static/media/teenpattiHomeSliderFun88.jpg",
          "SLIDERIMAGE@TEENPATTI3": "/static/media/AndarBaharHPNormal.jpg",
          "SLIDERIMAGE@TEENPATTI2": "/static/media/teenpatti2020HPNormal.jpg",
          "COLFIRST@GAME1": "/static/media/wheelGameHPFun88.jpg",
          "COLFIRST@GAME2": "/static/media/liveDiceGameHPFun88.jpg",
          "COLFIRST@GAME3": "/static/media/lucky7HPFun88.jpg",
          "HOMEPAGE@GAME1": "/static/media/CasinoJokerBetHMFun88.jpg",
          "HOMEPAGE@GAME2": "/static/media/casinoPokerHMFun88.jpg",
          "HOMEPAGE@GAME3": "/static/media/casinoFruitRaceHMFun88.jpg",
          "HOMEPAGE@GAME4": "/static/media/andarBaharLiveHMFun88.jpg",
          "HOMEPAGE@GAME5": "/static/media/casinoRouletteHMFun88.jpg",
          "HOMEPAGE@GAME6": "/static/media/wheelBetCasinoNewHMFun88.jpg",
          "HOMEPAGE@GAME7": "/static/media/casinoBetNumberHMFun88.jpg",
          "HOMEPAGE@GAME8": "/static/media/casinoliveDiceHMFun88.jpg",
          "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGameHMFun88.jpg",
          "HOMEPAGE@GAME10": "/static/media/32casinoCardsHMFun88.jpg",
          "DASHBOARD@CHOOSECHANNEL": "Choose One Channel",
          "MANUALDEPOSIT@YoloBankNew": "Copy one of the Bank Details and make payment",
          "LOYALTY@RULESREGULATIONS":"Rules & Regulations",
          "LOYALTY@RULESTEXT1":" Users will be automatically downgraded to the next lowest tier if they fail to reach the required monthly turnover amount. For example, if a user is in the Gold tier and unable to maintain a turnover of 70L in one month, the user's FUN88 loyalty tier is downgraded to Silver.",
          "LOYALTY@RULESTEXT2":" FUN88 reserves the right to cancel the bonus if, during the check of the gaming account, there are suspicions of a violation of the game's fairness or the use of strategies that the company, at its discretion, considers malicious. In disputable situations, the decision of the company's authorized officials is final",
          "LOYALTY@RULESTEXT3":"  If there is any violation of the rules, foul play, or other fraudulent activities, all such accounts will be excluded from participating in this promotion.",
          "LOYALTY@RULESTEXT4":" This promotion is subject to the general rules of FUN88",
          "LOYALTY@RULESTEXT5": " Up to 5% Refill Bonus is given on every deposit",
          "LOYALTY@RULESTEXT6": " Up to 5% Cashback Bonus is given on weekly losses",
          "LOYALTY@RULESTEXT8":"",
          "ProBonus@Sub": " Get a 388% First Time Deposit Bonus",
          "ProBonus@Sub5": " Refer & get 24% of your friends deposit amount",
          "Promotions@ReferralMessageTooltipThird":
          "We are awaiting your friend's third deposit in order to activate the 8% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
          "Promotions@ReferralMessageTooltipSecond": "We are awaiting your friend's second deposit in order to activate the 8% referral bonus for you and provide your friend with a redeposit bonus of up to 5%.",
          "Promotions@ReferralDesc": "Has signed up, We are waiting for Their first deposit to activate your 8% referral bonus and up to 388% welcome bonus for your friend",
          "NOTE@ELITE":'Fun88 reserves the right to change/modify these conditions at any time without prior notice',
          "PROMOTIONS@InstantChallengePromo":"FUN Instant Challenge 2025",
          "TITLE@PREMIUM_SPORTSBOOK": "B2B",
          "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "B2B Statement",
          "PROMOTIONS@LiveJackPot2025": "The FUN Jackpot",
          "LOYALTY@BLUETIER1ST": "MIN. TURNOVER 0",
          "LOYALTY@BLUETIER2ND": "2% Refill Bonus on Every Deposit",
          "LOYALTY@BLUETIER3RD": "2% Cashback Bonus on Weekly Losses",
          "LOYALTY@BLUETIER4TH": "",
          "LOYALTY@SILVERTIER1ST": "MIN. TURNOVER 25 LAKH",
          "LOYALTY@SILVERTIER2ND": "3% Refill Bonus on Every Deposit",
          "LOYALTY@SILVERTIER3RD": "3% Cashback Bonus on Weekly Losses",
          "LOYALTY@GOLDTIER1ST": "MIN. TURNOVER 70 LAKH",
          "LOYALTY@GOLDTIER2ND": "4% Refill Bonus on Every Deposit",
          "LOYALTY@GOLDTIER4": "4% Cashback Bonus on Weekly Losses",
          "LOYALTY@PLATIUNUM1ST": "MIN. TURNOVER 1 CRORE",
          "LOYALTY@PLATIUNUM2ND": "5% Refill Bonus on Every Deposit",
          "LOYALTY@PLATIUNUM6": "5% Cashback Bonus on Weekly Losses",
        }
    };
  } else {
    let _defaultHObj = {
      "Deposit@ReloadBonus": "Refill bonus",
      "NoteSpecialChannelWarning": "अनुशंसित चैनल पर",
      "NoteSpecialChannelWarningText": "पुनः जमा बोनस",
      "NoteSpecialChannelWarningEnd": "को न चूकें",
      "PROMOTIONS@RefillNewHead0": "हर पुनः जमा के साथ तरोताज़ा हो जाएँ - Yolo247 पर 5% रिफिल बोनस",
      "PROMOTIONS@RefillNewHead1": "फिर से जमा करें और 5% तक रीफिल बोनस प्राप्त करें",
      "PROMOTIONSREFILL@RefillNewDesc1": "विशेष खिलाड़ी विशेष ध्यान देने योग्य होते हैं, और इसी कारण हम हर पुनर्जमा पर 5% तक का रीफिल बोनस प्रदान कर रहे हैं।",
      "PROMOTIONS@RefillNewDesc101": "देश का एकमात्र और पहला प्लेटफॉर्म जो हर जमा पर बोनस प्रदान करता है।",
      "PROMOTIONS@RefillNewDesc102": "उपयोगकर्ताओं को हर जमा पर 5% रीफिल बोनस (प्लैटिनम सदस्य) मिलता है।",
      "PROMOTIONS@RefillNewDesc103": "गैर-प्लैटिनम सदस्यों के लिए रीफिल बोनस का प्रतिशत उनके स्तर पर निर्भर करेगा।",
      "PROMOTIONS@RefillNewHead2": "रीफिल बोनस क्या है और इसे कैसे प्राप्त करें?",
      "PROMOTIONS@RefillNewDesc2": "रीफिल बोनस वह बोनस है जो उपयोगकर्ता को पहले जमा के बाद हर जमा पर मिलता है। यह समझने के लिए कि बोनस कब और कैसे आपके मुख्य वॉलेट में क्रेडिट होता है, निम्नलिखित चरण देखें:",
      "PROMOTIONS@RefillNewDesc201": "वेबसाइट या Yolo247 ऐप के माध्यम से अपने Yolo247 खाते में लॉगिन करें।",
      "PROMOTIONS@RefillNewDesc202": "पहले जमा के बाद हर जमा के लिए, उपयोगकर्ता को 5% तक का रीफिल बोनस मिलेगा, जो तुरंत उनके मुख्य वॉलेट में जोड़ दिया जाएगा।",
      "PROMOTIONS@RefillNewHead3": "Yolo247 रीफिल बोनस के लिए कौन पात्र है?",
      "PROMOTIONS@RefillNewDesc301": "जो Yolo247 उपयोगकर्ता 'हाइलाइटेड चैनलों' का उपयोग करके पुनर्जमा कर रहे हैं, वे रीफिल बोनस के पात्र होंगे। रीफिल बोनस का प्रतिशत उपयोगकर्ता के स्तर पर निर्भर करेगा।",
      "PROMOTIONS@RefillNewDesc302": "जो उपयोगकर्ता गैर-हाइलाइटेड चैनलों का उपयोग कर रहे हैं, वे रीफिल बोनस के पात्र नहीं होंगे।",
      "PROMOTIONS@RefillNewDesc303": "तेजी से UPI भुगतान मोड में: सभी उपयोगकर्ताओं को रीफिल बोनस मिलेगा।",
      "PROMOTIONS@RefillNewDesc304": "नियमित UPI भुगतान मोड में पुनर्जमा करने की प्रक्रिया के दौरान, आप हाइलाइटेड चैनल देख सकते हैं।",
      "PROMOTIONS@RefillNewHead4": "Yolo247 रीफिल बोनस के लाभ",
      "PROMOTIONS@RefillNewDesc4": "रीफिल बोनस से उपयोगकर्ता को निम्नलिखित लाभ मिलते हैं:",
      "PROMOTIONS@RefillNewDesc401": "उपयोगकर्ताओं को खेलने के लिए अतिरिक्त प्रेरणा मिलती है क्योंकि उन्हें हर पुनर्जमा पर 5% तक का बोनस मिलता है।",
      "PROMOTIONS@RefillNewDesc402": "इस बोनस का उपयोग करके, खिलाड़ी विभिन्न खेलों को आज़मा सकते हैं, अपने कैसीनो खेल कौशल को सुधार सकते हैं, और जीतने की अपनी संभावना को बढ़ा सकते हैं।",
      "PROMOTIONS@RefillNewTermsHead": "Yolo247 पर रीफिल बोनस की शर्तें और नियम",
      "PROMOTIONS@RefillNewTermsDesc1": "प्रत्येक ग्राहक, पता, साझा कंप्यूटर, साझा IP पता और किसी भी समान खाता विवरण के लिए केवल एक बोनस की अनुमति है, जिसमें ई-मेल पता, बैंक खाता विवरण, क्रेडिट कार्ड जानकारी और भुगतान प्रणाली खाता शामिल है। इस बोनस के किसी भी दुरुपयोग से खाता बंद कर दिया जाएगा।",
      "PROMOTIONS@RefillNewTermsDesc2": "केवल वे उपयोगकर्ता, जो हाइलाइटेड चैनलों का उपयोग कर रहे हैं, रीफिल बोनस के पात्र होंगे।",
      "PROMOTIONS@RefillNewTermsDesc3": "हर सफल पुनर्जमा के बाद बोनस राशि उपयोगकर्ता के मुख्य वॉलेट में क्रेडिट की जाती है।",
      "PROMOTIONS@RefillNewTermsDesc4": "यह प्रोमोशन हर पुनर्जमा पर उपलब्ध है, और दिन के दौरान प्राप्त की गई प्रत्येक बोनस राशि अगले दिन रात 12 बजे IST पर समाप्त हो जाएगी।",
      "PROMOTIONS@RefillNewTermsDesc5": "उदाहरण: उपयोगकर्ता ने 21 सितंबर को सुबह 9 बजे ₹1,000 की पहली पुनर्जमा की। अब उपयोगकर्ता को ₹20 का बोनस मिलेगा; इस प्रकार, उपयोगकर्ता के खाते में कुल राशि ₹1020 हो जाएगी। अब मान लें कि उपयोगकर्ता उसी दिन 2 बजे ₹2000 की पुनर्जमा करता है। अब उपयोगकर्ता को ₹40 का बोनस मिलेगा। 22 सितंबर की मध्यरात्रि में 11:59:59 बजे उपयोगकर्ता को दिए गए ₹60 की बोनस राशि समाप्त हो जाएगी।",
      "PROMOTIONS@RefillNewTermsDesc6": "इस प्रोमोशन के लिए कई खातों को नहीं माना जाएगा। सभी खिलाड़ियों की डुप्लिकेट जांच की जाएगी। Yolo247 अधिकार सुरक्षित रखता है कि अगर कोई गलत खेल होता है तो खिलाड़ियों को अयोग्य घोषित कर सकता है।",
      "PROMOTIONS@RefillNewTermsDesc7": "Yolo247 अपने विवेकाधिकार पर किसी भी प्रोमोशन को संशोधित, रद्द, और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONS@RefillNewTermsDesc8": "बोनस राशि को निकाला नहीं जा सकता है, लेकिन बोनस से प्राप्त जीत को निकाला जा सकता है।",
      "PROMOTIONS@RefillNewTermsDesc9": "Yolo247 के सामान्य शर्तें और नियम लागू होते हैं।",
      "PROMOTIONS@RefillNewTermsDesc10": "P2P खेलों के लिए रोलओवर मान्य नहीं है।",
      "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
      "TITLE@fomoMarquee": "कोई चेतावनी नहीं दी जाएगी!!! कृपया ध्यान दें: इस वेबसाइट या इसके संबद्ध व्हाइटलेबल्स के एकाधिक खातों का उपयोग करने वाले ग्राहक, या वीपीएन या वीपीएन अनुप्रयोगों का उपयोग करने वाले खाते या प्रॉक्सी सर्वर का उपयोग करने वाले खाते अपने जीतने वाले दांव को रद्द कर सकते हैं। इसे प्लेटफ़ॉर्म के नियमों के विरुद्ध माना जाएगा || व्हाइट लेबल्स अब उपलब्ध हैं, अभी कॉल करें ||",
      "DASHBOARD@WithdrawalNoteDisclaimer3":
        "प्रति लेनदेन अधिकतम निकासी की अनुमति = ₹ 200,000",
      "PROMOTIONS@EvoCasinoCupPromo": "बैकारेट  बेट & वेन",
      "evoCasinoPromo@RegisterText":
        "Open the door to endless thrills by creating your very own Yolo247 account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
      "evoCasinoPromo@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "evoCasinoPromo@GeneralTermsConditionSecond":
        "The winners of each week will remain eligible for prizes in the following weeks as well.",
      "evoCasinoPromo@GeneralTermsConditionThird":
        "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles ( Instagram, Telegram) and your respective inbox.",
      "evoCasinoPromo@GeneralTermsConditionFourth":
        "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
      "evoCasinoPromo@GeneralTermsConditionFifth":
        "Winnings will be added to wallets as bonus every week.",
      "evoCasinoPromo@GeneralTermsConditionSixth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "evoCasinoPromo@GeneralTermsConditionSeventh":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "evoCasinoPromo@GeneralTermsConditionEighth":
        "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "evoCasinoPromo@GeneralTermsConditionNinth":
        "Yolo247 General Terms & Conditions apply",

      "TIER@ALLPLATINUMNOTE":
        "इस विश्व कप में प्लैटिनम स्तर का सदस्य बनने पर बधाई! अपने रॉयल्टी विशेषाधिकारों का आनंद लें, टर्नओवर पूरा करें और उसके बाद भी प्लैटिनम बने रहें",

      "TITLE@ninjacrush": "निंजा क्रैश",
      "PROMOTIONS@ACBPromo": "एशिया कप बोनान्ज़ा",
      "PROMOTIONS@acbPromoRightText1":
        "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
      "PROMOTIONS@acbPromoRightText2":
        "This Asia cup immerse yourself with exhilarating cricket action only on YOLO247.",
      "PROMOTIONS@acbPromoRightText3":
        "Join the biggest gaming event of Asia and stand a chance to win",
      "PROMOTIONS@acbPromoRightText4":
        " Extra Bonuses and Tickets to the Cricket World Cup 2023",

      "asiaCup@GeneralTermsConditionSecond": "Asia Cup Bonanza is valid from ",
      "asiaCup@GeneralTermsConditionSecondTwo":
        "30th August to 17th September 2023. ",
      "asiaCup@GeneralTermsConditionSecondThree":
        "Winners will be announced on ",
      "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
      "asiaCup@GeneralTermsConditionSecondFive":
        "on YOLO247 social media handles ( Instagram & Telegram).",
      "asiaCup@GeneralTermsConditionThird":
        "No Minimum bet amount required to enter this contest.",
      "asiaCup@GeneralTermsConditionFourth":
        "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
      "asiaCup@GeneralTermsConditionFifth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "asiaCup@GeneralTermsConditionSixth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "asiaCup@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "asiaCup@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",

      "PROMOTIONS@firstperson": "फर्स्ट पर्सन बेट एंड विन",
      "PROMOTIONS@iplCasinoCarnival": "आईपीएल कैसीनो कार्निवल",
      "PROMOTIONS@iplmatka": "आईपीएल सट्टेबाजी धमाका",
      iplline1:
        "The Most Exciting Time of the Year For Every Cricket Fans is where the",
      iplline2: "INDIAN PREMIER LEAGUE",
      "PROMOTIONSWELCOME@stakesamt":
        "दूसरे शब्दों में, बोनस रोलओवर आवश्यकताओं के लिए योगदान करने के लिए दांव कम से कम दशमलव 1.50 के ऑड्स के साथ होना चाहिए.",
      iplline3: "is Underway. Thats why IPL Lovers will recieve a special",
      "Games@NoGamesMessage": "⚠ इस प्रदाता के लिए कोई गेम उपलब्ध नहीं है",
      "Games@NoGamesMessage2":
        "✖ अन्य खेलों को देखने के लिए कृपया कोई भी लागू खोज फ़िल्टर हटा दें",
      iplline4: "IPL MATKA BONUS",
      iplline5: "on YOLO247 to enjoy this popular competition even now",
      casinoCarnivaltextline1:
        "Join the biggest gaming event of the year and stand a chance to win whopping cash prize from a pool of",
      casinoCarnivaltextline2: "INR 1 Crore,",
      casinoCarnivaltextline3: "as well as exclusive",
      casinoCarnivaltextline4: "Merchandise",
      casinoCarnivaltextline5: "From Yolo247.",
      casinoCarnivaltextline6: "To qualify for the prizes, achieve",
      casinoCarnivaltextline7: "a minimum turnover of 10,000 INR",
      casinoCarnivaltextline8:
        "in any live dealer game(s) and get placed in a level based on your performance. Don't miss out on this incredible opportunity to join the Carnival and win big this IPL season!",
      casinoCarnivaltextline9:
        "So, what are you waiting for? Join us for the ultimate gaming experience and take your IPL season to the next level at YOLO247!",
      "CasinoCarnival@TermsConditionTittle": "Terms and Conditions:",
      "CasinoCarnival@TermsConditionFirst":
        "This promotion is valid for all new & existing players",
      "CasinoCarnival@TermsConditionSecond":
        "IPL Casino Carnival Bonus is valid from 1st April to 31st May 2023. Winners will be announced on 5th June on YOLO247 social media handles ( Instagram & Telegram)",
      "CasinoCarnival@TermsConditionThird":
        "No Minimum bet amount required to enter this contest",
      "CasinoCarnival@TermsConditionFourth":
        "Only Live dealer games are applicable for this promotion",
      "CasinoCarnival@TermsConditionFifth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements",
      "CasinoCarnival@TermsConditionSixth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion",
      "CasinoCarnival@TermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions",
      "CasinoCarnival@TermsConditionEighth":
        " Yolo247 General Terms & Conditions apply",
      iplinfo:
        "The most exciting time of the year for every cricket fan is when the Indian Premier League is underway. That’s why IPL lovers will receive a special",
      infospan: "IPL MATKA BONUS",
      iplinfo1: "on YOLO247 to enjoy this popular competition even more.",
      iplinfo2:
        " Results for IPL will be declared on YOLO247 social media (Instagram & Telegram handles) on 5 June, 2023",

      "SPLMKT@PSL": "पाकिस्तान सुपर लीग",
      "PROMOTIONS@psl": "पैसा सुपर लीग",
      "PROMOTIONS@jetx1":
        "Smartsoft JETX टूर्नामेंट में बड़ी जीत का समय आ गया है। आपको केवल अपना टर्नओवर और मल्टीप्लायर मिशन पूरा करना है, लीडरबोर्ड व्हील घुमाना है और रोमांचक पुरस्कार जीतना है!",
      jetx: "जेटएक्स टूर्नामेंट",
      "PROMOTIONS@singYolo": "योलो247 के साथ गाओ",
      "PROMOTIONS@crazyCrashPromo": "क्रेजी क्रैश चैलेंज",
      "PROMOTIONS@crazyCrashPromoFIRSTHEAD": "सीसीसी कैसे दर्ज करें?",
      "PROMOTIONS@crazyCrashPromoPrizeHEAD": "मूल्य आवंटन",
      "crazyCrash@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",
      "crazyCrash@GeneralTermsConditionSecond":
        "Crazy Crash Challenge is valid from ",
      "crazyCrash@GeneralTermsConditionSecondValidFrom":
        "1st - 28th FEB 2023. ",
      "crazyCrash@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on ",
      "crazyCrash@GeneralTermsConditionSecondValidOn":
        "5th June, 2023 on YOLO247 social media handles ( Instagram & telegram )",
      "crazyCrash@GeneralTermsConditionThird":
        "For a bet to be valid, minimum 1.30 cash out is required",
      "crazyCrash@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "crazyCrash@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "crazyCrash@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
      "crazyCrash@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "crazyCrash@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      "PROMOTIONS@crazyCrashPromoMainDesc":
        "Its time you win Big & Get Crazy! So here we are  presenting to you the Crazy Crash Challenge ! Win pool prize of  INR 1,200,000 . All you need to do is PLAY any of your favourite Crash game (JetX, CricketX, Ballon, FootballX, PlinkoX, jetX3 & Cappadocia) from 1st Feb – 28th FEB 2023 to enter this challenge.",
      "PROMOTIONS@singYolo1":
        "रोमांचक 1+1 जीतें: 28 दिसंबर - 18 जनवरी 2023 तक हमारी प्रतियोगिता में प्रवेश करके इस कॉन्सर्ट के लिए डायमंड, प्लेटिनम, Yolo247 गोल्ड, सिल्वर और ब्रॉन्ज टिकट",
      "SLIDERIMAGE@TEENPATTI1":
        "/static/media/TeenpattiNormalHindi.524d7ca6.jpg",
      "SLIDERIMAGE@TEENPATTI2": "/static/media/TeenpattiNewHindi.5a091792.jpg",
      "SLIDERIMAGE@TEENPATTI3": "/static/media/Andar-baharHindi.b066157b.jpg",
      "COLFIRST@GAME1": "/static/media/wheelGamenewHindi.768d7df5.jpg",
      "COLFIRST@GAME2": "/static/media/liveDicegameNewHindi.795d9515.jpg",
      "COLFIRST@GAME3": "/static/media/lucky7newHindi.1ef15b75.jpg",
      "HOMEPAGE@GAME1": "/static/media/CasinoJokerBetHin.be807728.jpg",
      "HOMEPAGE@GAME4": "/static/media/baccaratNewHin.aebd19b7.jpg",
      "HOMEPAGE@GAME6": "/static/media/wheelBetVTVHin.917d2305.jpg",
      "HOMEPAGE@GAME2": "/static/media/casinoPokerHin.e6a77d4d.jpg",
      "HOMEPAGE@GAME7": "/static/media/casinoBetNumberHin.a05eb89d.jpg",
      "HOMEPAGE@GAME3": "/static/media/casinoFruitRace1Hin.62262478.jpg",
      "HOMEPAGE@GAME8": "/static/media/casinoliveDiceHin.08bc3c5a.jpg",
      "HOMEPAGE@GAME5": "/static/media/casinoRouletteHin.9d108123.jpg",
      "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGameHin.a92d01e1.jpg",
      "HOMEPAGE@GAME10": "/static/media/32casinoCardsHin.130b373e.jpg",
      SEARCH: "सर्च ",
      "AGENT_UPDATE@TRANSACTION_LOCK":
        "ट्रांसक्शन  प्रोसेसिंग , एक क्षण प्रतीक्षा करें",
      "BETFAIR_API_NG_EXCEPTION@ACCESS_DENIED": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG_EXCEPTION@INVALID_APP_KEY": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG_EXCEPTION@INVALID_INPUT_DATA": "इनवैलिड  डाटा ",
      "BETFAIR_API_NG_EXCEPTION@INVALID_SESSION_INFORMATION":
        "एक्सपीरेड  सेशन  डाटा ",
      "BETFAIR_API_NG_EXCEPTION@NO_APP_KEY": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG_EXCEPTION@NO_SESSION": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG_EXCEPTION@REQUEST_SIZE_EXCEEDS_LIMIT":
        "टेक्निकल  फेलियर ",
        "PROMOTIONS@IPLPOWERPLAY":"आईपीएल पावर प्ले बोनस",
        "PROMOTIONS@IPLPOWERPLAY1":"आईपीएल सट्टेबाजी धमाका पावर प्ले बोनस",
      "BETFAIR_API_NG_EXCEPTION@SERVICE_BUSY":
        "हम आपकी जानकारी प्रोसेसिंग कर रहे हैं",
      "BETFAIR_API_NG_EXCEPTION@TIMEOUT_ERROR":
        "हम आपकी जानकारी प्रोसेसिंग कर रहे हैं",
      "BETFAIR_API_NG_EXCEPTION@TOO_MANY_REQUESTS":
        "हम आपकी जानकारी प्रोसेसिंग कर रहे हैं",
      "BETFAIR_API_NG_EXCEPTION@TOO_MUCH_DATA":
        "हम आपकी जानकारी प्रोसेसिंग कर रहे हैं",
      "BETFAIR_API_NG_EXCEPTION@UNEXPECTED_ERROR": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG@BET_ACTION_ERROR": "टेक्निकल  फेलियर ",
      "BETFAIR_API_NG@BET_IN_PROGRESS": "प्रोसेसिंग  यूअर  बेट ",
      "BETFAIR_API_NG@BET_TAKEN_OR_LAPSED":
        "बेट रद्द नहीं की जा सकती। कृपया अपना पेज रीफ्रेश करें या फिर से लॉगिन करें",
      "BETFAIR_API_NG@CANCELLED_NOT_PLACED": "बेट रद्द करने की प्रक्रिया",
      "BETFAIR_API_NG@DUPLICATE_BETIDS": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@DUPLICATE_TRANSACTION": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@ERROR_IN_MATCHER": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@ERROR_IN_ORDER": "बेट नहीं प्लेस हो रहा है ",
      "BETFAIR_API_NG@INSUFFICIENT_FUNDS": "पर्याप्त बैलेंस नहीं",
      "BETFAIR_API_NG@INVALID_ACCOUNT_STATE":
        "कोई बेट अनुमति नहीं। कृपया अप-लाइन से संपर्क करें",
      "BETFAIR_API_NG@INVALID_BACK_LAY_COMBINATION": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_BET_ID": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@INVALID_BID_TYPE": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_CUSTOMER_ORDER_REF": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_MARKET_ID": "मार्किट मौजूद नहीं है",
      "BETFAIR_API_NG@INVALID_MARKET_VERSION": "अमान्य मार्किट",
      "BETFAIR_API_NG@INVALID_MIN_FILL_SIZE": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@INVALID_ODDS": "अमान्य ऑड्स",
      "BETFAIR_API_NG@INVALID_ORDER": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_ORDER_TYPE": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_PERSISTENCE_TYPE": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_PRICE_EDIT": "अमान्य प्राइस",
      "BETFAIR_API_NG@INVALID_PROFIT_RATIO": "अमान्य बेट",
      "BETFAIR_API_NG@INVALID_RUNNER": "रनर  मौजूद नहीं है",
      "BETFAIR_API_NG@INVALID_WALLET_STATUS": "पर्याप्त बैलेंस  नहीं",
      "BETFAIR_API_NG@LOSS_LIMIT_EXCEEDED": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@MARKET_NOT_OPEN_FOR_BETTING":
        "बेटिंग के लिए  मार्किट नहीं खुला",
      "BETFAIR_API_NG@MARKET_NOT_OPEN_FOR_BSP_BETTING":
        "बेटिंग के लिए  मार्किट नहीं खुला",
      "BETFAIR_API_NG@MARKET_SUSPENDED": "मार्केट सस्पेंडेड है",
      "BETFAIR_API_NG@NO_ACTION_REQUIRED": "किसी एक्शन की आवश्यकता नहीं",
      "BETFAIR_API_NG@NO_CHASING": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@PERMISSION_DENIED": "अनुमति नहीं मिली",
      "BETFAIR_API_NG@PROCESSED_WITH_ERRORS": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@REGULATOR_IS_NOT_AVAILABLE": "सर्विस अनुपलब्ध है",
      "BETFAIR_API_NG@REJECTED_BY_REGULATOR": "बेट फेलियर",
      "BETFAIR_API_NG@RELATED_ACTION_FAILED": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@RUNNER_REMOVED": "रनर को इवेंट से हटा दिया गया है",
      "BETFAIR_API_NG@SERVICE_UNAVAILABLE": "अनुरोधित सर्विस अनुपलब्ध है",
      "BETFAIR_API_NG@TIME_IN_FORCE_CONFLICT": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@TOO_MANY_INSTRUCTIONS": "प्रोसेसिंग",
      "BETFAIR_API_NG@UNEXPECTED_MIN_FILL_SIZE": "टेक्निकल  फेलियर",
      "BETFAIR_API_NG@UNEXPECTED_PERSISTENCE_TYPE": "टेक्निकल  फेलियर",
      "CASINO_STATEMENT_TABLE@COMMISION": "कमिशन",
      "CASINO_STATEMENT_TABLE@COMMISSION": "कमिशन",
      "CASINO_STATEMENT_TABLE@CHARGES": "प्रभार",
      "CUSTOMER_MEMBER_GET_TRANSACTION@FAILURE": "ट्रांसक्शन विफलता",
      "CUSTOMER_MEMBER_GET_TRANSACTION@SUCCESS": "ट्रांसक्शन सक्सेस",
      "CUSTOMER_MEMBER@MEMBER_CREATE_FAILURE": "अकाउंट क्रिएशन फेलियर",
      "CUSTOMER_MEMBER@MEMBER_CREATE_SUCCESS": "मेंबर सफलता बनाएँ",
      "CUSTOMER_MEMBER@MEMBER_CREATE_USERNAME_DUPLICATE": "यूजरनाम डुप्लीकेट",
      "CUSTOMER_MEMBER@MEMBER_CREDIT_INIT_FAILURE":
        "मेंबर क्रेडिट इनीशिएलाइजेशन फेलियर",
      "EXCHANGE_CANCEL_ORDER@ORDER_BF_CANCELORDER_FAILURE":
        "बेट रद्द करने में असमर्थ",
      "EXCHANGE_CANCEL_ORDER@ORDER_CHECK_ORDER_UNFOUND":
        "बेट रद्द करने में असमर्थ",
      "EXCHANGE_CANCEL_ORDER@SUCCESS": "बेट कांसेल्लेड सक्सेस्स्फुल्ली",
      "EXCHANGE_CHECKOUT_ORDER@BODY_PARAM_ERROR": "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_CREATE_TRANSACTIONS_EXCEPTION":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_CREATE_TRANSACTIONS_FAILURE":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_DELETE_CLEARED_ORDER_FAILURE":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_INSERT_CLEARED_ORDER_FAILURE":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_INSERT_SETTLE_CLOSED_FAILURE":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_MOVE_CLEARED_ORDER_EXCEPTION":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_MOVE_SETTLE_EXCEPTION":
        "टेक्निकल  फेलियर",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_NOT_FIND_ORDER": "बेट नहीं मिली",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_UPDATE_ORDER_FAILURE":
        "अपडेट करने के लिए सक्षम करें",
      "EXCHANGE_CHECKOUT_ORDER@SUCCESS": "सक्सेस",
      "EXCHANGE_PLACE_ORDER@EXCHANGE_UPPERLINES_ERROR":
        "बेट नहीं लगा पाए। कृपया अपने अप-लाइन से संपर्क करें",
      "EXCHANGE_PLACE_ORDER@ORDER_BETFAIR_IN_BLACKLIST":
        "बेटिंग डिसेबल्ड कर दी गई है। कृपया उपलैन से संपर्क करें",
      "EXCHANGE_PLACE_ORDER@ORDER_BETFAIR_IS_INVALID": "बेट लगाने में असमर्थ",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_API_TIMEOUT":
        "बेट की पुष्टि करने में असमर्थ। यह देखने के लिए कि क्या बेट स्वीकार की जाती है, कृपया 30 सेकंड में फिर से जाँच करें।",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_EXCEPTION_TIMEOUT": "टेक्निकल  फेलियर",
      "EXCHANGE_PLACE_ORDER@ORDER_BF_PT_FAILURE": "टेक्निकल  फेलियर",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_INSUFFICIENT": "पर्याप्त बैलेंस नहीं",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_LESS_THAN_PER_ORDER_LIMIT":
        "बेट लिमिट मिनिमम  बेट  लिमिट से कम है",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_OVER_PER_MARKET_LIMIT":
        "बेट  साइज  मैक्स मार्किट बेट लिमिट सीमा से अधिक है",
      "EXCHANGE_PLACE_ORDER@ORDER_CREDIT_OVER_PER_ORDER_LIMIT":
        "बेट साइज  मैक्स बेट  लिमिट सीमा से अधिक है",
      "EXCHANGE_PLACE_ORDER@ORDER_IS_INVALID":
        "बेट लगाने में असमर्थ, कृपया अपर लाइन से संपर्क करें",
      "EXCHANGE_PLACE_ORDER@ORDER_IS_UNDER_PROCESSING":
        "आपका आर्डर प्रोक्सेसेड किया जा रहा है, कृपया प्रतीक्षा करें",
      "EXCHANGE_PLACE_ORDER@ORDER_PRICE_IS_UNMATCHED":
        "ऑड्स बदल गए हैं। कृपया ईवेंट सूची से नए ऑड्स चुनें, या उन्हें टाइप करें",
      "EXCHANGE_PLACE_ORDER@ORDER_SETTING_FAILURE": "टेक्निकल  फेलियर",
      "EXCHANGE_PLACE_ORDER@ORDER_SNK_IS_INVALID": "बेट लगाने में असमर्थ",
      "EXCHANGE_PLACE_ORDER@SELECTION_SIZE_ZERO": "कोई सिलेक्शन स्टॉक नहीं है।",
      "EXCHANGE_PLACE_ORDER@SUCCESS": "बेट प्लेस्ड सक्सेस्स्फुल्ली",
      "EXCHANGE_RESETTLE@ORDER_MARKET_RESETTLE_ERROR": "नो आर्डर",
      "EXCHANGE_RESETTLE@ORDER_RESETTLE_GET_TRANSACTION_ERROR": "नो आर्डर",
      "EXCHANGE_UPDATE_ORDER@ORDER_BETFAIR_IN_BLACKLIST":
        "बेटिंग अक्षम कर दी गई है। कृपया उपलैन से संपर्क करें",
      "EXCHANGE_UPDATE_ORDER@ORDER_BETFAIR_IS_INVALID": "इनेबल बेट अपडेट",
      "EXCHANGE_UPDATE_ORDER@ORDER_SNK_IS_INVALID": "इनेबल बेट अपडेट",
      "EXCHANGE_UPDATE_ORDER@ORDER_UPDATEORDER_WRONG_SOURCE": "इनवैलिड मार्किट",
      "EXCHANGE_UPDATE_ORDER@SUCCESS": "बेट अपडेटेड सक्सेस्स्फुल्ली",
      "GAME@JACKPOT": "जैकपोट",
      "GQL_AGENT_MEMBER@ACCOUNT_PASSWORD_INVALID":
        "अकाउंट/पासवर्ड का प्रारूप गलत है या बहुत छोटा है",
      "GQL_AGENT_MEMBER@CREATE_ACCOUNT_DUPLICATE_ERROR":
        "अकाउंट का उपयोग किया गया है",
      "GQL_AGENT_MEMBER@CREATE_ACCOUNT_ERROR": "अकाउंट बनाते समय एरर होती है",
      "GQL_AGENT_MEMBER@UPDATE_ACCOUNT_ERROR":
        "अकाउंट अपडेट करते समय एरर होती है",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_CANCELORDER_FAILURE":
        "टेक्निकल फेलियर",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_PLACEORDER_FAILURE":
        "टेक्निकल फेलियर",
      "GQL_EXCHANGE_ORDER@ORDER_REOPEN_BF_NOT_ALLOWED": "",
      "GQL_EXCHANGE_ORDER@ORDER_UPDATE_PENDING_NO_PERMISSION": "नो परमिशन",
      "GQL_GAME_LOGIN@GAME_SERVICE_LOGIN_FAILURE":
        "गेम सर्वर व्यस्त है, कृपया बाद में पुन: प्रयास करें",
      "GQL_GAME_LOGIN@GAME_SERVICE_USER_POOL_OVER_LIMIT":
        "डेमो सिस्टम व्यस्त है। बाद में पुन: प्रयास करें",
      "GQL_GAME_LOGIN@GAME_SETTING_DISABLED":
        "प्लेटफार्म  वर्तमान में अक्षम है, कृपया अपने उपलैन से संपर्क करें",
      "GQL_GAME_TRANSFER@GAME_SERVICE_TRANSFER_FAILURE": "ट्रांसफर विफल।",
      "GQL_GAME_TRANSFER@GAME_SERVICE_TRANSFER_TIMEOUT":
        "कृपया और सेकंड प्रतीक्षा करें और रिजल्ट के लिए रिफ्रेश करें",
      "GQL_GAME_TRANSFER@MEMBER_TRANSATION_CREDIT_INSUFFICIENT":
        "क्रेडिट अपर्याप्त",
      "GQL_GAME_TRANSFER@PT_TRANSFER_FAILURE": "कैसिनो क्रेडिट ट्रांसफर फ़ैल",
      "GQL_MEMBER_LOGIN@MEMBER_IP_BLACKLISTED":
        "आपका आईपी अक्षम है, कृपया उप्पेर लाइन रेखा से संपर्क करें",
      "GQL_MEMBER_LOGIN@MEMBER_LOGIN_EUTOKEN_FAILURE":
        "लॉग इन करने के लिए सक्षम करें",
      "GQL_MEMBER_LOGIN@MEMBER_LOGIN_FAILURE": "यूजरनाम और पासवर्ड गलत है",
      "GQL_MEMBER_LOGIN@MEMBER_PERMISSIONS_FAILURE":
        "लॉग इन अक्षम। कृपया उपलैन से संपर्क करें",
      "GQL_MEMBER_LOGIN@MEMBER_UPPERLINES_PERMISSIONS_FAILURE":
        "आपकी उप्पेर लाइन इनएक्टिव /क्लोज्ड, कृपया जाँच करें",
      "GQL_MEMBER_LOGIN@SUCCESS": "वेलकम",
      "GQL_MEMBER_LOGOUT@MEMBER_LOGOUT_FAILURE":
        "लॉग आउट करने के लिए सक्षम करें",
      "GQL_MEMBER_LOGOUT@SUCCESS": "धन्यवाद",
      "GQL_MEMBER_TOGGLE_FAVORITE@SUCCESS": "ईवेंट पसंदीदा में जोड़ा गया",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_NEW_PASSWORD_NOT_CORRECT":
        "नया पासवर्ड करेक्ट नहीं है।",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_OLD_PASSWORD_NOT_CORRECT":
        "ओल्ड पासवर्ड गलत है",
      "GQL_MEMBER_UPDATE_PASSWORD@MEMBER_PASSWORD_DUPLICATE":
        "नय पासवर्ड ओल्ड पासवर्ड के साथ डुप्लीकेट गया है",
      "GQL_MEMBER_UPDATE_PASSWORD@SUCCESS": "पासवर्ड अपडेटेड सक्सेस्स्फुल्ली",
      "GQL_MEMBER_VALIDATE@MEMBER_TOKEN_VALIDATE_FAILURE": "टेक्निकल  फेलियर",
      "GQL_MEMBER_VALIDATE@SUCCESS": "वलिदाते सक्सेस ",
      "MEMBER_CANCEL_TRANSACTION@MEMBER_TRANSACTION_HAD_BEEN_CANCELLED":
        "बेट कांसेल्लेड सक्सेस्स्फुल्ली",
      "MEMBER_CANCEL_TRANSACTION@MEMBER_TRANSACTION_NOT_FOUND":
        "टेक्निकल  फेलियर",
      "MEMBER_CANCEL_TRANSACTION@SUCCESS": "कांसेल्लेड सक्सेस्स्फुल्ली",
      "MEMBER_CANCEL_TRANSACTION@URL_PARAM_ERROR": "टेक्निकल  फेलियर",
      "MEMBER_CREATE_SETTLE_TRANSACTION@SUCCESS": "सक्सेस  ",
      "MEMBER_CREATE_TRANSACTION@SUCCESS": "सक्सेस ",
      "MEMBER_DEPOSIT@SUCCESS": "मेंबर डिपाजिट सक्सेस",
      "MEMBER_GET_BALANCE@SUCCESS": "उपलब्ध बैलेंस सफलता प्राप्त करें",
      "MEMBER_TRANSACTION@TRANSACTION_CHECK_BALANCE_FAILED":
        "चेक क्रेडिट बैलेंस विफल",
      "MEMBER_TRANSACTION@TRANSACTION_CREDIT_INSUFFICIENT":
        "ट्रांसक्शन क्रेडिट अपर्याप्त है",
      "MEMBER_TRANSACTION@TRANSACTION_UPDATE_CREDIT_FAILED":
        "क्रेडिट अपडेट करना विफल रहा",
      "MEMBER_WITHDRAW@SUCCESS": "मेंबर विथड्रॉ सक्सेस ",
      "MEMBER@EXIST": "मेंबर एक्सिस्ट",
      "MEMBER@NOT_EXIST": "मेंबर नॉट एक्सिस्ट",
      "ORDER@ORDER@REMIND": "आपका आर्डर उनमचेड है और कांसेल्लेड हो गया है",
      "TABLE@FANCY1": "फैंसी १ ",
      "TABLE@FANCY2": "फैंसी २",
      "TABLE@FANCY3": "फैंसी ३ ",
      "TABLE@FANCY4": "फैंसी  ४",
      "THIRDPARTY_BETFAIR@ORDER_BF_GET_SSID_FAILURE": "टेक्निकल  फेलियर",
      "THIRDPARTY_CHECKOUT_ORDER@SUCCESS": "चेकआउट आर्डर सक्सेस ",
      "THIRDPARTY_CHECKOUT_ORDER@URL_PARAM_ERROR": "पैरामीटर गलत हैं",
      "THIRDPARTY_CHECKOUT_ORDER@WAGERID_EXIST":
        "इस वेजर आईडी की जाँच की जा चुकी है",
      "TITLE@IPL_ELECTION": "आईपीएल  कप  विनर 2023",
      "TITLE@ELECTION_2024": "अमेरिकी चुनाव 2024",
      "TITLE@HARYANA_ELECTION_2024": "हरियाणा चुनाव 2024",
      "TITLE@JACKPOT": "जैकपोट ",
      "TITLE@PSL_ELECTION": "प्सल कप",
      "TITLE@WB_ELECTION": "वेस्ट  बंगाल  इलेक्शन",
      "TRIAL_MODE_LOGIN@SUCCESS": "वेलकम  विसिटोर्स",
      "UPDATE_AI_PT@PT_SETTING_EXCEED": "पी टी  सेटिंग  एक्ससीड ",
      "UPDATE_AI_PT@SUCCESS": "सक्सेस",
      "BETFAIR_API_NG@INVALID_BET_SIZE": "इनवैलिड  बेट साइज ",
      "GQL_EXCHANGE_ORDER@BETTING_SERVICE_UPDATEORDER_FAILURE":
        "टेक्निकल फेलियर ",
      "ACCOUNT_STATEMENT_TABLE@AMOUNT": "अमाउंट",
      "ACCOUNT_STATEMENT_TABLE@BALANCE": "बैलेंस ",
      "ACCOUNT_STATEMENT_TABLE@CREDIT_LIMIT": "क्रेडिट  लिमिट",
      "ACCOUNT_STATEMENT_TABLE@DESCRIPTION": "डिस्क्रिप्शन",
      "ACCOUNT_STATEMENT_TABLE@FILTER_CASINO_ORDER": "ऑर्डर्स  ओनली ",
      "ACCOUNT_STATEMENT_TABLE@P&L": "प्रॉफिट & लोस्स ",
      "ACCOUNT_STATEMENT_TABLE@TIME": "टाइम  ",
      "ACCOUNT_STATEMENT_TABLE@TYPE": "टाइप  ",
      "ACCOUNT_STATEMENT@ALL": "आल ",
      "ACCOUNT_STATEMENT@BETTING_P&L": "बेटिंग प्रॉफिट/लोस्स एक्सचेंज",
      "ACCOUNT_STATEMENT@SETTLING": "सेटलिंग  स्टेटमेंट ",
      "ACCOUNT@AVAILABLE_CREDIT": "अवेलेबल  क्रेडिट  ",
      "ACCOUNT@BALANCE": "संतुलन",
      "ACCOUNT@GOLDTIER": "गोल्ड टियर",
      "ACCOUNT@CREDIT_LIMIT": "क्रेडिट  लिमिट    ",
      "ACCOUNT@NET_EXPOSURE": "नेट  एक्सपोज़र ",
      "ACCOUNT@WINNINGS": "विन्निंग्स",
      "AGENT_SIDEBAR@ACCOUNT": "अकाउंट ",
      "AGENT_SIDEBAR@ACTIVE_USERS": "एक्टिव  उसेर्स ",
      "AGENT_SIDEBAR@ASSOCIATED_ACCOUNT": " एसोसिएट  नया  अकाउंट ",
      "AGENT_SIDEBAR@CASINO_STATEMENT_AGENT": "कैसिनो  स्टेटमेंट ",
      "AGENT_SIDEBAR@COMMUNICATION": "कम्युनिकेशन ",
      "AGENT_SIDEBAR@DASHBOARD": "डैशबोर्ड ",
      "AGENT_SIDEBAR@DOWNLINE_SETTLE": "सेटल ",
      "AGENT_SIDEBAR@DOWNLINE_VIEW": "मैनेज ",
      "AGENT_SIDEBAR@LIVE_BET_LIST": "लाइव  बेट  लिस्ट ",
      "AGENT_SIDEBAR@MANAGE_DOWNLINE": "दोव्न्लिने ",
      "AGENT_SIDEBAR@MANAGE_EVENT": "मैनेज  इवेंट ",
      "AGENT_SIDEBAR@MY_ACTIVITY": "माय  एक्टिविटी ",
      "AGENT_SIDEBAR@MY_REPORT": "माय  रिपोर्ट ",
      "AGENT_SIDEBAR@NET_EXPOSURE": "नेट  एक्सपोज़र ",
      "AGENT_SIDEBAR@NOTIFICATION": "नोटिफिकेशन ",
      "AGENT_SIDEBAR@ORDER_MANAGEMENT": "बेट  रिपोर्ट  ",
      "AGENT_SIDEBAR@PL_REPORT_AGENT":
        "प्रॉफिट/लोस्स  एक्सचेंज रिपोर्ट - एजेंट ",
      "AGENT_SIDEBAR@PL_REPORT_MARKET":
        "प्रॉफिट/लोस्स  एक्सचेंज  रिपोर्ट- मार्किट ",
      "AGENT_SIDEBAR@PT_LISTING": "पोजीशन टेकिंग - लिस्ट ",
      "AGENT_SIDEBAR@RISK_MANAGEMENT": "रिस्क  मैनेजमेंट ",
      "AGENT_SIDEBAR@SETTLING_STATEMENT_AGENT": "सेटलिंग  स्टेटमेंट ",
      "AGENT_SIDEBAR@SPORT_STATEMENT_AGENT": "स्पोर्ट  स्टेटमेंट ",
      "AGENT_SIDEBAR@SUB_ACCOUNT": "सुब  एकाउंट ",
      "AGENT_SIDEBAR@LANGUAGE_SETTLE": "लैंग्वेज  सेटल ",
      "AGENT_SIDEBAR@ADMINISTRATOR": "एडमिनिस्ट्रेटर ",
      "AGENT_SIDEBAR@SUSPEND_PLATFORM": "ससपेंड  प्लेटफार्म ",
      "AGENT_SIDEBAR@IP_BLACKLIST": "आईप ब्लैकलिस्ट ",
      "AGENT_SIDEBAR@ACCOUNT_MANAGEMENT": "एकाउंट  मैनेजमेंट ",
      "AGENT_SIDEBAR@BETFAIR_PTSETTING": "बेटफेयर  PT  सेटिंग ",
      "AGENT_SIDEBAR@CURRENCY_SETTING": "करेंसी  सेटिंग ",
      "AGENT@ACCOUNT@AVAILABLE": "अवेलेबल  क्रेडिट ",
      "AGENT@ACCOUNT@DESCRIPTION": "डिस्क्रिप्शन ",
      "AGENT@ACCOUNT@EVENT": "इवेंट ",
      "AGENT@ACCOUNT@TIMESTAMP": "टीमस्टम्प ",
      "AGENT@ACCOUNT@TRANSACTION": "ट्रांसक्शन  क्रेडिट ",
      "AGENTMEMBER_UPDATE_FAILED@UPPERLINE_DISABLED":
        "आपके पास सूफीसन्त अनुमति नहीं है .",
      "APP@ANDROID@APK": "https://file.zaw2cdr4.com/1/app/bbb-2020102001.apk",
      "APP@ANDROID@DOWNLOAD": "Download APK",
      DEFAULT_APK: "http://",
      MPC_APK:
        "https://app-mpc-prod.s3-eu-west-1.amazonaws.com/MPC-release.apk",
      "9BETS_APK": "https://app-9bets-prod.s3-eu-west-1.amazonaws.com/9BET.apk",
      BBB_APK: "https://app-bbb-prod.s3.eu-west-1.amazonaws.com/BBB-Games.apk",
      MAHA_APK:
        "https://app-mahadev-prod.s3-eu-west-1.amazonaws.com/Mahadevbet.apk",
      PARI_MATCH_APK:
        "https://app-parimatch-prod.s3.eu-west-1.amazonaws.com/pari_match.apk",
      "APP@BRAND@NAME": "बबब ",
      "APP@DOWNLOAD@NAME": "३३एक्सच  अप्प ",
      "APP@DOWNLOAD@SLOGAN": "एक्सचेंज , कैसिनो , स्लॉट  गेम्स  आल  इन  ओने .",
      "APP@IOS@DOWNLOAD": "डाउनलोड  आइपीए ",
      "APP@IOS@IPAID": "५इ४३९डी१९१५९बी१ ",
      "BRAND_NAME@RULES": "बबब ",
      "CASINO_STATEMENT_TABLE@ACCOUNT": "अकाउंट ",
      "CASINO_STATEMENT_TABLE@AGENT": "एजेंट ",
      "CASINO_STATEMENT_TABLE@EVENT": "इवेंट ",
      "CASINO_STATEMENT_TABLE@GAME": "गेम ",
      "CASINO_STATEMENT_TABLE@PLACEDTIME": "प्लेस्ड  टाइम ",
      "CASINO_STATEMENT_TABLE@PLATFORM": "प्लेटफार्म ",
      "CASINO_STATEMENT_TABLE@PROVIDER": "प्रोवाइडर ",
      "CASINO_STATEMENT_TABLE@REFID": "रेफ  ईद ",
      "CASINO_STATEMENT_TABLE@SETTLEDTIME": "सेटल्ड  टाइम ",
      "CASINO_STATEMENT_TABLE@STAKE": "स्टेक ",
      "GAME@KINGMAKER": "किंग्समेकर",
      "CASINO_STATEMENT_TABLE@STATUS": "स्टेटस ",
      "CASINO_STATEMENT_TABLE@firstbet": "पहला दांव",
      "CASINO_STATEMENT_TABLE@cashout": "कैशआउट",
      "CASINO_STATEMENT_TABLE@TURNOVER": "टर्नओवर ",
      "CASINO_STATEMENT_TABLE@WINLOSS": "विन /लोस्स",
      "CASINO@COIN_BALANCE": "कॉइन  बैलेंस ",
      "CASINO@NOTE":
        "थे  करेंसी  डिसप्लाएड  इन  थे  कैसिनो  होल्ड्स  नो  वैल्यू  अस  थे  वैल्यू  ऑफ़  थे  कॉइन   इस  थे  शामे  अस  थे  वैल्यू  प्रे  डेटर्मीनेड  बिटवीन  यू  एंड  योर  उपलिने .",
      "CASINO@TITLE": "लाइव  कैसिनो ",
      "CASINO@TRANSFER_BUTTON": "ट्रांसफर ",
      "CHANGE_PASSWORD@LENGTH_ERROR": "६ २०  और २० अक्षरों के बीच का पासवर्ड",
      "CHANGE_PASSWORD@NEW_PASSWORD@DEFAULT_MESSAGE": "एंटर  नई  पासवर्ड ",
      "CHANGE_PASSWORD@NEW_PASSWORD@LABEL": "नई  पासवर्ड ",
      "CHANGE_PASSWORD@OLD_PASSWORD@DEFAULT_MESSAGE": "टाइप  ओल्ड  पासवर्ड ",
      "CHANGE_PASSWORD@OLD_PASSWORD@LABEL": "ओल्ड  पासवर्ड ",
      "CHANGE_PASSWORD@CONFIRM_PASSWORD@LABEL": "कन्फर्म पासवर्ड ",
      "CHANGE_PASSWORD@MINIMUM": "मिनिमम ८ चरक्टेर्स ",
      "CHANGE_PASSWORD@REPEAD_PASSWORD@DEFAULT_MESSAGE": "मैच  नई  पासवर्ड ",
      "CHANGE_PASSWORD@REPEAD_PASSWORD@LABEL": "रिपीट  पासवर्ड ",
      "CHANGE_PASSWORD@SUBMIT": "चेंज  पासवर्ड ",
      "DASHBOARD@BONUS": "बक्शीश",
      "DASHBOARD@AFFILIATE": "संबद्ध",
      "SIDEBAR@PROMOTIONS": "परोन्नति",
      "DATE_PICKER@END": "एन्ड  डेट ",
      "DATE_PICKER@FROM": "फ्रॉम ",
      "DATE_PICKER@SEARCH": "सर्च ",
      "DATE_PICKER@START": "स्टार्ट  डेट ",
      "DATE_PICKER@TITLE": "पिक  डेट ",
      "DATE_PICKER@TO": "तो ",
      "DETAIL_TABLE@BET_ID": "बेट  ईद ",
      "DETAIL_TABLE@FOOTER@BACK_SUBTOTAL": "बैक  सबटोटल ",
      "DETAIL_TABLE@FOOTER@COMMISSION": "कमीशन ",
      "DETAIL_TABLE@FOOTER@LAY_SUBTOTAL": "ले  सबटोटल ",
      "DETAIL_TABLE@FOOTER@NET": "नेट  मार्किट  टोटल ",
      "DETAIL_TABLE@FOOTER@NO_SUBTOTAL": "नो  सबटोटल ",
      "DETAIL_TABLE@FOOTER@NOT_AVAILABLE": "नॉट  अवेलेबल ",
      "DETAIL_TABLE@FOOTER@YES_SUBTOTAL": "यस  सबटोटल ",
      "FOOTER@CONNECTWITHUS": "हमारे साथ जुड़ें",
      "DETAIL_TABLE@ODDS": "ऑड्स ",
      "DETAIL_TABLE@P&L": "प्रॉफिट`/ लोस्स  एक्सचेंज ",
      "DETAIL_TABLE@PLACED": "प्लेस्ड ",
      "DETAIL_TABLE@SELECTION": "सिलेक्शन ",
      "DETAIL_TABLE@STAKE": "स्टेक ",
      "DETAIL_TABLE@STATUS": "स्टेटस ",
      "DETAIL_TABLE@TRANSACTION_NUMBER": "ट्रांसक्शन  नंबर ",
      "DETAIL_TABLE@TYPE": "टाइप ",
      "DOWNLINE@SETTING@MARKET": "मार्किट ",
      "DOWNLINE@SETTING@SPORT": "स्पोर्ट ",
      "EXCHANGE_CHECKOUT_ORDER@ORDER_CHECKOUT_DELETE_SETTLE_FAILURE":
        "टेक्निकल फेलियर ",
      "FIXEDODDS@REMIND":
        "कृपया ध्यान दें कन्वर्शन ऑफ़ सिक्के व्हेन प्रवेश थे फिक्स्ड ऑड्स सो बैलेंस  चेक करके बेटस प्लेस करो धन्यवाद.",
      "FOOT@COMPANYLICENSE": "कंपनी  लाइसेंस ",
      "FOOT@DISCONNECTPOLICY": "डिसकनेक्शन पालिसी ",
      "FOOT@EXCLUSIONPOLICY": "सेल्फ एक्सक्लूशन पालिसी ",
      "FOOT@LICENSEDETAILS": "", //"BBB Games is operated by SNK Entertainment N.V. a limited liability company incorporated under the laws of Curacao with company Registration number 148785 with registered office at Abraham de Veerstraat 7 , Curacao and is licensed and regulated by the Curacao authority as the regulatory body responsible holding a (Sub-license with License number 365/JAZ Sub-License GLH- OCCHKTW0704112019 granted on 11.04.2019). Players are requested not to contact any untrusted sources for BBB.Games accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.",
      "FOOT@PRIVACYPOLICY": "प्राइवेसी  पालिसी ",
      "FOOT@TERMSANDCONDITIONS": "नियम एवं शर्तें",
      "FOOT@ABOUTUS": "हमारे बारे में",
      "FOOT@RESPONSIBLEGAMBLING": "रेस्पोंसिबल  गैंबलिंग ",
      "FOOT@RESTRICTEDTERRITORIES": "रिस्ट्रिक्टेड  टेरिटरीज ",
      "FOOT@RULES": "रूल्स  & रेगुलेशंस ",
      "FOOT@TERMSANDPOLICY": " टर्म्स  एंड  पालिसी ",
      "FOOT@YEAR": "© 2019-2022", //code changed by John. Previous code was like "FOOT@YEAR": "Â© 2019-2021",
      "FOOTER@BEGAMBLEAWARE": "https://www.begambleaware.org/",
      "FOOTER@COPYRIGHT": "कॉपीराइट 2021-2025 एमपीसी। सर्वाधिकार सुरक्षित",
      "FOOTER@GAMINGPARTNERS": "गेमिंग पार्टनर",
      "FOOTER@PrincipalSponsors": "प्रधान प्रायोजक",
      "FOOTER@PrinciplePartner": "सिद्धांत भागीदार",
      "FOOTER@AssociatePartner": "साथी सहयोगी",
      "FOOTER@Associatesponsors": "सहयोगी प्रायोजक",
      "FOOTER@AwardsAndRecognition": "पुरस्कार और मान्यता",
      "FOOTER@EngagementPartner": "सगाई का साथी",
      "FOOTER@OfficialOverseasBackSponsor": "आधिकारिक विदेशी बैक प्रायोजक",
      "FOOTER@OficialTittleSponcer": "आधिकारिक शीर्षक प्रायोजक",
      "FOOTER@PrincipleSponcerFomo": "मुख्य प्रायोजक",
      "FOOTER@PAYMENTMETHODS": "भुगतान की विधि",
      "FOOTER@GAMECARE": "https://www.gamcare.org.uk/",
      "FOOTER@GAMSTOP": "https://www.gamstop.co.uk/",
      "GAME@MACAW": "मकाव",
      "GAME@AVIATRIX": "एविएट्रिक्स",
      "GAME@SEXYGAMING": "सेक्सी गेमिंग",
      "GAME@AGTEENPATTI": "TPT प्लस",
      "GAME@ALL": "आल  गेम्स ",
      "GAME@SPRIBE": "स्प्राइब",
      "GAME@STP": "एसटीपी",
      "GAME@AG": "एजी",
      "GAME@SPINMATIC": "स्पिनमैटिक",
      "GAME@NETENT": "नेटेन्ट ",
      "GAME@BETSOFT": "बेट सॉफ़्ट ",
      "GAME@PRAGMATICPLAY": "प्रग्माटिकप्ले",
      "GAME@TEENPATTI": "तीनपत्ती",
      "GAME@SUPERSPADE": "सुपर स्पाडे ",
      "GAME@EVOGAMES": "एवो गेम्स ",
      "GAME@BETGAMES": "बेट गेम्स ",
      "GAME@EZUGI": "एजुगि ",
      "GAME@TVBET": "टीवी बेट ",
      "GAME@SUPERNOWA": "सुपरनोवा",
      "GAME@ONEXBET": "ONEXBET",
      "GAME@ASIA_GAMING": "असीअगामिंग ",
      "GAME@BBB_FIXED_OODS": "फिक्स्ड  ऑड्स ",
      "GAME@ESPORTS": "इ -स्पोर्ट्स ",
      "GAME@LIVE_CASINO": "लाइव  कैसिनो ",
      "GAME@MICRO_GAMING": "मिक्रोगामिंग ",
      "GAME@PLAYTECH": "प्लेटेच ",
      "GAME@GALAXYS": "गैलेक्सी एस",
      "GAME@POKER": "पोकर  ",
      "GAME@POLITICS": "पॉलिटिक्स ",
      "GAME@SA_GAMING": "सगमिंग ",
      "GAME@SPECIAL_FANCY": "स्पेशल  फैंसी ",
      "GAME@TEEN_PATTI": "एक्सचेंज  कार्ड  गेम ",
      "GAME@VIRTUALSPORTS": "वर्चुअल  स्पोर्ट्स ",
      "GAME@WE_ARE_CASINO": "हम  है  कैसिनो ",
      "GLOBAL@BACK": "लगाई ",
      "GLOBAL@FANCY": "फैंसी ",
      "GLOBAL@LAY": "खाई ",
      "GLOBAL@LIABILITY": "लायबिलिटी ",
      "GLOBAL@LOSS": "लोस्स ",
      "GLOBAL@MATCH_ODDS": "मैच  ऑड्स ",
      "GLOBAL@NO": "नो ",
      "GLOBAL@ODDS": "ऑड्स ",
      "GLOBAL@SELECTION": "सिलेक्शन",
      "GLOBAL@PL": "प्रॉफिट/लोस्स ",
      "GLOBAL@PLACEHOLDER": "-",
      "GLOBAL@PROFIT": "प्रॉफिट ",
      "GLOBAL@RUNS": "रन्स ",
      "GLOBAL@STAKE": "स्टेक ",
      "GLOBAL@YES": "यस ",
      "VIRTUALSPORTSMENUTITLE@ENGLISHLEAGUE": "इंग्लिश लीग",
      "VIRTUALSPORTSMENUTITLE@EUROLEAGUE": "यूरो लीग",
      "GQL_GAME_ADD@PARAM_ERROR": "नाम और इमेज यूआरएल   शामिल किया जाना चाहिए.",
      "GQL_GAME_UPDATE@PARAM_ERROR": "नाम और इमेज यूआरएल शामिल किया जाना चाहिए",
      "HEADER@BBBHOME": "होम ",
      "HEADER@LIVESCORE": "लाइव स्कोर",
      "HEADER@MULTITV": "मल्टी टीवी",
      "HEADER@LIVETV": "लाइव टीवी",
      "HISTORY_TABLE@BETID": "बेट  ईद ",
      "HISTORY_TABLE@DESCRIPTION": "डिस्क्रिप्शन ",
      "HISTORY_TABLE@LIABILITY": "लायबिलिटी ",
      "HISTORY_TABLE@MATCHED": "मत्चेद ",
      "HISTORY_TABLE@ODDS": "ऑड्स ",
      "HISTORY_TABLE@ORDER_NUMBER": "बेट  नंबर ",
      "HISTORY_TABLE@PLACED": "प्लेस्ड ",
      "HISTORY_TABLE@POTENTIAL_PROFIT": "पोटेंशियल  प्रॉफिट ",
      "HISTORY_TABLE@PROFIT_LOSS": "प्रॉफिट /लोस्स ",
      "HISTORY_TABLE@SETTLED": "सेटल्ड ",
      "HISTORY_TABLE@STAKE": "स्टेक ",
      "HISTORY_TABLE@STATUS": "स्टेटस ",
      "HISTORY_TABLE@TIME": "टाइम ",
      "HISTORY_TABLE@TRANSACTION_NUMBER": "ट्रांसक्शन  नंबर ",
      "HISTORY_TABLE@TYPE": "टाइप ",
      "IOS@INSTALL@_OPT_STEP2":
        "अगर आपका स्क्रीन पॉप उप होगा थो डाउनलोड प्रोफाइल विण्डो , कृपणा बंद करके फिर  से अल्लोव बटन दबाई .",
      "IOS@INSTALL@_OPT_STEP5": "इनस्टॉल बटन दबाई इनस्टॉल बटन दबाई n.",
      "IOS@INSTALL@STEP1":
        "इनस्टॉल बटन दबाई,और फाइल फ़ोन में इनस्टॉल करो  और प्रोफाइल डाउनलोड करना है .",
      "IOS@INSTALL@STEP2":
        'यदि डिवाइस सेटिंग पर पुनर्निर्देशक नहीं है, तो कृपया "सेटिंग> जनरल > प्रोफ़ाइल" का पालन करें',
      "IOS@INSTALL@STEP3": "इनस्टॉल बटन दबाई इंस्टॉलिज़तिओन शुरू करने केलिए",
      "IOS@INSTALL@STEP4": "शुरू  करने केलिए ओपन दबाई .",
      "IOS@INSTALL@STEP5": "इनस्टॉल   एंड आगेबड़े ",
      "IOS@INSTALL@STEP6": "अप्प  आपको डेस्कटॉप पे मिल जायेगा",
      "IOVATION@INVALID": "डिवाइस और रीजन इनवैलिड",
      "LOGIN@CHECKBOX": "मेरी उम्र १८ वर्ष से अधिक है, मैं इससे सहमत हूं ",
      "LOGIN@CHECKBOX2": "नियम व शर्तें ",
      "LOGIN@CHECKBOX3": " गोपनीयता नीति",
      "LOGIN@DEFAULT_MESSAGE": "स्वागत हे",
      "LOGIN@CLICKHERETO": "यहां क्लिक करें",
      "LOGIN@CLICKHERE": "यहां क्लिक",
      "LOGIN@SIGNUP": "साईनप करने के लिए",
      "LOGIN@NOTABLE": "लॉगिन नहीं हो रहा है ?",
      "LOGIN@LOGINID": "लॉगिन आईडी",
      "BUTTON@RESETPASSWORD": "रीसेट पासवर्ड",
      "BUTTON@SHOWUSERNAME": "शो यूजरनेम",
      "LOGIN@EXISTINGUSER": "एक्सिस्टिंग यूजर?",
      "FORGOT@USERNAME": "यूजरनाम नाम भूल गए?",
      "FORGOT@PASSWORD": "पासवर्ड भूल गए?",
      "TITLE@populargames": "पॉपुलर गेम्स",
      "TITLE@smartsoft": "स्मार्टसॉफ्ट",
      "LOGIN@EMAIL": "customercare@bbb.games",
      "LOGIN@DEFAULT": "customercare",
      "LOGIN@MPCEMAIL": "customercare@mpc999.com",
      "LOGIN@9BETSEMAIL": "customercare@9bets.xyz",
      "LOGIN@BBBEMAIL": "customercare@bbb.games",
      "LOGIN@MAHADEVEMAIL": "customercare@mahadevbet.xyz",
      "LOGIN@PARIMATCH": "customercare@parimatch.exchange",
      "LOGIN@ERROR_MESSAGE": "लॉगिन  फेल्ड !",
      "LOGIN@ENTERUSERNAME": "कृपया आपका यूजरनाम एंटर करें।",
      "LOGIN@ENTERPASSWORD": "कृपया आपका पासवर्ड एंटर करें।",
      "LOGIN@18YEARS": "कृपया पुष्टि करें कि आपकी आयु 18 वर्ष से अधिक है।",
      "LOGIN@LOGGING_IN_MESSAGE": "लॉगिंग  इन ",
      "LOGIN@PASSWORD@LABEL": "पासवर्ड ",
      "LOGIN@PASSWORD@PLACEHOLDER": "एंटर  पासवर्ड ",
      "LOGIN@PHONE": "+३८०  ९३२४५०२२८ ",
      EXAMPLE: "उदाहरण के लिए :",
      withdrwalfooter:
        "…….. और बाद में निकासी पर 1% अतिरिक्त शुल्क लगाया जाएगा",
      "LOGIN@REMEMBER_ACCOUNT": "रेमेम्बेर  में ",
      "LOGIN@RESTRICTED": "रिस्ट्रिक्टेड  टेरिटरीज ",
      "LOGIN@SUBMIT": "लॉगिन ",
      "LOGIN@SUBMIT@UNCHECKED":
        "आप तब तक आगे नहीं बढ़ सकते जब तक आप यह सत्यापित नहीं कर लेते कि आपकी आयु 18 वर्ष से अधिक है।",
      "LOGIN@TITLE": "लॉग इन",
      "LOGIN@ID": "लॉगिन आईडी",
      "LOGIN@MOBILE": "मोबाइल नंबर",
      "LOGIN@EMAILID": "ईमेल आईडी दर्ज करें",
      "LOGIN@USERNAME": "यूजर आईडी दर्ज करें",
      "LOGIN@PASSWORD": "पासवर्ड",
      "SIGNUP@TITLE": "साइन अप",
      "AUTH@CLICKHERE": "यहाँ क्लिक करें",
      "AUTH@CLICKHERETO": "यहां क्लिक करें",
      "AUTH@EXISTINGUSER": "मौजूदा उपयोगकर्ता?",
      "AUTH@SUBMIT": "प्रस्तुत",
      "AUTH@NEXT": "NEXT",
      "PASSWORD@BUTTON": "पासवर्ड रीसेट",
      "USERNAME@BUTTON": "उपयोगकर्ता नाम दिखाएं",
      "USERNAME@LOGIN": "लॉग इन करना जारी रखें",
      "AUTH@OTP": "ओटीपी",
      "AUTH@SENT": "भेजा",
      "AUTH@SENDING": "भेजना...",
      "AUTH@VERIFY": "सत्यापित करना",
      "AUTH@VERIFIED": "सत्यापित",
      "AUTH@VERIFYING": "सत्यापित किया जा रहा है...",
      "AUTH@RESENDOTP": "ओटीपी प्राप्त नहीं हुआ?",
      "AUTH@RESEND": "पुन भेजें",
      "AUTH@BACK": "वापस",
      "CREATE@USERNAME": "उपयोगकर्ता नाम बनाएँ",
      "CREATE@PASSWORD": "पासवर्ड बनाएं",
      "CREATE@PHONENUMBER": "मोबाइल नंबर दर्ज करें",
      "SIGNUP@ENTERNAME": "नेम एंटर करें",
      "SIGNUP@ENTERPASSWORD": "पासवर्ड एंटर करें",
      "SIGNUP@ENTERNUMBER": "मोबाइल नंबर एंटर करें",
      "SIGNUP@FIRSTNAME": "पहला नाम",
      "SIGNUP@LASTNAME": "उपनाम",
      "SIGNUP@REFERRERCODE": "प्रोमो या रेफरर कोड (वैकल्पिक)",
      "LOGIN@ENTEROTP": "OTP एंटर करें",
      "LOGIN@NEW_PASSWORD": "एंटर नई पासवर्ड",
      "LOGIN@CONFIRM_NEW_PASSWORD": "कन्फर्म नई  पासवर्ड",
      "LOGIN@DEPOSIT": "जमा करना",
      "LOGIN@USERNAME@LABEL": "यूजरनाम ",
      "LOGIN@BALANCE@LABEL": "बैलेंस",
      "LOGIN@USERNAME@PLACEHOLDER": "एंटर  यूजरनाम ",
      "BONUS@FILTERS1": "सभी बोनस",
      "BONUS@FILTERS2": "पहली बार जमा",
      "BONUS@FILTERS3": "पुनः जमा बोनस",
      "BONUS@FILTERS4": "लाइव कैसीनो",
      "BONUS@FILTERS5": "एक मित्र को सूचित करें",
      "BONUS@FILTERS6": "स्लॉट खेल",
      "BONUS@FILTERS7": "खेल पुस्तक",
      "BONUS@FILTERS8": "अदला-बदली",
      "MATCHED_ORDER@AVERAGE": "एवरेज ",
      "MATCHED_ORDER@CONSOLIDATE": "कंसोलिडेटेड ",
      "MATCHED_ORDER@EVENT_DATE": "इवेंट  डेट ",
      "MATCHED_ORDER@TITLE": "मत्चेद  बेट ",
      "MEMBER@ACCOUNT_STATEMENT": "एकाउंट  स्टेटमेंट ",
      "MEMBER@CASINO_STATEMENT": "कैसिनो  स्टेटमेंट ",
      "MEMBER@CHANGE_PASSWORD": "चेंज  पासवर्ड ",
      "MEMBER@GOOGLE_AUTHENTICATION": "ऑथेंटिकटोर ",
      "MEMBER@LOG_OUT": "लोग  आउट ",
      "MEMBER@MY_ORDER": "माय  बेटस ",
      "MEMBER@P2P_PROFIT_LOSS": "प्रॉफिट  लोस्स ",
      "MEMBER@P2P_STATEMENT": "कैसिनो  स्टेटमेंट ",
      "MEMBER@PROFIT_LOSS": "प्रॉफिट  एंड  लोस्स ",
      "MEMBER@SPORT_STATEMENT": "स्पोर्ट  स्टेटमेंट ",
      "MEMBER@TRANSFER": "ट्रांसफर ",
      "MEMBER@MEMBER": "सदस्य",
      "MENU@BACK": "बैक ",
      "MENU@BETS": "मेरा बेत्स ",
      "MENU@HOME": "होम ",
      "MENU@INPLAY": "इंप्लॉय ",
      "LOGIN@INPLAYGAMES": "खेल खेलें",
      "MENU@MARKET": "मेरा मार्केट्स ",
      "MENU@FAVORITES": "पसंदीदा",
      "MENU@MENU": "मेनू ",
      "MENU@TEENPATTI": "एक्सचेंज  कार्ड  गेम्स ",
      "MY_ORDER@BOTH": "बोथ ",
      "MY_ORDER@CURRENT": "करंट बेट ",
      "MY_ORDER@MATCHED": "मत्चेद ",
      "MY_ORDER@PAST": "पास्ट बेट",
      "MY_ORDER@UNMATCHED": "उनमचेड ",
      "ONE_CLICK@EDIT": "एडिट  स्टेक्स ",
      "ONE_CLICK@OFF": "ऑफ ",
      "ONE_CLICK@ON": "ऑन ",
      "ONE_CLICK@REMIND":
        "कृपया ध्यान दें: 1 क्लिक बेटिंग को सक्षम करने से बुकमेकर के लिए सभी ऑड्स पर बेट स्वीकार करने में भी मदद मिलेगी।",
      "ONE_CLICK@SAVE": "सेव  ",
      "ONE_CLICK@TITLE": "१ -क्लिक  बेट ",
      ACCEPT_BOOKMAKER_ODDS: "बी.म-सभी ऑड्स एक्सेप्ट",
      "ORDER@ORDERSLIP":
        "ऑड्स {रिक्वेस्ट  प्राइस } से { बेस्ट प्राइस } में बदल गए हैं. क्या आप नई कीमत पर जारी रखना चाहते हैं?",
      "ORDERSLIP@CANCEL": "कैंसिल  एडिटिंग ",
      "ORDERSLIP@CANCEL_ALL": "कैंसिल  आल ",
      "ORDERSLIP@CANCEL_ORDER": "कैंसिल  आर्डर ",
      "ORDERSLIP@CLEAR": "क्लियर ",
      "ORDERSLIP@EDIT": "एडिट  स्टेक्स ",
      "ORDERSLIP@EMPTY": "नो  बेटस ",
      "ORDERSLIP@PLACE": "प्लेस  बेट ",
      "ORDERSLIP@PLACE_ALL": "प्लेस  आल ",
      "ORDERSLIP@SAVE": "सेव ",
      "ORDERSLIP@TITLE": "बेट  स्लिप ",
      "PASSWORD@DEFAULT_PASSWORD": "आपके सुरक्षा केलिए पासवर्ड बदल दीजिये .",
      "PENDING_ORDER@TITLE": "पेंडिंग आर्डर",
      "PLATFORM_DESCRIPTION@TEXT":
        "हम कई  स्पॉट और कई मार्किट ऑफर  करते है . यहां अपने बेटस  पर सर्वोत्तम रिटर्न प्राप्त करें!",
      "PLATFORM_INDEX@BUTTON1": "बबब  स्पोर्ट ",
      "PLATFORM_INDEX@BUTTON2": "",
      "PLATFORM_SUBTITLE@SUBTITLE1": "कई स्पोर्ट्स  पर अभी बेटस  लगाएं!",
      "PLATFORM_SUBTITLE@SUBTITLE2": "बेटस  लगाने के लिए नए मार्किटt",
      "PLATFORM_SUBTITLE@SUBTITLE3":
        "अब उपलब्ध सर्वोत्तम कैसीनो में खेलें! एक नया अनुभव",
      "PLATFORM_TITLE@TITLE1": "फास्टेस्ट  ऑड्स ",
      "PLATFORM_TITLE@TITLE2": "बेटिंग का नया अनुभव",
      "PLATFORM_TITLE@TITLE3": "कैसिनो ",
      "PLATFORM_TITLE@TITLE4": "लाइव  कैसिनो ",
      "PROFIT_LOSS_TABLE@COMMISSION": "कमीशन ",
      "PROFIT_LOSS_TABLE@MARKET": "मार्किट ",
      "PROFIT_LOSS_TABLE@GAMES": "खेल",
      "PROFIT_LOSS_TABLE@NET": "नेट ",
      "PROFIT_LOSS_TABLE@SETTLE_TIME": "सेटल  टाइम ",
      "PROFIT_LOSS_TABLE@START_TIME": "स्टार्ट  टाइम ",
      "RESOURCEFORM@BETFAIR": "बेटफेयर ",
      "RESOURCEFORM@BOOKMAKER": "बुकमेकर ",
      "RESOURCEFORM@PARKER": "पार्कर ",
      "RESOURCEFORM@WICKET": "विकेट ",
      "SEARCH@NO_MATCH": "कोई इवेंट्स  नहीं मिली ",
      "SEARCH@PLACEHOLDER": "सर्च  इवेंट्स ",
      "SIDEBAR@BACK_TO_BETTING": "बैक  तो  बेटिंग ",
      "SIDEBAR@FOOTNOTE": "रूल्स  & रेगुलेशंस  © २०१९  - २०२१ ",
      "SIDEBAR@FOOTNOTE_KYC": "KYC",
      "SIDEBAR@FOOTNOTE_UNDERAGE": "ुन्देरगे गैंबलिंग  अपराध है",
      "SIDEBAR@QUICK_ACCESS": "क्विक  एक्सेस ",
      "SIDEBAR@SPORTS": "स्पोर्ट्स ",
      "SPORT_STATEMENT_TABLE@ACCOUNT": "अकाउंट ",
      "SPORT_STATEMENT_TABLE@AGENT": "एजेंट ",
      "SPORT_STATEMENT_TABLE@EVENT": "इवेंट ",
      "SPORT_STATEMENT_TABLE@MARKET": "मार्किट ",
      "SPORT_STATEMENT_TABLE@PLACEDTIME": "प्लेस्ड  टाइम   ",
      "SPORT_STATEMENT_TABLE@PLATFORM": "प्लेटफार्म ",
      "SPORT_STATEMENT_TABLE@REFID": "रेफ  ईद ",
      "SPORT_STATEMENT_TABLE@SELECTION": "सिलेक्शन  ",
      "SPORT_STATEMENT_TABLE@SETTLEDTIME": "सेटल्ड  टाइम  ",
      "SPORT_STATEMENT_TABLE@STAKE": "स्टके : MW/FOW",
      "SPORT_STATEMENT_TABLE@STATUS": "स्टेटस ",
      "SPORT_STATEMENT_TABLE@WINLOSS": "विन /लोस्स : MW/FOW",
      "SPORT@ALL": "आल ",
      "SPORT@WORLDCUPFANCY": "विश्व कप फैंसी",
      "LOGIN@TOPGAMES": "शीर्ष खेल",
      "SPORT@CRICKET": "क्रिकेट ",
      "SPORT@FOOTBALL": "फुटबॉल ",
      "SPORT@GREYHOUND_RACING": "ग्रेहाउंड्स ",
      "SPORT@HORSE_RACING": "हॉर्सेज ",
      "SPORT@POLITICS": "पॉलिटिक्स ",
      "SPORT@TENNIS": "टेनिस ",
      "SPORT@VolleyBall": "वालीबाल",
      "SPORT@BASKETBALL": "बास्केटबाल",
      "STATEMENT@STATUS@All": "आल ",
      "STATEMENT@STATUS@BET": "बेट ",
      "STATEMENT@STATUS@CANCEL": "कैंसिल ",
      "STATEMENT@STATUS@CONFIRM": "कन्फर्म ",
      "STATEMENT@STATUS@DELETE": "डिलीट ",
      "STATEMENT@STATUS@SETTLE": "सेटल्ड ",
      "STATEMENT@WALLET@FOW": "FOW= फिक्स्ड  ऑड्स  वॉलेट ",
      "STATEMENT@WALLET@MW": "यम डॉबलेऊ = मैन वॉलेट ",
      "TABLE@BALL_RUNNING": "बॉल  रनिंग ",
      "TABLE@COMING_SOON": "किंग  सून !",
      "TABLE@IN_PLAY": "इन -प्ले ",
      "TABLE@MATCH": "मैच ",
      "TABLE@PROVIDER": "प्रोवाइडर ",
      "TABLE@MAX_BET": "मैक्स  बेट ",
      "TABLE@MAX_EXPOSURE": "मैक्स  एक्सपोज़र ",
      "TABLE@MAX_MARKET": "मैक्स  मार्किट ",
      "TABLE@NO_CONTENT": "नो  कंटेंट ",
      "TABLE@NO_EVENT": "नो  इवेंट / गेम ",
      "TABLE@NO_MARKET": "नो  मार्किट ",
      "TABLE@SESSION_RUNS": "फैंसी ",
      "TABLE@STATE": "स्टेट ",
      "TABLE@SUSPENDED": "सस्पेंडेड ",
      "TEST@TEST": "नल",
      "THIRD_PARTY@NOTIFICATION":
        "कृपया कैसीनो में INR को इग्नोर  करें. सिक्के का मूल्य अपलाइन के अनुसार है.",
      "THIRD_PARTY@NOTIFICATION_HINDI":
        "कृपया कैसीनो में INR को अनदेखा करें। सिक्के का मूल्य आपके द्वारा प्राप्त मूल्य के अनुसार रहेगा।",
      "THIRD_PARTY@NOTIFICATION_NO": "नो ",
      "THIRD_PARTY@NOTIFICATION_YES": "यस ",
      "THIRD_PARTY@REMIND":
        "कृपया कैसीनो में INR को इग्नोर  करें. सिक्के का मूल्य अपलाइन के अनुसार है.",
      "THIRD_PARTY@REMIND_YES": "ठीक है मै समझ गया",
      "THIRD_PARTY@REMIND@INDIAN":
        "à¤à¥à¤ªà¤¯à¤¾ à¤à¥à¤¸à¥à¤¨à¥ à¤®à¥à¤ INR à¤à¥ à¤à¤¨à¤¦à¥à¤à¤¾ à¤à¤°à¥à¤à¥¤ à¤¸à¤¿à¤à¥à¤à¥ à¤à¤¾ à¤®à¥à¤²à¥à¤¯ à¤à¤ªà¤à¥ à¤¦à¥à¤µà¤¾à¤°à¤¾ à¤ªà¥à¤°à¤¾à¤ªà¥à¤¤ à¤®à¥à¤²à¥à¤¯ à¤à¥ à¤à¤¨à¥à¤¸à¤¾à¤° à¤°à¤¹à¥à¤à¤¾à¥¤",
      "TITLE@BBBSPORT": "स्पोर्ट ",
      "TITLE@BIG_BOSS_2020": "बिगबॉस  २०२० ",
      "TITLE@BIHAR_ELECTIONS": "बिहार  एलेक्शंस ",
      "TITLE@UPELECTION": "यूपी चुनाव",
      "TITLE@PUNJABELECTION": "पंजाब चुनाव",
      "TABLE@TODAY": "आज",
      "TABLE@TOMORROW": "कल",
      "TABLE@UPCOMING": "अपकमिंग ",
      "TABLE@STATUS": "स्टेटस ",
      "TABLE@CLICK_ON_ODDS_TO_BET": "क्लिक  ओन   ऑड्स  तू   बेट ",
      "TABLE@OPTIONS": "ऑप्शंस ",
      "TABLE@BOOST_YOUR_BET": "बूस्ट  युवर   बेट ",
      "TABLE@USE_BOOKMAKER_AND_FANCY": "उपयोग बुकमेकर एंड फैंसी ",
      "TABLE@TEAMS": "टीम्स",
      "TITLE@LIVE_DEALER":
        "लाइव  डीलर  कैसीनोस  ब्रौघत  तू  यू  बीए   वर्ल्डस    बेस्ट  प्रोवाइडर्स ",
      "TITLE@DISCOVER_SUPER7S": "डिस्कवर  था   SUPER7S  एज ",
      "TITLE@DISCOVER_IEXCH": "डिस्कवर  था   IEXCH  एज ",
      "TITLE@DISCOVER_STRIKERS": "डिस्कवर  था   STRIKERS 365  एज ",
      "TITLE@COME_PLAY_RULE": "कम  . प्ले  . रूल ",
      "TITLE@PLAY": "प्ले",
      "TITLE@DEMO": "डेमो ",
      "TITLE@PLAY_NOW": "प्ले नाउ ",
      "TITLE@EXPLORE_NOW": "एक्स्प्लोर नाउ",
      "TITLE@SPORTS": "स्पोर्ट्स",
      "TITLE@TOP": "टॉप",
      "TITLE@BLACKJACK": "ब्लैकजैक ",
      "TITLE@ROULETTE": "रौलेट",
      "TITLE@BACCARAT": "बकरात ",
      "TITLE@DRAGON_TIGER": "ड्रैगन  टाइगर ",
      "TITLE@SIDE_BET_CITY": "साइड बेट  सिटी",
      "TITLE@INDIAN_GAMES": "इंडियन गेम्स ",
      "TITLE@ANDAR_BAHAR": "अंदर बहार ",
      "TITLE@7UP": "७ उप एंड डाउन ",
      "TITLE@DICE_GAMES": "डाइस गेम्स ",
      "TITLE@SPECIALS": "स्पेशल्स",
      "TITLE@MY_ACCOUNT": "मेय अकाउंट",
      "TITLE@ACCOUNT_STATEMENT": "अकाउंट स्टेटमेंट",
      "TITLE@MY_BETS": "मेय बेटस",
      "TITLE@CASINO_STATEMENT": "कैसिनो स्टेटमेंट ",
      "EXCHANGE@INPLAYGAMES": "खेल खेलें",
      "EXCHANGE@CRICKET": "क्रिकेट",
      "EXCHANGE@FOOTBALL": "फुटबॉल",
      "EXCHANGE@TENNIS": "टेनिस",
      "EXCHANGE@WORLDCUPFANCY": "विश्व कप फैंसी",
      "EVENTS@NO_EVENT": "नो इवेंट ",
      "EVENTS@EVENT_INVALID": "इवेंट इस इनवैलिड ओर  क्लोज्ड  ",
      "EVENTS@GOTO_INPLAY": "इन-प्ले जाओ",
      "GLOBAL@SEARCHBYGAMES": "सर्च बय  गेम्स ",
      "BETSLIP@MIN": "मिनिमम :",
      "GLOBAL@PROVIDERS": "प्रोवाइडर्स",
      "TITLE@DEVICE_TIME": "डिवाइस का समय",
      "MEMBER@SECURITY": "सुरक्षा",
      "TITLE@PLATFORMBALANCE": "प्लेटफार्म बैलेंस",
      "TITLE@BALANCE": "बैलेंस",
      "TITLE@TRANSFER_TO_MAINWALLET": "ट्रांसफर तू मैन वॉलेट ",
      "TITLE@REFRESH": "रिफ्रेश",
      "TITLE@SORTBY": "द्वारा क्रमबद्ध।",
      "TITLE@PROVIDER": "प्रोवाइडर",
      "TITLE@MARQUE1":
        "आपका बोनस, स्वतः सक्रिय - अपने मनोरंजन साथी को अभी एक्सप्लोर करें !! रोमांचक पुरस्कारों के लिए हमारा बोनस पृष्ठ देखें",
      "TITLE@MARQUE": "Yolo247 - बियॉन्ड गेम्स",
      "LANG@ENGLISHLANGUAGE": "अंग्रेज़ी",
      "LANG@HINDILANGUAGE": "हिंदी",
      "LANG@LANGUAGESETTINGS": "भाषा सेटिंग्स।",
      "TITLE@MAXBET": "मैक्स बेट:",
      "TITLE@MAXMARKET": "मैक्स बाजार:",
      "TITLE@NO": "नहीं",
      "TITLE@YES": "हाँ",
      "TITLE@SUPERNOWA": "सुपरनोवा",
      "TITLE@STEP1": "चरण 1 : ",
      "TITLE@STEP2": "चरण 2 : ",
      "TITLE@STEP3": "चरण 3 : ",
      "TITLE@SETP1_INFO":
        " AppStore या GooglePlay से सीधे अपने फ़ोन पर Google Authenticator डाउनलोड करें।. ",
      "TITLE@SETP2_INFO": "Google Authenticator के भीतर अकाउंट सेटअप करें। .",
      "TITLE@SETP3_INFO":
        "Google Authenticator के भीतर QR कोड स्कैन करें और निचे संख्या एंटर करें।",
      "TITLE@PRESS_HERE": "यहाँ दबाओ",
      "TITLE@STAKES": "दांव",
      // "TITLE@DEVICE_TIME":"डिवाइस का समय",
      "TRANSFER@MAINWALLET": "माईन वॉलेट",
      "TRANSFER@WACS": "Hindi",
      "TRANSFER@AGTEENPATTI": "Hindi",
      "TRANSFER@SLOTTERY": "Hindi",
      "SECURITY@VERIFICATIONCODE": "संख्या",
      "BUTTON@VERIFY": "वेरीफाई करें",
      "BUTTON@DISABLEVERIFY": "२ फैक्टर ऑथेंटिकेशन डिसएबल करो ",
      "BUTTON@BACK": "वापस",
      "ORDERLIST@SELECTED": "सिलेक्टेड इवेंट :",
      "ORDERLIST@ALL": "आल ",
      "BETRADAR@FLOAT": "फ्लोट",
      "BETRADAR@CLOSE": "क्लोज",
      "APP@SCANQR":
        "ऐप डाउनलोड करने या बटन पर क्लिक करने के लिए क्यूआर कोड स्कैन करें",
      "CHANGE_PASSWORD@SUCCESS": "आपका पासवर्ड सफलतापूर्वक बदल दिया गया है",
      "UPDATE_PASSWORD@FAIL": "पासवर्ड अपडेट करने में विफल !",
      "CHANGE_PASSWORD@FAIL": "पासवर्ड बदलें विफल!",
      "NOTIFY@PRICE": "प्राइस",
      "NOTIFY@MATCH": "मैच",
      "NOTIFY@UNMATCH": "उनमातच",
      "TITLE@PSL": "पीएसएल कप",
      "TITLE@LIGHTTHEME": "लाइट थीम",
      "TITLE@DARKTHEME": "डार्क थीम",

      // "TITLE@BINGO": "बिंगो ",
      "TITLE@ENTER_CODE_HERE": "एंटर कोड हेरे ",
      "TITLE@SUBMIT": "सबमिट",
      "TITLE@ENTER_GOOGLE_AUTHENTICATOR_CODE":
        "कृपया अपना Google Authenticator कोड दर्ज करें",
      "TITLE@PRIVACY_POLICY": "प्राइवेसी  पालिसी ",
      "TITLE@DISCONNECTION_POLICY": "डिसकनेक्शन  पालिसी ",
      "TITLE@RESPONSIBLE_GAMBLING": "रेस्पोंसिबल  गैंबलिंग ",
      "TITLE@RULES_&_REGULATION": "रूल्स  एंड  रेगुलेशन ",
      "TITLE@EXCLUSION_POLICY": " एक्सक्लूशन  पालिसी ",
      "TITLE@RESTRICTED_TERRITORIES": "रिस्ट्रिक्टेड  टेरिटरीज ",
      "TITLE@TERMSPOLICY": "टर्म्स  एंड  पालिसी ",
      "TITLE@BOOKMAKER": "बुकमेकर ",
      "TITLE@POKER": "पोकर ",
      "TITLE@BIGBASH": "बिग बैश ",
      "ELITE@NOTE":'चर्चा की गई नियम एवं शर्तें पुष्टि के रूप में आपके इनबॉक्स संदेश पर भेजी जाएंगी',
      "TITLE@SPECIALMARKET": "स्पेशल मार्केट",
      "TITLE@PROMOTIONS": "पदोन्नति",
      "TITLE@InstantGames": "झटपट खेल",
      "TITLE@Bonus": "बक्शीश",
      "TITLE@LOYALTY": "निष्ठा",
      "TITLE@CASINO": "कैसिनो ",
      "TITLE@DELHI": "दिल्ली  इलेक्शन ",
      "TITLE@ESPORTS": "इ -स्पोर्ट्स ",
      "TITLE@EXCHANGE": "एक्सचेंज ",
      "TITLE@FAVORITE": "फेवरिट्स ",
      "TITLE@PREMIUM_SPORTSBOOK": "प्रीमियम स्पोर्ट्सबुक",
      "TITLE@HARYANA": "हरयाणा  इलेक्शन ",
      "TITLE@INPLAY": "इन -प्ले ",
      "TITLE@IPL_WINNER_2020": "आईपीएल  २०२० ",
      "TITLE@JHARKHAND": "झारखण्ड  इलेक्शन ",
      "TITLE@LIVECASINO": "लाइव  कैसिनो ",
      "TITLE@SLOTGAME": "स्लॉट  गेम ",
      "TITLE@WORLDCUP": "विश्व कप विजेता",
      "TITLE@WORLDCUPT20": "विश्व कप T20",
      "TITLE@LIVEGAMES": "लाइव गेम्स",
      "TITLE@VIRTUALGAMES": "वर्चुअल गेम्स ",
      "TITLE@Tournaments": "प्रतियोगिता",
      "TITLE@BINGO": "बिंगो ",
      "TITLE@BLACKJACKSIDEBAR": "ब्लैक जैक",
      "TITLE@ANDARBAHARSIDEBAR": "अंधर बहारी",
      "TITLE@ROULETTESIDEBAR": "रूले",
      "TITLE@BACCARATSIDEBAR": "बैकारेट",
      "TITLE@CARDGAME": "कार्ड  गेम ",
      "TITLE@SPORT": "स्पोर्ट ",
      "TITLE@MAHARASHTRA": "महाराष्ट्र  इलेक्शन ",
      "TITLE@P2P": "पोकर ",
      "TITLE@POLITICS": "पॉलिटिक्स ",
      "TITLE@POPULAR": "पॉपुलर ",
      // "TITLE@JACKPOT": "जैकपोट ",
      "TITLE@SPECIALEVENT": "स्पेशल  मार्किट ",
      // "TITLE@SPECIALEVENT": "चुनाव",
      "TITLE@TEENPATTI": "तीनपत्ती ",
      "TITLE@HOTGAMES": "हॉट गेम्स",
      "TITLE@AVIATOR": "एविएटर",
      "TITLE@Plinko": "प्लिंको एक्स",
      "TITLE@Footballx": "फुटबॉल एक्स",
      "TITLE@Baloon": "बलून",
      "TITLE@Mines": "खानों",
      "TITLE@LIGHTINGROULETTE": "लाइटनिंग रूलेट",
      "TITLE@TEENPATTI20-20": "टीनपट्टी 20-20",
      "TITLE@MonopolyLive": "मोनोपॉली लाइव",
      "TITLE@LightningDice": "लाइटनिंग डाइस",
      "TITLE@JetX": "जेट एक्स",
      "TITLE@CashShow": "कैश शो",
      "TITLE@roketon": "रॉकेटन",
      "TITLE@FunkyTimes": "फंकी टाइम",
      "TITLE@crazytime": "क्रेज़ी टाइम",
      "TITLE@DragonTiger": "ड्रैगन टाइगर",
      "TITLE@CricketX": "क्रिकेट एक्स",
      "TITLE@RPS": "रॉक कागज़ की कैंची",
      "TITLE@TEENPATTIPLUS": "तीनपत्ती ",
      "TITLE@ANDARBAHAR": "अंदर बाहर",
      "TITLE@US_ELECTION": "US इलेक्शन ",
      "TITLE@VIRTUALSPORTS": "वर्चुअल  स्पोर्ट्स ",
      "TRANSFER@EXCHANAGE_INTO": "ट्रांसफर  ईंटो ",
      "TRANSFER@EXCHANGE_NOTE":
        "**{ कृपया ध्यान दें कि सिक्कों का कन्वर्शन  {फ्रॉम प्लेटफार्म } और {तूपलैटफोर्म} . के बीच ट्रांसफरिंग  होने पर होता है}.",
      "UNMATCHED_ORDER@CANCEL": "कैंसिल ",
      "UNMATCHED_ORDER@PLACE_UPDATE": "अपडेट  बेट ",
      "UNMATCHED_ORDER@TITLE": "उनमचेड  बेट ",
      "UNMATCHED_ORDER@UPDATE": "अपडेट ",
      // for new dashboard

      "TITLE@AccountInfo": "खाते की जानकारी",
      "TITLE@Edit": "संपादित करें",
      "TITLE@General": "सामान्य",
      "TITLE@UserName": "यूज़र नेम",
      "TITLE@ContactDetails": "सम्पर्क करने का विवरण",
      "TITLE@Email": "ईमेल (वैकल्पिक)",
      "TITLE@Apps": "ऐप्स",
      "TITLE@TelegramUsername": "टेलीग्राम यूज़र नेम",
      "TITLE@SkypeID": "स्काइप आईडी",
      "TITLE@WhatsApp": "व्हाट्सएप",
      "TITLE@Referral": "रेफरल",
      "TITLE@Code": "कोड",
      "TITLE@Link": "लिंक",
      "TITLE@CompleteProfile": "अपनी प्रोफाइल पूरी कीजिए",
      "TITLE@PendingItems": "लंबित वस्तुएँ",
      "TITLE@SetWithdrawalPassword": "निकासी पासवर्ड सेट करें",
      "TITLE@CompleteKYC": "पूर्ण केवाईसी",
      "TITLE@AddBankDetails": "बैंक विवरण जोड़ें",
      "TITLE@AddAppDetails": "ऐप विवरण जोड़ें",

      "SIDEBAR@MYDASHBOARD": "डैशबोर्ड",
      "SIDEBAR@PROFILE": "प्रोफ़ाइल",
      "SIDEBAR@FINANCES": "वित्त",
      "SIDEBAR@DEPOSIT": "जमा करना",
      "SIDEBAR@WITHDRAWAL": "निकासी",
      "SIDEBAR@HISTORY": "इतिहास",
      "SIDEBAR@MYBETS": "माई बेट्स",
      "SIDEBAR@P&L": "लाभ हानि",
      "SIDEBAR@CASINOSTATEMENT": "कैसीनो स्टेटमेंट",
      "SIDEBAR@ACCOUNTSTATEMENT": "खाता विवरण",
      "SIDEBAR@VIRTUALSPORTSTATEMENT": "वर्चुअल स्पोर्ट्स स्टेटमेंट ",
      "SIDEBAR@TurnoverStatement": "कारोबार विवरण",
      "TUNOVERStatement@TurnoverDetails": "कारोबार विवरण",
      "TUNOVERStatement@EventName": "कार्यक्रम नाम",
      "TUNOVERStatement@MarketName": "बाजार का नाम",
      "TUNOVERStatement@Market": "बाजार",
      "TUNOVERStatement@Winloss": "Win/Loss",
      "TUNOVERStatement@Turnover": "कारोबार",
      "TUNOVERStatement@Date": "दिनांक",
      "TUNOVERStatement@SettleTime": "सेटल टाइम",
      "TUNOVERStatement@Platform": "लैटफ़ॉर्म",
      "TUNOVERStatement@Provider": "प्रदाता",
      "TUNOVERStatement@GameName": "गेम का नाम",
      "TUNOVERStatement@BetAmount": "शर्त राशि",
      "SIDEBAR@BONUS": "बक्शीश",
      "SIDEBAR@BETDETAIL": "शर्त विवरण",
      "SIDEBAR@MESSAGES": "संदेशों",
      "SIDEBAR@ACCOUNTINFO": "खाते की जानकारी",
      "SIDEBAR@LINKSOCIAL": "लिंक सोशल मीडिया",
      "SIDEBAR@PASSWORD": "पासवर्ड",
      "DASHBOARD_CURRENTPROFILEPASSWORD@LABEL": "वर्तमान प्रोफ़ाइल पासवर्ड",
      "DASHBOARD@PASSWORD": "पासवर्ड बदलें",
      "DASHBOARD@BANKDETAILS": "बैंक विवरण",
      "DASHBOARD@KYCVERIFICATION": "केवाईसी सत्यापन",
      "DASHBOARD@DEPOSIT": "जमा करना",
      "DASHBOARD@CHOOSEDEPOSITTYPE": "जमा प्रकार चुनें",
      "DASHBOARD@MINONESECTION": "(न्यूनतम एक चयन आवश्यक)",
      "DASHBOARD@CARDDETAILS": "कार्ड के विवरण",
      "HistoryTable@ReferanceNo": "संदर्भ संक्या",
      "HistoryTable@TimeDate": "समय और दिनांक",
      "Table@Type": "प्रकार",
      "HistoryTable@Description": "विवरण",
      "HistoryTable@Amount": "राशि",
      "HistoryTable@Status": "स्थिति",
      "HistoryTable@Balance": "संतुलन",
      "DASHBOARD@HISTORY": "इतिहास",
      "SIDEBAR@BANKDETAIL": "बैंक विवरण",
      "SIDEBAR@Bonusdetails": "बोनस विवरण",
      "DASHBOARD@Transfer": "स्थानांतरण करना",
      "TITLE@HUNDRED": "ध हंड्रेड",
      "LOYALTY@RULESTEXT1":
        "*यदि टर्नओवर 1 महीने की अवधि में आवश्यक राशि से कम है, तो खिलाड़ी स्वचालित रूप से पूर्व स्तर पर डाउनग्रेड हो जाएगा, उदाहरण के लिए, यदि आप एक गोल्ड टियर सदस्य हैं और आप एक महीने की अवधि में 40L का टर्नओवर बनाए रखने में असमर्थ हैं, आपका लॉयल्टी प्रोग्राम टियर सिल्वर वगैरह में शिफ्ट हो जाएगा।",
      "LOYALTY@RULESTEXT2":
        "*निकासी राशि के नियम और शर्तों में कोई बदलाव नहीं होगा",
      "LOYALTY@RULESTEXT3":
        "*कंपनी के पास बोनस को रद्द करने का अधिकार सुरक्षित है और बिना कारण बताए क्लाइंट को इसे देने से इनकार करने का अधिकार सुरक्षित है यदि गेमिंग खाते की जांच के दौरान, गेम की निष्पक्षता के उल्लंघन का संदेह है या कंपनी द्वारा रणनीतियों का उपयोग किया जाता है। इसका विवेक दुर्भावनापूर्ण माना जाता है। विवादित स्थितियों में कंपनी के अधिकृत अधिकारियों का निर्णय अंतिम होता है.",
      "LOYALTY@RULESTEXT4":
        "*नियमों के उल्लंघन, बेईमानी या अन्य धोखाधड़ी गतिविधियों के मामले में, ऐसे सभी खातों को भागीदारी से बाहर रखा जाएगा",
      "LOYALTY@RULESTEXT5": "*यह ऑफ़र YOLO247 . के सामान्य नियमों के अधीन है",
      "LOYALTY@RULESTEXT6": "*प्रत्येक पुन: जमा पर 2% बोनस प्रदान किया जाएगा",
      "LOYALTY@RULESTEXT7": "*साप्ताहिक नुकसान पर 2% कैश बैक प्रदान किया जाएगा",
      "LOYALTY@RULESTEXT8":
        "*चुने गए लॉयल्टी प्रोग्राम टियर के अनुसार अतिरिक्त कैशबैक की जानकारी दी जाएगी",

      "Quicklink@Top": "त्वरित लिंक",
      "QuickLink@Inplay": "एक्सचेंज ",
      "QuickLink@Process": "जमा करना",
      "QuickLink@History": "इतिहास",
      "QuickLink@Message": "संदेश",

      "Bonus@Top": "बक्शीश",
      "ProBonus@Sports": "खेल",
      "ProBonus@Welcome": "स्वागत बोनस",
      "ProBonus@Reload": "बोनस पुनः लोड",
      "ProBonus@Pregmatic": "कैशबैक बोनस",
      "ProBonus@Referral": "रेफरल बोनस",
      "ProBonus@Sub": "400% पहली बार जमा बोनस प्राप्त करें",
      "ProBonus@Sub1": "15% कैशबैक बोनस",
      "ProBonus@Sub3": "प्रत्येक जमा पर 5 तक% बोनस प्राप्त करें",
      "ProBonus@Sub4": "आपके वॉलेट में तुरंत 5 तक% कैशबैक",
      "ProBonus@Sub5": " अपने दोस्तों की जमा राशि का 20% देखें और प्राप्त करें",
      "ProBonusButton@CheckStatus": "चेक योर बोनस प्रोग्रेस",

      "SIDEBAR@Deposit": "जमा करना",
      "SIDEBAR@Withdrawal": "निकालना",
      "SIDEBAR@History": "इतिहास",
      "SIDEBAR@MyBets": "मेरा दांव",
      "SIDEBR@ProfitLoss": "लाभ हानि",
      "SIDEBAR@Casino": "कैसीनो बयान",
      "SIDEBAR@SPORTBOOK_STATEMENT": "स्पोर्ट्सबुक स्टेटमेंट",
      "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "प्रीमियम स्पोर्ट्सबुक स्टेटमेंट",
      "SIDEBAR@STP": "स्प्लिट द पॉट स्टेटमेंट",
      "SIDEBAR@GALAXY": "गलाक्षीस स्टेटमेंट",
      "SIDEBAR@AVIATRIX": "एविएट्रिक्स स्टेटमेंट",
      "SIDEBAR@EVOPLAY": "इवोप्ले स्टेटमेंट",
      "SIDEBAR@JILI": "जीली स्टेटमेंट",
      "SIDEBAR@TURBOGAMES": "टर्बो गेम्स स्टेटमेंट",
      "MEMBER@SPORTBOOK_STATEMENT": "स्पोर्ट्सबुक स्टेटमेंट",
      "MEMBER@VIRTUALSPORTS_STATEMENT": "वर्चुअल स्पोर्ट्स स्टेटमेंट",
      "SIDEBAR@Bonus": "बक्शीश",
      "SIDEBAR@Msg": "संदेश",
      "Messages@View": "पढ़ें",
      "Messages@UNRead": "पढ़ें",
      "Unread@Msg": "अपठित संदेश",
      "Support@support": "सामान्य प्रश्न",
      "SIDEBAR@Linksocial": "लिंक सोशल मीडिया",

      "SOCIALMEDIA@LinkSocialMedia": "लिंक सोशल मीडिया",
      "SOCIALMEDIA@LinkAcc": "लिंक करने के लिए खाता चुनें",
      "SOCIALMEDIA@LinkFast":
        "तेजी से लॉगिन करने के लिए अपने सोशल मीडिया खातों को लिंक करें",

      "DASHBOARD@ProfilePAssword": "खाता पासवर्ड",
      "DASHBOARD@Withdrwal": "निकासी पासवर्ड",
      "DASHBOARD@reset": "रीसेट",
      "DASHBOARD@submit": "प्रस्तुत",
      "SOCIALMEDIA@AddNewBank": "+ नया बैंक खाता जोड़ें",
      "Progress@BasicDetails": "बुनियादी विवरण",
      "Progress@Document": "दस्तावेज़ प्रस्तुत करना",
      "Progress@Review": "समीक्षा",
      "Progress@Approval": "अनुमति",

      "DASHBOARD@KYCThankMsg":
        "आवेदन करने के लिए धन्यवाद! हमें आपके दस्तावेज़ प्राप्त हो गए हैं और हम शीघ्र ही इसकी समीक्षा करेंगे।",
      "DASHBOARD@KYCThankMsgApproved":
        "हमने आपके दस्तावेज़ों की समीक्षा की और अब इसकी पुष्टि हो गई है! आप अपनी निकासी प्रक्रिया जारी रख सकते हैं।",
      "DASHBOARD@KYCThankMsgRejected": "कारण: दस्तावेज़ मेल नहीं खा रहा है।",
      "DASHBOARD@chooseDocs": "अपलोड करने के लिए कोई एक दस्तावेज़ चुनें",
      "DASHBOARD@chooseMinimumOne": "(न्यूनतम एक दस्तावेज की आवश्यकता है)",
      "DASHBOARD@Docsdetail":
        "दस्तावेज़ विवरण (किसी विशेष वर्ण की अनुमति नहीं है)",
      "DASHBOARD@DocUpload": "अपलोड दस्तावेज़",
      "DASHBOARD@DocUploadAfter": "अपना दस्तावेज़ अपलोड करें",
      "DASHBOARD@DocSize": "(छवि का अधिकतम आकार 6 एमबी)",
      "DASHBOARD@DocSubmit": "प्रस्तुत",
      "DASHBOARD@DocReset": "रीसेट",
      "dashboard@toolotip": "किसी भी प्रश्न के लिए 24/7 सहायता से संपर्क करें",

      "Document@AadharCard": "आधार कार्ड",
      "Document@PanCard": "पैन कार्ड",
      "Document@DrivingLicense": "ड्राइविंग लाइसेंस",
      "Document@Passport": "पासपोर्ट",
      "Kyc@status": "केवाईसी",

      "DASHBOARD@DefaultAcc": "मूल खाता",
      "DASHBOARD@DefaultUPI": "मूल यूपीआई",
      "DASHBOARD@Remarks": "टिप्पणियों",
      "DASHBOARD@edit": "संपादन करना",
      "DASHBOARD@delete": "मिटाना",

      "DASHBOARD@Deleteconfirm": "क्या आप वाकई इस खाते को हटाएं चाहते हैं?",
      "DASHBOARD@Deleteconfirm1": "इसे पूर्ववत नहीं किया जा सकता।",

      "Confirm@heading": "क्या आपको यकीन है?",
      "Confirm@Delete": "मिटाना",
      "Confirm@Cancel": "रद्द करें",
      "DASHBOARD@Messageshow":
        "सुरक्षा कारणों से, निकासी की अनुमति केवल खाता स्वामी ही है। यदि आपको कोई समस्या है, तो हमसे संपर्क करने में संकोच न करें",
      "DASHBOARD@customrService": "ग्राहक सेवा",
      "DASHBOARD@Reset": "रीसेट",
      "DASHBOARD@UpdateAcc": "अद्यतन खाता",
      "DASHBOARD@AddAcc": "खाता जोड़ो",
      "DASHBOARD@sendOtp": "OTP भेजें",
      "Withdrawal@Complete": "अपना केवाईसी पूरा करें",
      "Withdrawal@GoToKYC": "केवाईसी पेज पर जाएं",

      "Payment@UpiPayment": "नियमित यूपीआई",
      "Payment@NetBanking": "नेट बैंकिंग",
      "Paymnt@E-wallet": "ई वॉलेट",
      "Channel@ChannelA": "चैनल ए",
      "DASHBOARD@Transacton": "लेन-देन केवल के बीच",
      "DASHBOARD@NoOfTransaction": "प्रति दिन लेन-देन का समय",
      "DASHBOARD@BankDetail": "बैंक का विवरण",
      "DASHBOARD@forgotPassword": "पासवर्ड भूल गए ?",
      "DASHBOARD@ChangeAddAcc": "खाता जोड़ें",
      "DASHBOARD@reminder": "ध्यान दें:",
      "DASHBOARD@reminder1": "(* ) वाले क्षेत्र अनिवार्य हैं",
      "DASHBOARD@reminder2": "निकासी सीमा:",
      "DASHBOARD@reminder3":
        "एक बार जब आप सबमिशन पूरा कर लेते हैं, तो कृपया अनुमोदन समय के लिए ग्राहक सेवा से संपर्क करें।",
      "DASHBOARD@reminder4":
        "यदि आपको कोई समस्या है, तो हमारी ग्राहक सेवा से संपर्क करने में संकोच न करें।",

      "HISTORY@All": "सब",
      "HISTORY@deposit": "जमा करना",
      "HISTORY@withdraw": "निकालना",
      "PROMOTIONS@ALL": "सभी",
      "PROMOTIONS@INPROCESS": "इस प्रक्रिया में",
      "PROMOTIONS@COMPLETED": "पुरा होना।",
      "Promotions@Amount": "बोनस राशि",
      "Promotions@Reqdate": "अनुरोध की तिथि",
      "Promotions@Exdate": "समाप्त होने की तिथि",
      "Promotions@status": "दर्जा",
      "Promotions@Turnover": "कारोबार की जरूरत",
      "Promotions@Terms": "शर्तें",
      "Promotions@CANCEL": "बोनस रद्द करें",
      "Promotions@COMPLETED": "पूरा किया हुआ",
      "Promotions@EXPIRED": "खत्म हो चुका",
      "Promotions@RELEASED": "मुक्त",
      "Promotions@PROCESS": "चालू",
      "Promotions@QUEUED": "कतारबद्ध",
      "Promotions@Details": "विवरण",
      "Promotions@Close": "बंद करना",
      "Promotions@1stReferral": "पहला जमा रेफरल बोनस",
      "Promotions@2ndReferral": "दूसरा जमा रेफरल बोनस",
      "Promotions@3rdReferral": "तीसरा जमा रेफरल बोनस",
      "Promotions@DailyDepositMsg": "का दैनिक जमा बोनस",
      "Promotions@DailyDepositMsg2": "शामिल",
      "Promotions@IplDailyCashbackMsg": "का प्रोमो कोड बोनस",
      "Promotions@IplDailyCashbackMsg2": "शामिल",
      "Promotions@TopChoiceCashbackMsg": "शीर्ष विकल्प बोनस शामिल",
      "Promotions@TopChoiceCashbackMsg2": "शीर्ष विकल्प बोनस शामिल, प्रोमो कोड बोनस",
      "Promotions@TOTALAmnt": "आपने ₹ जीत लिया है",
      "Promotions@TOTALAmnts": "बोनस अब तक!",
      "Promotions@WINBONUS": "जमा करें और तुरंत बोनस जीतें",
      "Promotions@RefereeName": "रेफरी का नाम",
      "Promotions@RefereeHurry": "हुर्रे !",
      "Promotions@ReferralFriend": "आपके दोस्त",
      "Promotions@ReferralDesc":
        "हम आपके 10% रेफ़रल बोनस और आपके मित्र के लिए 400% स्वागत बोनस को सक्रिय करने के लिए उनकी पहली जमा राशि की प्रतीक्षा कर रहे हैं",
      "Promotions@ReferralDescSTD":
        "साइन अप किया है, हम आपके 5% रेफ़रल बोनस को सक्रिय करने और अपने दोस्त के लिए एक रिडिपोज़िट बोनस प्राप्त करने के लिए उनकी दूसरी जमा राशि की प्रतीक्षा कर रहे हैं",
      "Promotions@ReferralDescTTD":
        "साइन अप किया है, हम आपके 5% रेफ़रल बोनस को सक्रिय करने के लिए और अपने दोस्त के लिए एक रिडिपोज़िट बोनस प्राप्त करने के लिए उनकी तीसरी जमा राशि की प्रतीक्षा कर रहे हैं",
      "Promotions@ReferralCode": "आपका रेफरल कोड",
      "Promotions@RefereMessageSecondStart": "उपयोगकर्ता द्वारा दूसरी जमा: ",
      "Promotions@RefereMessageSecondEnd": "लंबित है",
      "Promotions@RefereMessageThirdStart": "उपयोगकर्ता द्वारा तीसरी जमा:",
      "Promotions@RefereMessageThirdEnd": "लंबित है",
      "Promotions@ReferralMessageTooltipSecond":
        "हम आपके लिए 5% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की दूसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का रिडिपॉजिट बोनस प्रदान कर रहे हैं।.",
      "Promotions@ReferralMessageTooltipThird":
        "हम आपके लिए 5% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की तीसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का पुनः जमा बोनस प्रदान कर रहे हैं।.",
      "DASHBOARD@status": "स्थिति",
      "Pagination@Transaction": "लेन-देन",
      "DASHBOARD@MY_ORDER": "मेरा दांव",
      "DASHBOARD@PROFITLOSS": "लाभ और हानि",
      "DASHBOARD@CASINOSTMT": "कैसीनो बयान",
      "DASHBOARD@WITHDRAWALPASSWORD": "निकासी",
      "BreadCrumb@Homepage": "होम पेज",
      "DASHBOARD@channelA": "चैनल ए",
      "DASHBOARD@channelB": "चैनल बी",
      "Dashboard@SubTxt": "न्यूनतम/अधिकतम: ",
      "Dashboard@SubTxt1": "न्यूनतम/अधिकतम सीमा: ",

      "SIDEBAR@KYCVERIFICATION": "केवाईसी सत्यापन",
      "MEMBER@Dashboard": "सदस्य डैशबोर्ड",
      "QuickLink@livecasino": "लाइव कैसीनो",
      scanQRCode: "QR कोड स्कैन करें",
      "Deposit@scanQRCode": "स्कैन और भुगतान",
      "MENU@GOONTURN": "आगे बढ़ो, तुम्हारी बारी है",
      "TITLE@instagram": "इंस्टाग्राम",
      "MENU@PLAYANDNEVER": "खेलें और कभी बूढ़े न हों",
      "MENU@IPLCUP": "अपनी चाल बनाओ, हैट्रिक",
      "MENU@ENJOY": "कभी न खत्म होने वाली मस्ती का आनंद लें",
      "MENU@ExploreALL": "हमारे सभी प्रचार बोनस का अन्वेषण करें खेल और कैसीनो",
      "MENU@SPORTBOOK": "खेल मेनू",
      "GAME@SPORTBOOK": "खेल मेनू",
      "SUBMENU@IPLCUP2022": "आईपीएल कप 2022",
      "SUBMENU@TEENPATTI2020": "तीनपत्ती 2020",
      "TopGames@DownloadAPP": "ऐप",
      "TITLE@BetSlipTittle": "शर्त पर्ची",
      "Header@Affiliate": "संबद्ध",
      "TITLE@DISCOVER_BBBEDGE": "योलो247 एज की खोज करें",
      "TITLE@BETDAILYEDGE": "बेटडेली एज की खोज करें",
      setWithdrawalPassword: "निकासी पासवर्ड सेट करें",
      "ACCOUNT@P&L": "पी & एल",
      "ACCOUNT@BONUS": "जीता हुआ बोनस ",
      "Withdrawal@Completekyc":
        "केवाईसी आवश्यक है यदि आपके कोई प्रश्न हैं, तो हमारी ग्राहक सेवा से संपर्क करें।",
      "TITLE@BLUETIER": "ब्लू टियर",
      "TITLE@SILVERTIER": "सिल्वर टियर",
      "TITLE@GOLDTIER": "गोल्ड टियर",
      "TITLE@PLATINUMTIER": "प्लेटिनम टियर",
      "TITLE@ELITETIER": "संभ्रांत स्तर",
      "LOYALTY@RULESREGULATIONS": "नियम और विनियम",
      __ts: "१६१२४२२१६७७८४",
      "BUTTON@KnowMoreBtn": "अधिक जानते हैं",
      "BONUS@WELCOMEBONUS": "वेलकम बोनस",
      "BONUS@WELCOMEINFO":
        "हमें YOLO 247 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको ",
      "VIRTUALSPORTSMENUTITLE@CRICKET": "क्रिकेट",
      "VIRTUALSPORTSMENUTITLE@WC": "बंडेसलिगा",
      "VIRTUALSPORTSMENUTITLE@worldcup": "विश्व कप",
      "VIRTUALSPORTSMENUTITLE@EL": "इंग्लिश लीग",
      "VIRTUALSPORTSMENUTITLE@TENNIS": "टेनिस",
      "VIRTUALSPORTSMENUTITLE@BASKETBALL": "बास्केटबाल",
      "PROMOTIONSTITLE@WELCOME": "स्वागत बोनस",
      "PROMOTIONSTITLE@REFILL": "रीफिल बोनस",
      "PROMOTIONSTITLE@CASHBACK": "कैशबैक बोनस",
      "VIRTUALSPORTSMENUTITLE@FOOTER": "यहाँ अपनी स्वतंत्रता प्राप्त करें",
      "ACCOUNT@BLUETIER": "BLUE TIER", //"नीला तारा",

      //PROMOTIONS (HINDI)
      "PROMOTIONS@WELCOMEBONS": " वेलकम बोनस",
      "PROMOTIONS@REFILLBONUS": " रिफिल बोनस ",
      "PROMOTIONS@CASHBACKBONUS": "कैश-बैक बोनस ",
      "PROMOTIONSWELCOME@FIRSTLINE":
        "हम यहां YOLO 247 पर इस बात से खुश हैं कि आपने हम पर भरोसा करने और हमारे प्लेटफॉर्म पर अपनी पहली जमा राशि बनाने का फैसला किया। हम अपने ग्राहकों को खुश करना पसंद करते हैं और इसलिए आप यहां अपनी पहली जमा राशि पर उपहार के पात्र हैं। यह जानने के लिए नीचे पढ़ें कि आप बिना किसी अधिकतम सीमा के अपनी जमा राशि पर 400% के इस बोनस का लाभ कैसे उठा सकते हैं",
      "PROMOTIONSWELCOME@SECONDLINE": "वेलकम बोनस क्या होता है?",
      "PROMOTIONSWELCOME@SECONDLINEINFO":
        "यदि आप साइन अप करने के 30 मिनट के भीतर अपनी पहली जमा राशि जमा करते हैं, तो आप अपनी पहली जमा राशि पर 400% स्वागत बोनस के लिए पात्र हैं। 30 मिनट के बाद भी, आप 300% के विशाल स्वागत बोनस के पात्र हैं। तो आप इस बोनस का दावा कैसे कर सकते हैं? बस इस एक सरल चरण का पालन करें:",
      "PROMOTIONSWELCOME@FAQ": "सामान्य प्रश्न",
      "FOOT@FAQ": "सामान्य प्रश्न",
      "PROMOTIONSWELCOME@HOWTOGET": "वेलकम बोनस क्या होता है?",
      "PROMOTIONSWELCOME@STEPS":
        "चरण 1: अपनी बोनस राशि को प्राप्त करने के लिये आपको अपने बोनस अमाउंट का 24X RO करना होगा| इस आवशयकता को पूरा करते ही आपका बोनस अमाउंट आपके वॉलेट मे ऑटोमेटिकली क्रेडिट हो जाएगा| अब आप सोच रहे होंगे कि RO की आवश्यकता क्या होती है? अच्छी तरह से समझने के लिए नीचे एक आसान उदाहरण पढिये।",
      "PROMOTIONSWELCOME@EXAMPLE": "यहाँ एक उदाहरण है:",
      "PROMOTIONSWELCOME@LI": "1.आप का पहले डिपाजिट =   ₹1,000",
      "PROMOTIONSWELCOME@LI2": "2. इसलिए आपका 300% बोनस = ₹3,000",
      "PROMOTIONSWELCOME@LI3":
        "ग)आपको अपनी बोनस राशि के 24X की आरओ आवश्यकता को पूरा करना होगा, जो कि 3,000 * 24 = 72,000 रुपये है। एक बार जब आप 72,000 रुपये की वैध आरओ आवश्यकता प्राप्त कर लेते हैं, तो 3,000 रुपये की बोनस राशि होगी तुरंत आपके बटुए में जोड़ा गया।",
      "PROMOTIONSWELCOME@RO": "RO आवश्यकता क्या होता है?",
      "PROMOTIONSWELCOME@ROINFO":
        "RO का मतलब रोल ओवर होता है , आपको अपने बोनस प्राप्त करने के लिए एक निश्चित RO आवश्यकता को पूरा करना होगा. RO गणना इस प्रकार है:",
      "PROMOTIONSWELCOME@ROINFO1":
        "तो यहाँ एक उदाहरण है: आपने रूले में काले रंग पर 100 रुपये का दांव लगाया।तो आपकी शर्त राशि  है: 100 ₹",
      "PROMOTIONSWELCOME@ROEXMPLE": "अब 3 परिदृश्य हैं:",
      "PROMOTIONSWELCOME@ROEXMPLE1":
        "1. आप 100 ₹  जीत जाते हैं तो |पी एंड एल| = 100 वैध RO  = (|100|/100) = 100% इसलिए आपका वैध RO = 100 ₹",
      "PROMOTIONSWELCOME@ROEXMPLE2":
        "2.आप 100 ₹ हार जाते हैं तो |पी एंड एल| = -100 ₹ तो वैध RO = (|-100|/100) = 100% इसलिए आपका वैध RO = 100 ₹",
      "PROMOTIONSWELCOME@ROEXMPLE3":
        "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 30% से कम है",
      "PROMOTIONSWELCOME@TERMS": "नियम और शर्तें:",
      "PROMOTIONSWELCOME@TERMS1":
        "  यह प्रचार केवल सिर्फ नये रजिस्टर्ड खिलाड़ियों के पहले सफल डिपाजिट पर है.",
      "PROMOTIONSWELCOME@TERMS2":
        "जैसे ही टर्नओवर अवसक्ता पूरी होजाएगी बोनस अमाउंट आपके वॉलेट  में  क्रेडिट होजाए गा.",
      "PROMOTIONSWELCOME@TERMS3":
        "यह प्रमोशन प्रति खिलाड़ी सिर्फ एक बार ही उपलब्ध है.",
      "PROMOTIONSWELCOME@TERMS14": " वेलकम बोनस की अधिकतम सीमा 24,000 INR है।",
      "PROMOTIONSWELCOME@TERMS4":
        " बोनस अमाउंट को 20 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",
      "PROMOTIONSWELCOME@TERMS5":
        "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
      "PROMOTIONSWELCOME@TERMS6":
        "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 30% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 30% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",
      "PROMOTIONSWELCOME@TERMS7":
        "शून्य / रद्द / ड्रा दांव , कैश आउट दांव को डिपाजिट या बोनस टर्नओवर आवश्यकता की गणना न करें।.",
      "PROMOTIONSWELCOME@TERMS8":
        "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। YOLO 247 के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
      "PROMOTIONSWELCOME@TERMS9":
        " इस प्रचार का उपयोग किसी अन्य प्रचार के साथ संयोजन के रूप में या प्रचार में योगदान करने के लिए नहीं किया जा सकता है।.",
      "PROMOTIONSWELCOME@TERMS10":
        " Yolo247 अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
      "PROMOTIONSWELCOME@TERMS11":
        "बोनस राशि निकली नहीं जा सकती लेकिन बोनस अमाउंट से जीती राशि निकली जा सकती है।.",
      "PROMOTIONSWELCOME@TERMS12":
        "किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , Yolo247 आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
      "PROMOTIONSWELCOME@TERMS13":
        "Yolo 247 सामान्य नियम और शर्तें लागू होती हैं।.",
      "PROMOTIONSWELCOME@TERMSp2p":
        " रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
      "PROMOTIONS@COMMON":
        "ग्राहक, परिवार, साझा कंप्यूटर, साझा आईपी पता (IP address),और ई-मेल बैंक खाते के विवरण, क्रेडिट कार्ड की जानकारी और भुगतान खाते सहित एक समान खाते के विवरण के लिए केवल एक बोनस की अनुमति है। इस बोनस ऑफ़र का कोई भी दुरुपयोग करने पर खाता बंद कर दिया जाएगा।",
      "DASHBOARD@PromotionsAndBonus": "प्रचार और बोनस",
      "DASHBOARD@KNOWMORE": "अधिक जानते हैं",

      //refill promotions (hindi)

      "PROMOTIONSREFILL@REFILLBONUS": " रिफिल बोनस ",
      "PROMOTIONSREFILL@REFILLBONUS1":
        "देश में पहला और एकमात्र प्लेटफॉर्म जो अब प्रत्येक डिपाजिट पर बोनस प्रदान करता है।",
      "PROMOTIONSREFILL@REFILLBONUS2":
        "Yolo247 पर अब प्रत्येक जमा के साथ थोड़ा अधिक कमाएं और अधिक जीतने के लिए बढ़त हासिल करें, और इस प्रकार प्रत्येक नई जमा के साथ एक नया अवसर मिलता है! योलो247 5% तक अतिरिक्त बोनस (प्लैटिनम सदस्यों के लिए) के साथ प्रत्येक जमा पर आपका स्वागत करता है!!",
      "PROMOTIONSREFILL@REFILLBONUS3": "रिफिल बोनस क्या होता है? ",
      "PROMOTIONSREFILL@REFILLBONUS4":
        "आप योग्य हैं  5% रिफिल बोनस के हर नये री डिपाजिट पर | तो आइए समझते हैं कि यह कैसे काम करता है...",
      "PROMOTIONSREFILL@REFILLSTEPS": "1.) अपने Yolo247 खाते में लॉगिन करें।",
      "PROMOTIONSREFILL@REFILLSTEPS2":
        "आपकी पहले डिपाजिट के अलावा, अब से आपके द्वारा सफलतापूर्वक डिपाजिट करने के तुरंत बाद आपको अपने मुख्य वॉलेट में 5% अतिरिक्त बोनस दिया जायेगा.",

      "PROMOTIONSREFILL@TERMS1":
        " आपके सफल डिपाजिट के बाद बोनस राशि आपके मुख्य वॉलेट में जारी की जाएगी।.",
      "PROMOTIONSREFILL@aded":
        " रीफिल बोनस केवल फास्ट यूपी आई (fast UPI) और फास्ट डिपॉजिट (fast deposit) चैनलोंका उपयोग करकेकिए गए जमा पर लागूहोता है।",
      "PROMOTIONSREFILL@TERMS2":
        "यह प्रचार हर री डिपाजिट बोनस पर उपलब्ध है और दिन के दौरान प्राप्त किया गया बोनस अग्ले दिन 24 पर समाप्त हो जायेगा",
      "PROMOTIONSREFILL@EXAMPLE": "उदाहरण:",
      "PROMOTIONSREFILL@TERM":
        "a.) 31 मई  के सुबह 10 बजे ,1000 INR का पहला री डिपाजिट किया जाता है,तो आपको 20 INR का बोनस प्राप्त होगा, इस प्रकार आपके खाते में कुल राशि 1020 INR  हेगी | अब मान लीजिए कि आप उसी दिन दोपहर 3 बजे 2000 INR का एक और डिपाजिट करते हैं, तो आपको खाते में कुल राशि 2040 INR हेगी मतलब आप नई 40 INR का प्बोनस प्राप्त किया। 1 जून की मध्यरात्रि को 11:59:59 बजे IST दिन भर में दी गई 60 INR की बोनस राशि समाप्त हो जाएगी और अब आपके मुख्य वॉलेट में उपलब्ध नहीं होगी।.",
      "PROMOTIONSREFILL@2EXAMPLE":
        "b.) एक और उदाहरण: मान लीजिए कि आपने 15 सितंबर को दोपहर 1:00 बजे 100,000 जमा किए हैं, 2,000 रुपये का रिफिल बोनस (ब्लू टियर के लिए), 16 सितंबर की रात 11:59:59 बजे समाप्त होगा। तो, यह बोनस  कुल 35 घंटे के लिए के लिए एक्टिव रहेगा।",
      "PROMOTIONSREFILL@TERMS3":
        "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। YOLO 247 के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
      "PROMOTIONSREFILL@TERMS4":
        " इस प्रचार का उपयोग किसी अन्य प्रचार के साथ संयोजन के रूप में या प्रचार में योगदान करने के लिए नहीं किया जा सकता है।.",
      "PROMOTIONSREFILL@TERMS5":
        " Yolo247 अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONSREFILL@TERMS6":
        "किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , Yolo247 आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONSREFILL@TERMSp2p":
        "रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",

      //PROMOTION CASHBACK(HINDI)
      "PROMOTIONSCASHBACK@1STLINE":
        "Yolo247 आपके साप्ताहिक घाटे पर आपके वॉलेट में तुरंत 2% कैशबैक बोनस प्रदान करता है",

      "PROMOTIONSCASHBACK@2NDLINE": "कैश-बैक बोनस क्या होता है?",
      "PROMOTIONSCASHBACK@3RDLINE":
        "क्या यह बहुत अच्छा नहीं होगा यदि आप अपने घाटे को कम कर सकें? यहाँ Yolo 247 पर, अब अपने साप्ताहिक नुकसान पर तुरंत अपने वॉलेट में 5% कैशबैक बोनस प्राप्त करें।.",
      "PROMOTIONSCASHBACK@4THLINE":
        "इसे आसान बनाने के लिए मैं आपको एक उदाहरण क्यों नहीं दिखाता।.",
      "PROMOTIONSCASHBACK@5THLINE":
        "कहो कि आपने पिछले सप्ताह में 10,000 INR खो दिए (प्रत्येक सप्ताह सोमवार 00:00:01 पूर्वाह्न से रविवार 23:59:59 अपराह्न तक गिना जाता है),आपको आपके मुख्य वॉलेट में तुरंत साप्ताहिक नुकसान का 5% क्रेडिट किया जाएगा, जो कि इस उदाहरण में 500 INR है |  .",
      "PROMOTIONSCASHBACK@TERM1":
        "बोनस राशि सोमवार को आपके मुख्य वॉलेट में डिपाजिट की जाएगी जो आपके साप्ताहिक नुकसान की गणना करेगी।.",
      "PROMOTIONSCASHBACK@TERM2":
        " यह प्रचार आपके साप्ताहिक नुकसान पर उपलब्ध है और इस बोनस का लाभ उठाने के लिए बोनस का फैसला प्लेटफॉर्म पर किसी के समग्र |पी एंड एल|  द्वारा किया जाएगा|.",
      "PROMOTIONSCASHBACK@TERM7":
        "कैशबैक बोनस हर 7 दिनों के बाद समाप्त हो जाएगा।.",
      "PROMOTIONSCASHBACK@TERM3":
        "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। YOLO 247 के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।",
      "PROMOTIONSCASHBACK@TERM4":
        " Yolo247 अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONSCASHBACK@TERM5":
        "बोनस राशि निकली नहीं जा सकती लेकिन बोनस अमाउंट से जीती राशि निकली जा सकती है।",
      "PROMOTIONSCASHBACK@TERMS6":
        "Yolo 247 सामान्य नियम और शर्तें लागू होती हैं।",
      "PROMOTIONSCASHBACK@TERMSp2p":
        "रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
      "TITLE@Save": "सहेजें",
      "TITLE@LOYALTYTAGLINE":
        "आप सिर्फ एक बार जीते हैं। अधिक खेलें, अधिक कमाएं",
      "TITLE@PROMOTIONSTAGLINE": "थोड़ा अतिरिक्त कभी दर्द नहीं देता!",

      // promotion referal hindi

      "PROMOTIONS@REFERRAL": "रेफ़रल बोनस",
      "PROMOTIONS@REFERRALHEAD":
        "पेश है Yolo247 रेफर-ए-फ्रेंड प्रोग्राम: एक विन-विन अवसर!",
      "PROMOTIONS@SIGNUPBONUS": "साइनअप बोनस",
      "PROMOTIONSREFERRAL@1st":
        "एक ऐसी दुनिया की कल्पना करें जहां हर किसी को आपका मित्र होने का सौभाग्य प्राप्त हो ",
      "PROMOTIONSREFERRAL@2":
        "ठीक है, हम मानते हैं कि असाधारण दोस्ती असाधारण पुरस्कारों की हकदार है.",
      "PROMOTIONSREFERRAL@3":
        "इसलिए हमने अपना रोमांचक रेफर-ए-फ्रेंड प्रोग्राम बनाया है, जो सबसे अच्छा ऑनलाइन बेटिंग है.",
      "PROMOTIONSREFERRAL@4":
        "Yolo247 रेफरल कोड सभी नए और पुराने उपयोगकर्ताओं के लिए खुला है। जानिए रेफरल बोनस कैसे काम करता है ",
      "PROMOTIONSREFERRAL@5":
        "आप एक दोस्त को रेफर करते हैं जो आपके रेफरल लिंक का उपयोग करके YOLO247 के साथ साइन अप करता है और 10,000 INR डिपाजिट  करता है, तो आप 500 INR बोनस के हकदार होगें जब आपके दोसत अपने डिज़िट का 3 गुना RO पूरा करे गा ।",
      "PROMOTIONSREFERRAL@6":
        "Yolo247 रेफरल बोनस कैसे काम करता है? | Yolo247 रेफरल कोड",
      "PROMOTIONSREFERRAL@7":
        "1. अपने दोस्तों को बोर्ड पर लाएँ और एक अविस्मरणीय गेमिंग अनुभव के लिए हमसे जुड़ें.",
      "PROMOTIONSREFERRAL@8":
        "2. जब आपके मित्र आपके अद्वितीय रेफ़रल लिंक या कोड (Yolo247 रेफ़रल कोड) का उपयोग करके YOLO247 के साथ साइन अप करते हैं, तो मज़ा शुरू होता है",
      "PROMOTIONSREFERRAL@9":
        "3. हर बार जब आपका संदर्भित मित्र जमा करता है, तो आपको उदार प्रतिशत संरचना के आधार पर बोनस प्राप्त होगा:",
      "PROMOTIONSREFERRAL@91": "उनकी पहली जमा राशि पर 10% बोनस",
      "PROMOTIONSREFERRAL@92": "उनकी दूसरी जमा राशि पर 5% बोनस",
      "PROMOTIONSREFERRAL@93": "उनकी तीसरी जमा राशि पर 5% बोनस",
      PROMOTIONSExampleHead: "आइए इसे एक उदाहरण के साथ तोड़ दें:",
      "PROMOTIONSREFERRAL@10":
        "मान लीजिए कि आप एक ऐसे दोस्त को रेफर करते हैं जो YOLO247 के साथ इस रोमांचकारी यात्रा को शुरू करने का फैसला करता है।",
      "PROMOTIONSREFERRAL@101":
        "आपका मित्र साइन अप करता है और कुल जमा करता है (पहले तीन जमा), मान लीजिए, 30,000 INR।",
      "PROMOTIONSREFERRAL@102":
        "उनकी जमा राशि की 3X RO आवश्यकता को पूरा करने पर, आप 6,000 INR के शानदार बोनस के पात्र बन जाते हैं!",
      "PROMOTIONS@Referafriend": "किसी दोस्त को कैसे रेफर करें",
      "PROMOTIONS@Referafriend01":
        "1.	अपने मित्र के साथ अपना अद्वितीय रेफ़रल कोड/यूआरएल साझा करें और उन्हें YOLO247 में शामिल होने के लिए प्रोत्साहित करें। ",
      "PROMOTIONS@Referafriend02":
        "2.	इस ऑनलाइन कैसीनो रेफरल बोनस के लिए आपको क्रेडिट प्राप्त करना सुनिश्चित करने के लिए आपके मित्र को आपके रेफरल कोड का उपयोग करके YOLO247 पर पंजीकरण करना चाहिए।  ",
      "PROMOTIONS@Referafriend03":
        "3.	एक बार जब आपका मित्र अपनी प्रारंभिक तीन जमा पूरी कर लेता है, तो उत्साह बढ़ जाता है। ",
      "PROMOTIONS@Referafriend04":
        "4.	जब आपका दोस्त 3X RO आवश्यकता को पूरा करता है, तो आपको 20% (10%, 5%) के बराबर एक रेफरल बोनस प्राप्त होगा ",
      "PROMOTIONS@Referafriend05":
        "5.	YOLO247 समुदाय में शामिल हों और आनंद साझा करने और पुरस्कारों को एक साथ प्राप्त करने के इस अविश्वसनीय अवसर का लाभ उठाएं। आज ही अपने दोस्तों को रेफ़र करना शुरू करें और रोमांचकारी अनुभवों की दुनिया अनलॉक करें! ",
      "PROMOTIONSREFERRAL@RO1":
        "तो, मान लें कि आपका दोस्त 20,000 INR जमा करता है और वह अपनी जमा राशि का 3X RO पूरा करता है यानी, वह 60,000 INR के वैध RO दांव सफलतापूर्वक पूरा करता है, तो आपको उसकी पहली जमा राशि का 10% बोनस यानी 2,000 INR प्राप्त होगा। ",
      "PROMOTIONSREFERRAL@R02":
        "अब आपका दोस्त दूसरी बार 10,000 रुपये जमा करता है और 30,000 रुपये के वैध आरओ बेट्स को सफलतापूर्वक पूरा करता है, तो आपको उसकी दूसरी जमा राशि का 5% बोनस मिलेगा यानी 500 रुपये।",
      "PROMOTIONSREFERRAL@R03":
        "अब आपका दोस्त तीसरी बार 5,000 रुपये जमा करता है और 15,000 रुपये के वैध आरओ दांव को सफलतापूर्वक पूरा करता है, तो आपको उसकी तीसरी जमा राशि का 5% बोनस मिलेगा, यानी 250 रुपये।",
      "PROMOTIONSREFERRAL@RO": "रेफरल बोनस के लिए RO आवश्यकता क्या है?",
      "PROMOTIONSREFERRAL@R04":
        "इसलिए एक बोनस राशि के लिए कुल रुपये। 2,750। इस तरह आप इस सर्वश्रेष्ठ ऑनलाइन बेटिंग रेफरल बोनस का उपयोग कर सकते हैं।",
      "PROMOTIONSREFERRAL@R0END":
        "आप हमारा Yolo247 वेलकम बोनस भी देख सकते हैं. ",
      "PROMOTIONSREFERRAL@11":
        "तो चलिए मान लेते हैं कि आपके दोस्त 10,000 INR डिपाजिट करते हैं और वह अपने डिपाजिट का 3X RO पूरा करता है यानी वह 30,000 INR के वैध RO के दांव को सफलतापूर्वक पूरा करता है तो आपको उसकी डिपाजिट का 5% बोनस यानी 500 INR मिले गा । ",
      "PROMOTIONSREFERRAL@TERM1":
        " संदर्भित उपयोगकर्ता द्वारा किए गए पहले तीन डिपॉजिट के लिए रेफरल बोनस दिया जाएगा.",
      "PROMOTIONSREFERRAL@TERM2":
        "एक समय में केवल एक रेफरल बोनस सक्रिय होगा। उदाहरण के लिए, यदि पहले डिपॉजिट के लिए रेफरल बोनस सक्रिय है, तो दूसरे और तीसरे डिपॉजिट के बोनस को कतारबद्ध किया जाएगा और बाद में संसाधित किया जाएगा।",
      "PROMOTIONSREFERRAL@TERM3":
        "रेफरी द्वारा पहली जमा और टर्नओवर आवश्यकताओं को पूरा करने के बाद बोनस राशि आपके मुख्य वॉलेट में जारी की जाएगी। इसी तरह दूसरे के लिए.",
      "PROMOTIONSREFERRAL@TERM4":
        " जमा राशि को 3 (तीन) बार रोल ओवर किया जाना चाहिए। प्लेटफॉर्म पर कहीं भी उत्पन्न टर्नओवर की गणना टर्नओवर आवश्यकताओं के लिए की जाएगी।",
      "PROMOTIONSREFERRAL@TERM5":
        "सक्रियण की तारीख से 7 दिनों के बाद रेफरल बोनस समाप्त हो जाएगा। रेफरल बोनस की वैधता अवधि 3 महीने होगी। यदि उपयोगकर्ता इस अवधि के भीतर कोई जमा नहीं करता है, तो बोनस योजना स्वतः समाप्त हो जाएगी.",
      "PROMOTIONSREFERRAL@TERM6":
        "टर्नओवर राशि की गणना 'वैध टर्नओवर' के रूप में की जाती है और वैध टर्नओवर के लिए सूत्र निम्नलिखित है: आपके लाभ और हानि के कुल मूल्य का कुल हिस्सेदारी से अनुपात 50% से अधिक या उसके बराबर होना चाहिए। वैध कारोबार की गणना खेलों के लिए 'बाजार' या कैसीनो के लिए 'राउंड' द्वारा की जाती है। तो, आप खेल में 1 बाजार में कई दांव लगा सकते हैं या कैसीनो के 1 दौर में कई दांव लगा सकते हैं, लेकिन कुल हिस्सेदारी अनुपात का कुल लाभ और हानि 50% से अधिक या उसके बराबर होना चाहिए। कृपया ध्यान दें कि एक्सचेंज में, बेटफेयर द्वारा प्रदान किए गए मैच ऑड्स और बदले में पेश किए गए कई सट्टेबाजों को 1 बाजार माना जाएगा।",
      "PROMOTIONSREFERRAL@TERM7":
        " शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट्स को डिपॉजिट या बोनस टर्नओवर आवश्यकताओं की ओर नहीं गिना जाता है।",
      "PROMOTIONSREFERRAL@TERM8":
        "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट जांच की जाएगी। पदोन्नति में किसी भी मिलीभगत या किसी भी बेईमानी से खेलने पर खिलाड़ियों को अयोग्य घोषित करने का अधिकार Yolo247 के पास सुरक्षित है।",
      "PROMOTIONSREFERRAL@TERM9":
        " इस प्रचार का उपयोग किसी अन्य पदोन्नति के साथ संयोजन में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
      "PROMOTIONSREFERRAL@TERM":
        "कृपया ध्यान दें कि एक्सचेंज में, बेटफेयर द्वारा प्रदान की गई मैच ऑड्स और एक्सचेंज में दी जाने वाली कई बुकमेकर्स को 1 मार्केट माना जाएगा।",
      "PROMOTIONSREFERRAL@TERM10":
        "Yolo247 अपने विवेक से किसी भी पदोन्नति को संशोधित करने, रद्द करने और पुनः दावा करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONSREFERRAL@TERM11":
        "घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की जाती है, Yolo247 आपके धन को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "PROMOTIONSREFERRAL@TERM12": "Yolo247 सामान्य नियम और शर्तें लागू.",
      PROMOTIONSIGNUPBONUS1:
        "Q. क्या मैं साइन अप बोनस और औसे जीती होवी राशी को विथद्रव कर सकता हूँ?",
      PROMOTIONSIGNUPBONUS2:
        "A. साइन अप बोनस और औसे जीती होवी राशी को विथद्र नहीं कर सकते यह सिर्फ प्लेटफार्म को समजने के लिए है |",

      "TEXT@turnover": "कारोबार",
      "TEXT@NA": "ना",
      "LOYALTY@BLUETIER1ST": "प्रत्येक पुनः जमा पर 2% बोनस",
      "LOYALTY@BLUETIER2ND": "साप्ताहिक हार पर 2% कैशबैक बोनस",
      "LOYALTY@BLUETIER3RD":
        "कोई न्यूनतम टर्नओवर राशि ब्लू टियर में होने की आवश्यकता नहीं है",
      "LOYALTY@BLUETIER4TH":
        "कोई टर्नओवर की स्थिति नहीं, आपके वॉलेट में सीधा बोनस और कोई अधिकतम सीमा नहीं",
      "LOYALTY@SILVERTIER1ST": "प्रत्येक पुनः जमा पर 3% बोनस",
      "LOYALTY@SILVERTIER2ND": "साप्ताहिक हार पर 3% कैशबैक बोनस",
      "LOYALTY@SILVERTIER3RD":
        "25 लाख/प्रति माह की न्यूनतम टर्नओवर राशि सिल्वर टियर में होनी चाहिए",
      "LOYALTY@GOLDTIER1ST": "प्रत्येक पुनः जमा पर 4% बोनस",
      "LOYALTY@GOLDTIER2ND": "साप्ताहिक हार पर 4% कैशबैक बोनस",
      "LOYALTY@GOLDTIER3D": "दो विशेष आयोजन आमंत्रित",
      "LOYALTY@GOLDTIER3RD":
        "प्रति माह 40L की न्यूनतम टर्नओवर राशि गोल्ड टियर में होना आवश्यक है",
      "LOYALTY@PLATIUNUM1ST": "प्रत्येक पुनः जमा पर 5% बोनस",
      "LOYALTY@PLATIUNUM2ND": "साप्ताहिक हार पर 5% कैशबैक बोनस",
      "LOYALTY@PLATIUNUM3RD":
        "सेलिब्रिटी डिनर आमंत्रण फाइव एक्सक्लूसिव इवेंट दो न्यूनतम टर्नओवर राशि के लिए सभी समावेशी विदेश यात्रा को आमंत्रित करता है 1CR प्लैटिनम टियर में होना आवश्यक है",
      "TEXT@INVITE": "निमंत्रण द्वारा ही",

      "DASHBOARD@ChooseChannelText": "एक चैनल चुनें",
      "DASHBOARD@noChanners":
        "हमारे जमा चैनल व्यस्त हैं। कृपया बाद में प्रयास करें, या ग्राहक सेवा से संपर्क करें।",
      "DASHBOARD@CHOOSECHANNEL": "चैनल चुनें",
      "FOOT@KYCPOLICY": "केवाईसी नीति",
      "FOOT@AMLpolicy": "धन शोधन निवारण कार्यक्रम",
      "LOYALTY@GOLDTIER4":
        "गोल्ड टियर में होने के लिए आवश्यक न्यूनतम कारोबार राशि 40L",
      "LOYALTY@PLATIUNUM3": "सेलिब्रिटी डिनर आमंत्रित",
      "LOYALTY@PLATIUNUM4": "पांच विशेष कार्यक्रम आमंत्रित",
      "LOYALTY@PLATIUNUM5": "दो के लिए सभी समावेशी विदेश यात्रा",
      "LOYALTY@PLATIUNUM6":
        "प्लेटिनम टियर में होने के लिए आवश्यक न्यूनतम टर्नओवर राशि प्रति माह 1CR",
        "LOYALTY@Elite": "Elite उपयोगकर्ताओं की शर्तों",
        "LOYALTY@Elite1": "पर उनके संबंधित",
        "LOYALTY@Elite2": "रिलेशनशिप मैनेजर द्वारा चर्चा की जाएगी",
      "MANUALDEPOSIT@Heading": "जमा करने के आसान 4 चरण",
      "MANUALDEPOSIT@YoloBank": " YOLO247 बैंक विवरण",
      "MANUALDEPOSIT@YoloBankNew": "इस बैंक विवरण को कॉपी करें और भुगतान करें",
      "MANUALDEPOSIT@YoloBankTransaction": "लेनदेन का विवरण दर्ज करें",
      "MANUALDEPOSIT@YoloBankAcc": "खाता संख्या",
      "MANUALDEPOSIT@YoloBankAccHolder": "खाता धारक",
      "MANUALDEPOSIT@YoloBankName": "बैंक का नाम",
      "MANUALDEPOSIT@YoloIFSC": "IFSC कोड",
      "Transaction ID": "ट्रांसक्शन ईद",
      "MANUALDEPOSIT@YoloBankuploadRecipt": "अपलोड पेमेंट रिसिप्ट ",
      "MANUALDEPOSIT@YoloBankVerifyPayment": "भुगतान विवरण सत्यापित करें",
      "MANUALDEP@DocSubmit": "(मैक्सिमम साइज छवि 5MB)",
      "MANUALDEPOSIT@MinDeposit": "न्यूनतम जमा",
      "MANUALDEPOSIT@MaxDeposit": "अधिकतम जमा",
      "Payment@MD": "फ़ास्ट बैंक",
      "DEPOSIT@NOTE":
        "नोट:यदि एक चैनल पर कोई तकनीकी बैंक त्रुटि है, तो कृपया दूसरे चैनल का प्रयास करें।",
      "deposit@tips":
        "पैसे सहित हमारे ग्राहकों का विवरण हमेशा सुरक्षित रहता है। किसी भी सहायता के लिए, कृपया हमारी कुशल ग्राहक सेवा टीम से संपर्क करें",
      "Paymnt@Fast-UPI": "फास्ट यूपीआई",
      "MANUALDEPOSIT@PleaseVerify":
        "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। YOLO247 किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",
      "MANUALDEPOSIT@ThedailyDepositBonusIsApplicable":
        "कृपया FAST BANK TRANSFER हेतु IMPS/RTGS/NEFT का प्रयोग करे ।",
      "MANUALDEPOSIT@IfYourDeposit":
        "यदि आपकी जमा राशि मांगी गई राशि से मेल नहीं खाती है, तो इसे धोखाधड़ी माना जाएगा और धनराशि रोक ली जाएगी। उदाहरण: यदि आप ₹500 जमा करते हैं और जमा अनुरोध में ₹1000 लिखते हैं, तो जमा राशि आपके वॉलेट में जमा नहीं की जाएगी या आपके खाते में वापस नहीं की जाएगी।",
      "MANUALDEPOSIT@UPINEW": "भुगतान यूपीआई विवरण",
      "MANUALDEPOSIT@UPI": "यूपीआई आईडी : ",
      "MANUALDEPOSIT@UPIName": "यूपीआई नाम : ",
      "MANUALDEPOSIT@UPIOption": "क्यूआर कोड के लिए यहां क्लिक करें",
      "MANUALDEPOSIT@UPIID": "योलो247 यूपीआई आईडी (एक चुने)",

      "DEPOSIT@BONUSNOTE":
        "नोट :- आपके खाते में जमा होने के बाद बोनस जमा किया जाएगा",
      "DEPOSIT@CONTENTNOTE":
        "आपके खाते में जमा होने के बाद बोनस जमा किया जाएगा",
      "PROMOTIONSTITLE@SIGNUP": "साइनअप बोनस",
      "PROMOTIONS@REFERRl": "रेफरल बोनस",
      "TITLE@SLOTBINGO": "स्लॉट & बिंगो",
      //AccountInfoTierComponent
      "TIER@CurrentTurnOver": "इस महीने के लिए आपका टर्नओवर है",
      "TIER@TierName": "नाम",
      "TIER@TierContactNumber": "संपर्क संख्या",
      "TIER@TierEmail": "ईमेल",
      "TIER@TierStartedText": "नीला शुरू किया",
      "TIER@TierEndText": "₹5L में सिल्वर",
      "TIER@FinishedBlue": "समाप्त नीला",
      "TIER@FinishedSilver": "समाप्त सिल्वर",
      "TIER@PlatinumLevel": "आप प्लेटिनम स्तर पर पहुंच गए हैं!",
      "TIER@₹25LtoGold": "₹25L में गोल्ड",
      "TIER@₹1CrtoPlatinum": "₹1 करोड़ से प्लेटिनम",
      "DASHBOARD@WITHDRAWALADDBANK": "बैंक जोड़ें",
      "DASHBOARD@WITHDRAWALVIEWBANK": "बैंक देखें",
      "HEADER@VIEWALL": "सभी देखें",
      "DASHBOARD@WITHDRAWALFAQ": "सामान्य प्रश्न",
      "DASHBOARD@ADDUPI": "यूपीआई जोड़ें",
      "DASHBOARD@VIEWUPI": "यूपीआई देखें",
      "DASHBOARD@WithdrawPROMOTIONS": "पदोन्नति",
      "DASHBOARD@WithdrawalNote": "टिप्पणी:",
      "DASHBOARD@WithdrawalNote1": "अपना बचा हुआ बैलेंस निकालने के लिए ₹",
      "DASHBOARD@WithdrawalNote13":
        "प्रति माह अधिकतम निकासी अनुमत लेनदेन = ₹ 25,00,000",
      "DASHBOARD@WithdrawalNote11": "का टर्नओवर पूरा करना होगा",
      "Select@speeed": "गति का चयन करें",
      "Insta@WD": "तत्काल निकासी",
      "Normal@WD": "सामान्य निकासी",
      "Avilable@WD": "उपलब्ध निकासी",
      "Bonus@WD": "बोनस के कारण निकासी अवरुद्ध",
      "Pending@WD": "टर्नओवर लंबित होने के कारण निकासी अवरुद्ध",
      "PendingDesc@WD":
        "पूर्ण शेष राशि निकालने के लिए इस आरओ आवश्यकता को पूरा करना होगा",
      "PendingTooltip@WD":
        "पूर्ण शेष राशि निकालने के लिए इस आरओ आवश्यकता को पूरा करना होगा",
      "BonusTooltip@WD":
        "बोनस से अर्जित जीत को वापस लिया जा सकता है, लेकिन बोनस केवल खेलने के उद्देश्य से है और इसे वापस नहीं लिया जा सकता है।",
      "DASHBOARD@ADDUPIACC": "+ नई UPI आईडी जोड़ें",
      "SIDEBAR@UPIDETAIL": "UPI डीटेइल्",
      "MaxWDlimit": "प्रति दिन अधिकतम निकासी सीमा: ",
      "MaxWDlimitAllowed":"प्रति लेनदेन अधिकतम निकासी की अनुमति :",
      "MaxWDlimitAllowedMonth":"प्रति माह अधिकतम निकासी लेनदेन की अनुमति: ",
      "DASHBOARD@WithdrawalNoteNormalW": "निकासी सीमा: ",
      "asdiscussed":'जैसा कि चर्चा की गई है',
      "DASHBOARD@MWithdrawalNoteNormalW": "न्यूनतम निकासी सीमा: ",
      "DASHBOARD@WithdrawalNoteInstaW": "निकासी सीमा: ",
      "DASHBOARD@WithdrawalNoteInstaUPIW": "तत्काल यूपीआई निकासी सीमा: ",
      "DASHBOARD@WithdrawalNote4":
        "सदस्यों को प्रति दिन एक निःशुल्क निकासी की अनुमति है। आपके स्तर के आधार पर, दूसरी निकासी के बाद से नाममात्र सेवा शुल्क लागू किया जाएगा",
      "DASHBOARD@WithdrawalNoteExtra": "प्रति दिन निकासी शुल्क:",
      "TIER@Finished": "समाप्त ",
      "TIER@MostValuableCustomer": "आप हमारे सबसे मूल्यवान ग्राहक हैं!",
      "TITLE@ASIACUP": "एशिया कप",
      "PROMOTIONSWB@WELCOMEB":
        "दूसरे शब्दों में, बोनस रोलओवर आवश्यकताओं के लिए योगदान करने के लिए दांव कम से कम दशमलव 1.30 के ऑड्स के साथ होना चाहिए।.",
      "PROMOTIONS@CATCH99": "कैच99 S2",
      "PROMOTIONS@CATCH99Qoute": "भाग्य बोल्ड का",
      "PROMOTIONS@CATCH99Qoute1": "साथ देता है",
      "PROMOTIONS@CATCH99Qoute2": "बोल्ड हो जाओ",
      "PROMOTIONS@CATCH99Qoute3": "सोने के साथ",
      "PROMOTIONS@JetxCelestial": "जेटएक्स सेलेस्टियल रश",
      "PROMOTIONS@JetSetWithJetX": "जेटएक्स के साथ जेट सेट",
      "PROMOTIONS@WomensWorldCup": "क्रिकेट विश्व कप - महिला",
      "PROMOTIONS@CricketCashbackMarathon": "क्रिकेट कैशबैक मैराथन",
      "PROMOTIONS@CrazyTimeEpicBonanza": "क्रेज़ी टाइम महाकाव्य बोनान्ज़ा",
      "PROMOTIONS@AviatrixDoubleDhamaka": "एविएट्रिक्स डबल धमाका",
      "PROMOTIONS@AviatorHighFlyer": "एविएटर हाई फ़्लायर",
      "PROMOTIONS@Smartsoft": "स्मार्ट सॉफ्ट: स्पिन एंड विन फेस्ट",
      "PROMOTIONS@SmartSoftJetxCelestial": "स्मार्ट सॉफ्ट: स्पिन करें और जीतें",
      "PROMOTIONS@MinesLegendTrophy": "माइंस लीजेंड ट्रॉफी",
      "PROMOTIONS@CaribbeanLeagueBonus": "कैरेबियन क्रिकेट धमाका",
      "PROMOTIONS@EvolutionRouletteCup": "इवोल्यूशन रूलेट कप",
      "PROMOTIONS@AviatrixFlyAndPlay": "एविएट्रिक्स - उड़ो और खेलो",
      "PROMOTIONS@AviatrixJetToCrash": "एविएट्रिक्स - नकदी के लिए जेट, एक छप बनाओ",
      "PROMOTIONS@BigBashBigCash": "बड़ी पार्टी, बड़ी नकदी",
      "PROMOTIONS@SuperSlotsChallenge": "सुपर स्लॉट्स चैलेंज",
      "PROMOTIONS@AviatorRainPromo": "एविएटर रेन",
      "PROMOTIONS@AviatorSkyBoundMiles": "एविएटर: स्काई बाउंड माइल्स",
      "PROMOTIONS@FomoWorldCupLossBackExch": "WC एक्सचेंज बेट्स के लिए 100% हानि वापसी",
      "PROMOTIONS@FomoWorldCupGoIndiaGoJackpot": "गो इंडिया गो जैकपॉट: 50 लाख रूपये!",
      "PROMOTIONS@FomoAviatorWingsOfAdventure": "एविएटर - विंग्स ऑफ़ एडवेंचर!",
      "PROMOTIONS@FomoEvolutionCasinoChallenge": "इवोउशन कैसीनो चैलेंज!",
      "PROMOTIONS@FomoAviatrixFlyHigh": "एविएट्रिक्स - ऊंची उड़ान भरें और जीतें!",
      "PROMOTIONS@FomoWorldCupDailyDesposit": "दैनिक जमा बोनस",
      "PROMOTIONS@FomoLossBackBonus": "लॉसबैक बोनस",
      "PROMOTIONS@WorldCupLossbackBonus": "WC एक्सचेंज बेट्स के लिए 100% लॉसबैक",
      "PROMOTIONS@WorldCupDoubleCashbackBonus": "डबल कैशबैक बोनस!",
      "PROMOTIONS@WorldCupDailyDepositBonus": "दैनिक जमा बोनस!",
      "PROMOTIONS@WorldCupEvolutionCasinoCupBonus": "इवोल्यूशन कैसीनो कप",
      "PROMOTIONS@WorldCupDoubleCashbackBonusBazzi": "10% डबल कैशबैक",
      "PROMOTIONS@EvolutionCasinoTourneyBazzi": "इवोल्यूशन कैसीनो टूर्नामेंट!",
      "PROMOTIONS@DailyRewardsBazzi": "दैनिक पुरस्कार",
      "PROMOTIONS@AviatrixFBE": "एविएट्रिक्स - फ्री बेट्स एक्स्ट्रावैगेंज़ा",
      "PROMOTIONS@IndianGalaxsysLeague": "इंडियन गैलेक्सिस लीग",
      "PROMOTIONS@EvolutionCasionCup": "इवोल्यूशन कैसीनो कप",
      "PROMOTIONS@DoubleCashback": "आईपीएल: फ्री हिट कैशबैक",
      "Catch99@Howtoenter": "जैकपॉट कैसे",
      "Catch99@thejackpot": "दर्ज करें",
      "Catch99@Step1": "चरण 1: रजिस्टर",
      "Catch99@Step1TextContent":
        "यदि आपके पास अभी तक एक Yolo247 खाता नहीं है, तो एक Yolo247 खाता बनाएं। अपने नाम और मोबाइल नंबर के साथ रजिस्टर करें।",
      "Catch99@Step2":
        "चरण 2: किसी भी तरीके से जमा करें और अपने पसंदीदा गेम खेलना शुरू करें!",
      "Catch99@Step2TextContent":
        "25 सितंबर से 25 अक्टूबर तक ₹ 990/- या उससे अधिक मूल्य की प्रत्येक बेट जमा करें और खेलें और  990 ग्राम सोना जैकपॉट पुरस्कार जीतने का मौका प्राप्त करे।",
      "Catch99@TermsTittle": "नियम और शर्तें:",
      "Catch99@TermsHeadContent":
        "जितना अधिक आप 990 ₹पये और उससे अधिक के साथ खेलते हैं, जैकपॉट जीतने की संभावना उतनी ही अधिक होगी। विजेताओं को Yolo247 के इनबॉक्स और Yolo247 के सोशल मीडिया पेजों पर सूचित किया जाएगा। लीडर बोर्ड को हर हफ्ते अपडेट किया जाएगा और लकी ड्रा 29 अक्टूबर को इंस्टाग्राम पेज पर लाइव सत्र के माध्यम से होगा!",
      "Catch99@TermsConditionPointsFirst":
        "1. जैकपॉट – 990 ग्राम सोना : 1 विजेता",
      "Catch99@TermsConditionPointsFirstSub":
        "1 भाग्यशाली विजेता को 990 ग्राम सोना जीतने का मौका मिलेगा। इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी को 1000 चिप्स अर्जित करने की आवश्यकता होती है। वह 1000 चिप्स तभी अर्जित करेगा जब वे 25 अक्टूबर को या उससे पहले ₹ 9,90,000 से अधिक या बराबर सट्टेबाजी की आवश्यकता को पूरा करते हैं|",
      "Catch99@TermsConditionPointsSecond":
        "2. जीतें 99 ग्राम सोना: 10 विजेता ",
      "Catch99@TermsConditionPointsSecondSub":
        "जैकपॉट में प्रवेश करने के लिए खिलाड़ी को 750 चिप्स अर्जित करने की आवश्यकता होती है। खिलाड़ी 750 चिप्स तभी अर्जित करेगा जब वह 25 अक्टूबर को या उससे पहले ₹742,500 से अधिक या उसके बराबर सट्टेबाजी की आवश्यकता को पूरा करता है।",
      "Catch99@TermsConditionPointsThird": "3. 9 ग्राम सोना: 25 विजेता",
      "Catch99@TermsConditionPointsThirdSub":
        "जैकपॉट में प्रवेश करने के लिए खिलाड़ी को 500 चिप्स अर्जित करने की आवश्यकता है। खिलाड़ी 500 चिप्स तभी अर्जित करेगा जब वह 25 अक्टूबर को या उससे पहले ₹ 4,95,000 से अधिक या उसके बराबर की सट्टेबाजी की आवश्यकता को पूरा करता है।",
      "Catch99@TermsConditionPointsFourth": "4. ₹9,900 जीतें: 99 विजेता",
      "Catch99@TermsConditionPointsFourthSub":
        "जैकपॉट में प्रवेश करने के लिए खिलाड़ी को 250 चिप्स अर्जित करने की आवश्यकता होती है। खिलाड़ी 250 चिप्स तभी अर्जित करेगा जब वह 25 अक्टूबर को या उससे पहले 2,47,500 ₹पये से अधिक या उसके बराबर की सट्टेबाजी की आवश्यकता को पूरा करता है। यह राशि अभियान के अंत में खिलाड़ियों के वालेट में जमा की जाएगी।",
      "Catch99@TermsConditionPointsFifth": "5. ₹990 जीतें: 990 विजेता",
      "Catch99@TermsConditionPointsFifthSub":
        "जैकपॉट में प्रवेश करने के लिए खिलाड़ी को 100 चिप्स अर्जित करने की आवश्यकता होती है। खिलाड़ी 100 चिप्स तभी अर्जित करेगा जब वह 25 अक्टूबर को या उससे पहले ₹99,000 से अधिक या उसके बराबर की सट्टेबाजी की आवश्यकता को पूरा करेगा। यह राशि अभियान के अंत में खिलाड़ियों के वाले में जमा की जाएगी।",
      "Catch99@GeneralTermsConditionTittle": "सामान्य नियम और शर्तें:",
      "Catch99@GeneralTermsConditionFirst":
        "यह प्रोमो  सभी नए और मौजूदा खिलाड़ियों के लिए मान्य है।",
      "Catch99@GeneralTermsConditionSecond":
        "कैच99 जीएमटी+5:30 के अनुसार 25 सितंबर'22 से 25 अक्टूबर'22 तक वैध है। विजेताओं की घोषणा 29 अक्टूबर'22 को की जाएगी",
      "Catch99@GeneralTermsConditionThird":
        "बेट्स वैध माने जाने के लिए कम से कम दशमलव 1.30 के ऑड्स के साथ होनी चाहिए।",
      "Catch99@GeneralTermsConditionFourth":
        "शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट को जमा या बोनस रोलओवर आवश्यकताओं के लिए नहीं गिना जाता है।",
      "Catch99@GeneralTermsConditionFifth":
        "खिलाड़ियों के एक से अधिक खाते इस प्रोमो के लिए योग् नहीं होंगे । सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। यदि पदोन्नति में मिलीभगत या कोई बेईमानी होती है तो Yolo247 खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है।",
      "Catch99@GeneralTermsConditionSixth":
        "इस प्रोमो का उपयोग किसी अन्य प्रोमो के साथ संयोजन में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
      "Catch99@GeneralTermsConditionSeventh":
        "Yolo247 अपने अधिकार से किसी भी प्रमोशन को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "Catch99@GeneralTermsConditionEighth":
        "Yolo247 कि सामान्य नियम और शर्तें लागू होती हैं।",
      "Catch99@GeneralTermsConditionNinth":
        " ग्राहक, परिवार, साझा कंप्यूटर, साझा आईपी पता (IP address),और ई-मेल बैंक खाते के विवरण, क्रेडिट कार्ड की जानकारी और भुगतान खाते सहित एक समान खाते के विवरण के लिए केवल एक बोनस की अनुमति है। इस बोनस ऑफ़र का कोई भी दुरुपयोग करने पर खाता बंद कर दिया जाएगा।.",
      "TITLE@bigbash": "बिग बैश",
      "FOOT@blog": " ब्लॉग",
      "FOOT@news": "समाचार",
      "FOOT@Press": "प्रेस",
      "Whatsapp@whatsappText": "तुरंत एक आईडी प्राप्त करें",
      "Whatsapp@whatsappTextsub": "अभी यहां क्लिक करें",
      "Deposit@FastUpiDeposit": "फास्ट यूपीआई जमा",
      "Deposit@FastBankDeposit": "फास्ट बैंक जमा",
      "Deposit@RegularUpiDeposit": "नियमित यूपीआई जमा",
      "Deposit@NetBankingDeposit": "नेट बैंकिंग जमा",
      "bigboss@bb": "बिग्ग बॉस",
      "DEPOSIT@Attention": "उपयोगकर्ता ध्यान दें",
      "DEPOSIT@Attentionnote":
        "आपकी अगली जमा राशि पर हमारा बैंक और UPI विवरण समान नहीं हो सकता है। हमेशा, हमारे जमा पृष्ठ पर दिखाए गए बैंक और यूपीआई खातों में भुगतान करें",
      "MANUALDEPOSIT@SelectOption": "विकल्प चुनें",
      "GAME@MPLAY": "एमप्ले",
      "GAME@7MOJO": "7मोजोस",
      "GAME@BTG": "बीटीजी",
      "GAME@NLCGAMES": "नो लिमिट सिटी",
      "GAME@REDTIGERGAES": "रेडीगर",
      "FIFA@WC": "फ़ीफ़ा वर्ल्ड कप",
      "PROMOTIONS@AviatorBonus": "एविएटर का स्काईबाउंड माइल्स",
      "PROMOTIONS@BBLBonus": "बीबीएल: बिग बैश!",
      "PROMOTIONS@AviatorQoute": "यह उच्च होने का समय है और",
      "PROMOTIONS@AviatorQoute1": "इसलिए हम यहां",
      "PROMOTIONS@AviatorQoute2": "के एविएटर बोनान्ज़ा",
      "PROMOTIONS@AviatorQoute3": "बोनस क साथ हैं !!",
      "PROMOTIONS@AviatorQoute4": "INR 250,000 !!",
      "Aviator@KnowMore": "अधिक जानते हे",
      "Aviator@Allyouneedtodo": "आपको केवल",
      "Aviator@15thNov": "18 नवंबर से 18 दिसंबर",
      "Aviator@AndYoucanStand":
        "तक एविएटर पर न्यूनतम 5,000 दांव लगाने की जरूरत है और आप रोमांचक लाभ जीतने का मौका पा सकते हैं!",
      "Aviator@TheAviatorbonusisvalid":
        "एविएटर बोनस न्यूनतम 1.30 कैशआउट के लिए मान्य है,",
      "Aviator@uptoasHigh": "जितना आप जा सकते हैं उतना अधिक!",
      "Aviator@StartplayingAviatornow":
        "अब एविएटर खेलना शुरू करें और उड़ना शुरू करें! अपनी प्रगति पर नज़र रखने के लिए इन-गेम लीडर बोर्ड पर नज़र रखें और 247 भाग्यशाली खिलाड़ियों के पास जीतने का मौका है! परिणाम",
      "Aviator@18thDEC2022":
        "20 दिसंबर 2022 को YOLO247 वेबसाइट और सोशल मीडिया पर घोषित किए जाएंगे!",
      "Aviator@Howtoenter": "AVIATOR प्रोग्राम के साथ कैसे प्रवेश करें?",
      "Aviator@Step1": "रजिस्टर",
      "Aviator@Step1TextContent":
        "यदि आपके पास अभी तक एक Yolo247 खाता नहीं है, तो एक Yolo247 खाता बनाएं। अपने नाम और मोबाइल नंबर के साथ रजिस्टर करें।",
      "Aviator@Step2":
        "किसी भी तरीके से जमा करें और अपने पसंदीदा गेम खेलना शुरू करें!",
      "Aviator@Step2TextContent":
        "INR 250,000 पूल का एविएटर बोनान्ज़ा बोनस जीतने के लिए न्यूनतम 5,000 या अधिक दांव लगाएं और लगाएं!",
      "Aviator@ReadMoreAbout": "नीचे दिए गए ऑफ़र के बारे में और पढ़ें:",
      "Aviator@20LuckyWinner":
        "प्रथम पुरस्कार INR 5,000 और मुफ्त लॉयल्टी टियर अपग्रेड: 20 विजेता 20 भाग्यशाली विजेताओं को YOLO247 में प्रत्येक को INR 5,000 जीतने का और मुफ्त लॉयल्टी टियर अपग्रेड का मौका मिलेगा जो आपको YOLO247 पर खेलते समय अतिरिक्त रीलोड और कैशबैक बोनस जैसे अधिक लाभ प्राप्त करेगा। 18 नवंबर-18 दिसंबर 2022 के दौरान आयोजित इस ऑफ़र में प्रवेश करने के लिए खिलाड़ी को न्यूनतम 15,001 या अधिक दांव लगाने की आवश्यकता है।",
      "Aviator@Aviator2ndPriceText":
        "दूसरा पुरस्कार 2,500 रुपये और मुफ्त लॉयल्टी टियर अपग्रेड: 40 विजेता 40 भाग्यशाली विजेताओं को YOLO247 पर प्रत्येक को 2,500 रुपये और मुफ्त लॉयल्टी टियर अपग्रेड जीतने का मौका मिलेगा जो आपको YOLO247 पर खेलते समय अतिरिक्त रीलोड और कैशबैक बोनस जैसे अधिक लाभ प्राप्त करेगा। 18 नवंबर-18 दिसंबर 2022 के दौरान आयोजित इस ऑफ़र में प्रवेश करने के लिए खिलाड़ी को न्यूनतम 10,001 -15,000 दांव लगाने की आवश्यकता है।",
      "Aviator@Aviator3rdPriceText":
        "70 भाग्यशाली विजेताओं को YOLO 247 पर प्रत्येक को INR 1,250 और मुफ्त लॉयल्टी टियर अपग्रेड जीतने का मौका मिलेगा जो आपको YOLO247 पर खेलते समय अतिरिक्त रीलोड और कैशबैक बोनस जैसे अधिक लाभ प्राप्त करेगा। 18 नवंबर-18 दिसंबर 2022 के दौरान आयोजित इस ऑफ़र में प्रवेश करने के लिए खिलाड़ी को न्यूनतम 5,001-10,000 दांव लगाने की आवश्यकता है।",
      "Aviator@Aviator4thPriceText":
        "117 भाग्यशाली विजेता को YOLO247 पर प्रत्येक को INR 250 और मुफ्त लॉयल्टी टियर अपग्रेड जीतने का मौका मिलेगा जो आपको YOLO247 पर खेलते समय अतिरिक्त रीलोड और कैशबैक बोनस जैसे अधिक लाभ प्राप्त करेगा। 18 नवंबर-18 दिसंबर 2022 के दौरान आयोजित इस ऑफ़र में प्रवेश करने के लिए खिलाड़ी को न्यूनतम 5,000 दांव लगाने की आवश्यकता है।",
      "Aviator@GeneralTermsConditionTittle": "नियम एवं शर्तें:",
      "Aviator@GeneralTermsConditionFirst":
        "यह पदोन्नति सभी नए और मौजूदा खिलाड़ियों के लिए मान्य है।",
      "Aviator@GeneralTermsConditionSecond": "'फ्लाई हाई विद एविएटर'",
      "BBL@GeneralTermsCOnditionsSecondEnd": "बिग बैश लीग सीजन",
      "Aviator@GeneralTermsConditionSecondValidFrom":
        "18 नवंबर से 18 दिसंबर 22 तक",
      "Aviator@GeneralTermsConditionSecondWinnersWillBe":
        "वैध है। विजेताओं की घोषणा",
      "Aviator@GeneralTermsConditionSecondValidOn":
        "20 दिसंबर '22 को की जाएगी।",
      "Aviator@GeneralTermsConditionThird":
        " एविएटर बोनस न्यूनतम 1.30 कैश आउट के लिए मान्य है",
      "Aviator@GeneralTermsConditionFourth":
        "शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट को जमा या बोनस रोलओवर आवश्यकताओं के लिए नहीं गिना जाता है।",
      "Aviator@GeneralTermsConditionFifth":
        "इस पदोन्नति पात्रता/मानदंड के लिए एकाधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। यदि पदोन्नति में मिलीभगत या कोई बेईमानी होती है तो Yolo247 खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है।",
      "Aviator@GeneralTermsConditionSixth":
        "इस पदोन्नति का उपयोग किसी अन्य पदोन्नति के साथ संयोजन के रूप में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
      "Aviator@GeneralTermsConditionSeventh":
        "Yolo247 अपने विवेक से किसी भी प्रमोशन को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "Aviator@GeneralTermsConditionEighth":
        "Yolo247 सामान्य नियम और शर्तें लागू होती हैं।",
      "FifaFever@Step1": "रजिस्टर करें",
      "FifaFever@Step2":
        "किसी भी तरीके से जमा करें और अपने पसंदीदा गेम खेलना शुरू करें!",
      "PROMOTIONS@FIFAFEVER": "FIFA फीवर",
      "PROMOTIONS@FIFADESC":
        "F.I.F.A 2022 चालू है और हम YOLO247 पर रोल करने के लिए तैयार हैं, ",
      "PROMOTIONS@FIFADESC1":
        "जो आपके लिए INR 9,900,000 का फीफा विश्व कप पूल लेकर आया है!",
      "PROMOTIONS@BBLBonusDesc":
        "बिग बैश लीग आ चुकी है और यह केवल YOLO247 पर बड़ा बोनस बनाने का समय है !! आप का बड़ा बोनस पूल पुरस्कार जीत सकते हैं",
      "PROMOTIONS@BBLBonusDesc1": "YOLO247 स्पेशल मार्केट में INR 1,729,000!!",
      "PROMOTIONS@FIFAINNERFIRSTHEAD": "FIFA फीवर में कैसे प्रवेश करें?",
      "PROMOTIONS@FIFAINNERFIRSTStep1":
        "यदि आपके पास अभी तक एक Yolo247 खाता नहीं है, तो एक Yolo247 खाता बनाएं। अपने नाम और मोबाइल नंबर के साथ रजिस्टर करें।",
      "PROMOTIONS@FIFAINNERFIRSTStep2":
        "INR 9,900,000 का फीफा पूल पुरस्कार जीतने के लिए रु.1,000/- या अधिक मूल्य की प्रत्येक बेट जमा करें और खेलें",
      "PROMOTIONS@FIFAINNERFIRSTFoot":
        "आपको बस इतना करना है कि 2022 फीफा विश्व कप मैचों में न्यूनतम 1,000 रुपये का दांव 20 नवंबर से 18 दिसंबर 2022 तक 1.30 के न्यूनतम ऑड्स पर लगाएं।",
      "PROMOTIONS@FIFAINNERSECONDHEAD":
        "नीचे दिए गए ऑफ़र के बारे में और पढ़ें:",
      "PROMOTIONS@FIFAINNERSECONDTABLETITLE":
        "INR 1,000 या उससे अधिक की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी। अपनी प्रगति पर नज़र रखने के लिए इन-गेम लीडर बोर्ड पर नज़र रखें और 115 भाग्यशाली खिलाड़ी INR 9,900,000 जीतने का मौका देते हैं। परिणाम YOLO247 वेबसाइट और सोशल मीडिया पर 20 दिसंबर 2022 को घोषित किए जाएंगे!",

      "FifaFever@GeneralTermsConditionFirst":
        "यह पदोन्नति सभी नए और मौजूदा खिलाड़ियों के लिए मान्य है।",
      "FifaFever@GeneralTermsConditionSecond": " फीफा फीवर ",
      "FifaFever@GeneralTermsConditionSecondValidFrom":
        "20 नवंबर से 18 दिसंबर 22 तक वैध है।",
      "FifaFever@GeneralTermsConditionSecondWinnersWillBe":
        "विजेताओं की घोषणा ",
      "FifaFever@GeneralTermsConditionSecondValidOn":
        "20 दिसंबर 22 को की जाएगी।",
      "FifaFever@GeneralTermsConditionThird":
        "शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट को जमा या बोनस रोलओवर आवश्यकताओं के लिए नहीं गिना जाता है।",
      "FifaFever@GeneralTermsConditionFourth":
        "इस पदोन्नति पात्रता/मानदंड के लिए एकाधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। यदि पदोन्नति में मिलीभगत या कोई बेईमानी होती है तो Yolo247 खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है।",
      "FifaFever@GeneralTermsConditionFifth":
        "इस पदोन्नति का उपयोग किसी अन्य पदोन्नति के साथ संयोजन के रूप में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
      "FifaFever@GeneralTermsConditionSixth":
        "Yolo247 अपने विवेक से किसी भी प्रमोशन को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
      "FifaFever@GeneralTermsConditionSeventh":
        "Yolo247 सामान्य नियम और शर्तें लागू होती हैं।",
      "GAME@KA-GAMES": "कगामिंग",
      "PROMOTIONS@1LuckyWinner":
        "1 भाग्यशाली विजेता को 15 लाख रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 10,000 सिक्के अर्जित करने की आवश्यकता है। 1,000 रुपये या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "PROMOTIONS@2LuckyWinner":
        "2 भाग्यशाली विजेता को 10 लाख रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 9,000 सिक्के अर्जित करने की आवश्यकता है। INR 1,000 या अधिक राशि का प्रत्येक दांव आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगा।",
      "PROMOTIONS@3LuckyWinner":
        "3 भाग्यशाली विजेता को 5 लाख रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 8,000 सिक्के अर्जित करने की आवश्यकता है। INR 1,000 या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "PROMOTIONS@4LuckyWinner":
        "4 भाग्यशाली विजेता को 2.5 लाख रुपये जीतने का मौका मिलेगा खिलाड़ी (खिलाड़ियों) को 20 नवंबर से 18 दिसंबर 2012 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए 7,000 सिक्के अर्जित करने की आवश्यकता है। प्रत्येक पर 1,000 रुपये या अधिक राशि की शर्त लगाई जाएगी आपको 100 जैकपॉट सिक्के कमाने में मदद करता है",
      "PROMOTIONS@5LuckyWinner":
        "5 भाग्यशाली विजेता को 1.5 लाख रुपये जीतने का मौका मिलेगा खिलाड़ी (खिलाड़ियों) को 20 नवंबर से 18 दिसंबर 2012 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए 6,000 सिक्के अर्जित करने की आवश्यकता है। प्रत्येक पर 1,000 रुपये या अधिक राशि का दांव लगाया जाएगा। आपको 100 जैकपॉट सिक्के कमाने में मदद करता है",
      "PROMOTIONS@10LuckyWinner":
        "10 भाग्यशाली विजेताओं को 1 लाख रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 5,000 सिक्के अर्जित करने की आवश्यकता है। INR 1,000 या अधिक राशि का प्रत्येक दांव आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगा।",
      "PROMOTIONS@15LuckyWinner":
        "15 भाग्यशाली विजेताओं को 50 हजार रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 4,000 सिक्के अर्जित करने की आवश्यकता है। INR 1,000 या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "PROMOTIONS@20LuckyWinner":
        "20 लकी विनर को 50 हजार रुपए जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 3,000 सिक्के अर्जित करने की आवश्यकता है। 1,000 रुपये या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "PROMOTIONS@25LuckyWinner":
        "25 लकी विनर को 25 हजार रुपए जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 2,000 सिक्के अर्जित करने की आवश्यकता है। 1,000 रुपये या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "PROMOTIONS@30LuckyWinner":
        "30 लकी विनर को 10 हजार रुपये जीतने का मौका मिलेगा। 20 नवंबर से 18 दिसंबर 2022 तक आयोजित फीफा विश्व कप के दौरान इस जैकपॉट में प्रवेश करने के लिए खिलाड़ी (खिलाड़ियों) को 1,000 सिक्के अर्जित करने की आवश्यकता है। 1,000 रुपये या अधिक राशि की प्रत्येक शर्त आपको 100 जैकपॉट सिक्के अर्जित करने में मदद करेगी।",
      "page@yoloAppDownload": "Yolo ऐप डाउनलोड करें",
      "YoloAppDwnld@DownloadApp": "डाउनलोड",
      "YoloAppDwnld@BrandName": "योलो",
      "YoloAppDwnld@BrandNameLast": "247",
      "YoloAppDwnld@App": "ऐप",
      "YoloAppDwnld@for": "एंड्रॉयड",
      "YoloAppDwnld@Android": "के लिये",
      "YoloAppDwnld@TheYoloAppIsAvailable":
        "YOLO ऐप Android उपकरणों के लिए मुफ्त डाउनलोड के रूप में उपलब्ध है। ऐप अंग्रेजी और हिंदी को सपोर्ट करता है। ऐप का नवीनतम संस्करण डाउनलोड करें, अपना खाता पंजीकृत करें।",
      "YoloAppDwnld@Download": "डाउनलोड",
      "YoloAppDwnld@ScreenShot": "स्क्रीनशॉट",
      "YoloAppDwnld@TakeALookAtTheScreenshot":
        "नीचे दिए गए योलो ऐप इंटरफ़ेस के स्क्रीनशॉट पर एक नज़र डालें।",
      "YoloAppDwnld@ScanTheQr": "क्यूआर को स्कैन करें",
      "YoloAppDwnld@AndDownloadTheApp": "और ऐप डाउनलोड करें या क्लिक करें",
      "YoloAppDwnld@AppBenefitsFor": "मोबाइल यूजर के लिए योलो",
      "YoloAppDwnld@MobileUsers": "ऐप के फायदे",
      "YoloAppDwnld@InOrderForYouBet":
        "आपके लिए न केवल घर पर बल्कि किसी भी ऐसे स्थान पर जहां इंटरनेट है, दांव लगाने के लिए YOLO बेटिंग ऐप ने एक सुविधाजनक एप्लिकेशन विकसित किया है। ऐप सभी एंड्रॉइड सिस्टम के लिए बहुत अच्छा काम करता है। आवेदन के मुख्य लाभों पर विचार करें",
      "YoloAppDwnld@BenefitsStep1":
        "जहां भी एक स्थिर इंटरनेट है, वहां एप्लिकेशन का उपयोग किया जा सकता है",
      "YoloAppDwnld@BenefitsStep2":
        "एप्लिकेशन पहले से ही आपके डिवाइस पर डाउनलोड किया गया है, इसलिए यह तेजी से लोड होता है और कम डेटा की आवश्यकता होती है।",
      "YoloAppDwnld@BenefitsStep3":
        "एप्लिकेशन में एक न्यूनतर और सरल इंटरफ़ेस है, जिससे आप जल्दी से यह पता लगा सकते हैं कि यह कैसे काम करता है।",
      "YoloAppDwnld@BenefitsStep4":
        "एप्लिकेशन के उपयोगकर्ताओं के लिए, विभिन्न बोनस और प्रचार जोड़े गए हैं।",
      "YoloAppDwnld@BenefitsStep5":
        "आप एप्लिकेशन को केवल एक उंगली से नियंत्रित कर सकते हैं।",
      "YoloAppDwnld@BenefitsStep6":
        "सभी डेटा को साइट के सभी संस्करणों के साथ सिंक्रनाइज़ किया जाता है।",
      "YoloAppDwnld@BenefitsStep7":
        "आवेदन हमेशा उपलब्ध है और रखरखाव के दौरान आपकी गतिविधियों को बाधित नहीं करता है।",
      "YoloAppDwnld@HowTo": "कैसे ",
      "YoloAppDwnld@DownloadInHowTo": "डाउनलोड",
      "YoloAppDwnld@AndInstall": "और इंस्टॉल करें?",
      "YoloAppDwnld@TechnicallyTheYolo247Apk":
        "तकनीकी रूप से, YOLO247 APK पूरी तरह से आधिकारिक वेबसाइट की नकल करता है और इसके डिजाइन और सुविधाओं को दोहराता है। आप इसे सीधे हमारी साइट के माध्यम से डाउनलोड और इंस्टॉल कर सकते हैं। बस नीचे बताए गए कुछ स्टेप्स को फॉलो करें।",
      "YoloAppDwnld@ClickOn": "मेनू विकल्प पर ",
      "YoloAppDwnld@ClickOnMenu": "क्लिक",
      "YoloAppDwnld@ClickOnMenuOptions": "मेनू विकल्प पर क्लिक करें",
      "YoloAppDwnld@ClickTheMenu": "स्क्रीन के नीचे 'मेनू' बटन पर क्लिक करें।",
      "YoloAppDwnld@DownloadTheAppFile": "ऐप फ़ाइल डाउनलोड करें",
      "YoloAppDwnld@ClickTheDownldAndroid":
        "स्क्रीन के नीचे 'एंड्रॉइड ऐप डाउनलोड करें' बटन पर क्लिक करें।",
      "YoloAppDwnld@WaitForTheFile": "योलो ऐप डाउनलोड होने तक प्रतीक्षा करें",
      "YoloAppDwnld@OnceTheProcessIs":
        "एक बार प्रक्रिया पूरी हो जाने के बाद, इंस्टालेशन प्रारंभ करें।",
      "YoloAppDwnld@InstallTheYoloApp":
        "अपने स्मार्टफोन में योलो ऐप इंस्टॉल करें",
      "YoloAppDwnld@WaitForTheInsatalationprocess":
        "इंस्टालेशन प्रक्रिया पूरी होने तक प्रतीक्षा करें, फिर इसे अपने मोबाइल पर शॉर्टकट के माध्यम से खोलें।",
      "BBL@InnertextLeft":
        "यहाँ है और यह केवल YOLO247 पर बड़ा बोनस बनाने का समय है !! आप YOLO247 स्पेशल मार्केट में INR 1,729,000 का बड़ा बोनस पूल पुरस्कार जीत सकते हैं और इस एक्शन से भरपूर T20 टूर्नामेंट के सभी 8 हफ्तों के दौरान अपने नुकसान पर विशेष कैशबैक बोनस जीतने का मौका भी पा सकते हैं!",
      "BBl@InnerTextLeft1":
        ": आपको लगता है कि बीबीएल जीतने वाली टीम की भविष्यवाणी करके INR 1,729,000 का जैकपॉट पूल बोनस कमाएं। परिणाम YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
      "BBl@InnertextLeft2":
        "  : इस टूर्नामेंट में लगाई गई बेट के लिए अपने साप्ताहिक नुकसान पर 100% तक विशेष कैशबैक बोनस अर्जित करें। कैशबैक विजेताओं के लिए हर सप्ताह YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर परिणाम घोषित किए जाएंगे.",
      "Bbl@InnerTextLeftHead": "बिग बैश लीग",
      "Bbl@InnerTextLeftHead1": "बिग बैश बड़ा बोनस ",
      "Bbl@InnerTextLeftHead2": "बिग बैश बड़ा बोनस",
      "BBl@MiddleHead": "अंदर कैसे आएं",
      "BBl@MiddleHead2": "बीबीबीबी प्रतियोगिता?",
      "BBl@MiddleDepositStep":
        "किसी भी विधि का उपयोग करके जमा करें और बीबीएल खेलना शुरू करें!",
      "BBL@MiddleDepositStepHead": "जमा",
      "BBL@CardsTitle1": "बड़ा बोनस विशेष बाजार",
      "BBL@CardsTitle2": "बिग बैश बड़ा कैशबैक",
      "BBL@CardsTitle3": "24 भाग्यशाली",
      "BBL@CardsTitle4": "47 भाग्यशाली",
      "BBL@CardsTitle5": "247 भाग्यशाली",
      "BBL@CardsSubText1": "1,729,000 INR:",
      "BBL@CardsSubText11": "7 विजेता",
      "BBL@CardsSubText2": "318 विजेता :",
      "BBL@CardsSubText21": "100% तक कैशबैक",
      "BBL@CardsSubText3": "100% तक कैशबैक :",
      "BBL@CardsSubText31": "24 विजेता",
      "BBL@CardsSubText4": "50% तक कैशबैक :",
      "BBL@CardsSubText41": "47 विजेता",
      "BBL@CardsSubText5": "सरप्राइज़ कैशबैक :",
      "BBL@CardsSubText51": "247 विजेता",
      "BBL@CardsDescMain1":
        "7 भाग्यशाली विजेताओं में से प्रत्येक को 247,000 रुपये जीतने का मौका मिलेगा !!",
      "BBL@CardsDescSub1":
        "आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि YOLO247 विशेष बाजारों में बीबीएल जीतेगी। इस प्रतियोगिता में प्रवेश करने के लिए न्यूनतम बेट 2470 INR है। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
      "BBL@CardsDescMain2":
        "318 विजेता 13 दिसंबर 22 से 4 फरवरी 23 तक हर हफ्ते 100% तक कैशबैक बोनस कमा सकते हैं !!",
      "BBL@CardsDescSub2":
        " आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि YOLO247 विशेष बाजारों में बीबीएल जीतेगी। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
      "BBL@CardsDescMain3":
        "विजेताओं को हर हफ्ते 100% कैश बैक बोनस जीतने का मौका मिलेगा!!!!",
      "BBL@CardsDescSub3":
        " आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 10 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",
      "BBL@CardsDescMain4":
        "विजेताओं को हर हफ्ते 50% कैश बैक बोनस जीतने का मौका मिलेगा!",
      "BBL@CardsDescSub4":
        "आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 5 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते YOLO247 सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",
      "BBL@CardsDescMain5":
        "विजेताओं को हर हफ्ते सरप्राइज कैशबैक बोनस जीतने का मौका मिलेगा!",
      "BBl@CardsDescSub5":
        "इस प्रतियोगिता में प्रवेश करने के लिए आपको बस इतना करना है कि बीबीएल टूर्नामेंट में हर हफ्ते न्यूनतम INR 500 का दांव लगाना है। भाग्यशाली विजेताओं को यह सरप्राइज बोनस राशि प्रत्येक सोमवार को उनके वॉलेट में प्राप्त होगी।",
      "BBL@GeneralTermsConditionFirst":
        "यह पदोन्नति सभी नए और मौजूदा खिलाड़ियों के लिए मान्य है।",
      "BBL@GeneralTermsConditionSecond":
        "यह प्रमोशन बिग बैश लीग सीज़न के दौरान वैध है",
      BBLGeneralTermsCOnditionsSecondValidFromYear: "2022/2023",
      "BBL@GeneralTermsConditionSecondValidFrom":
        "(13 दिसंबर'22 - 4 फरवरी'23).",
      "BBL@GeneralTermsConditionThird":
        "बेट्स को वैध माने जाने के लिए कम से कम डेसीमल 1.30 के ऑड्स के साथ होना चाहिए",
      "BBL@GeneralTermsConditionFourth":
        "शून्य/रद्द/ड्रा बेट, कैश्ड-आउट बेट जमा या बोनस रोलओवर आवश्यकताओं की ओर नहीं गिने जाते हैं",
      "BBL@GeneralTermsConditionFifth":
        "इस प्रचार योग्यता/मानदंड के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ी डुप्लीकेट चेकिंग के अधीन होंगे। अगर प्रचार में मिलीभगत या कोई गलत खेल होता है तो Yolo247 खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है",
      "BBL@GeneralTermsConditionSixth":
        "इस प्रचार का उपयोग किसी अन्य पदोन्नति के साथ संयोजन में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है",
      "BBL@GeneralTermsConditionSeventh":
        "Yolo247 अपने विवेक से किसी भी पदोन्नति को संशोधित करने, रद्द करने और पुनः दावा करने या अस्वीकार करने का अधिकार सुरक्षित रखता है",
      "BBL@GeneralTermsConditionEightth":
        " Yolo247 सामान्य नियम और शर्तें लागू",
        "GAME@VIVO" :"विवो",
        "GAME@EVOPLAY" :"इवोप्ले",
        "GAME@JILI" :"जीली",
        "GAME@TURBOGAMES" :"टर्बोगेम्स",
      "Deposit@promo": "डिपाजिट नाउ ",
      "arjith@promohead":
        "अरिजीत सिंह के संगीत कार्यक्रम के लिए खेलें और टिकट जीतें और साथ में गाएं!",
      "arjith@promoheadP":
        "अब YOLO247 पर प्रत्येक डिपॉजिट पर आपको न केवल अतिरिक्त री डिपॉजिट बोनस मिलेगा बल्कि अब आपको और आपके खास को पुणे में 27 जनवरी 2023 को होने वाले अरिजीत सिंह के कॉन्सर्ट के लिए टिकट जीतने का भी मौका मिलेगा!",
      "arjith@list1":
        "प्रत्येक श्रेणी में शीर्ष जमाकर्ताओं को दिए गए स्लैब के अनुसार टिकट जीतने का मौका मिलेगा",
      "arjith@list2":
        "प्रतियोगिता में भाग लेने के लिए, कृपया अपना उपयोगकर्ता नाम और स्थान",
      "arjith@extra": "पर इस विषय के साथ ईमेल करें: Yolo247 कंसर्ट",
      "arjith@list3":
        "परिणाम 21 जनवरी 2023 को आपके Yolo247 इनबॉक्स और आपके ईमेल पर घोषित किए जाएंगे!",
      "arjith@term1": "यह प्रमोशन सभी नए और मौजूदा खिलाड़ियों के लिए मान्य है ",
      "arjith@term2": "यह प्रमोशन 28 दिसंबर से 18 जनवरी 2023 तक वैध है",
      "arjith@term3":
        "इस प्रचार योग्यता/मानदंड के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ी डुप्लीकेट चेकिंग के अधीन होंगे। Yolo247 का अधिकार सुरक्षित है यदि प्रचार में मिलीभगत या कोई गलत खेल होता है तो खिलाड़ियों को अयोग्य घोषित करें",
      "arjith@term4":
        "Yolo247 किसी भी पदोन्नति को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है अपना विवेक",
      "arjith@term5": " Yolo247 सामान्य नियम और शर्तें लागू",
      "/////this is comment//////there is no hindi provided for jetx":
        "we have to remove this once this promotion ended after jan 24th////////////",
      "Jetx@GeneralTermsConditionSecond":
        "This promotion is valid for all new & existing players Only bets placed using real money on the full website, mobile or mobile app versions are participating in the tournament.",
      "Jetx@GeneralTermsConditionThird":
        "  Bets must be collected at least on multiplier X2 or higher or must be lost.",
      "Jetx@GeneralTermsConditionFourth":
        "You can spin the points wheel for during 30 seconds after it appears. In the case of inactivity, after 30 seconds the wheel will automatically spin and the player will be rewarded on a random basis.",
      "Jetx@GeneralTermsConditionFifth":
        "Leaderboard points and progress will be displayed inside the participating games lobby.",
      "Jetx@GeneralTermsConditionSixth":
        "The player who earns the maximum number of points will win the tournament.",
      "Jetx@GeneralTermsConditionSeventh":
        "At the end of each stage, during 3 working days winner players will receive prizes.",
      "Jetx@GeneralTermsConditionEightth":
        "If a loss of connection or technical issue arises, all affected spins are considered invalid and may not be included in the tournament results.",
      "Jetx@GeneralTermsConditionnine":
        "Casino and Smartsoft receive the right to cancel any points earned or withhold prizes if they believe that all or part of the outcome comes as results of fraud or collusion with other players.",
      "Jetx@GeneralTermsConditionten":
        "Smartsoft reserves the right to change, suspend or cancel this offer at any time.",
      "Jetx@GeneralTermsConditioneleven":
        "By taking part in this offer, the customer agrees to these terms and conditions and to the general terms and conditions of Casino.",
      "Jetx@GeneralTermsConditiontwelve":
        "All decisions made by the Casino management team are final.",
      "Jetx@GeneralTermsCondition13":
        "The winning amount will be based on the latest currency exchange value",
      "jetx@deposit2":
        "In order to win, the players need to accomplish the given missions. Top 500 players on the leaderboard stand a chance to win a pool prize of INR 4,400,000",
      "jetx@deposit3":
        " The leaderboard is working on a points system. For receiving points, player has to spin a leaderboard wheel, which contains 6 different points:1,2,10,25,50,100.",
      "jetx@depnote": "Deposit using any method and start playing JETX!",
      "PROMOTIONS@jetxt":
        "Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.",
      "jetx@Step1": "Register",
      "jetx@GeneralTermsConditionFirst":
        "This promotion is valid for all new & existing players.",

      "////////////there is no hindi provided for jet x":
        "we have to remove above once this promotion ended after jan 24th////////////",
      PrePlay: "Pre-play",
      PrePlayNote:
        "नोट: यह जीरो कमीशन प्री-प्ले मार्केट है और गेम शुरू होने के बाद मार्केट को निलंबित कर दिया जाएगा।",
      "ipl@GeneralTermsConditionFirst":
        "IPL Dhamaka Bonus is valid for all new & existing players.",
      "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
      "ipl@GeneralTermsConditionSecondValidFrom":
        "31st march to 28th may, 2023 on YOLO247 social media handles (Instagram & telegram)",
      "ipl@GeneralTermsConditionSecondWinnersWillBe":
        "Winners will be announced on ",
      "ipl@GeneralTermsConditionSecondValidOn":
        "YOLO247 social media handles ( Instagram & telegram )",
      "ipl@GeneralTermsConditionThird":
        "Maximum pay-out for Additional cashback bonus is INR 1,000",
      "ipl@GeneralTermsConditionThirdext":
        "Bet should be placed at minimum odds of 1.30",
      "ipl@GeneralTermsConditionFourth":
        "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
      "ipl@GeneralTermsConditionFifth":
        "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
      "ipl@GeneralTermsConditionSixth":
        "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
      "ipl@GeneralTermsConditionSeventh":
        "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
      "ipl@GeneralTermsConditionEighth":
        "Yolo247 General Terms & Conditions apply.",
      "Deplost@NoChannel":
        "यह चैनल व्यस्त है। कृपया कोई अन्य भुगतान विधि आज़माएं",
      "DASHBOARD@WithdrawalNoteDisclaimer4":
        "रात 12:00 बजे से सुबह 6:00 बजे के बीच जमा किए गए निकासी अनुरोधों को बैंकिंग सर्वर समस्याओं के कारण संसाधित होने में अधिक समय लग सकता है जो आमतौर पर रात या गैर-बैंकिंग घंटों के दौरान अनुभव की जाती हैं।",
      "PROMOTIONS@funkytime": "फंकी टाइम : बेट एंड विन !",
      "PROMOTIONS@WCCBONAZA": "कैशबैक बोनेंजा",
      "PROMOTIONS@Platinumrush": "प्लैटिनम रश",
      "Aviator@KnowMore2": "Know More",
      "TITLE@paperplane": "पेपर प्लेन",
      "NOTE@ELITE":'Yolo247 बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
      "PROMOTIONSREFILL@aded1": "कैशबैक बोनस हर 48 घंटे में समाप्त हो जाता है।",
      "TITLE@wcbetboost": "विश्व कप बेटबूस्ट",
      "TITLE@EvoCrazyPachinko": "इवोल्यूशन क्रेज़ी पचिनको: शर्त लगाओ और जीतो",
      "OTPWithDrawal": 'OTP authentication required to initiate transaction when selecting a new bank account.',
      "WithdrawalChannelMsg":"अगले पृष्ठ में, देरी से बचने के लिए अपने भुगतान का यूटीआर नंबर सबमिट करें",
      "depositbonuscode":"मेरे पास एक प्रोमो कोड है",
      "MAX@WD":'प्रति लेनदेन अधिकतम निकासी की अनुमति : चर्चा के अनुसार',
      "USERREGPOPUP@Text": "बैंक फॉर्म भरने के लिए निम्नलिखित विवरण आवश्यक हैं",
      "Payment@Crypto": "फास्ट क्रिप्टो",
      "Deposit@CryptoDeposit": "फास्ट क्रिप्टो जमा",
      "TITLE@FUN88EDGE": "FUN88 EDGE की खोज करें",
      "DEPOSIT@BONUSNOTEUnsuccessful": "यदि लेनदेन असफल हो तो कृपया पुनः प्रयास करें.",
      "DEPOSIT@MESSAGETRANSACID": "लेन-देन आईडी",
      "DEPOSIT@MESSAGEDATETIME": "दिनांक समय",
      "DEPOSIT@MESSAGEBtnText": "होमपेज पर वापस जाएं",
      "DEPOSIT@MESSAGEBtnText2": "लेन-देन इतिहास देखें",
      "FOOTER@TitleSponsorSeason10": "Title Sponsor Season 10",
      "FOOTER@OfficialGlobalAmbassador2023-25": "Official Global Ambassador 2023-25",
      "FOOTER@OfficialShirtSponsorEPLFrom2017-20": "Official Shirt Sponsor EPL from 2017-20",
      "FOOTER@GlobalBrandAmbassador2023-24": "Global Brand Ambassador 2023-24",
      "FOOTER@OfficialBettingPartnerSince2012": "Official Betting Partner, Since 2012",
      "FOOTER@OfficialShirtSponsorBurnleyFCin2015": "Official Shirt Sponsor, Burnley F.C. in 2015",
      "MEMBER@transactionHistory": "पुराना लेन-देन",
      "MEMBER@betHistory": "पुराना ⁠बेट",
      "DEPOSIT@FASTCryptoSUPPORTSText": "तेज़ क्रिप्टो समर्थन",
      "depositCryptoTerms@ContentFirst": "जमा करने से पहले, कृपया अपने वॉलेट शुल्क की समीक्षा करें ताकि यह सुनिश्चित हो सके कि कुल राशि प्लेटफ़ॉर्म राशि से मेल खाती है",
      "depositCryptoTerms@ContentSecond": "किसी तीसरे पक्ष प्रदाता के माध्यम से क्रिप्टो खरीदना उनकी सेवा की शर्तों और ऑपरेटर की केवाईसी नीति के पालन के प्रति आपकी सहमति को दर्शाता है।",
      "depositCryptoTerms@ContentThired": "यदि आपकी जमा राशि अनुरोधित राशि से मेल नहीं खाती है, तो धनराशि रोकी जा सकती है",
      "depositCryptoTerms@ContentFourth": "कृपया किसी भी प्रश्न के लिए ग्राहक सहायता से संपर्क करें",
       "FOOT@sponsorship":"प्रायोजक",
       "PROMOTIONS@InstantChallengePromo":"क्रेजी इंस्टेंट चैलेंज 2025",
      "FOOT@customercare":"ग्राहक सेवा"
  };

    obj = {
      31: {
        ..._defaultHObj,
        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing YOLO247 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Yolo247 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें YOLO 247 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर YOLO247 की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% बोनस का लाभ कैसे उठा सकते हैं, यह जानने के लिए नीचे पढ़ें",
        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
        "PROMOTIONS@LiveJackPot2025": "लाइव जैकपॉट",
        },

      32: {
        ..._defaultHObj,
        "DASHBOARD@uploadFileSuccessMsg": "Uploaded Sucessfully",
        "DASHBOARD@WithdrawalNoteExtra":'अधिकतम निकासी सीमा/दिन :',
        "DASHBOARD@WithdrawalNote4":
        "₹400,000 तक",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing YOLO247 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Yolo247 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हम यहां YOLO 247 पर इस बात से खुश हैं कि आपने हम पर भरोसा करने और हमारे प्लेटफॉर्म पर अपनी पहली जमा राशि बनाने का फैसला किया। हम अपने ग्राहकों को खुश करना पसंद करते हैं और इसलिए आप यहां अपनी पहली जमा राशि पर उपहार के पात्र हैं। यह जानने के लिए नीचे पढ़ें कि आप बिना किसी अधिकतम सीमा के अपनी जमा राशि पर 400% के इस बोनस का लाभ कैसे उठा सकते हैं",
        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
        "PROMOTIONS@LiveJackPot2025": "लाइव जैकपॉट",
      },

      33: {
        ..._defaultHObj,
        "PROMOTIONS@BigBashBigCash": "बोनस बिग बैश",
        "PROMOTIONS@RefillNewHead0": "हर पुनः जमा के साथ जीत - बाज़ीअड्डा पर 5% रिफिल बोनस",
        "PROMOTIONS@RefillNewHead1": "फिर से जमा करें और 5% तक रीफिल बोनस प्राप्त करें",
        "PROMOTIONS@RefillNewDesc1": "आप खास हैं और सर्वश्रेष्ठ के हकदार हैं, इसलिए हम हर पुनर्जमा पर 5% का रीफिल बोनस प्रदान कर रहे हैं।",
        "PROMOTIONS@RefillNewDesc101": "देश का एकमात्र प्लेटफॉर्म जो हर जमा पर बोनस प्रदान करता है।",
        "PROMOTIONS@RefillNewDesc102": "उपयोगकर्ताओं को हर पुनर्जमा पर 5% का रीफिल बोनस (प्लैटिनम सदस्यों के लिए) मिलता है।",
        "PROMOTIONS@RefillNewDesc103": "गैर-प्लैटिनम सदस्यों के लिए रीफिल बोनस का प्रतिशत उनके स्तर पर निर्भर करेगा।",
        "PROMOTIONS@RefillNewHead2": "रीफिल बोनस क्या है और इसे कैसे प्राप्त करें?",
        "PROMOTIONS@RefillNewDesc2": "रीफिल बोनस वह बोनस है जो उपयोगकर्ता को पहले जमा के बाद हर जमा पर मिलता है। यह समझने के लिए कि बोनस कब और कैसे आपके मुख्य वॉलेट में क्रेडिट होता है, निम्नलिखित चरण देखें:",
        "PROMOTIONS@RefillNewDesc201": "वेबसाइट या BaaziAdda ऐप के माध्यम से अपने BaaziAdda खाते में लॉगिन करें।",
        "PROMOTIONS@RefillNewDesc202": "हर पुनर्जमा के लिए, उपयोगकर्ता को 5% तक का रीफिल बोनस मिलेगा, जो तुरंत उनके मुख्य वॉलेट में जोड़ दिया जाएगा।",
        "PROMOTIONS@RefillNewHead3": "BaaziAdda रीफिल बोनस के लिए कौन पात्र है?",
        "PROMOTIONS@RefillNewDesc301": "जो BaaziAdda उपयोगकर्ता 'हाइलाइटेड चैनलों' का उपयोग करके पुनर्जमा कर रहे हैं, वे रीफिल बोनस के पात्र होंगे। रीफिल बोनस का प्रतिशत उपयोगकर्ता के स्तर पर निर्भर करेगा।",
        "PROMOTIONS@RefillNewDesc302": "जो उपयोगकर्ता गैर-हाइलाइटेड चैनलों का उपयोग कर रहे हैं, वे रीफिल बोनस के पात्र नहीं होंगे।",
        "PROMOTIONS@RefillNewDesc303": "तेजी से UPI भुगतान मोड में: सभी उपयोगकर्ताओं को रीफिल बोनस मिलेगा।",
        "PROMOTIONS@RefillNewDesc304": "नियमित UPI भुगतान मोड में पुनर्जमा करने की प्रक्रिया के दौरान, आप हाइलाइटेड चैनल देख सकते हैं।",
        "PROMOTIONS@RefillNewHead4": "BaaziAdda रीफिल बोनस के लाभ",
        "PROMOTIONS@RefillNewDesc4": "रीफिल बोनस से उपयोगकर्ता को निम्नलिखित लाभ मिलते हैं:",
        "PROMOTIONS@RefillNewDesc401": "उपयोगकर्ताओं को खेलने के लिए अतिरिक्त प्रेरणा मिलती है क्योंकि उन्हें हर पुनर्जमा पर 5% तक का बोनस मिलता है।",
        "PROMOTIONS@RefillNewDesc402": "खिलाड़ी इस बोनस का उपयोग विभिन्न खेलों को खेलने, अपने कैसीनो खेल कौशल में सुधार करने और जीतने की संभावना बढ़ाने के लिए कर सकते हैं।",
        "PROMOTIONS@RefillNewTermsHead": "BaaziAdda पर रीफिल बोनस की शर्तें और नियम",
        "PROMOTIONS@RefillNewTermsDesc1": "प्रत्येक ग्राहक, पता, साझा कंप्यूटर, साझा IP पता और किसी भी समान खाता विवरण के लिए केवल एक बोनस की अनुमति है, जिसमें ई-मेल पता, बैंक खाता विवरण, क्रेडिट कार्ड जानकारी और भुगतान प्रणाली खाता शामिल है। इस बोनस के किसी भी दुरुपयोग से खाता बंद कर दिया जाएगा।",
        "PROMOTIONS@RefillNewTermsDesc2": "केवल वे उपयोगकर्ता, जो हाइलाइटेड चैनलों का उपयोग कर रहे हैं, रीफिल बोनस के पात्र होंगे।",
        "PROMOTIONS@RefillNewTermsDesc3": "हर सफल पुनर्जमा के बाद बोनस राशि उपयोगकर्ता के मुख्य वॉलेट में क्रेडिट की जाती है।",
        "PROMOTIONS@RefillNewTermsDesc4": "यह प्रोमोशन हर पुनर्जमा पर उपलब्ध है, और दिन के दौरान प्राप्त की गई प्रत्येक बोनस राशि अगले दिन रात 12 बजे IST पर समाप्त हो जाएगी।",
        "PROMOTIONS@RefillNewTermsDesc5": "उदाहरण: उपयोगकर्ता ने 21 सितंबर को सुबह 9 बजे ₹1,000 की पहली पुनर्जमा की। अब उपयोगकर्ता को ₹20 का बोनस मिलेगा; इस प्रकार, उपयोगकर्ता के खाते में कुल राशि ₹1020 हो जाएगी। अब मान लें कि उपयोगकर्ता उसी दिन 2 बजे ₹2000 की पुनर्जमा करता है। अब उपयोगकर्ता को ₹40 का बोनस मिलेगा। 22 सितंबर की मध्यरात्रि में 11:59:59 बजे उपयोगकर्ता को दिए गए ₹60 की बोनस राशि समाप्त हो जाएगी।",
        "PROMOTIONS@RefillNewTermsDesc6": "इस प्रोमोशन के लिए कई खातों को नहीं माना जाएगा। सभी खिलाड़ियों की डुप्लिकेट जांच की जाएगी। BaaziAdda अधिकार सुरक्षित रखता है कि अगर कोई गलत खेल होता है तो खिलाड़ियों को अयोग्य घोषित कर सकता है।",
        "PROMOTIONS@RefillNewTermsDesc7": "BaaziAdda अपने विवेकाधिकार पर किसी भी प्रोमोशन को संशोधित, रद्द, और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "PROMOTIONS@RefillNewTermsDesc8": "बोनस राशि को निकाला नहीं जा सकता है, लेकिन बोनस से प्राप्त जीत को निकाला जा सकता है।",
        "PROMOTIONS@RefillNewTermsDesc9": "BaaziAdda के सामान्य शर्तें और नियम लागू होते हैं।",
        "PROMOTIONS@RefillNewTermsDesc10": "P2P खेलों के लिए रोलओवर मान्य नहीं है।",
        "NOTE@ELITE":'Baaziadda बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
        "YoloAppDwnld@WaitForTheFile": "Baazi Adda ऐप डाउनलोड होने तक प्रतीक्षा करें",
        "TITLE@BETDAILYEDGE": "बाज़ियाअड्डा एज की खोज करें",
        "PROMOTIONSCASHBACK@1STLINE":
        "baazi Adda आपके साप्ताहिक घाटे पर आपके वॉलेट में तुरंत 2% कैशबैक बोनस प्रदान करता है",
        "evoCasinoPromo@RegisterText":
          "Open the door to endless thrills by creating your very own Baazi Adda account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
        "evoCasinoPromo@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "evoCasinoPromo@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "evoCasinoPromo@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Baazi Adda social media handles ( Instagram, Telegram) and your respective inbox.",
        "evoCasinoPromo@GeneralTermsConditionFourth":
          "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
        "evoCasinoPromo@GeneralTermsConditionFifth":
          "Winnings will be added to wallets as bonus every week.",
        "evoCasinoPromo@GeneralTermsConditionSixth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "evoCasinoPromo@GeneralTermsConditionSeventh":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "evoCasinoPromo@GeneralTermsConditionEighth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "evoCasinoPromo@GeneralTermsConditionNinth":
          "Baazi Adda General Terms & Conditions apply",

        "PROMOTIONS@acbPromoRightText1":
          "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
        "PROMOTIONS@acbPromoRightText2":
          "This Asia cup immerse yourself with exhilarating cricket action only on Baazi Adda.",
        "PROMOTIONS@acbPromoRightText3":
          "Join the biggest gaming event of Asia and stand a chance to win",
        "PROMOTIONS@acbPromoRightText4":
          " Extra Bonuses and Tickets to the Cricket World Cup 2023",
        "asiaCup@GeneralTermsConditionSecond":
          "Asia Cup Bonanza is valid from ",
        "asiaCup@GeneralTermsConditionSecondTwo":
          "30th August to 17th September 2023. ",
        "asiaCup@GeneralTermsConditionSecondThree":
          "Winners will be announced on ",
        "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
        "asiaCup@GeneralTermsConditionSecondFive":
          "on Baazi Adda social media handles ( Instagram & Telegram).",
        "asiaCup@GeneralTermsConditionThird":
          "No Minimum bet amount required to enter this contest.",
        "asiaCup@GeneralTermsConditionFourth":
          "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
        "asiaCup@GeneralTermsConditionFifth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "asiaCup@GeneralTermsConditionSixth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "asiaCup@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "asiaCup@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        // please remove after first bet peromotion ends

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Baazi Adda social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Baazi Adda General Terms & Conditions apply",

        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Baazi Adda social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Baazi Adda & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Baazi Adda General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Baazi Adda members",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Baazi Adda reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Baazi Adda reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        "PROMOTIONSWELCOME@TERMS4":
          "6)  बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",
        "PROMOTIONSWELCOME@TERMS6":
          "8) टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",
        "PROMOTIONSWELCOME@TERMS5":
          "7) टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",
        "PROMOTIONSWELCOME@STEPS":
          "चरण 1: अपनी बोनस राशि को प्राप्त करने के लिये आपको अपने बोनस अमाउंट का 24X RO करना होगा| इस आवशयकता को पूरा करते ही आपका बोनस अमाउंट आपके वॉलेट मे ऑटोमेटिकली क्रेडिट हो जाएगा| अब आप सोच रहे होंगे कि RO की आवश्यकता क्या होती है? अच्छी तरह से समझने के लिए नीचे एक आसान उदाहरण पढिये।",
        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें Baazi Adda पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर Baazi Adda की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% के इस बोनस का लाभ कैसे उठा सकते हैं",
        "PROMOTIONSREFILL@REFILLBONUS4":
          "बाज़ियाअड्डा पर, आप अपने द्वारा की गई प्रत्येक जमा राशि पर 5% रीफिल बोनस के लिए पात्र हैं। प्लैटिनम उपयोगकर्ताओं के लिए, यह 5% की भारी वृद्धि है।",

        "PROMOTIONSCASHBACK@5THLINE":
          "कहो कि आपने पिछले सप्ताह में 10,000 INR खो दिए (प्रत्येक सप्ताह सोमवार 00:00:01 पूर्वाह्न से रविवार 23:59:59 अपराह्न तक गिना जाता है),आपको आपके मुख्य वॉलेट में तुरंत साप्ताहिक नुकसान का 5% क्रेडिट किया जाएगा, जो कि इस उदाहरण में 500 INR है |  .",

        iplline4: "IPL SATTA MATKA BONUS",
        "PROMOTIONS@iplmatka": "आईपीएल सट्टेबाजी धमाका",
        "MANUALDEPOSIT@PleaseVerify":
        "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। Baazi Adda किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",

        "ipl@GeneralTermsConditionFirstBD":
          "The IPL Satta Matka Bonanza 2023 is open to all existing and new Baazi Adda users.",
        "ipl@GeneralTermsConditionSecondBD":
          "The IPL Satta Matka Bonanza 2023 is valid from 31 March, 2023, to 28 May, 2023. Winners will be announced through Baazi Adda social media platforms on",
        "ipl@GeneralTermsConditionThirdBD":
          "To be eligible to enter the IPL Satta Matka Bonanza 2023, a user should place a minimum bet of Rs. 1000 on every IPL match.",
        "ipl@GeneralTermsConditionThirdextBD":
          "The bets should be placed at minimum odds of 1.30.",
        "ipl@GeneralTermsConditionFourthBD":
          "Cashed Out, Void, Cancelled, or Draw bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifthBD":
          "Multiple accounts will not be considered for the IPL Satta Matka Bonanza 2023. All players will be subject to duplicate checking.",
        "ipl@GeneralTermsConditionSixthBD":
          "Baazi Adda reserves the right to disqualify players if collusion or any fairplay violation occurs in the IPL Satta Matka Bonanza 2023.",
        "ipl@GeneralTermsConditionSeventhBD":
          "The IPL Satta Matka Bonanza 2023 cannot be used in conjunction with any other existing promotions on Baazi Adda.",
        "ipl@GeneralTermsConditionEighthBD":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "ipl@GeneralTermsConditionNinethBD":
          "Baazi Adda General Terms & Conditions apply.",
        "ipl@GeneralTermsConditiontenthBD": "",
        "ipl@GeneralTermsConditionSecondValidFromBD":
          "31 March, 2023, to 28 May, 2023.",
        "ipl@GeneralTermsConditionSecondWinnersWillBeBD": "5 June, 2023",

        iplline5: "on Baazi Adda to enjoy this popular competition even now",

        iplinfo:
          "The most exciting time of the year for every cricket fan is when the Indian Premier League is underway. That’s why IPL lovers will receive a special",
        infospan: "IPL MATKA BONUS",
        iplinfo1: "on Baazi Adda to enjoy this popular competition even more.",
        iplinfo2:
          " Results for IPL will be declared on Baazi Adda social media (Instagram & Telegram handles) on _______ date TBD",

        "ipl@GeneralTermsConditionFirst":
          "IPL Dhamaka Bonus is valid for all new & existing players.",
        "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
        "ipl@GeneralTermsConditionSecondValidFrom":
          "______ to _____.  _______ on Baazi Adda social media handles (Instagram & telegram)",
        "ipl@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on ",
        "ipl@GeneralTermsConditionSecondValidOn":
          "Baazi Adda social media handles ( Instagram & telegram )",
        "ipl@GeneralTermsConditionThird":
          "Maximum pay-out for Additional cashback bonus is INR 1,000",
        "ipl@GeneralTermsConditionThirdext":
          "Bet should be placed at minimum odds of 1.30",
        "ipl@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "ipl@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
        "ipl@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "ipl@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",

        "PROMOTIONS@psl": "पीएसल 50:50 क्रिकेट धमाका",
        "TITLE@LOYALTYTAGLINE": "विन डेली ऑन बज़ियाअड्डा, आइरन मोरे ",

        "PROMOTIONSREFILL@TERMS2":
          "यह प्रचार हर री डिपाजिट बोनस पर उपलब्ध है और दिन के दौरान प्राप्त किया गया बोनस अग्ले दिन 24 पर समाप्त हो जायेगा",
        "PROMOTIONSREFILL@TERMS6":
          "8.)किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , बाजी अड्डा आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "PROMOTIONSREFILL@TERMSp2p":
          "9.)रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
        "PROMOTIONSREFILL@TERMS5":
          " 7.) बाजी अड्डा अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "TITLE@betdailyMarquee": "बाजी अड्डा - जीत का अड्डा, बाजीअड्डा !!",
        "PROMOTIONS@BBLBonusDesc1":
          "बाजी अड्डा स्पेशल मार्केट में INR 1,729,000!!",

        "FifaFever@GeneralTermsConditionFourth":
          "इस पदोन्नति पात्रता/मानदंड के लिए एकाधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। यदि पदोन्नति में मिलीभगत या कोई बेईमानी होती है तो बाजी अड्डा खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है।",
        "FifaFever@GeneralTermsConditionFifth":
          "इस पदोन्नति का उपयोग किसी अन्य पदोन्नति के साथ संयोजन के रूप में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
        "FifaFever@GeneralTermsConditionSixth":
          "बाजी अड्डा अपने विवेक से किसी भी प्रमोशन को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "FifaFever@GeneralTermsConditionSeventh":
          "बाजी अड्डा सामान्य नियम और शर्तें लागू होती हैं।",

        "BBL@CardsDescSub3":
          " आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 10 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",
        "BBL@CardsDescMain4":
          "विजेताओं को हर हफ्ते 50% कैश बैक बोनस जीतने का मौका मिलेगा!",
        "BBL@CardsDescSub4":
          "आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 5 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",

        "BBL@CardsDescSub1":
          "आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि बाजी अड्डा विशेष बाजारों में बीबीएल जीतेगी। इस प्रतियोगिता में प्रवेश करने के लिए न्यूनतम बेट 2470 INR है। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
        "BBL@CardsDescMain2":
          "318 विजेता 13 दिसंबर 22 से 4 फरवरी 23 तक हर हफ्ते 100% तक कैशबैक बोनस कमा सकते हैं !!",
        "BBL@CardsDescSub2":
          " आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि बाजी अड्डा विशेष बाजारों में बीबीएल जीतेगी। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",

        "PROMOTIONS@FIFAINNERFIRSTStep1":
          "यदि आपके पास अभी तक एक बाजी अड्डा खाता नहीं है, तो एक बाजी अड्डा खाता बनाएं। अपने नाम और मोबाइल नंबर के साथ रजिस्टर करें।",
        "BBl@InnerTextLeft1":
          ": आपको लगता है कि बीबीएल जीतने वाली टीम की भविष्यवाणी करके INR 1,729,000 का जैकपॉट पूल बोनस कमाएं। परिणाम बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
        "BBl@InnertextLeft2":
          "  : इस टूर्नामेंट में लगाई गई बेट के लिए अपने साप्ताहिक नुकसान पर 100% तक विशेष कैशबैक बोनस अर्जित करें। कैशबैक विजेताओं के लिए हर सप्ताह बाजी अड्डा सोशल मीडिया इंस्टाग्राम हैंडल पर परिणाम घोषित किए जाएंगे.",

        "BBL@InnertextLeft":
          "यहाँ है और यह केवल बाजी अड्डा पर बड़ा बोनस बनाने का समय है !! आप बाजी अड्डा स्पेशल मार्केट में INR 1,729,000 का बड़ा बोनस पूल पुरस्कार जीत सकते हैं और इस एक्शन से भरपूर T20 टूर्नामेंट के सभी 8 हफ्तों के दौरान अपने नुकसान पर विशेष कैशबैक बोनस जीतने का मौका भी पा सकते हैं!",

        "LOYALTY@RULESTEXT5": "*यह ऑफ़र बाजी अड्डा . के सामान्य नियमों के अधीन है",
        "PROMOTIONS@BBLBonusDesc":
          "बिग बैश लीग आ चुकी है और यह केवल बाजी अड्डा पर बड़ा बोनस बनाने का समय है !! आप का बड़ा बोनस पूल पुरस्कार जीत सकते हैं",

        "PROMOTIONSREFILL@REFILLSTEPS2":
          "आपकी पहले डिपाजिट के अलावा, अब से आपके द्वारा सफलतापूर्वक डिपाजिट करने के तुरंत बाद आपको अपने मुख्य वॉलेट में 5% अतिरिक्त बोनस दिया जायेगा.",

        "PROMOTIONSREFERRAL@TERM10":
          "बाजी अड्डा अपने विवेक से किसी भी पदोन्नति को संशोधित करने, रद्द करने और पुनः दावा करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSREFERRAL@TERM7":
          "शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट्स को डिपॉजिट या बोनस टर्नओवर आवश्यकताओं की ओर नहीं गिना जाता है।",
        "PROMOTIONSREFERRAL@TERM11":
          "घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की जाती है, बाजी अड्डा आपके धन को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।",

        "PROMOTIONSREFERRAL@TERM9":
          "इस प्रचार का उपयोग किसी अन्य पदोन्नति के साथ संयोजन में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
        "PROMOTIONSREFERRAL@7":
          "1. अपने रेफ़रल कोड/यूआरएल को अपने दोसत के साथ शेयर करें और उन्हें बाजी अड्डा में शामिल होने के लिए कहें।",

        "PROMOTIONSREFERRAL@5":
          "आप एक दोस्त को रेफर करते हैं जो आपके रेफरल लिंक का उपयोग करके बाजी अड्डा के साथ साइन अप करता है और 10,000 INR डिपाजिट  करता है, तो आप 500 INR बोनस के हकदार होगें जब आपके दोसत अपने डिज़िट का 3 गुना RO पूरा करे गा ।.",

        "PROMOTIONSCASHBACK@TERM3":
          "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बाजी अड्डा के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
        "PROMOTIONSCASHBACK@TERM4":
          "बाजी अड्डा अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "PROMOTIONSCASHBACK@TERMS6":
          "बाजी अड्डा सामान्य नियम और शर्तें लागू होती हैं।.",
        "PROMOTIONSCASHBACK@TERMSp2p":
          "रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",

        "PROMOTIONSCASHBACK@3RDLINE":
          "क्या यह बहुत अच्छा नहीं होगा यदि आप अपने घाटे को कम कर सकें? यहाँ बाजी अड्डा पर, अब अपने साप्ताहिक नुकसान पर तुरंत अपने वॉलेट में 5% कैशबैक बोनस प्राप्त करें।.",

        "PROMOTIONSREFILL@TERMS3":
          "  इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बाजी अड्डा के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",

        "PROMOTIONSREFILL@REFILLSTEPS": "1.) अपने बाजी अड्डा खाते में लॉगिन करें।",
        "PROMOTIONSREFILL@REFILLBONUS2":
          "बाजी अड्डा पर अब प्रत्येक जमा के साथ थोड़ा अधिक कमाएं और अधिक जीतने के लिए बढ़त हासिल करें, और इस प्रकार प्रत्येक नई जमा के साथ एक नया अवसर मिलता है! योलो247 5% तक अतिरिक्त बोनस (प्लैटिनम सदस्यों के लिए) के साथ प्रत्येक जमा पर आपका स्वागत करता है!!",

        "PROMOTIONSWELCOME@TERMS12":
          "किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , बाजी अड्डा आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSWELCOME@TERMS13":
          " बाजी अड्डा सामान्य नियम और शर्तें लागू होती हैं।.",
        "PROMOTIONSWELCOME@TERMSp2p":
          " रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
        "MANUALDEPOSIT@UPIID": "Baazi Adda UPI IDs (Select One)",
        "PROMOTIONSWELCOME@TERMS10":
          " बाजी अड्डा अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSWELCOME@TERMS8":
          "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बाजी अड्डा के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
        "Aviator@Allyouneedtodo":
          "All you need to do is  place minimum 5,000 bets on Aviator from ",
        "Aviator@15thNov": "5th February - 1st March 2023",
        "Aviator@AndYoucanStand":
          "and you can stand a chance to win exciting benefits!",
        "Aviator@TheAviatorbonusisvalid":
          "The Aviator bonus is valid for minimum 1.30 cashout,",
        "Aviator@uptoasHigh": "up to as High as you can go!",
        "Aviator@StartplayingAviatornow":
          "Start playing Aviator now And start flying! Keep an eye on the in-game leader boards to track your progress & 230 lucky players stand a chance to WIN! Results will be declared on Baazi Adda website & social media on",
        "Aviator@18thDEC2022": "3rd March 2023!",
        "Aviator@Howtoenter": "How to Enter the Play Big with Aviator program?",
        "Aviator@Step1TextContent":
          "Create a Baazi Adda account if you do not have one yet. Register with your name and mobile number.",
        "Aviator@Step2TextContent":
          "Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 235,000 pool!",
        "Aviator@20LuckyWinner":
          "20 lucky winner will get a chance to win INR 3,000 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 10,001 or more bets  to enter this offer",
        "Aviator@Aviator2ndPriceText":
          "40 lucky winner will get a chance to win INR 2,000 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 5,001 -10,000 bets  to enter this offer",
        "Aviator@Aviator3rdPriceText":
          "70 lucky winner will get a chance to win INR 1,000 each and free loyalty tier upgrade at Baazi Addawhich will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to place minimum of 5,000 bets  to enter this offer",
        "Aviator@Aviator4thPriceText":
          "100 lucky winner will get a chance to win INR 250 each and free loyalty tier upgrade at Baazi Adda which will get you more benefits like additional reload & cashback while playing at Baazi Adda. The player needs to play Aviator  to enter this offer",
        "Aviator@GeneralTermsConditionSecondValidFrom":
          "5th February to 1st March 2023.",
        "Aviator@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on",
        "Aviator@GeneralTermsConditionSecondValidOn": "3rd March 2023",
        "Aviator@GeneralTermsConditionSecond":
          "‘Play Big with Aviator’ is valid from",
        "Aviator@GeneralTermsConditionFifth":
          " Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Baazi Adda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Aviator@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "Aviator@GeneralTermsConditionSeventh":
          "Baazi Adda reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Aviator@GeneralTermsConditionEighth":
          "Baazi Adda General Terms & Conditions apply.",
        "Aviator@ReadMoreAbout": "Read more about the offer below:",
        "Aviator@Step1": "Register",
        "Aviator@Step2":
          "Deposit using any method and start playing your favourite games!",
        "Aviator@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Aviator@GeneralTermsConditionTittle": "Terms & Conditions:",
        "Aviator@GeneralTermsConditionThird":
          "The Aviator bonus is valid for minimum 1.30 cash out",
        "Aviator@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "PROMOTIONS@AviatorQoute":
          "Its time to Play Big & hence we are here with Aviator Bonanza Bonus of ",
        "PROMOTIONS@AviatorPrice": "INR 235,000 !!",
        "Aviator@KnowMore2": "Know More",
        "YoloAppDwnld@TechnicallyTheYolo247Apk":
          "तकनीकी रूप से, Baazi Adda APK पूरी तरह से आधिकारिक वेबसाइट की नकल करता है और इसके डिजाइन और सुविधाओं को दोहराता है। आप इसे सीधे हमारी साइट के माध्यम से डाउनलोड और इंस्टॉल कर सकते हैं। बस नीचे बताए गए कुछ स्टेप्स को फॉलो करें।",
        "YoloAppDwnld@InstallTheYoloApp":
          "अपने स्मार्टफोन में बाजी अड्डा ऐप इंस्टॉल करें",
        "YoloAppDwnld@AppBenefitsFor": "मोबाइल यूजर के लिए बाजी अड्डा",
        "YoloAppDwnld@InOrderForYouBet":
          "आपके लिए न केवल घर पर बल्कि किसी भी ऐसे स्थान पर जहां इंटरनेट है, दांव लगाने के लिए बाजी अड्डा बेटिंग ऐप ने एक सुविधाजनक एप्लिकेशन विकसित किया है। ऐप सभी एंड्रॉइड सिस्टम के लिए बहुत अच्छा काम करता है। आवेदन के मुख्य लाभों पर विचार करें",
        "YoloAppDwnld@TakeALookAtTheScreenshot":
          "नीचे दिए गए बाजी अड्डा ऐप इंटरफ़ेस के स्क्रीनशॉट पर एक नज़र डालें।",
        "YoloAppDwnld@TheYoloAppIsAvailable":
          "Baazi Adda ऐप Android उपकरणों के लिए मुफ्त डाउनलोड के रूप में उपलब्ध है। ऐप अंग्रेजी और हिंदी को सपोर्ट करता है। ऐप का नवीनतम संस्करण डाउनलोड करें, अपना खाता पंजीकृत करें।",
        "YoloAppDwnld@BrandName": "",
        "YoloAppDwnld@BrandNameLast": "बाजी अड्डा",
        "PSL@TableTittle": "पीएसएल 2023 लीडर बोर्ड",
        "PROMOTIONSREFERRAL@TERM12": "बाजी अड्डा सामान्य नियम और शर्तें लागू.",
        "PROMOTIONS@REFERRALHEAD":
          "पेश है बाजी अड्डा रेफर-ए-फ्रेंड प्रोग्राम: एक विन-विन अवसर!",
        "PROMOTIONSREFERRAL@4":
          "बाजी अड्डा रेफरल कोड सभी नए और पुराने उपयोगकर्ताओं के लिए खुला है। जानिए रेफरल बोनस कैसे काम करता है ",

        "PROMOTIONSREFERRAL@6":
          "बाजी अड्डा रेफरल बोनस कैसे काम करता है? | बाजी अड्डा रेफरल कोड",
        "PROMOTIONSREFERRAL@8":
          "2. जब आपके मित्र आपके अद्वितीय रेफ़रल लिंक या कोड (बाजी अड्डा रेफ़रल कोड) का उपयोग करके बाजी अड्डा के साथ साइन अप करते हैं, तो मज़ा शुरू होता है",
        "PROMOTIONSREFERRAL@10":
          "मान लीजिए कि आप एक ऐसे दोस्त को रेफर करते हैं जो बाजी अड्डा के साथ इस रोमांचकारी यात्रा को शुरू करने का फैसला करता है।",
        "PROMOTIONS@Referafriend01":
          "1.	अपने मित्र के साथ अपना अद्वितीय रेफ़रल कोड/यूआरएल साझा करें और उन्हें बाजी अड्डा में शामिल होने के लिए प्रोत्साहित करें। ",
        "PROMOTIONS@Referafriend05":
          "5.	बाजी अड्डा समुदाय में शामिल हों और आनंद साझा करने और पुरस्कारों को एक साथ प्राप्त करने के इस अविश्वसनीय अवसर का लाभ उठाएं। आज ही अपने दोस्तों को रेफ़र करना शुरू करें और रोमांचकारी अनुभवों की दुनिया अनलॉक करें! ",
        "PROMOTIONS@Referafriend02":
          "2.	इस ऑनलाइन कैसीनो रेफरल बोनस के लिए आपको क्रेडिट प्राप्त करना सुनिश्चित करने के लिए आपके मित्र को आपके रेफरल कोड का उपयोग करके बाजी अड्डा पर पंजीकरण करना चाहिए।  ",
        "PROMOTIONSREFERRAL@R0END":
          "आप हमारा बाजी अड्डा वेलकम बोनस भी देख सकते हैं. ",
        "PROMOTIONS@dailyDepoBonusText":
          "Best Casino Daily Deposit Scheme brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Baazi Adda.",
          "PROMOTIONS@CaribbeanLeagueBonus": "कैरेबियन लीग बोनस",
        "PROMOTIONS@LiveJackPot2025": "लाइव बाज़ी चैलेंज",
          "PROMOTIONS@InstantChallengePromo":"बाज़ी त्वरित चुनौती"
      },

      34: {
        ..._defaultHObj,
        "PROMOTIONS@BigBashBigCash": "बोनस बिग बैश",
        "NOTE@ELITE":'Baaziadda बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
        "YoloAppDwnld@WaitForTheFile": "बेटडेली ऐप डाउनलोड होने तक प्रतीक्षा करें",
        "evoCasinoPromo@RegisterText":
          "Open the door to endless thrills by creating your very own Betdaily account. Register using your name and mobile number, and get ready to embark on an unforgettable journey like no other.",
        "evoCasinoPromo@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "evoCasinoPromo@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "evoCasinoPromo@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Betdaily social media handles ( Instagram, Telegram) and your respective inbox.",
        "evoCasinoPromo@GeneralTermsConditionFourth":
          "This promotion is only applicable for Qualified games : please check the Promotion Schedule tables",
        "evoCasinoPromo@GeneralTermsConditionFifth":
          "Winnings will be added to wallets as bonus every week.",
        "evoCasinoPromo@GeneralTermsConditionSixth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "evoCasinoPromo@GeneralTermsConditionSeventh":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "evoCasinoPromo@GeneralTermsConditionEighth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "evoCasinoPromo@GeneralTermsConditionNinth":
          "Betdaily General Terms & Conditions apply",

        "PROMOTIONS@acbPromoRightText1":
          "The Asia Cup is here again! Asia’s biggest cricket tourney and a prelude to the world cup 2023.",
        "PROMOTIONS@acbPromoRightText2":
          "This Asia cup immerse yourself with exhilarating cricket action only on BETDAILY.",
        "PROMOTIONS@acbPromoRightText3":
          "Join the biggest gaming event of Asia and stand a chance to win",
        "PROMOTIONS@acbPromoRightText4":
          " Extra Bonuses and Tickets to the Cricket World Cup 2023",
        "asiaCup@GeneralTermsConditionSecond":
          "Asia Cup Bonanza is valid from ",
        "asiaCup@GeneralTermsConditionSecondTwo":
          "30th August to 17th September 2023. ",
        "asiaCup@GeneralTermsConditionSecondThree":
          "Winners will be announced on ",
        "asiaCup@GeneralTermsConditionSecondFour": "20th September ",
        "asiaCup@GeneralTermsConditionSecondFive":
          "on BETDAILY social media handles ( Instagram & Telegram).",
        "asiaCup@GeneralTermsConditionThird":
          "No Minimum bet amount required to enter this contest.",
        "asiaCup@GeneralTermsConditionFourth":
          "Only Sports Book, Premium Sports Book, Fancy are applicable for this bonanza.",
        "asiaCup@GeneralTermsConditionFifth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "asiaCup@GeneralTermsConditionSixth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. BETDAILY reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "asiaCup@GeneralTermsConditionSeventh":
          "BETDAILY reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "asiaCup@GeneralTermsConditionEighth":
          "BETDAILY General Terms & Conditions apply.",

        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Betdaily social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Betdaily General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Betdaily members",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Betdaily reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Betdaily reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on betdaily social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Betdaily & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Betdaily General Terms & Conditions apply",
        "PROMOTIONSREFILL@REFILLBONUS4":
          "बेटडेली पर, आप अपने द्वारा की गई प्रत्येक जमा राशि पर 5% रीफिल बोनस के लिए पात्र हैं। प्लैटिनम उपयोगकर्ताओं के लिए, यह 5% की भारी वृद्धि है।",

        "PROMOTIONSWELCOME@TERMS4":
          "6)  बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",
        "PROMOTIONSWELCOME@TERMS6":
          "8) टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",
        "PROMOTIONSWELCOME@TERMS5":
          "7) टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",
        "PROMOTIONSWELCOME@STEPS":
          "चरण 1: अपनी बोनस राशि को प्राप्त करने के लिये आपको अपने बोनस अमाउंट का 24X RO करना होगा| इस आवशयकता को पूरा करते ही आपका बोनस अमाउंट आपके वॉलेट मे ऑटोमेटिकली क्रेडिट हो जाएगा| अब आप सोच रहे होंगे कि RO की आवश्यकता क्या होती है? अच्छी तरह से समझने के लिए नीचे एक आसान उदाहरण पढिये।",
        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें Betdaily पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर Betdaily की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% के इस बोनस का लाभ कैसे उठा सकते हैं",

        "PROMOTIONS@iplmatka": "आईपीएल सट्टेबाजी धमाका",
        iplline4: "IPL SATTA MATKA BONUS",
        "ipl@GeneralTermsConditionFirstBD":
          "The IPL Satta Matka Bonanza 2023 is open to all existing and new Bet Daily users.",
        "ipl@GeneralTermsConditionSecondBD":
          "The IPL Satta Matka Bonanza 2023 is valid from 31 March, 2023, to 28 May, 2023. Winners will be announced through Bet Daily social media platforms on",
        "ipl@GeneralTermsConditionThirdBD":
          "To be eligible to enter the IPL Satta Matka Bonanza 2023, a user should place a minimum bet of Rs. 1000 on every IPL match.",
        "ipl@GeneralTermsConditionThirdextBD":
          "The bets should be placed at minimum odds of 1.30.",
        "ipl@GeneralTermsConditionFourthBD":
          "Cashed Out, Void, Cancelled, or Draw bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifthBD":
          "Multiple accounts will not be considered for the IPL Satta Matka Bonanza 2023. All players will be subject to duplicate checking.",
        "ipl@GeneralTermsConditionSixthBD":
          "Bet Daily reserves the right to disqualify players if collusion or any fairplay violation occurs in the IPL Satta Matka Bonanza 2023.",
        "ipl@GeneralTermsConditionSeventhBD":
          "The IPL Satta Matka Bonanza 2023 cannot be used in conjunction with any other existing promotions on Bet Daily.",
        "ipl@GeneralTermsConditionEighthBD":
          "Bet Daily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "ipl@GeneralTermsConditionNinethBD":
          "Bet Daily General Terms & Conditions apply.",
        "ipl@GeneralTermsConditiontenthBD": "",
        "ipl@GeneralTermsConditionSecondValidFromBD":
          "31 March, 2023, to 28 May, 2023.",
        "ipl@GeneralTermsConditionSecondWinnersWillBeBD": "5 June, 2023",

        iplline5: "on BETDAILY to enjoy this popular competition even now",

        "ipl@GeneralTermsConditionFirst":
          "IPL Dhamaka Bonus is valid for all new & existing players.",
        "ipl@GeneralTermsConditionSecond": "IPL  contest is valid from  ",
        "ipl@GeneralTermsConditionSecondValidFrom":
          "______ to _____.  _______ on Betdaily social media handles (Instagram & telegram)",
        "ipl@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on ",
        "ipl@GeneralTermsConditionSecondValidOn":
          "Betdaily social media handles ( Instagram & telegram )",
        "ipl@GeneralTermsConditionThird":
          "Maximum pay-out for Additional cashback bonus is INR 1,000",
        "ipl@GeneralTermsConditionThirdext":
          "Bet should be placed at minimum odds of 1.30",
        "ipl@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "ipl@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "ipl@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion",
        "ipl@GeneralTermsConditionSeventh":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "ipl@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",
        "PROMOTIONS@psl": "पीएसल 50:50 क्रिकेट धमाका",
        "TITLE@LOYALTYTAGLINE": "विन डेली ऑन बेट डेली,ीर्ण मोरे",

        "PROMOTIONSREFILL@TERMS2":
          "यह प्रचार हर री डिपाजिट बोनस पर उपलब्ध है और दिन के दौरान प्राप्त किया गया बोनस अग्ले दिन 24 पर समाप्त हो जायेगा",

        "PROMOTIONSREFILL@TERMS6":
          "किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , बेटडैली आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "PROMOTIONSREFILL@TERMSp2p":
          "रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
        "PROMOTIONSREFILL@TERMS5":
          " बेटडैली अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",

        "TITLE@betdailyMarquee": "बेटडैली -",
        "PROMOTIONS@BBLBonusDesc1":
          "बेटडैली स्पेशल मार्केट में INR 1,729,000!!",
        "FifaFever@GeneralTermsConditionFourth":
          "इस पदोन्नति पात्रता/मानदंड के लिए एकाधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। यदि पदोन्नति में मिलीभगत या कोई बेईमानी होती है तो बेटडैली खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित रखता है।",
        "FifaFever@GeneralTermsConditionFifth":
          "इस पदोन्नति का उपयोग किसी अन्य पदोन्नति के साथ संयोजन के रूप में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
        "FifaFever@GeneralTermsConditionSixth":
          "बेटडैली अपने विवेक से किसी भी प्रमोशन को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "FifaFever@GeneralTermsConditionSeventh":
          "बेटडैली सामान्य नियम और शर्तें लागू होती हैं।",

        "BBL@CardsDescSub3":
          " आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 10 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",
        "BBL@CardsDescMain4":
          "विजेताओं को हर हफ्ते 50% कैश बैक बोनस जीतने का मौका मिलेगा!",
        "BBL@CardsDescSub4":
          "आपको बस इतना करना है कि इस प्रतियोगिता में प्रवेश करने के लिए हर हफ्ते बीबीएल टूर्नामेंट में न्यूनतम 1,000 रुपये के 5 दांव लगाएं। कैशबैक की गणना साप्ताहिक नुकसान पर की जाएगी और प्रत्येक सोमवार को भाग्यशाली विजेताओं के बटुए में लोड की जाएगी। हर हफ्ते बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर भी नतीजे घोषित किए जाएंगे।",

        "BBL@CardsDescSub1":
          "आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि बेटडैली विशेष बाजारों में बीबीएल जीतेगी। इस प्रतियोगिता में प्रवेश करने के लिए न्यूनतम बेट 2470 INR है। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
        "BBL@CardsDescMain2":
          "318 विजेता 13 दिसंबर 22 से 4 फरवरी 23 तक हर हफ्ते 100% तक कैशबैक बोनस कमा सकते हैं !!",
        "BBL@CardsDescSub2":
          " आपको केवल उस टीम पर दांव लगाना है जो आपको लगता है कि बेटडैली विशेष बाजारों में बीबीएल जीतेगी। समय सीमा 13 दिसंबर 2022 – 2 फरवरी 2023! परिणाम बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",

        "PROMOTIONS@FIFAINNERFIRSTStep1":
          "यदि आपके पास अभी तक एक बेटडैली खाता नहीं है, तो एक बेटडैली खाता बनाएं। अपने नाम और मोबाइल नंबर के साथ रजिस्टर करें।",
        "BBl@InnerTextLeft1":
          ": आपको लगता है कि बीबीएल जीतने वाली टीम की भविष्यवाणी करके INR 1,729,000 का जैकपॉट पूल बोनस कमाएं। परिणाम बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर 5 फरवरी 2023 को घोषित किए जाएंगे!",
        "BBl@InnertextLeft2":
          "  : इस टूर्नामेंट में लगाई गई बेट के लिए अपने साप्ताहिक नुकसान पर 100% तक विशेष कैशबैक बोनस अर्जित करें। कैशबैक विजेताओं के लिए हर सप्ताह बेटडैली सोशल मीडिया इंस्टाग्राम हैंडल पर परिणाम घोषित किए जाएंगे.",

        "BBL@InnertextLeft":
          "यहाँ है और यह केवल बेटडैली पर बड़ा बोनस बनाने का समय है !! आप बेटडैली स्पेशल मार्केट में INR 1,729,000 का बड़ा बोनस पूल पुरस्कार जीत सकते हैं और इस एक्शन से भरपूर T20 टूर्नामेंट के सभी 8 हफ्तों के दौरान अपने नुकसान पर विशेष कैशबैक बोनस जीतने का मौका भी पा सकते हैं!",

        "LOYALTY@RULESTEXT5": "*यह ऑफ़र बेटडैली . के सामान्य नियमों के अधीन है",
        "PROMOTIONS@BBLBonusDesc":
          "बिग बैश लीग आ चुकी है और यह केवल बेटडैली पर बड़ा बोनस बनाने का समय है !! आप का बड़ा बोनस पूल पुरस्कार जीत सकते हैं",
        "PROMOTIONSREFERRAL@TERM10":
          "बेटडैली अपने विवेक से किसी भी पदोन्नति को संशोधित करने, रद्द करने और पुनः दावा करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSREFERRAL@TERM7":
          "शून्य/रद्द/ड्रा बेट्स, कैश्ड-आउट बेट्स को डिपॉजिट या बोनस टर्नओवर आवश्यकताओं की ओर नहीं गिना जाता है।",
        "PROMOTIONSREFERRAL@TERM11":
          "घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की जाती है, बेटडैली आपके धन को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।.",

        "PROMOTIONSREFERRAL@TERM9":
          "इस प्रचार का उपयोग किसी अन्य पदोन्नति के साथ संयोजन में या पात्रता में योगदान करने के लिए नहीं किया जा सकता है।",
        "PROMOTIONSREFERRAL@7":
          "1. अपने रेफ़रल कोड/यूआरएल को अपने दोसत के साथ शेयर करें और उन्हें बेटडैली में शामिल होने के लिए कहें।",

        "PROMOTIONSREFERRAL@5":
          "आप एक दोस्त को रेफर करते हैं जो आपके रेफरल लिंक का उपयोग करके बेटडैली के साथ साइन अप करता है और 10,000 INR डिपाजिट  करता है, तो आप 500 INR बोनस के हकदार होगें जब आपके दोसत अपने डिज़िट का 3 गुना RO पूरा करे गा ।.",

        "PROMOTIONSCASHBACK@TERM3":
          "  इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बेटडैली के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
        "PROMOTIONSCASHBACK@TERM4":
          "बेटडैली अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।",
        "PROMOTIONSCASHBACK@TERMS6":
          "बेटडैली सामान्य नियम और शर्तें लागू होती हैं।.",
        "PROMOTIONSCASHBACK@TERMSp2p":
          "रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
        "PROMOTIONSCASHBACK@3RDLINE":
          "क्या यह बहुत अच्छा नहीं होगा यदि आप अपने घाटे को कम कर सकें? यहाँ बेटडैली पर, अब अपने साप्ताहिक नुकसान पर तुरंत अपने वॉलेट में 5% कैशबैक बोनस प्राप्त करें।.",

        "PROMOTIONSREFILL@TERMS3":
          "  इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बेटडैली के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",

        "PROMOTIONSREFILL@REFILLSTEPS": "1.) अपने बेटडैली खाते में लॉगिन करें।",
        "PROMOTIONSREFILL@REFILLBONUS2":
          "बेटडैली पर अब प्रत्येक जमा के साथ थोड़ा अधिक कमाएं और अधिक जीतने के लिए बढ़त हासिल करें, और इस प्रकार प्रत्येक नई जमा के साथ एक नया अवसर मिलता है! योलो247 5% तक अतिरिक्त बोनस (प्लैटिनम सदस्यों के लिए) के साथ प्रत्येक जमा पर आपका स्वागत करता है!!",

        "PROMOTIONSWELCOME@TERMS12":
          "किसी भी घटना में, खिलाड़ी (खिलाड़ियों) की किसी भी धोखाधड़ी गतिविधि की पहचान की गई ते , बेटडैली आपके फंड को रोकने और आपकी निकासी को अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSWELCOME@TERMS13":
          " बेटडैली सामान्य नियम और शर्तें लागू होती हैं।.",
        "PROMOTIONSWELCOME@TERMSp2p":
          " रोलओवर किसी भी P2P गेम के लिए मान्य नहीं है",
        "MANUALDEPOSIT@UPIID": "BETDAILY UPI IDs (Select One)",
        "PROMOTIONSWELCOME@TERMS10":
          "बेटडैली अपने विवेक से किसी भी प्रचार को संशोधित करने, रद्द करने और पुनः प्राप्त करने या अस्वीकार करने का अधिकार सुरक्षित रखता है।.",
        "PROMOTIONSWELCOME@TERMS8":
          "इस प्रचार के लिए एक से अधिक खातों पर विचार नहीं किया जाएगा। सभी खिलाड़ियों की डुप्लीकेट चेकिंग की जाएगी। बेटडैली के पास खिलाड़ियों को अयोग्य घोषित करने का अधिकार सुरक्षित है यदि प्रमोशन में कोई मिलीभगत या कोई बेईमानी होती है।.",
        "Aviator@Allyouneedtodo":
          "All you need to do is  place minimum 5,000 bets on Aviator from ",
        "Aviator@15thNov": "5th February - 1st March 2023",
        "Aviator@AndYoucanStand":
          "and you can stand a chance to win exciting benefits!",
        "Aviator@TheAviatorbonusisvalid":
          "The Aviator bonus is valid for minimum 1.30 cashout,",
        "Aviator@uptoasHigh": "up to as High as you can go!",
        "Aviator@StartplayingAviatornow":
          "Start playing Aviator now And start flying! Keep an eye on the in-game leader boards to track your progress & 230 lucky players stand a chance to WIN! Results will be declared on Betdaily website & social media on",
        "Aviator@18thDEC2022": "3rd March 2023!",
        "Aviator@Howtoenter": "How to Enter the Play Big with Aviator program?",
        "Aviator@Step1TextContent":
          "Create a Betdaily account if you do not have one yet. Register with your name and mobile number.",
        "Aviator@Step2TextContent":
          "Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 235,000 pool!",
        "Aviator@20LuckyWinner":
          "20 lucky winner will get a chance to win INR 3,000 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 10,001 or more bets  to enter this offer",
        "Aviator@Aviator2ndPriceText":
          "40 lucky winner will get a chance to win INR 2,000 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 5,001 -10,000 bets  to enter this offer",
        "Aviator@Aviator3rdPriceText":
          "70 lucky winner will get a chance to win INR 1,000 each and free loyalty tier upgrade at BetDailywhich will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to place minimum of 5,000 bets  to enter this offer",
        "Aviator@Aviator4thPriceText":
          "100 lucky winner will get a chance to win INR 250 each and free loyalty tier upgrade at BetDaily which will get you more benefits like additional reload & cashback while playing at BetDaily. The player needs to play Aviator  to enter this offer",
        "Aviator@GeneralTermsConditionSecondValidFrom":
          "5th February to 1st March 2023.",
        "Aviator@GeneralTermsConditionSecondWinnersWillBe":
          "Winners will be announced on",
        "Aviator@GeneralTermsConditionSecondValidOn": "3rd March 2023",
        "Aviator@GeneralTermsConditionSecond":
          "‘Play Big with Aviator’ is valid from",
        "Aviator@GeneralTermsConditionFifth":
          " Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Betdaily reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Aviator@GeneralTermsConditionSixth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "Aviator@GeneralTermsConditionSeventh":
          "Betdaily reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Aviator@GeneralTermsConditionEighth":
          "Betdaily General Terms & Conditions apply.",
        "Aviator@ReadMoreAbout": "Read more about the offer below:",
        "Aviator@Step1": "Register",
        "Aviator@Step2":
          "Deposit using any method and start playing your favourite games!",
        "Aviator@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Aviator@GeneralTermsConditionTittle": "Terms & Conditions:",
        "Aviator@GeneralTermsConditionThird":
          "The Aviator bonus is valid for minimum 1.30 cash out",
        "Aviator@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "PROMOTIONS@AviatorQoute":
          "Its time to Play Big & hence we are here with Aviator Bonanza Bonus of ",
        "PROMOTIONS@AviatorPrice": "INR 235,000 !!",
        "Aviator@KnowMore2": "Know More",
        "YoloAppDwnld@TechnicallyTheYolo247Apk":
          "तकनीकी रूप से, BETDAILY APK पूरी तरह से आधिकारिक वेबसाइट की नकल करता है और इसके डिजाइन और सुविधाओं को दोहराता है। आप इसे सीधे हमारी साइट के माध्यम से डाउनलोड और इंस्टॉल कर सकते हैं। बस नीचे बताए गए कुछ स्टेप्स को फॉलो करें।",
        "YoloAppDwnld@InstallTheYoloApp":
          "अपने स्मार्टफोन में बेटडैली ऐप इंस्टॉल करें",
        "YoloAppDwnld@AppBenefitsFor": "मोबाइल यूजर के लिए बेटडैली",
        "YoloAppDwnld@InOrderForYouBet":
          "आपके लिए न केवल घर पर बल्कि किसी भी ऐसे स्थान पर जहां इंटरनेट है, दांव लगाने के लिए बेटडैली बेटिंग ऐप ने एक सुविधाजनक एप्लिकेशन विकसित किया है। ऐप सभी एंड्रॉइड सिस्टम के लिए बहुत अच्छा काम करता है। आवेदन के मुख्य लाभों पर विचार करें",
        "YoloAppDwnld@TakeALookAtTheScreenshot":
          "नीचे दिए गए बेटडैली ऐप इंटरफ़ेस के स्क्रीनशॉट पर एक नज़र डालें।",
        "YoloAppDwnld@TheYoloAppIsAvailable":
          "BETDAILY ऐप Android उपकरणों के लिए मुफ्त डाउनलोड के रूप में उपलब्ध है। ऐप अंग्रेजी और हिंदी को सपोर्ट करता है। ऐप का नवीनतम संस्करण डाउनलोड करें, अपना खाता पंजीकृत करें।",
        "YoloAppDwnld@BrandName": "",
        "YoloAppDwnld@BrandNameLast": "बेटडैली",
        "PSL@TableTittle": "पीएसएल 2023 लीडर बोर्ड",
        "PROMOTIONSREFERRAL@TERM12": "बेटडैली सामान्य नियम और शर्तें लागू.",
        "PROMOTIONS@REFERRALHEAD":
          "पेश है बेटडैली रेफर-ए-फ्रेंड प्रोग्राम: एक विन-विन अवसर!",
        "PROMOTIONSREFERRAL@4":
          "बेटडैली रेफरल कोड सभी नए और पुराने उपयोगकर्ताओं के लिए खुला है। जानिए रेफरल बोनस कैसे काम करता है ",
        "PROMOTIONSREFILL@REFILLSTEPS2":
          "आपकी पहले डिपाजिट के अलावा, अब से आपके द्वारा सफलतापूर्वक डिपाजिट करने के तुरंत बाद आपको अपने मुख्य वॉलेट में 5% अतिरिक्त बोनस दिया जायेगा.",
        "PROMOTIONSCASHBACK@5THLINE":
          "कहो कि आपने पिछले सप्ताह में 10,000 INR खो दिए (प्रत्येक सप्ताह सोमवार 00:00:01 पूर्वाह्न से रविवार 23:59:59 अपराह्न तक गिना जाता है),आपको आपके मुख्य वॉलेट में तुरंत साप्ताहिक नुकसान का 5% क्रेडिट किया जाएगा, जो कि इस उदाहरण में 500 INR है |  .",

        "PROMOTIONSREFERRAL@6":
          "बेटडैली रेफरल बोनस कैसे काम करता है? | बेटडैली रेफरल कोड",
        "PROMOTIONSREFERRAL@8":
          "2. जब आपके मित्र आपके अद्वितीय रेफ़रल लिंक या कोड (बेटडैली रेफ़रल कोड) का उपयोग करके बेटडैली के साथ साइन अप करते हैं, तो मज़ा शुरू होता है",
        "PROMOTIONSREFERRAL@10":
          "मान लीजिए कि आप एक ऐसे दोस्त को रेफर करते हैं जो बेटडैली के साथ इस रोमांचकारी यात्रा को शुरू करने का फैसला करता है।",
        "PROMOTIONS@Referafriend01":
          "1.	अपने मित्र के साथ अपना अद्वितीय रेफ़रल कोड/यूआरएल साझा करें और उन्हें बेटडैली में शामिल होने के लिए प्रोत्साहित करें। ",
        "PROMOTIONS@Referafriend05":
          "5.	बेटडैली समुदाय में शामिल हों और आनंद साझा करने और पुरस्कारों को एक साथ प्राप्त करने के इस अविश्वसनीय अवसर का लाभ उठाएं। आज ही अपने दोस्तों को रेफ़र करना शुरू करें और रोमांचकारी अनुभवों की दुनिया अनलॉक करें! ",
        "PROMOTIONS@Referafriend02":
          "2.	इस ऑनलाइन कैसीनो रेफरल बोनस के लिए आपको क्रेडिट प्राप्त करना सुनिश्चित करने के लिए आपके मित्र को आपके रेफरल कोड का उपयोग करके बेटडैली पर पंजीकरण करना चाहिए।  ",
        "PROMOTIONSREFERRAL@R0END":
          "आप हमारा बेटडैली वेलकम बोनस भी देख सकते हैं. ",
        "PROMOTIONS@dailyDepoBonusText":
          "Best Casino Daily Deposit Scheme brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Betdaily.",
          "PROMOTIONSCASHBACK@1STLINE":
          "Betdaily आपके साप्ताहिक घाटे पर आपके वॉलेट में तुरंत 2% कैशबैक बोनस प्रदान करता है",
          "PROMOTIONS@LiveJackPot2025": "लाइव बाज़ी चैलेंज",
          "PROMOTIONS@InstantChallengePromo":"बाज़ी त्वरित चुनौती"
      },

      39: {
        ..._defaultHObj,
        "Deposit@ReloadBonus": "Redeposit bonus",
        "PROMOTIONS@BigBashBigCash": "बिग बैश बिग बोनस",
        "ProBonus@Welcome": "स्वागत बोनस",
        "ProBonus@Reload": "पुनः जमा बोनस",
        "ProBonus@Pregmatic": "लॉसबैक बोनस",
        "ProBonus@Referral": "रेफरल बोनस",
        "ProBonus@Sub": "700% पहली बार जमा बोनस प्राप्त करें",
        "ProBonus@Sub3": "प्रत्येक जमा पर 5% तक बोनस प्राप्त करें",
        "ProBonus@Sub4": "आपके वॉलेट में तुरंत 5% तक कैशबैक",
        "ProBonus@Sub5": " अपने दोस्तों की जमा राशि का 21% देखें और प्राप्त करें",
        "PROMOTIONSTITLE@CASHBACK": "लॉसबैक बोनस",
        "PROMOTIONS@CASHBACKBONUS": "लॉसबैक बोनस",
        "Promotions@ReferralMessageTooltipThird":
        "हम आपके लिए 7% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की तीसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 7% तक का पुनः जमा बोनस प्रदान कर रहे हैं।.",

        "Promotions@ReferralDesc":
        "हम आपके 7% रेफ़रल बोनस और आपके मित्र के लिए 700% स्वागत बोनस को सक्रिय करने के लिए उनकी पहली जमा राशि की प्रतीक्षा कर रहे हैं",
        "Promotions@ReferralMessageTooltipSecond":
        "हम आपके लिए 7% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की दूसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 7% तक का रिडिपॉजिट बोनस प्रदान कर रहे हैं।.",

        "FomoAppDwnld@BrandName": "फोमो7",
        "FomoApp@Online": "ऑनलाइन",
        "FomoApp@Betting": "बेटिंग ऐप",
        "FomoAppDwnld@BettingApk": "सर्वश्रेष्ठ कैसीनो और स्पोर्ट्स बेटिंग एप्प",
        "FomoAppDwnld@TheFomoAppIsAvailable": "डिजिटल युग में, फेवरेट कैसीनो खेलों का आनंद लें और फोमो7 ऑनलाइन बेटिंग एप्प के साथ खेलें। उपयोगकर्ता सहायक इंटरफेस और स्लिक नेविगेशन के साथ, फोमो7 ऐप भारत के शीर्ष बेटिंग ऐप्स में शामिल है।",
        "FomoAppDwnld@App": "एप्प",
        "FomoAppDwnld@Pages": "पेज",
        "FomoAppDwnld@ScreenshotTextOne": "700 से अधिक कैसीनो खेलों और स्पोर्ट्स बेटिंग के अलावा, रोमांचक बोनस, प्रोमोशंस, और उच्च गुणवत्ता का ग्राहक समर्थन",
        "FomoAppDwnld@ScreenshotTextTwo": "भारत के सर्वश्रेष्ठ ऑनलाइन बेटिंग ऐप पर कैसीनो गेमिंग प्रेमियों का समर्थन करता है।",
        "FomoAppDwnld@AppFeatures": "कुंजी विशेषताएँ ",
        "FomoAppDwnld@BrandNameFeature": "फोमो7",
        "FomoAppDwnld@AppOnlineApp": "ऑनलाइन बेटिंग एप्प",
        "FomoAppDwnld@KeyFeatureText": "जबकि भारत में ऑनलाइन बेटिंग ऐप्स की मांग बढ़ रही है, लेकिन कोई ऐसा असली पैसे का खेल ऐप्स नहीं है जो फोमो7 एप्प के साथ मिल सकता है। निम्नलिखित कुछ मुख्य विशेषताएं हैं फोमो7 ऑनलाइन बेटिंग एप्प की",
        "FomoAppDwnld@BenefitsStep1": "एक सुगम इंटरफेस और मुलायम नेविगेशन।",
        "FomoAppDwnld@BenefitsStep2": "दोनों दुनियां का अच्छा आनंद लें - कैसीनो खेल और स्पोर्ट्स बेटिंग।",
        "FomoAppDwnld@BenefitsStep3": "ऐप सुगमता से चलता है और केवल स्मार्टफोन उपकरण पर थोड़ी सी जगह लेता है।",
        "FomoAppDwnld@BenefitsStep4": "कोई सदस्यता या छुपी शुल्क के साथ मुफ्त में उपयोग करें।",
        "FomoAppDwnld@BenefitsStep5": "भारत में सर्वश्रेष्ठ कानूनी बेटिंग ऐप्स में से एक जो उपयोगकर्ताओं के डेटा को सुरक्षित और सुरक्षित रखता है।",
        "FomoAppDwnld@BenefitsStep6": "ग्राहक समर्थन टीम एप्प के माध्यम से 24x7 उपलब्ध है।",
        "FomoAppDwnld@HowTo": "कैसे डाउनलोड करें ",
        "FomoAppDwnld@ApkFor": " एप्प के लिए",
        "FomoAppDwnld@Android": "एंड्रॉयड?",
        "FomoAppDwnld@TechnicallyFomoApkOne": "फोमो7 ऐप में एंड्रॉयड स्मार्टफोन के लिए स्पोर्ट्स बेटिंग और विभिन्न कैसीनो खेल होते हैं। फोमो7 एंड्रॉयड ऐप को आसानी से",
        "FomoAppDwnld@TechnicallyFomoApkTwo": " डाउनलोड और इंस्टॉल करना है। फोमो7 एप्प को डाउनलोड करने के लिए निम्नलिखित कदमों का पालन करें",
        "YoloAppDwnld@WaitForTheFile": "फ़ाइल का डाउनलोड होने का इंतजार करें",
        "YoloAppDwnld@InstallTheYoloApp": "अपने स्मार्टफोन पर फोमो7 ऐप को स्थापित करें",
        "FomoAppDwnld@SattaApp": " सर्वश्रेष्ठ ऑनलाइन",
        "FomoAppDwnld@SattaAppwhy": "क्यों ",
        "FomoAppDwnld@SattaAppIndia": "सट्टा ऐप है?",
        "FomoAppDwnld@SattaAppText": "हमने आपके ऑनलाइन सट्टा एप्प के रूप में फोमो7 को चुनने के लिए शीर्ष 3 कारणों की सूची बनाई है।",
        "FomoAppDwnld@SattaTextOne": "आइए जानें कैसे डाउनलोड करें सर्वश्रेष्ठ",
        "FomoAppDwnld@SattaTextTwo": "ऑनलाइन सट्टा ऐप है।",
        "FomoAppDwnld@NumberOne": "01",
        "FomoAppDwnld@NumberTwo": "02",
        "FomoAppDwnld@NumberOneText": "आप QR कोड को स्कैन करके फोमो7 वेबसाइट के माध्यम से फोमो7 एप्प को डाउनलोड कर सकते हैं। QR कोड फोमो7 होमपेज के ऊपरी दाईं ओर पर स्थित है। अपने फ़ोन पर एप्लिकेशन के स्थापना को पूरा करने के लिए निर्देशों का पालन करें।",
        "FomoAppDwnld@NumberTwoText": "यदि आप अपने स्मार्टफोन पर फोमो7 वेबसाइट ब्राउज़ कर रहे हैं, तो फोमो7 होमपेज पर मेनू बटन पर टैप करें। डाउनलोड एंड्रॉयड एप्प पर क्लिक करें और बेट एपीके डाउनलोड फ़ाइल को स्थापित करने के लिए निर्देशों का पालन करें।",
        "FomoAppDwnld@SignUp": " एप्प साइनअप",
        "FomoAppDwnld@DepositText": "फ़र्स्ट जमा ऑन ",
        "FomoAppDwnld@SignUpTextOne": "फोमो7 एप्प पर साइन अप करना तत्काल है। पहले फोमो7 लॉगिन करने से पहले, आपको उपयोगकर्ता नाम और पासवर्ड बनाना होगा और नाम, ईमेल आईडी, मोबाइल नंबर आदि जैसे कुछ व्यक्तिगत विवरण दर्ज करने होंगे।",
        "FomoAppDwnld@SignUpTextTwo": "फोमो7 एप्प पर जमा करने के लिए, एप्प खोलें और अपने क्रेडेंशियल्स के साथ लॉग इन करें। हरा वॉलेट आइकन पर टैप करें और एप्प पर चार विकल्पों में से एक का चयन करें—फास्ट बैंक, फास्ट UPI, नियमित UPI, या नेट बैंकिंग—एप्प पर",
        "FomoAppDwnld@Bonus&Promo": "बोनस और प्रोमोशन",
        "FomoAppDwnld@BonusOn": "पर ",
        "FomoAppDwnld@PromotionTextOne": "फोमो7 एप्प पर बोहत सारे बोनस और प्रोमोशन उपलब्ध हैं। फोमो7 पर कैसीनो और स्पोर्ट्स बेटिंग बोनस प्राप्त करें ",
        "FomoAppDwnld@PromotionTextTwo": "नियमित रूप से। यहां फोमो7 पर उपलब्ध बोनस की सूची है",
        "FomoAppDwnld@PromotionTextThree": "फोमो7 ऑनलाइन कैसीनो एप्प डाउनलोड पूरा करें, जमा करते रहें,",
        "FomoAppDwnld@PromotionTextFour": "और बोनस कमाएं।",
        "FomoAppDwnld@CasinoApp": " कैसीनो ऐप",
        "FomoAppDwnld@CasinoLineTextOne": "फ़ाइल की प्रतीक्षा करें फोमो7 ऐप 700+ रोमांचक और पुरस्कृत ऑनलाइन कैसीनो गेम का घर है। कैसीनो गेम विभिन्न प्रकार के होते हैं, जैसे स्लॉट, क्रैश, पासा, व्हील गेम और भी बहुत कुछ। भारत में कई कैसीनो ऐप्स में से, फोमो7 कैसीनो अपने खेलों की विस्तृत श्रृंखला के लिए जाना जाता है। सर्वश्रेष्ठ ऑनलाइन कैसीनो मोबाइल ऐप पर डाउनलोड करने और खेलने के लिए उपलब्ध लोकप्रिय खेलों की सूची यहां दी गई है:",
        "FomoAppDwnld@CasinoLineTextFour": "फोमो7 ऑनलाइन कैसीनो ऐप डाउनलोड विकल्प है ",
        "FomoAppDwnld@CasinoLineTextFive": "मुखपृष्ठ पर उपलब्ध है.",
        "FomoAppDwnld@SportsBet": "स्पोर्ट्स बेटिंग पर ",
        "FomoAppDwnld@BettingApp": "बेटिंग एप्प",
        "FomoAppDwnld@SportsTextOne": "फोमो7 बेटिंग एप्प दुनिया भर में हो रहे खेल के इवेंट को होस्ट करता है। क्रिकेट बेटिंग ऐप विभिन्न टूर्नामेंट कवर करता है, जैसे कि IPL, BBL, ODI वर्ल्ड कप, T20 वर्ल्ड कप, और अन्य अंतरराष्ट्रीय और घरेलू टूर्नामेंट। यह भारत में सर्वश्रेष्ठ ऑनलाइन क्रिकेट बेटिंग ऐप है, जिसमें रोमांचक बेटिंग मार्केट्स और शीर्ष बेटिंग अवसर होते हैं।",
        "FomoAppDwnld@SportsTextTwo": "एप्प विभिन्न क्रिकेट बेटिंग प्रेमियों की आवश्यकताओं को पूरा करता है। लाइव क्रिकेट ऐप डाउनलोड करके, आप तत्काल ऐप डाउनलोड कर सकते हैं। क्योंकि कई सारे बोनस और रोमांचक प्रोमोशन उपलब्ध हैं।",
        "FomoAppDwnld@SportsTextThree": "भारत में सभी फुटबॉल बेटिंग एप्स में से फोमो7 सर्वश्रेष्ठ फुटबॉल बेटिंग एप्प है। फोमो7 को भारत में सर्वश्रेष्ठ फुटबॉल बेटिंग ऐप क्यों माना जाता है, क्योंकि यह विभिन्न पॉपुलर लीग्स को कवर करता है जैसे कि EPL, ला लीगा, बुंडेसलीगा, यूईएफए चैम्पियंस लीग, और अन्य। फोमो7 एप्प बेटिंग प्रेमियों को पॉपुलर फुटबॉल घटनाओं पर बेट करने की सुविधा देता है जैसे कि फीफा वर्ल्ड कप, यूईएफए यूरो कप, कोपा अमेरिका, और अधिक। यह सबसे अच्छा टेनिस बेटिंग एप्प भी है, क्योंकि यह विश्वव्यापी ग्रैंड स्लैम और एटीपी घटनाओं को कवर करता है।",
        "FomoAppDwnld@SportsTextFour": "स्पोर्ट्स बेटिंग का सबसे बड़ा लाभ यह है कि आप पूर्व-मैच बेटिंग और लाइव मैच पर बेटिंग या लाइव मैच पर बेटिंग कर सकते हैं। इसीलिए यह भारत में सर्वश्रेष्ठ स्पोर्ट्स बेटिंग ऐप के रूप में खड़ा है।",
        "FomoAppDwnld@BettingTextOne": "आईपीएल से अधिक कोई टी20 इवेंट मनाया नहीं जाता है। लीग में 70 से अधिक मैच खेले जाते हैं, क्रिकेट प्रेमियों को दो महीने के दौरान एक उत्सव का समय बिताना होता है। आईपीएल ऑनलाइन बेटिंग एप्प ने सर्वश्रेष्ठ बोनस, अद्वितीय बेटिंग बाजार, रोमांचक प्रमोशन, और बोनस प्रदान करते हुए सर्वश्रेष्ठ आईपीएल सट्टा एप्प बनाया है।",
        "FomoAppDwnld@BettingHeading": "ऑनलाइन आईपीएल बेटिंग एप्प",
        "FomoAppDwnld@FAQquestionOne": "क्या फोमो7 एप्प डाउनलोड करना आसान है?",
        "FomoAppDwnld@FAQquestionTwo": "फोमो7 ऑनलाइन बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से खेल पर बेटिंग का आनंद ले सकता हूँ?",
        "FomoAppDwnld@FAQquestionThree": "फोमो7 इंडियन क्रिकेट बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से टूर्नामेंट पर बेट कर सकता हूँ?",
        "FomoAppDwnld@FAQanswerOne": "हां, आप थोड़े ही और आसान कदमों के साथ ब्रांड वेबसाइट पर जाकर फोमो7 ऑनलाइन बेटिंग एप्प डाउनलोड कर सकते हैं।",
        "FomoAppDwnld@FAQanswerTwo": "फोमो7 इंडिया बेटिंग एप्प डाउनलोड करने के बाद, आप क्रिकेट, फुटबॉल, टेनिस, और अधिक पर बेट कर सकते हैं।",
        "FomoAppDwnld@FAQanswerThree": "फोमो7 इंडियन बेटिंग एप्प डाउनलोड करने के बाद, आप विभिन्न क्रिकेट टूर्नामेंट्स जैसे कि वर्ल्ड कप, चैंपियंस ट्रॉफी, आईसीसी टी20 वर्ल्ड कप, और लोकप्रिय टी20 घरेलू लीग्स जैसे कि आईपीएल, BBL, PSL, और अधिक पर बेट कर सकते हैं।",
        "FomoAppDwnld@CasinoHeadTextOne": "तेज",
        "FomoAppDwnld@CasinoHeadTextTwo": "सुरक्षित",
        "FomoAppDwnld@CasinoHeadTextThree": "बेहतर",
        "FomoAppDwnld@CasinoBodyTextOne": "ऑनलाइन असली पैसे जुआ एप्स के मामले में, तेज होना पहले विचार किया जाने वाला पहलू है। फोमो7 apk उपयोगकर्ता अनुकूल है और नेविगेशन और लोडिंग के लिए त्वरित है।",
        "FomoAppDwnld@CasinoBodyTextTwo": "आपकी लेन-देन हमारे प्लेटफ़ॉर्म पर सुरक्षित और सुरक्षित है। इसके अलावा, आपके पास सभी प्रसिद्ध भुगतान विधियाँ हैं।",
        "FomoAppDwnld@CasinoBodyTextThree": "चैसे ही आपके पास कैसीनो और स्पोर्ट्स बेटिंग होती है, आपका अनुभव निश्चित रूप से बेहतर होता है।",
        "FomoAppDwnld@BonusContentTitleOne": "स्वागत बोनस",
        "FomoAppDwnld@BonusContentTitleTwo": "रेफ़रल बोनस",
        "FomoAppDwnld@BonusContentTitleThree": "री-डिपॉजिट बोनस",
        "FomoAppDwnld@BonusContentBodyOne": "नए उपयोगकर्ताओं को फोमो7 कैसीनो एप्प डाउनलोड करने और उनका पहला जमा करने पर 700% वेलकम बोनस मिलता है। यह भारत में सबसे बड़ा कैसीनो एप्प डाउनलोड बोनस है।",
        "FomoAppDwnld@BonusContentBodyTwo": "रेफ़रल बोनस फोमो7 पर सबसे लोकप्रिय कैसीनो एप्प बोनस है। जब आप अपने दोस्तों को संदर्भित करते हैं, और वे फोमो7 में शामिल होते हैं, तो आप 21% रेफ़रल बोनस के लिए पात्र होते हैं।",
        "FomoAppDwnld@BonusContentBodyThree": "पहले के अलावा हर जमा पर आपको 7% री-डिपॉजिट बोनस मिलेगा। यह फोमो7 पर सफल जमा पर आवर्ती नि: शुल्क कैसीनो बोनस है।",
        "FomoAppDwnld@OnlineTextOne": "एविएटर गेम ऑनलाइन डाउनलोड",
        "FomoAppDwnld@OnlineTextTwo": "ड्रैगन टाइगर गेम डाउनलोड",
        "FomoAppDwnld@OnlineTextThree": "रूलेट गेम एप्प डाउनलोड",
        "FomoAppDwnld@OnlineTextFour": "लाइटनिंग रूलेट ऐप डाउनलोड",
        "FomoAppDwnld@FAQHeading": "पूछे जाने वाले प्रश्न",
        "FomoAppDwnld@DownloadTextOne": "ऐप को सफलतापूर्वक डाउनलोड करने के बाद अगला कदम है रजिस्ट्रेशन और ",
        "FomoAppDwnld@DownloadTextTwo": "फोमो7 पर अपनी पहली जमा राशि बनाना।",
        "FomoAppDwnld@ClickOnMenu": " ",
        "FomoAppDwnld@Menu": "मेनू ",
        "FomoAppDwnld@Options": "विकल्प पर क्लिक करें",
        "FomoAppDwnld@Download": "ऐप फ़ाइल ",
        "FomoAppDwnld@TheApp": "डाउनलोड करें",
        "FomoAppDwnld@WaitForFile": "फ़ाइल डाउनलोड होने तक  ",
        "FomoAppDwnld@ToDownload": "प्रतीक्षा करें",
        "FomoAppDwnld@InstallTheFomoApp": "अपने स्मार्टफ़ोन पर फोमो7 ऐप ",
        "FomoAppDwnld@SmartPhoneFomo": "इंस्टॉल करें",

        "PROMOTIONS@REFILLBONUS": "पुनः जमा बोनस",
        "PROMOTIONSTITLE@REFILL": "पुनः जमा बोनस",
        "LOYALTY@RULESTEXT1":
          " यदि टर्नओवर 1 महीने की अवधि में आवश्यक राशि से कम है, तो खिलाड़ी स्वचालित रूप से पूर्व स्तर पर डाउनग्रेड हो जाएगा, उदाहरण के लिए, यदि आप एक गोल्ड टियर सदस्य हैं और आप एक महीने की अवधि में 40L का टर्नओवर बनाए रखने में असमर्थ हैं, आपका लॉयल्टी प्रोग्राम टियर सिल्वर वगैरह में शिफ्ट हो जाएगा।",
        "LOYALTY@RULESTEXT2":
          " निकासी राशि के नियम और शर्तों में कोई बदलाव नहीं होगा",
        "LOYALTY@RULESTEXT3":
          " कंपनी के पास बोनस को रद्द करने का अधिकार सुरक्षित है और बिना कारण बताए क्लाइंट को इसे देने से इनकार करने का अधिकार सुरक्षित है यदि गेमिंग खाते की जांच के दौरान, गेम की निष्पक्षता के उल्लंघन का संदेह है या कंपनी द्वारा रणनीतियों का उपयोग किया जाता है। इसका विवेक दुर्भावनापूर्ण माना जाता है। विवादित स्थितियों में कंपनी के अधिकृत अधिकारियों का निर्णय अंतिम होता है.",
        "LOYALTY@RULESTEXT4":
          " नियमों के उल्लंघन, बेईमानी या अन्य धोखाधड़ी गतिविधियों के मामले में, ऐसे सभी खातों को भागीदारी से बाहर रखा जाएगा",
        "LOYALTY@RULESTEXT5": " यह ऑफ़र FOMO7 . के सामान्य नियमों के अधीन है",
        "LOYALTY@RULESTEXT6": " प्रत्येक पुन: जमा पर 2% बोनस प्रदान किया जाएगा",
        "LOYALTY@RULESTEXT7": " साप्ताहिक नुकसान पर 2% कैश बैक प्रदान किया जाएगा",
        "LOYALTY@RULESTEXT8":
          " चुने गए लॉयल्टी प्रोग्राम टियर के अनुसार अतिरिक्त कैशबैक की जानकारी दी जाएगी",
        "MANUALDEPOSIT@PleaseVerify":
        "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। FOMO7 किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",
        // "TITLE@MARQUE1": " ",
        "TITLE@betdailyMarquee": "FOMO7 - ",
        "TITLE@MARQUE": "FOMO7 -",
        "TITLE@LOYALTYTAGLINE": "वफादारी",
        "TITLE@FOMOEDGE": "फ़ोमो7 एज की खोज करें",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing YOLO247 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Yolo247 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Yolo247 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on YOLO247 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Yolo247 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Yolo247 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें YOLO 247 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर YOLO247 की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% बोनस का लाभ कैसे उठा सकते हैं, यह जानने के लिए नीचे पढ़ें",
        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
        "PROMOTIONS@dailyDepoBonusText":
          "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
        "PROMOTIONS@CaribbeanLeagueBonus": "कैरेबियन रॉयल बोनस",
        "PROMOTIONS@EvolutionRouletteCup": "इवोल्यूशन क्रेजी कप",
        "PROMOTIONS@AviatorRainPromo": "एविएटर शावर",
        "NOTE@ELITE":'FOMO7 बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
      "PROMOTIONS@LiveJackPot2025": "वास्तविक सौदा",
        "PROMOTIONS@InstantChallengePromo":"त्वरित चुनौती 2025"
      },
      40: {
        ..._defaultHObj,
        "Deposit@ReloadBonus": "Redeposit bonus",
        "PROMOTIONS@BigBashBigCash": "बिग बैश बिग बोनस",
        "ProBonus@Welcome": "स्वागत बोनस",
        "ProBonus@Reload": "पुनः जमा बोनस",
        "ProBonus@Pregmatic": "लॉसबैक बोनस",
        "ProBonus@Referral": "रेफरल बोनस",
        "ProBonus@Sub": "700% पहली बार जमा बोनस प्राप्त करें",
        "ProBonus@Sub3": "प्रत्येक जमा पर 5% तक बोनस प्राप्त करें",
        "ProBonus@Sub4": "आपके वॉलेट में तुरंत 5% तक कैशबैक",
        "ProBonus@Sub5": " अपने दोस्तों की जमा राशि का 21% देखें और प्राप्त करें",
        "PROMOTIONSTITLE@CASHBACK": "लॉसबैक बोनस",
        "PROMOTIONS@CASHBACKBONUS": "लॉसबैक बोनस",
        "Promotions@ReferralMessageTooltipThird":
        "हम आपके लिए 7% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की तीसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 7% तक का पुनः जमा बोनस प्रदान कर रहे हैं।.",

        "Promotions@ReferralDesc":
        "हम आपके 7% रेफ़रल बोनस और आपके मित्र के लिए 700% स्वागत बोनस को सक्रिय करने के लिए उनकी पहली जमा राशि की प्रतीक्षा कर रहे हैं",
        "Promotions@ReferralMessageTooltipSecond":
        "हम आपके लिए 7% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की दूसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 7% तक का रिडिपॉजिट बोनस प्रदान कर रहे हैं।.",

        "FomoAppDwnld@BrandName": "फोमो7",
        "FomoApp@Online": "ऑनलाइन",
        "FomoApp@Betting": "बेटिंग ऐप",
        "FomoAppDwnld@BettingApk": "सर्वश्रेष्ठ कैसीनो और स्पोर्ट्स बेटिंग एप्प",
        "FomoAppDwnld@TheFomoAppIsAvailable": "डिजिटल युग में, फेवरेट कैसीनो खेलों का आनंद लें और फोमो7 ऑनलाइन बेटिंग एप्प के साथ खेलें। उपयोगकर्ता सहायक इंटरफेस और स्लिक नेविगेशन के साथ, फोमो7 ऐप भारत के शीर्ष बेटिंग ऐप्स में शामिल है।",
        "FomoAppDwnld@App": "एप्प",
        "FomoAppDwnld@Pages": "पेज",
        "FomoAppDwnld@ScreenshotTextOne": "700 से अधिक कैसीनो खेलों और स्पोर्ट्स बेटिंग के अलावा, रोमांचक बोनस, प्रोमोशंस, और उच्च गुणवत्ता का ग्राहक समर्थन",
        "FomoAppDwnld@ScreenshotTextTwo": "भारत के सर्वश्रेष्ठ ऑनलाइन बेटिंग ऐप पर कैसीनो गेमिंग प्रेमियों का समर्थन करता है।",
        "FomoAppDwnld@AppFeatures": "कुंजी विशेषताएँ ",
        "FomoAppDwnld@BrandNameFeature": "फोमो7",
        "FomoAppDwnld@AppOnlineApp": "ऑनलाइन बेटिंग एप्प",
        "FomoAppDwnld@KeyFeatureText": "जबकि भारत में ऑनलाइन बेटिंग ऐप्स की मांग बढ़ रही है, लेकिन कोई ऐसा असली पैसे का खेल ऐप्स नहीं है जो फोमो7 एप्प के साथ मिल सकता है। निम्नलिखित कुछ मुख्य विशेषताएं हैं फोमो7 ऑनलाइन बेटिंग एप्प की",
        "FomoAppDwnld@BenefitsStep1": "एक सुगम इंटरफेस और मुलायम नेविगेशन।",
        "FomoAppDwnld@BenefitsStep2": "दोनों दुनियां का अच्छा आनंद लें - कैसीनो खेल और स्पोर्ट्स बेटिंग।",
        "FomoAppDwnld@BenefitsStep3": "ऐप सुगमता से चलता है और केवल स्मार्टफोन उपकरण पर थोड़ी सी जगह लेता है।",
        "FomoAppDwnld@BenefitsStep4": "कोई सदस्यता या छुपी शुल्क के साथ मुफ्त में उपयोग करें।",
        "FomoAppDwnld@BenefitsStep5": "भारत में सर्वश्रेष्ठ कानूनी बेटिंग ऐप्स में से एक जो उपयोगकर्ताओं के डेटा को सुरक्षित और सुरक्षित रखता है।",
        "FomoAppDwnld@BenefitsStep6": "ग्राहक समर्थन टीम एप्प के माध्यम से 24x7 उपलब्ध है।",
        "FomoAppDwnld@HowTo": "कैसे डाउनलोड करें ",
        "FomoAppDwnld@ApkFor": " एप्प के लिए",
        "FomoAppDwnld@Android": "एंड्रॉयड?",
        "FomoAppDwnld@TechnicallyFomoApkOne": "फोमो7 ऐप में एंड्रॉयड स्मार्टफोन के लिए स्पोर्ट्स बेटिंग और विभिन्न कैसीनो खेल होते हैं। फोमो7 एंड्रॉयड ऐप को आसानी से",
        "FomoAppDwnld@TechnicallyFomoApkTwo": " डाउनलोड और इंस्टॉल करना है। फोमो7 एप्प को डाउनलोड करने के लिए निम्नलिखित कदमों का पालन करें",
        "YoloAppDwnld@WaitForTheFile": "फ़ाइल का डाउनलोड होने का इंतजार करें",
        "YoloAppDwnld@InstallTheYoloApp": "अपने स्मार्टफोन पर फोमो7 ऐप को स्थापित करें",
        "FomoAppDwnld@SattaApp": " सर्वश्रेष्ठ ऑनलाइन",
        "FomoAppDwnld@SattaAppwhy": "क्यों ",
        "FomoAppDwnld@SattaAppIndia": "सट्टा ऐप है?",
        "FomoAppDwnld@SattaAppText": "हमने आपके ऑनलाइन सट्टा एप्प के रूप में फोमो7 को चुनने के लिए शीर्ष 3 कारणों की सूची बनाई है।",
        "FomoAppDwnld@SattaTextOne": "आइए जानें कैसे डाउनलोड करें सर्वश्रेष्ठ",
        "FomoAppDwnld@SattaTextTwo": "ऑनलाइन सट्टा ऐप है।",
        "FomoAppDwnld@NumberOne": "01",
        "FomoAppDwnld@NumberTwo": "02",
        "FomoAppDwnld@NumberOneText": "आप QR कोड को स्कैन करके फोमो7 वेबसाइट के माध्यम से फोमो7 एप्प को डाउनलोड कर सकते हैं। QR कोड फोमो7 होमपेज के ऊपरी दाईं ओर पर स्थित है। अपने फ़ोन पर एप्लिकेशन के स्थापना को पूरा करने के लिए निर्देशों का पालन करें।",
        "FomoAppDwnld@NumberTwoText": "यदि आप अपने स्मार्टफोन पर फोमो7 वेबसाइट ब्राउज़ कर रहे हैं, तो फोमो7 होमपेज पर मेनू बटन पर टैप करें। डाउनलोड एंड्रॉयड एप्प पर क्लिक करें और बेट एपीके डाउनलोड फ़ाइल को स्थापित करने के लिए निर्देशों का पालन करें।",
        "FomoAppDwnld@SignUp": " एप्प साइनअप",
        "FomoAppDwnld@DepositText": "फ़र्स्ट जमा ऑन ",
        "FomoAppDwnld@SignUpTextOne": "फोमो7 एप्प पर साइन अप करना तत्काल है। पहले फोमो7 लॉगिन करने से पहले, आपको उपयोगकर्ता नाम और पासवर्ड बनाना होगा और नाम, ईमेल आईडी, मोबाइल नंबर आदि जैसे कुछ व्यक्तिगत विवरण दर्ज करने होंगे।",
        "FomoAppDwnld@SignUpTextTwo": "फोमो7 एप्प पर जमा करने के लिए, एप्प खोलें और अपने क्रेडेंशियल्स के साथ लॉग इन करें। हरा वॉलेट आइकन पर टैप करें और एप्प पर चार विकल्पों में से एक का चयन करें—फास्ट बैंक, फास्ट UPI, नियमित UPI, या नेट बैंकिंग—एप्प पर",
        "FomoAppDwnld@Bonus&Promo": "बोनस और प्रोमोशन",
        "FomoAppDwnld@BonusOn": "पर ",
        "FomoAppDwnld@PromotionTextOne": "फोमो7 एप्प पर बोहत सारे बोनस और प्रोमोशन उपलब्ध हैं। फोमो7 पर कैसीनो और स्पोर्ट्स बेटिंग बोनस प्राप्त करें ",
        "FomoAppDwnld@PromotionTextTwo": "नियमित रूप से। यहां फोमो7 पर उपलब्ध बोनस की सूची है",
        "FomoAppDwnld@PromotionTextThree": "फोमो7 ऑनलाइन कैसीनो एप्प डाउनलोड पूरा करें, जमा करते रहें,",
        "FomoAppDwnld@PromotionTextFour": "और बोनस कमाएं।",
        "FomoAppDwnld@CasinoApp": " कैसीनो ऐप",
        "FomoAppDwnld@CasinoLineTextOne": "फ़ाइल की प्रतीक्षा करें फोमो7 ऐप 700+ रोमांचक और पुरस्कृत ऑनलाइन कैसीनो गेम का घर है। कैसीनो गेम विभिन्न प्रकार के होते हैं, जैसे स्लॉट, क्रैश, पासा, व्हील गेम और भी बहुत कुछ। भारत में कई कैसीनो ऐप्स में से, फोमो7 कैसीनो अपने खेलों की विस्तृत श्रृंखला के लिए जाना जाता है। सर्वश्रेष्ठ ऑनलाइन कैसीनो मोबाइल ऐप पर डाउनलोड करने और खेलने के लिए उपलब्ध लोकप्रिय खेलों की सूची यहां दी गई है:",
        "FomoAppDwnld@CasinoLineTextFour": "फोमो7 ऑनलाइन कैसीनो ऐप डाउनलोड विकल्प है ",
        "FomoAppDwnld@CasinoLineTextFive": "मुखपृष्ठ पर उपलब्ध है.",
        "FomoAppDwnld@SportsBet": "स्पोर्ट्स बेटिंग पर ",
        "FomoAppDwnld@BettingApp": "बेटिंग एप्प",
        "FomoAppDwnld@SportsTextOne": "फोमो7 बेटिंग एप्प दुनिया भर में हो रहे खेल के इवेंट को होस्ट करता है। क्रिकेट बेटिंग ऐप विभिन्न टूर्नामेंट कवर करता है, जैसे कि IPL, BBL, ODI वर्ल्ड कप, T20 वर्ल्ड कप, और अन्य अंतरराष्ट्रीय और घरेलू टूर्नामेंट। यह भारत में सर्वश्रेष्ठ ऑनलाइन क्रिकेट बेटिंग ऐप है, जिसमें रोमांचक बेटिंग मार्केट्स और शीर्ष बेटिंग अवसर होते हैं।",
        "FomoAppDwnld@SportsTextTwo": "एप्प विभिन्न क्रिकेट बेटिंग प्रेमियों की आवश्यकताओं को पूरा करता है। लाइव क्रिकेट ऐप डाउनलोड करके, आप तत्काल ऐप डाउनलोड कर सकते हैं। क्योंकि कई सारे बोनस और रोमांचक प्रोमोशन उपलब्ध हैं।",
        "FomoAppDwnld@SportsTextThree": "भारत में सभी फुटबॉल बेटिंग एप्स में से फोमो7 सर्वश्रेष्ठ फुटबॉल बेटिंग एप्प है। फोमो7 को भारत में सर्वश्रेष्ठ फुटबॉल बेटिंग ऐप क्यों माना जाता है, क्योंकि यह विभिन्न पॉपुलर लीग्स को कवर करता है जैसे कि EPL, ला लीगा, बुंडेसलीगा, यूईएफए चैम्पियंस लीग, और अन्य। फोमो7 एप्प बेटिंग प्रेमियों को पॉपुलर फुटबॉल घटनाओं पर बेट करने की सुविधा देता है जैसे कि फीफा वर्ल्ड कप, यूईएफए यूरो कप, कोपा अमेरिका, और अधिक। यह सबसे अच्छा टेनिस बेटिंग एप्प भी है, क्योंकि यह विश्वव्यापी ग्रैंड स्लैम और एटीपी घटनाओं को कवर करता है।",
        "FomoAppDwnld@SportsTextFour": "स्पोर्ट्स बेटिंग का सबसे बड़ा लाभ यह है कि आप पूर्व-मैच बेटिंग और लाइव मैच पर बेटिंग या लाइव मैच पर बेटिंग कर सकते हैं। इसीलिए यह भारत में सर्वश्रेष्ठ स्पोर्ट्स बेटिंग ऐप के रूप में खड़ा है।",
        "FomoAppDwnld@BettingTextOne": "आईपीएल से अधिक कोई टी20 इवेंट मनाया नहीं जाता है। लीग में 70 से अधिक मैच खेले जाते हैं, क्रिकेट प्रेमियों को दो महीने के दौरान एक उत्सव का समय बिताना होता है। आईपीएल ऑनलाइन बेटिंग एप्प ने सर्वश्रेष्ठ बोनस, अद्वितीय बेटिंग बाजार, रोमांचक प्रमोशन, और बोनस प्रदान करते हुए सर्वश्रेष्ठ आईपीएल सट्टा एप्प बनाया है।",
        "FomoAppDwnld@BettingHeading": "ऑनलाइन आईपीएल बेटिंग एप्प",
        "FomoAppDwnld@FAQquestionOne": "क्या फोमो7 एप्प डाउनलोड करना आसान है?",
        "FomoAppDwnld@FAQquestionTwo": "फोमो7 ऑनलाइन बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से खेल पर बेटिंग का आनंद ले सकता हूँ?",
        "FomoAppDwnld@FAQquestionThree": "फोमो7 इंडियन क्रिकेट बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से टूर्नामेंट पर बेट कर सकता हूँ?",
        "FomoAppDwnld@FAQanswerOne": "हां, आप थोड़े ही और आसान कदमों के साथ ब्रांड वेबसाइट पर जाकर फोमो7 ऑनलाइन बेटिंग एप्प डाउनलोड कर सकते हैं।",
        "FomoAppDwnld@FAQanswerTwo": "फोमो7 इंडिया बेटिंग एप्प डाउनलोड करने के बाद, आप क्रिकेट, फुटबॉल, टेनिस, और अधिक पर बेट कर सकते हैं।",
        "FomoAppDwnld@FAQanswerThree": "फोमो7 इंडियन बेटिंग एप्प डाउनलोड करने के बाद, आप विभिन्न क्रिकेट टूर्नामेंट्स जैसे कि वर्ल्ड कप, चैंपियंस ट्रॉफी, आईसीसी टी20 वर्ल्ड कप, और लोकप्रिय टी20 घरेलू लीग्स जैसे कि आईपीएल, BBL, PSL, और अधिक पर बेट कर सकते हैं।",
        "FomoAppDwnld@CasinoHeadTextOne": "तेज",
        "FomoAppDwnld@CasinoHeadTextTwo": "सुरक्षित",
        "FomoAppDwnld@CasinoHeadTextThree": "बेहतर",
        "FomoAppDwnld@CasinoBodyTextOne": "ऑनलाइन असली पैसे जुआ एप्स के मामले में, तेज होना पहले विचार किया जाने वाला पहलू है। फोमो7 apk उपयोगकर्ता अनुकूल है और नेविगेशन और लोडिंग के लिए त्वरित है।",
        "FomoAppDwnld@CasinoBodyTextTwo": "आपकी लेन-देन हमारे प्लेटफ़ॉर्म पर सुरक्षित और सुरक्षित है। इसके अलावा, आपके पास सभी प्रसिद्ध भुगतान विधियाँ हैं।",
        "FomoAppDwnld@CasinoBodyTextThree": "चैसे ही आपके पास कैसीनो और स्पोर्ट्स बेटिंग होती है, आपका अनुभव निश्चित रूप से बेहतर होता है।",
        "FomoAppDwnld@BonusContentTitleOne": "स्वागत बोनस",
        "FomoAppDwnld@BonusContentTitleTwo": "रेफ़रल बोनस",
        "FomoAppDwnld@BonusContentTitleThree": "री-डिपॉजिट बोनस",
        "FomoAppDwnld@BonusContentBodyOne": "नए उपयोगकर्ताओं को फोमो7 कैसीनो एप्प डाउनलोड करने और उनका पहला जमा करने पर 700% वेलकम बोनस मिलता है। यह भारत में सबसे बड़ा कैसीनो एप्प डाउनलोड बोनस है।",
        "FomoAppDwnld@BonusContentBodyTwo": "रेफ़रल बोनस फोमो7 पर सबसे लोकप्रिय कैसीनो एप्प बोनस है। जब आप अपने दोस्तों को संदर्भित करते हैं, और वे फोमो7 में शामिल होते हैं, तो आप 21% रेफ़रल बोनस के लिए पात्र होते हैं।",
        "FomoAppDwnld@BonusContentBodyThree": "पहले के अलावा हर जमा पर आपको 7% री-डिपॉजिट बोनस मिलेगा। यह फोमो7 पर सफल जमा पर आवर्ती नि: शुल्क कैसीनो बोनस है।",
        "FomoAppDwnld@OnlineTextOne": "एविएटर गेम ऑनलाइन डाउनलोड",
        "FomoAppDwnld@OnlineTextTwo": "ड्रैगन टाइगर गेम डाउनलोड",
        "FomoAppDwnld@OnlineTextThree": "रूलेट गेम एप्प डाउनलोड",
        "FomoAppDwnld@OnlineTextFour": "लाइटनिंग रूलेट ऐप डाउनलोड",
        "FomoAppDwnld@FAQHeading": "पूछे जाने वाले प्रश्न",
        "FomoAppDwnld@DownloadTextOne": "ऐप को सफलतापूर्वक डाउनलोड करने के बाद अगला कदम है रजिस्ट्रेशन और ",
        "FomoAppDwnld@DownloadTextTwo": "फोमो7 पर अपनी पहली जमा राशि बनाना।",
        "FomoAppDwnld@ClickOnMenu": " ",
        "FomoAppDwnld@Menu": "मेनू ",
        "FomoAppDwnld@Options": "विकल्प पर क्लिक करें",
        "FomoAppDwnld@Download": "ऐप फ़ाइल ",
        "FomoAppDwnld@TheApp": "डाउनलोड करें",
        "FomoAppDwnld@WaitForFile": "फ़ाइल डाउनलोड होने तक  ",
        "FomoAppDwnld@ToDownload": "प्रतीक्षा करें",
        "FomoAppDwnld@InstallTheFomoApp": "अपने स्मार्टफ़ोन पर फोमो7 ऐप ",
        "FomoAppDwnld@SmartPhoneFomo": "इंस्टॉल करें",

        "PROMOTIONS@REFILLBONUS": "पुनः जमा बोनस",
        "PROMOTIONSTITLE@REFILL": "पुनः जमा बोनस",
        "USERREGPOPUP@Text": "बैंक फॉर्म भरने के लिए निम्नलिखित विवरण आवश्यक हैं",
        "LOYALTY@RULESTEXT1":
          " यदि टर्नओवर 1 महीने की अवधि में आवश्यक राशि से कम है, तो खिलाड़ी स्वचालित रूप से पूर्व स्तर पर डाउनग्रेड हो जाएगा, उदाहरण के लिए, यदि आप एक गोल्ड टियर सदस्य हैं और आप एक महीने की अवधि में 40L का टर्नओवर बनाए रखने में असमर्थ हैं, आपका लॉयल्टी प्रोग्राम टियर सिल्वर वगैरह में शिफ्ट हो जाएगा।",
        "LOYALTY@RULESTEXT2":
          " निकासी राशि के नियम और शर्तों में कोई बदलाव नहीं होगा",
        "LOYALTY@RULESTEXT3":
          " कंपनी के पास बोनस को रद्द करने का अधिकार सुरक्षित है और बिना कारण बताए क्लाइंट को इसे देने से इनकार करने का अधिकार सुरक्षित है यदि गेमिंग खाते की जांच के दौरान, गेम की निष्पक्षता के उल्लंघन का संदेह है या कंपनी द्वारा रणनीतियों का उपयोग किया जाता है। इसका विवेक दुर्भावनापूर्ण माना जाता है। विवादित स्थितियों में कंपनी के अधिकृत अधिकारियों का निर्णय अंतिम होता है.",
        "LOYALTY@RULESTEXT4":
          " नियमों के उल्लंघन, बेईमानी या अन्य धोखाधड़ी गतिविधियों के मामले में, ऐसे सभी खातों को भागीदारी से बाहर रखा जाएगा",
        "LOYALTY@RULESTEXT5": " यह ऑफ़र FOMO7 . के सामान्य नियमों के अधीन है",
        "LOYALTY@RULESTEXT6": " प्रत्येक पुन: जमा पर 2% बोनस प्रदान किया जाएगा",
        "LOYALTY@RULESTEXT7": " साप्ताहिक नुकसान पर 2% कैश बैक प्रदान किया जाएगा",
        "LOYALTY@RULESTEXT8":
          " चुने गए लॉयल्टी प्रोग्राम टियर के अनुसार अतिरिक्त कैशबैक की जानकारी दी जाएगी",
        "MANUALDEPOSIT@PleaseVerify":
        "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। FOMO7 किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",
        // "TITLE@MARQUE1": " ",
        "TITLE@betdailyMarquee": "FOMO7 - ",
        "TITLE@MARQUE": "FOMO7 -",
        "TITLE@LOYALTYTAGLINE": "वफादारी",
        "TITLE@FOMOEDGE": "फ़ोमो7 एज की खोज करें",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Yolo social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. FOMO7 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "FOMO7 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "FOMO7 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing FOMO7 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.FOMO7 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "FOMO7 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, FOMO7 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "FOMO7 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on FOMO7 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. FOMO7 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "FOMO7 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "FOMO7 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें YOLO 247 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर FOMO7 की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% बोनस का लाभ कैसे उठा सकते हैं, यह जानने के लिए नीचे पढ़ें",
        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
        "PROMOTIONS@dailyDepoBonusText":
          "FOMO7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on FOMO7.",
          "NOTE@ELITE":'FOMO7 बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
          "PROMOTIONS@CaribbeanLeagueBonus": "कैरेबियन रॉयल बोनस",
        "PROMOTIONS@EvolutionRouletteCup": "इवोल्यूशन रूलेट कप",
        "PROMOTIONS@AviatorRainPromo": "एविएटर शावर",
        "PROMOTIONS@LiveJackPot2025": "वास्तविक सौदा",
        "PROMOTIONS@InstantChallengePromo":"त्वरित चुनौती 2025"
      },
      46: {
        ..._defaultHObj,
        "FunAppDwnld@BrandName": "Fun88",
        "FunApp@Online": "ऑनलाइन",
        "FunApp@Betting": "बेटिंग ऐप",
        "FunAppDwnld@BettingApk": "सर्वश्रेष्ठ कैसीनो और स्पोर्ट्स बेटिंग एप्प",
        "FunAppDwnld@TheFunAppIsAvailable": "डिजिटल युग में, फेवरेट कैसीनो खेलों का आनंद लें और Fun88 ऑनलाइन बेटिंग एप्प के साथ खेलें। उपयोगकर्ता सहायक इंटरफेस और स्लिक नेविगेशन के साथ, Fun88 ऐप भारत के शीर्ष बेटिंग ऐप्स में शामिल है।",
        "FunAppDwnld@App": "एप्प",
        "FunAppDwnld@Pages": "पेज",
        "FunAppDwnld@ScreenshotTextOne": "700 से अधिक कैसीनो खेलों और स्पोर्ट्स बेटिंग के अलावा, रोमांचक बोनस, प्रोमोशंस, और उच्च गुणवत्ता का ग्राहक समर्थन",
        "FunAppDwnld@ScreenshotTextTwo": "भारत के सर्वश्रेष्ठ ऑनलाइन बेटिंग ऐप पर कैसीनो गेमिंग प्रेमियों का समर्थन करता है।",
        "FunAppDwnld@AppFeatures": "कुंजी विशेषताएँ ",
        "FunAppDwnld@BrandNameFeature": "Fun88",
        "FunAppDwnld@AppOnlineApp": "ऑनलाइन बेटिंग एप्प",
        "FunAppDwnld@KeyFeatureText": "जबकि भारत में ऑनलाइन बेटिंग ऐप्स की मांग बढ़ रही है, लेकिन कोई ऐसा असली पैसे का खेल ऐप्स नहीं है जो Fun88 एप्प के साथ मिल सकता है। निम्नलिखित कुछ मुख्य विशेषताएं हैं Fun88 ऑनलाइन बेटिंग एप्प की",
        "FunAppDwnld@BenefitsStep1": "एक सुगम इंटरफेस और मुलायम नेविगेशन।",
        "FunAppDwnld@BenefitsStep2": "दोनों दुनियां का अच्छा आनंद लें - कैसीनो खेल और स्पोर्ट्स बेटिंग।",
        "FunAppDwnld@BenefitsStep3": "ऐप सुगमता से चलता है और केवल स्मार्टफोन उपकरण पर थोड़ी सी जगह लेता है।",
        "FunAppDwnld@BenefitsStep4": "कोई सदस्यता या छुपी शुल्क के साथ मुफ्त में उपयोग करें।",
        "FunAppDwnld@BenefitsStep5": "भारत में सर्वश्रेष्ठ कानूनी बेटिंग ऐप्स में से एक जो उपयोगकर्ताओं के डेटा को सुरक्षित और सुरक्षित रखता है।",
        "FunAppDwnld@BenefitsStep6": "ग्राहक समर्थन टीम एप्प के माध्यम से 24x7 उपलब्ध है।",
        "FunAppDwnld@HowTo": "कैसे डाउनलोड करें ",
        "FunAppDwnld@ApkFor": " एप्प के लिए",
        "FunAppDwnld@Android": "एंड्रॉयड?",
        "FunAppDwnld@TechnicallyFunApkOne": "Fun88 ऐप में एंड्रॉयड स्मार्टफोन के लिए स्पोर्ट्स बेटिंग और विभिन्न कैसीनो खेल होते हैं। Fun88 एंड्रॉयड ऐप को आसानी से",
        "FunAppDwnld@TechnicallyFunApkTwo": " डाउनलोड और इंस्टॉल करना है। Fun88 एप्प को डाउनलोड करने के लिए निम्नलिखित कदमों का पालन करें",
        "YoloAppDwnld@WaitForTheFile": "फ़ाइल का डाउनलोड होने का इंतजार करें",
        "YoloAppDwnld@InstallTheYoloApp": "अपने स्मार्टफोन पर Fun88 ऐप को स्थापित करें",
        "FunAppDwnld@SattaApp": " सर्वश्रेष्ठ ऑनलाइन",
        "FunAppDwnld@SattaAppwhy": "क्यों ",
        "FunAppDwnld@SattaAppIndia": "सट्टा ऐप है?",
        "FunAppDwnld@SattaAppText": "हमने आपके ऑनलाइन सट्टा एप्प के रूप में Fun88 को चुनने के लिए शीर्ष 3 कारणों की सूची बनाई है।",
        "FunAppDwnld@SattaTextOne": "आइए जानें कैसे डाउनलोड करें सर्वश्रेष्ठ",
        "FunAppDwnld@SattaTextTwo": "ऑनलाइन सट्टा ऐप है।",
        "FunAppDwnld@NumberOne": "01",
        "FunAppDwnld@NumberTwo": "02",
        "FunAppDwnld@NumberOneText": "आप QR कोड को स्कैन करके Fun88 वेबसाइट के माध्यम से Fun88 एप्प को डाउनलोड कर सकते हैं। QR कोड Fun88 होमपेज के ऊपरी दाईं ओर पर स्थित है। अपने फ़ोन पर एप्लिकेशन के स्थापना को पूरा करने के लिए निर्देशों का पालन करें।",
        "FunAppDwnld@NumberTwoText": "यदि आप अपने स्मार्टफोन पर Fun88 वेबसाइट ब्राउज़ कर रहे हैं, तो Fun88 होमपेज पर मेनू बटन पर टैप करें। डाउनलोड एंड्रॉयड एप्प पर क्लिक करें और बेट एपीके डाउनलोड फ़ाइल को स्थापित करने के लिए निर्देशों का पालन करें।",
        "FunAppDwnld@SignUp": " एप्प साइनअप",
        "FunAppDwnld@DepositText": "फ़र्स्ट जमा ऑन ",
        "FunAppDwnld@SignUpTextOne": "Fun88 एप्प पर साइन अप करना तत्काल है। पहले Fun88 लॉगिन करने से पहले, आपको उपयोगकर्ता नाम और पासवर्ड बनाना होगा और नाम, ईमेल आईडी, मोबाइल नंबर आदि जैसे कुछ व्यक्तिगत विवरण दर्ज करने होंगे।",
        "FunAppDwnld@SignUpTextTwo": "Fun88 एप्प पर जमा करने के लिए, एप्प खोलें और अपने क्रेडेंशियल्स के साथ लॉग इन करें। हरा वॉलेट आइकन पर टैप करें और एप्प पर चार विकल्पों में से एक का चयन करें—फास्ट बैंक, फास्ट UPI, नियमित UPI, या नेट बैंकिंग—एप्प पर",
        "FunAppDwnld@Bonus&Promo": "बोनस और प्रोमोशन",
        "FunAppDwnld@BonusOn": "पर ",
        "FunAppDwnld@PromotionTextOne": "Fun88 एप्प पर बोहत सारे बोनस और प्रोमोशन उपलब्ध हैं। Fun88 पर कैसीनो और स्पोर्ट्स बेटिंग बोनस प्राप्त करें ",
        "FunAppDwnld@PromotionTextTwo": "नियमित रूप से। यहां Fun88 पर उपलब्ध बोनस की सूची है",
        "FunAppDwnld@PromotionTextThree": "Fun88 ऑनलाइन कैसीनो एप्प डाउनलोड पूरा करें, जमा करते रहें,",
        "FunAppDwnld@PromotionTextFour": "और बोनस कमाएं।",
        "FunAppDwnld@CasinoApp": " कैसीनो ऐप",
        "FunAppDwnld@CasinoLineTextOne": "फ़ाइल की प्रतीक्षा करें Fun88 ऐप 700+ रोमांचक और पुरस्कृत ऑनलाइन कैसीनो गेम का घर है। कैसीनो गेम विभिन्न प्रकार के होते हैं, जैसे स्लॉट, क्रैश, पासा, व्हील गेम और भी बहुत कुछ। भारत में कई कैसीनो ऐप्स में से, Fun88 कैसीनो अपने खेलों की विस्तृत श्रृंखला के लिए जाना जाता है। सर्वश्रेष्ठ ऑनलाइन कैसीनो मोबाइल ऐप पर डाउनलोड करने और खेलने के लिए उपलब्ध लोकप्रिय खेलों की सूची यहां दी गई है:",
        "FunAppDwnld@CasinoLineTextFour": "Fun88 ऑनलाइन कैसीनो ऐप डाउनलोड विकल्प है ",
        "FunAppDwnld@CasinoLineTextFive": "मुखपृष्ठ पर उपलब्ध है.",
        "FunAppDwnld@SportsBet": "स्पोर्ट्स बेटिंग पर ",
        "FunAppDwnld@BettingApp": "बेटिंग एप्प",
        "FunAppDwnld@SportsTextOne": "Fun88 बेटिंग एप्प दुनिया भर में हो रहे खेल के इवेंट को होस्ट करता है। क्रिकेट बेटिंग ऐप विभिन्न टूर्नामेंट कवर करता है, जैसे कि IPL, BBL, ODI वर्ल्ड कप, T20 वर्ल्ड कप, और अन्य अंतरराष्ट्रीय और घरेलू टूर्नामेंट। यह भारत में सर्वश्रेष्ठ ऑनलाइन क्रिकेट बेटिंग ऐप है, जिसमें रोमांचक बेटिंग मार्केट्स और शीर्ष बेटिंग अवसर होते हैं।",
        "FunAppDwnld@SportsTextTwo": "एप्प विभिन्न क्रिकेट बेटिंग प्रेमियों की आवश्यकताओं को पूरा करता है। लाइव क्रिकेट ऐप डाउनलोड करके, आप तत्काल ऐप डाउनलोड कर सकते हैं। क्योंकि कई सारे बोनस और रोमांचक प्रोमोशन उपलब्ध हैं।",
        "FunAppDwnld@SportsTextThree": "भारत में सभी फुटबॉल बेटिंग एप्स में से Fun88 सर्वश्रेष्ठ फुटबॉल बेटिंग एप्प है। Fun88 को भारत में सर्वश्रेष्ठ फुटबॉल बेटिंग ऐप क्यों माना जाता है, क्योंकि यह विभिन्न पॉपुलर लीग्स को कवर करता है जैसे कि EPL, ला लीगा, बुंडेसलीगा, यूईएफए चैम्पियंस लीग, और अन्य। Fun88 एप्प बेटिंग प्रेमियों को पॉपुलर फुटबॉल घटनाओं पर बेट करने की सुविधा देता है जैसे कि फीफा वर्ल्ड कप, यूईएफए यूरो कप, कोपा अमेरिका, और अधिक। यह सबसे अच्छा टेनिस बेटिंग एप्प भी है, क्योंकि यह विश्वव्यापी ग्रैंड स्लैम और एटीपी घटनाओं को कवर करता है।",
        "FunAppDwnld@SportsTextFour": "स्पोर्ट्स बेटिंग का सबसे बड़ा लाभ यह है कि आप पूर्व-मैच बेटिंग और लाइव मैच पर बेटिंग या लाइव मैच पर बेटिंग कर सकते हैं। इसीलिए यह भारत में सर्वश्रेष्ठ स्पोर्ट्स बेटिंग ऐप के रूप में खड़ा है।",
        "FunAppDwnld@BettingTextOne": "आईपीएल से अधिक कोई टी20 इवेंट मनाया नहीं जाता है। लीग में 70 से अधिक मैच खेले जाते हैं, क्रिकेट प्रेमियों को दो महीने के दौरान एक उत्सव का समय बिताना होता है। आईपीएल ऑनलाइन बेटिंग एप्प ने सर्वश्रेष्ठ बोनस, अद्वितीय बेटिंग बाजार, रोमांचक प्रमोशन, और बोनस प्रदान करते हुए सर्वश्रेष्ठ आईपीएल सट्टा एप्प बनाया है।",
        "FunAppDwnld@BettingHeading": "ऑनलाइन आईपीएल बेटिंग एप्प",
        "FunAppDwnld@FAQquestionOne": "क्या Fun88 एप्प डाउनलोड करना आसान है?",
        "FunAppDwnld@FAQquestionTwo": "Fun88 ऑनलाइन बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से खेल पर बेटिंग का आनंद ले सकता हूँ?",
        "FunAppDwnld@FAQquestionThree": "Fun88 इंडियन क्रिकेट बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से टूर्नामेंट पर बेट कर सकता हूँ?",
        "FunAppDwnld@FAQanswerOne": "हां, आप थोड़े ही और आसान कदमों के साथ ब्रांड वेबसाइट पर जाकर Fun88 ऑनलाइन बेटिंग एप्प डाउनलोड कर सकते हैं।",
        "FunAppDwnld@FAQanswerTwo": "Fun88 इंडिया बेटिंग एप्प डाउनलोड करने के बाद, आप क्रिकेट, फुटबॉल, टेनिस, और अधिक पर बेट कर सकते हैं।",
        "FunAppDwnld@FAQanswerThree": "Fun88 इंडियन बेटिंग एप्प डाउनलोड करने के बाद, आप विभिन्न क्रिकेट टूर्नामेंट्स जैसे कि वर्ल्ड कप, चैंपियंस ट्रॉफी, आईसीसी टी20 वर्ल्ड कप, और लोकप्रिय टी20 घरेलू लीग्स जैसे कि आईपीएल, BBL, PSL, और अधिक पर बेट कर सकते हैं।",
        "FunAppDwnld@CasinoHeadTextOne": "तेज",
        "FunAppDwnld@CasinoHeadTextTwo": "सुरक्षित",
        "FunAppDwnld@CasinoHeadTextThree": "बेहतर",
        "FunAppDwnld@CasinoBodyTextOne": "ऑनलाइन असली पैसे जुआ एप्स के मामले में, तेज होना पहले विचार किया जाने वाला पहलू है। Fun88 apk उपयोगकर्ता अनुकूल है और नेविगेशन और लोडिंग के लिए त्वरित है।",
        "FunAppDwnld@CasinoBodyTextTwo": "आपकी लेन-देन हमारे प्लेटफ़ॉर्म पर सुरक्षित और सुरक्षित है। इसके अलावा, आपके पास सभी प्रसिद्ध भुगतान विधियाँ हैं।",
        "FunAppDwnld@CasinoBodyTextThree": "चैसे ही आपके पास कैसीनो और स्पोर्ट्स बेटिंग होती है, आपका अनुभव निश्चित रूप से बेहतर होता है।",
        "FunAppDwnld@BonusContentTitleOne": "स्वागत बोनस",
        "FunAppDwnld@BonusContentTitleTwo": "रेफ़रल बोनस",
        "FunAppDwnld@BonusContentTitleThree": "रीफिल बोनस",
        "FunAppDwnld@BonusContentBodyOne": "नए उपयोगकर्ताओं को Fun88 कैसीनो एप्प डाउनलोड करने और उनका पहला जमा करने पर 388% वेलकम बोनस मिलता है। यह भारत में सबसे बड़ा कैसीनो एप्प डाउनलोड बोनस है।",
        "FunAppDwnld@BonusContentBodyTwo": "रेफ़रल बोनस Fun88 पर सबसे लोकप्रिय कैसीनो एप्प बोनस है। जब आप अपने दोस्तों को संदर्भित करते हैं, और वे Fun88 में शामिल होते हैं, तो आप 24% रेफ़रल बोनस के लिए पात्र होते हैं।",
        "FunAppDwnld@BonusContentBodyThree": "पहले के अलावा हर जमा पर आपको 5% रीफिल बोनस मिलेगा। यह Fun88 पर सफल जमा पर आवर्ती नि: शुल्क कैसीनो बोनस है।",
        "FunAppDwnld@OnlineTextOne": "एविएटर गेम ऑनलाइन डाउनलोड",
        "FunAppDwnld@OnlineTextTwo": "ड्रैगन टाइगर गेम डाउनलोड",
        "FunAppDwnld@OnlineTextThree": "रूलेट गेम एप्प डाउनलोड",
        "FunAppDwnld@OnlineTextFour": "लाइटनिंग रूलेट ऐप डाउनलोड",
        "FunAppDwnld@FAQHeading": "पूछे जाने वाले प्रश्न",
        "FunAppDwnld@DownloadTextOne": "ऐप को सफलतापूर्वक डाउनलोड करने के बाद अगला कदम है रजिस्ट्रेशन और ",
        "FunAppDwnld@DownloadTextTwo": "Fun88 पर अपनी पहली जमा राशि बनाना।",
        "FunAppDwnld@ClickOnMenu": " ",
        "FunAppDwnld@Menu": "मेनू ",
        "FunAppDwnld@Options": "विकल्प पर क्लिक करें",
        "FunAppDwnld@Download": "ऐप फ़ाइल ",
        "FunAppDwnld@TheApp": "डाउनलोड करें",
        "FunAppDwnld@WaitForFile": "फ़ाइल डाउनलोड होने तक  ",
        "FunAppDwnld@ToDownload": "प्रतीक्षा करें",
        "FunAppDwnld@InstallTheFunApp": "अपने स्मार्टफ़ोन पर Fun88 ऐप ",
        "FunAppDwnld@SmartPhoneFun": "इंस्टॉल करें",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Fun88 social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Fun88 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Fun88 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Fun88 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Fun88 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.Fun88 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Fun88 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Fun88 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Fun88 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Fun88 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Fun88 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Fun88 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Fun88 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें Fun88 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर Fun88 की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% बोनस का लाभ कैसे उठा सकते हैं, यह जानने के लिए नीचे पढ़ें",
        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
        "PROMOTIONS@dailyDepoBonusText":
          "Fun88 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Fun88.",
          "SLIDERIMAGE@TEENPATTI1": "/static/media/teenpattiHomeSliderHiFun88.jpg",
          "SLIDERIMAGE@TEENPATTI3": "/static/media/AndarBaharHPNormalHindi.jpg",
          "SLIDERIMAGE@TEENPATTI2": "/static/media/teenpatti2020HPNormalHindi.jpg",
          "COLFIRST@GAME1": "/static/media/wheelGameHPFun88Hindi.jpg",
          "COLFIRST@GAME2": "/static/media/liveDiceGameHPFun88Hindi.jpg",
          "COLFIRST@GAME3": "/static/media/lucky7HPFun88Hindi.jpg",
          "HOMEPAGE@GAME1": "/static/media/CasinoJokerBetHMFun88Hindi.jpg",
          "HOMEPAGE@GAME2": "/static/media/casinoPokerHMFun88Hindi.jpg",
          "HOMEPAGE@GAME3": "/static/media/casinoFruitRaceHMFun88Hindi.jpg",
          "HOMEPAGE@GAME4": "/static/media/andarBaharLiveHMFun88Hindi.jpg",
          "HOMEPAGE@GAME5": "/static/media/casinoRouletteHMFun88Hindi.jpg",
          "HOMEPAGE@GAME6": "/static/media/wheelBetCasinoNewHMFun88Hindi.jpg",
          "HOMEPAGE@GAME7": "/static/media/casinoBetNumberHMFun88Hindi.jpg",
          "HOMEPAGE@GAME8": "/static/media/casinoliveDiceHMFun88Hindi.jpg",
          "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGameHMFun88Hindi.jpg",
          "HOMEPAGE@GAME10": "/static/media/32casinoCardsHMFun88Hindi.jpg",
          "DASHBOARD@CHOOSECHANNEL": "एक चैनल चुनें",
          "MANUALDEPOSIT@YoloBankNew": "किसी एक बैंक विवरण की प्रतिलिपि बनाएँ और भुगतान करें",
          "LOYALTY@RULESTEXT1": "यदि उपयोगकर्ता आवश्यक मासिक टर्नओवर राशि तक पहुँचने में असफल रहते हैं, तो उन्हें स्वचालित रूप से अगले निम्न स्तर पर डाउनग्रेड कर दिया जाएगा। उदाहरण के लिए, यदि कोई उपयोगकर्ता गोल्ड टियर में है और एक महीने में 70 लाख का टर्नओवर बनाए रखने में असमर्थ है, तो उपयोगकर्ता का FUN88 लॉयल्टी टियर सिल्वर में डाउनग्रेड कर दिया जाएगा।",
          "LOYALTY@RULESTEXT2": "FUN88 गेमिंग अकाउंट की जांच के दौरान, यदि गेम की निष्पक्षता के उल्लंघन या ऐसी रणनीतियों के उपयोग का संदेह होता है, जिसे कंपनी अपनी विवेकाधिकार में दुर्भावनापूर्ण मानती है, तो बोनस रद्द करने का अधिकार सुरक्षित रखता है। विवादास्पद स्थितियों में, कंपनी के अधिकृत अधिकारियों का निर्णय अंतिम होगा।",
          "LOYALTY@RULESTEXT3": "यदि नियमों का कोई उल्लंघन, गलत खेल, या अन्य धोखाधड़ी गतिविधियां होती हैं, तो ऐसे सभी खातों को इस प्रमोशन में भाग लेने से बाहर कर दिया जाएगा।",
          "LOYALTY@RULESTEXT4": "यह प्रमोशन FUN88 के सामान्य नियमों के अधीन है।",
          "LOYALTY@RULESTEXT5": "प्रत्येक जमा पर 5% तक का रीफिल बोनस दिया जाता है।",
          "LOYALTY@RULESTEXT6": "साप्ताहिक नुकसान पर 5% तक का कैशबैक बोनस दिया जाता है।",
          "LOYALTY@RULESTEXT8": "",
          "TITLE@MARQUE": "Fun88 - लगाओ शर्त. ",
          "ProBonus@Sub": "388% पहली बार जमा बोनस प्राप्त करें",
          "ProBonus@Sub5": " अपने दोस्तों की जमा राशि का 24% देखें और प्राप्त करें",
          "MANUALDEPOSIT@PleaseVerify":
          "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। Fun88 किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",
          "Promotions@ReferralMessageTooltipThird": "हम आपके लिए 8% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की तीसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का पुनः जमा बोनस प्रदान कर रहे हैं।.",
          "Promotions@ReferralMessageTooltipSecond": "हम आपके लिए 8% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की दूसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का रिडिपॉजिट बोनस प्रदान कर रहे हैं।.",
          "Promotions@ReferralDesc": "हम आपके 8% रेफ़रल बोनस और आपके मित्र के लिए 388% स्वागत बोनस को सक्रिय करने के लिए उनकी पहली जमा राशि की प्रतीक्षा कर रहे हैं",
          "NOTE@ELITE":'Fun88 बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
          "PROMOTIONS@InstantChallengePromo":"मज़ेदार त्वरित चुनौती 2025",
          "TITLE@PREMIUM_SPORTSBOOK": "व्.से.व्",
          "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "व्.से.व् विवरण",
          "PROMOTIONS@LiveJackPot2025": "मजेदार जैकपॉट",
          "LOYALTY@BLUETIER1ST": "न्यूनतम टर्नओवर 0",
          "LOYALTY@BLUETIER2ND": "हर जमा पर 2% रीफिल बोनस",
          "LOYALTY@BLUETIER3RD": "साप्ताहिक नुकसान पर 2% कैशबैक बोनस",
          "LOYALTY@BLUETIER4TH": "",
          "LOYALTY@SILVERTIER1ST": "न्यूनतम टर्नओवर 25 लाख",
          "LOYALTY@SILVERTIER2ND": "हर जमा पर 3% रीफिल बोनस",
          "LOYALTY@SILVERTIER3RD": "साप्ताहिक नुकसान पर 3% कैशबैक बोनस",
          "LOYALTY@GOLDTIER1ST": "न्यूनतम टर्नओवर 70 लाख",
          "LOYALTY@GOLDTIER2ND": "हर जमा पर 4% रीफिल बोनस",
          "LOYALTY@GOLDTIER4": "साप्ताहिक नुकसान पर 4% कैशबैक बोनस",
          "LOYALTY@PLATIUNUM1ST": "न्यूनतम टर्नओवर 1 करोड़",
          "LOYALTY@PLATIUNUM2ND": "हर जमा पर 5% रीफिल बोनस",
          "LOYALTY@PLATIUNUM6": "साप्ताहिक नुकसान पर 5% कैशबैक बोनस",
        
        },
      47: {
        ..._defaultHObj,
        "FunAppDwnld@BrandName": "Fun88",
        "FunApp@Online": "ऑनलाइन",
        "FunApp@Betting": "बेटिंग ऐप",
        "FunAppDwnld@BettingApk": "सर्वश्रेष्ठ कैसीनो और स्पोर्ट्स बेटिंग एप्प",
        "FunAppDwnld@TheFunAppIsAvailable": "डिजिटल युग में, फेवरेट कैसीनो खेलों का आनंद लें और Fun88 ऑनलाइन बेटिंग एप्प के साथ खेलें। उपयोगकर्ता सहायक इंटरफेस और स्लिक नेविगेशन के साथ, Fun88 ऐप भारत के शीर्ष बेटिंग ऐप्स में शामिल है।",
        "FunAppDwnld@App": "एप्प",
        "FunAppDwnld@Pages": "पेज",
        "FunAppDwnld@ScreenshotTextOne": "700 से अधिक कैसीनो खेलों और स्पोर्ट्स बेटिंग के अलावा, रोमांचक बोनस, प्रोमोशंस, और उच्च गुणवत्ता का ग्राहक समर्थन",
        "FunAppDwnld@ScreenshotTextTwo": "भारत के सर्वश्रेष्ठ ऑनलाइन बेटिंग ऐप पर कैसीनो गेमिंग प्रेमियों का समर्थन करता है।",
        "FunAppDwnld@AppFeatures": "कुंजी विशेषताएँ ",
        "FunAppDwnld@BrandNameFeature": "Fun88",
        "FunAppDwnld@AppOnlineApp": "ऑनलाइन बेटिंग एप्प",
        "FunAppDwnld@KeyFeatureText": "जबकि भारत में ऑनलाइन बेटिंग ऐप्स की मांग बढ़ रही है, लेकिन कोई ऐसा असली पैसे का खेल ऐप्स नहीं है जो Fun88 एप्प के साथ मिल सकता है। निम्नलिखित कुछ मुख्य विशेषताएं हैं Fun88 ऑनलाइन बेटिंग एप्प की",
        "FunAppDwnld@BenefitsStep1": "एक सुगम इंटरफेस और मुलायम नेविगेशन।",
        "FunAppDwnld@BenefitsStep2": "दोनों दुनियां का अच्छा आनंद लें - कैसीनो खेल और स्पोर्ट्स बेटिंग।",
        "FunAppDwnld@BenefitsStep3": "ऐप सुगमता से चलता है और केवल स्मार्टफोन उपकरण पर थोड़ी सी जगह लेता है।",
        "FunAppDwnld@BenefitsStep4": "कोई सदस्यता या छुपी शुल्क के साथ मुफ्त में उपयोग करें।",
        "FunAppDwnld@BenefitsStep5": "भारत में सर्वश्रेष्ठ कानूनी बेटिंग ऐप्स में से एक जो उपयोगकर्ताओं के डेटा को सुरक्षित और सुरक्षित रखता है।",
        "FunAppDwnld@BenefitsStep6": "ग्राहक समर्थन टीम एप्प के माध्यम से 24x7 उपलब्ध है।",
        "FunAppDwnld@HowTo": "कैसे डाउनलोड करें ",
        "FunAppDwnld@ApkFor": " एप्प के लिए",
        "FunAppDwnld@Android": "एंड्रॉयड?",
        "FunAppDwnld@TechnicallyFunApkOne": "Fun88 ऐप में एंड्रॉयड स्मार्टफोन के लिए स्पोर्ट्स बेटिंग और विभिन्न कैसीनो खेल होते हैं। Fun88 एंड्रॉयड ऐप को आसानी से",
        "FunAppDwnld@TechnicallyFunApkTwo": " डाउनलोड और इंस्टॉल करना है। Fun88 एप्प को डाउनलोड करने के लिए निम्नलिखित कदमों का पालन करें",
        "YoloAppDwnld@WaitForTheFile": "फ़ाइल का डाउनलोड होने का इंतजार करें",
        "YoloAppDwnld@InstallTheYoloApp": "अपने स्मार्टफोन पर Fun88 ऐप को स्थापित करें",
        "FunAppDwnld@SattaApp": " सर्वश्रेष्ठ ऑनलाइन",
        "FunAppDwnld@SattaAppwhy": "क्यों ",
        "FunAppDwnld@SattaAppIndia": "सट्टा ऐप है?",
        "FunAppDwnld@SattaAppText": "हमने आपके ऑनलाइन सट्टा एप्प के रूप में Fun88 को चुनने के लिए शीर्ष 3 कारणों की सूची बनाई है।",
        "FunAppDwnld@SattaTextOne": "आइए जानें कैसे डाउनलोड करें सर्वश्रेष्ठ",
        "FunAppDwnld@SattaTextTwo": "ऑनलाइन सट्टा ऐप है।",
        "FunAppDwnld@NumberOne": "01",
        "FunAppDwnld@NumberTwo": "02",
        "FunAppDwnld@NumberOneText": "आप QR कोड को स्कैन करके Fun88 वेबसाइट के माध्यम से Fun88 एप्प को डाउनलोड कर सकते हैं। QR कोड Fun88 होमपेज के ऊपरी दाईं ओर पर स्थित है। अपने फ़ोन पर एप्लिकेशन के स्थापना को पूरा करने के लिए निर्देशों का पालन करें।",
        "FunAppDwnld@NumberTwoText": "यदि आप अपने स्मार्टफोन पर Fun88 वेबसाइट ब्राउज़ कर रहे हैं, तो Fun88 होमपेज पर मेनू बटन पर टैप करें। डाउनलोड एंड्रॉयड एप्प पर क्लिक करें और बेट एपीके डाउनलोड फ़ाइल को स्थापित करने के लिए निर्देशों का पालन करें।",
        "FunAppDwnld@SignUp": " एप्प साइनअप",
        "FunAppDwnld@DepositText": "फ़र्स्ट जमा ऑन ",
        "FunAppDwnld@SignUpTextOne": "Fun88 एप्प पर साइन अप करना तत्काल है। पहले Fun88 लॉगिन करने से पहले, आपको उपयोगकर्ता नाम और पासवर्ड बनाना होगा और नाम, ईमेल आईडी, मोबाइल नंबर आदि जैसे कुछ व्यक्तिगत विवरण दर्ज करने होंगे।",
        "FunAppDwnld@SignUpTextTwo": "Fun88 एप्प पर जमा करने के लिए, एप्प खोलें और अपने क्रेडेंशियल्स के साथ लॉग इन करें। हरा वॉलेट आइकन पर टैप करें और एप्प पर चार विकल्पों में से एक का चयन करें—फास्ट बैंक, फास्ट UPI, नियमित UPI, या नेट बैंकिंग—एप्प पर",
        "FunAppDwnld@Bonus&Promo": "बोनस और प्रोमोशन",
        "FunAppDwnld@BonusOn": "पर ",
        "FunAppDwnld@PromotionTextOne": "Fun88 एप्प पर बोहत सारे बोनस और प्रोमोशन उपलब्ध हैं। Fun88 पर कैसीनो और स्पोर्ट्स बेटिंग बोनस प्राप्त करें ",
        "FunAppDwnld@PromotionTextTwo": "नियमित रूप से। यहां Fun88 पर उपलब्ध बोनस की सूची है",
        "FunAppDwnld@PromotionTextThree": "Fun88 ऑनलाइन कैसीनो एप्प डाउनलोड पूरा करें, जमा करते रहें,",
        "FunAppDwnld@PromotionTextFour": "और बोनस कमाएं।",
        "FunAppDwnld@CasinoApp": " कैसीनो ऐप",
        "FunAppDwnld@CasinoLineTextOne": "फ़ाइल की प्रतीक्षा करें Fun88 ऐप 700+ रोमांचक और पुरस्कृत ऑनलाइन कैसीनो गेम का घर है। कैसीनो गेम विभिन्न प्रकार के होते हैं, जैसे स्लॉट, क्रैश, पासा, व्हील गेम और भी बहुत कुछ। भारत में कई कैसीनो ऐप्स में से, Fun88 कैसीनो अपने खेलों की विस्तृत श्रृंखला के लिए जाना जाता है। सर्वश्रेष्ठ ऑनलाइन कैसीनो मोबाइल ऐप पर डाउनलोड करने और खेलने के लिए उपलब्ध लोकप्रिय खेलों की सूची यहां दी गई है:",
        "FunAppDwnld@CasinoLineTextFour": "Fun88 ऑनलाइन कैसीनो ऐप डाउनलोड विकल्प है ",
        "FunAppDwnld@CasinoLineTextFive": "मुखपृष्ठ पर उपलब्ध है.",
        "FunAppDwnld@SportsBet": "स्पोर्ट्स बेटिंग पर ",
        "FunAppDwnld@BettingApp": "बेटिंग एप्प",
        "FunAppDwnld@SportsTextOne": "Fun88 बेटिंग एप्प दुनिया भर में हो रहे खेल के इवेंट को होस्ट करता है। क्रिकेट बेटिंग ऐप विभिन्न टूर्नामेंट कवर करता है, जैसे कि IPL, BBL, ODI वर्ल्ड कप, T20 वर्ल्ड कप, और अन्य अंतरराष्ट्रीय और घरेलू टूर्नामेंट। यह भारत में सर्वश्रेष्ठ ऑनलाइन क्रिकेट बेटिंग ऐप है, जिसमें रोमांचक बेटिंग मार्केट्स और शीर्ष बेटिंग अवसर होते हैं।",
        "FunAppDwnld@SportsTextTwo": "एप्प विभिन्न क्रिकेट बेटिंग प्रेमियों की आवश्यकताओं को पूरा करता है। लाइव क्रिकेट ऐप डाउनलोड करके, आप तत्काल ऐप डाउनलोड कर सकते हैं। क्योंकि कई सारे बोनस और रोमांचक प्रोमोशन उपलब्ध हैं।",
        "FunAppDwnld@SportsTextThree": "भारत में सभी फुटबॉल बेटिंग एप्स में से Fun88 सर्वश्रेष्ठ फुटबॉल बेटिंग एप्प है। Fun88 को भारत में सर्वश्रेष्ठ फुटबॉल बेटिंग ऐप क्यों माना जाता है, क्योंकि यह विभिन्न पॉपुलर लीग्स को कवर करता है जैसे कि EPL, ला लीगा, बुंडेसलीगा, यूईएफए चैम्पियंस लीग, और अन्य। Fun88 एप्प बेटिंग प्रेमियों को पॉपुलर फुटबॉल घटनाओं पर बेट करने की सुविधा देता है जैसे कि फीफा वर्ल्ड कप, यूईएफए यूरो कप, कोपा अमेरिका, और अधिक। यह सबसे अच्छा टेनिस बेटिंग एप्प भी है, क्योंकि यह विश्वव्यापी ग्रैंड स्लैम और एटीपी घटनाओं को कवर करता है।",
        "FunAppDwnld@SportsTextFour": "स्पोर्ट्स बेटिंग का सबसे बड़ा लाभ यह है कि आप पूर्व-मैच बेटिंग और लाइव मैच पर बेटिंग या लाइव मैच पर बेटिंग कर सकते हैं। इसीलिए यह भारत में सर्वश्रेष्ठ स्पोर्ट्स बेटिंग ऐप के रूप में खड़ा है।",
        "FunAppDwnld@BettingTextOne": "आईपीएल से अधिक कोई टी20 इवेंट मनाया नहीं जाता है। लीग में 70 से अधिक मैच खेले जाते हैं, क्रिकेट प्रेमियों को दो महीने के दौरान एक उत्सव का समय बिताना होता है। आईपीएल ऑनलाइन बेटिंग एप्प ने सर्वश्रेष्ठ बोनस, अद्वितीय बेटिंग बाजार, रोमांचक प्रमोशन, और बोनस प्रदान करते हुए सर्वश्रेष्ठ आईपीएल सट्टा एप्प बनाया है।",
        "FunAppDwnld@BettingHeading": "ऑनलाइन आईपीएल बेटिंग एप्प",
        "FunAppDwnld@FAQquestionOne": "क्या Fun88 एप्प डाउनलोड करना आसान है?",
        "FunAppDwnld@FAQquestionTwo": "Fun88 ऑनलाइन बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से खेल पर बेटिंग का आनंद ले सकता हूँ?",
        "FunAppDwnld@FAQquestionThree": "Fun88 इंडियन क्रिकेट बेटिंग एप्प डाउनलोड करने के बाद मैं कौन-कौन से टूर्नामेंट पर बेट कर सकता हूँ?",
        "FunAppDwnld@FAQanswerOne": "हां, आप थोड़े ही और आसान कदमों के साथ ब्रांड वेबसाइट पर जाकर Fun88 ऑनलाइन बेटिंग एप्प डाउनलोड कर सकते हैं।",
        "FunAppDwnld@FAQanswerTwo": "Fun88 इंडिया बेटिंग एप्प डाउनलोड करने के बाद, आप क्रिकेट, फुटबॉल, टेनिस, और अधिक पर बेट कर सकते हैं।",
        "FunAppDwnld@FAQanswerThree": "Fun88 इंडियन बेटिंग एप्प डाउनलोड करने के बाद, आप विभिन्न क्रिकेट टूर्नामेंट्स जैसे कि वर्ल्ड कप, चैंपियंस ट्रॉफी, आईसीसी टी20 वर्ल्ड कप, और लोकप्रिय टी20 घरेलू लीग्स जैसे कि आईपीएल, BBL, PSL, और अधिक पर बेट कर सकते हैं।",
        "FunAppDwnld@CasinoHeadTextOne": "तेज",
        "FunAppDwnld@CasinoHeadTextTwo": "सुरक्षित",
        "FunAppDwnld@CasinoHeadTextThree": "बेहतर",
        "FunAppDwnld@CasinoBodyTextOne": "ऑनलाइन असली पैसे जुआ एप्स के मामले में, तेज होना पहले विचार किया जाने वाला पहलू है। Fun88 apk उपयोगकर्ता अनुकूल है और नेविगेशन और लोडिंग के लिए त्वरित है।",
        "FunAppDwnld@CasinoBodyTextTwo": "आपकी लेन-देन हमारे प्लेटफ़ॉर्म पर सुरक्षित और सुरक्षित है। इसके अलावा, आपके पास सभी प्रसिद्ध भुगतान विधियाँ हैं।",
        "FunAppDwnld@CasinoBodyTextThree": "चैसे ही आपके पास कैसीनो और स्पोर्ट्स बेटिंग होती है, आपका अनुभव निश्चित रूप से बेहतर होता है।",
        "FunAppDwnld@BonusContentTitleOne": "स्वागत बोनस",
        "FunAppDwnld@BonusContentTitleTwo": "रेफ़रल बोनस",
        "FunAppDwnld@BonusContentTitleThree": "रीफिल बोनस",
        "FunAppDwnld@BonusContentBodyOne": "नए उपयोगकर्ताओं को Fun88 कैसीनो एप्प डाउनलोड करने और उनका पहला जमा करने पर 388% वेलकम बोनस मिलता है। यह भारत में सबसे बड़ा कैसीनो एप्प डाउनलोड बोनस है।",
        "FunAppDwnld@BonusContentBodyTwo": "रेफ़रल बोनस Fun88 पर सबसे लोकप्रिय कैसीनो एप्प बोनस है। जब आप अपने दोस्तों को संदर्भित करते हैं, और वे Fun88 में शामिल होते हैं, तो आप 24% रेफ़रल बोनस के लिए पात्र होते हैं।",
        "FunAppDwnld@BonusContentBodyThree": "पहले के अलावा हर जमा पर आपको 5% रीफिल बोनस मिलेगा। यह Fun88 पर सफल जमा पर आवर्ती नि: शुल्क कैसीनो बोनस है।",
        "FunAppDwnld@OnlineTextOne": "एविएटर गेम ऑनलाइन डाउनलोड",
        "FunAppDwnld@OnlineTextTwo": "ड्रैगन टाइगर गेम डाउनलोड",
        "FunAppDwnld@OnlineTextThree": "रूलेट गेम एप्प डाउनलोड",
        "FunAppDwnld@OnlineTextFour": "लाइटनिंग रूलेट ऐप डाउनलोड",
        "FunAppDwnld@FAQHeading": "पूछे जाने वाले प्रश्न",
        "FunAppDwnld@DownloadTextOne": "ऐप को सफलतापूर्वक डाउनलोड करने के बाद अगला कदम है रजिस्ट्रेशन और ",
        "FunAppDwnld@DownloadTextTwo": "Fun88 पर अपनी पहली जमा राशि बनाना।",
        "FunAppDwnld@ClickOnMenu": " ",
        "FunAppDwnld@Menu": "मेनू ",
        "FunAppDwnld@Options": "विकल्प पर क्लिक करें",
        "FunAppDwnld@Download": "ऐप फ़ाइल ",
        "FunAppDwnld@TheApp": "डाउनलोड करें",
        "FunAppDwnld@WaitForFile": "फ़ाइल डाउनलोड होने तक  ",
        "FunAppDwnld@ToDownload": "प्रतीक्षा करें",
        "FunAppDwnld@InstallTheFunApp": "अपने स्मार्टफ़ोन पर Fun88 ऐप ",
        "FunAppDwnld@SmartPhoneFun": "इंस्टॉल करें",
        "Funkytime@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "Funkytime@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "Funkytime@GeneralTermsConditionThird":
          "Winners will be decided by Evolution and announced in the week after the promo within 3 working days on Fun88 social media handles ( Instagram & telegram)",
        "Funkytime@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "Funkytime@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Fun88 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "Funkytime@GeneralTermsConditionSixth":
          "Fun88 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "Funkytime@GeneralTermsConditionSeventh":
          "Fun88 General Terms & Conditions apply",

        "DailyDepositBonus@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing Fun88 members.",
        "DailyDepositBonus@GeneralTermsConditionSecond":
          "This bonus amount will be capped at INR 7,000",
        "DailyDepositBonus@GeneralTermsConditionThird":
          "Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).",
        "DailyDepositBonus@GeneralTermsConditionFourth":
          "Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.Fun88 reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.",
        "DailyDepositBonus@GeneralTermsConditionFifth":
          "This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.",
        "DailyDepositBonus@GeneralTermsConditionSixth":
          "Fun88 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.",
        "DailyDepositBonus@GeneralTermsConditionSeventh":
          "In the event, any fraudulent activity of the player(s) has been identified, Fun88 reserves the right to withhold your funds and reject your withdrawals.",
        "DailyDepositBonus@GeneralTermsConditionEighth":
          "Fun88 General Terms & Conditions apply.",

        "firstbet@GeneralTermsConditionFirst":
          "This promotion is valid for all new & existing players.",
        "firstbet@GeneralTermsConditionSecond":
          "The winners of each week will remain eligible for prizes in the following weeks as well.",
        "firstbet@GeneralTermsConditionThird":
          "Winners will be announced in the week after the promo within 3 working days on Fun88 social media handles( Instagram & telegram)",
        "firstbet@GeneralTermsConditionFourth":
          "Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.",
        "firstbet@GeneralTermsConditionFifth":
          "Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. Fun88 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.",
        "firstbet@GeneralTermsConditionSixth":
          "Fun88 & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.",
        "firstbet@GeneralTermsConditionSeventh":
          "Fun88 General Terms & Conditions apply",

        "PROMOTIONSWELCOME@TERMS6":
          "टर्नओवर अमाउंट  की गणना 'वैध टर्नओवर' है और वैध टर्नओवर का सूत्र निम्नलिखित है: |पी एंड एल| के आपके निरपेक्ष मूल्य और कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | वैध टर्नओवर की गणना “मार्केट” खेल के लिए है और “राउंड” कैसीनो  के लिए है | तो आप खेल में इक “मार्केट”  में कई दांव लगा सकते हैं या कैसीनो के एक दौर में कई दांव लगा सकते हैं, लेकिन कुल शर्त राशि का अनुपात 50% से अधिक या उसके बराबर होना चाहिए | *कृपया ध्यान दें कि स्पोर्टस  एक्सचेंज मे  Betfair मे दिए गए मैच ऑड्स और मल्टीपल बुकमेकर्स जो एक्सचेंज का हिसा है उनको एक “मार्केट” माना जाएगा।",

        "PROMOTIONSWELCOME@TERMS4":
          " बोनस अमाउंट को 24 गुना से अधिक RO किया जाना चाहिए| टर्नओवर जहाँ भी जनरेट होगा जिस बी प्लेटफार्म पर उसका गणना टर्नओवर आवश्यकता पर किया जाए गा.",

        "PROMOTIONSWELCOME@ROEXMPLE3":
          "3.आप 5 ₹  जीत जाते हैं और आपकी शर्त राशि  है 100 ₹ तो वैध RO= (|5|/100) = 5% इसलिए आपका वैध RO = 0 ₹ क्यूँ की  आपका वैध RO 50% से कम है",

        "PROMOTIONSWELCOME@ROREQ":
          "दूसरेशब्दोंमें, बेट बोनस रोलओवर आवश्यकताओंकेलिए योगदान करनेकेलिए कम सेकम दशमलव 1.50 केऑड्स केसाथ होनी चाहिए।",

        "PROMOTIONSWELCOME@FIRSTLINE":
          "हमें Fun88 पर इस बात क़ि खुशी हैं क़ि आपने हम पर भरोसा करा और हमारे प्लेटफॉर्म पर अपना पहला डिपॉजिट करने का फैसला किया हैं । हम अपने ग्राहकों के भरोसे का मान करते हैं और हमे आपको सूचित करते हुए अतियंत  ख़ुशी हो रही है की आपके पहले डिपाजिट पर Fun88 की ओर  से हम आपको 400% बोनस देना चाहते हैं  | अब आप अपने  पहले  डिपाजिट पर 400% बोनस का लाभ कैसे उठा सकते हैं, यह जानने के लिए नीचे पढ़ें",
        "PROMOTIONSWELCOME@TERMS5":
          "टर्नओवर आवश्यकता को आपके पहले डिपाजिट के 7 दिनों के भीतर पूरा किया जाना चाहिए यदि टर्नओवर की आवश्यकता 7 दिनों के भीतर पूरी नहीं होती है तो बोनस समाप्त हो जाएगा।.",
        "PROMOTIONS@dailyDepoBonusText":
          "Fun88 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Fun88.",
          "SLIDERIMAGE@TEENPATTI1": "/static/media/teenpattiHomeSliderHiFun88.jpg",
          "SLIDERIMAGE@TEENPATTI3": "/static/media/AndarBaharHPNormalHindi.jpg",
          "SLIDERIMAGE@TEENPATTI2": "/static/media/teenpatti2020HPNormalHindi.jpg",
          "COLFIRST@GAME1": "/static/media/wheelGameHPFun88Hindi.jpg",
          "COLFIRST@GAME2": "/static/media/liveDiceGameHPFun88Hindi.jpg",
          "COLFIRST@GAME3": "/static/media/lucky7HPFun88Hindi.jpg",
          "HOMEPAGE@GAME1": "/static/media/CasinoJokerBetHMFun88Hindi.jpg",
          "HOMEPAGE@GAME2": "/static/media/casinoPokerHMFun88Hindi.jpg",
          "HOMEPAGE@GAME3": "/static/media/casinoFruitRaceHMFun88Hindi.jpg",
          "HOMEPAGE@GAME4": "/static/media/andarBaharLiveHMFun88Hindi.jpg",
          "HOMEPAGE@GAME5": "/static/media/casinoRouletteHMFun88Hindi.jpg",
          "HOMEPAGE@GAME6": "/static/media/wheelBetCasinoNewHMFun88Hindi.jpg",
          "HOMEPAGE@GAME7": "/static/media/casinoBetNumberHMFun88Hindi.jpg",
          "HOMEPAGE@GAME8": "/static/media/casinoliveDiceHMFun88Hindi.jpg",
          "HOMEPAGE@GAME9": "/static/media/blackJackCasinoGameHMFun88Hindi.jpg",
          "HOMEPAGE@GAME10": "/static/media/32casinoCardsHMFun88Hindi.jpg",
          "DASHBOARD@CHOOSECHANNEL": "एक चैनल चुनें",
          "MANUALDEPOSIT@YoloBankNew": "किसी एक बैंक विवरण की प्रतिलिपि बनाएँ और भुगतान करें",
          "LOYALTY@RULESTEXT1": "यदि उपयोगकर्ता आवश्यक मासिक टर्नओवर राशि तक पहुँचने में असफल रहते हैं, तो उन्हें स्वचालित रूप से अगले निम्न स्तर पर डाउनग्रेड कर दिया जाएगा। उदाहरण के लिए, यदि कोई उपयोगकर्ता गोल्ड टियर में है और एक महीने में 70 लाख का टर्नओवर बनाए रखने में असमर्थ है, तो उपयोगकर्ता का FUN88 लॉयल्टी टियर सिल्वर में डाउनग्रेड कर दिया जाएगा।",
          "LOYALTY@RULESTEXT2": "FUN88 गेमिंग अकाउंट की जांच के दौरान, यदि गेम की निष्पक्षता के उल्लंघन या ऐसी रणनीतियों के उपयोग का संदेह होता है, जिसे कंपनी अपनी विवेकाधिकार में दुर्भावनापूर्ण मानती है, तो बोनस रद्द करने का अधिकार सुरक्षित रखता है। विवादास्पद स्थितियों में, कंपनी के अधिकृत अधिकारियों का निर्णय अंतिम होगा।",
          "LOYALTY@RULESTEXT3": "यदि नियमों का कोई उल्लंघन, गलत खेल, या अन्य धोखाधड़ी गतिविधियां होती हैं, तो ऐसे सभी खातों को इस प्रमोशन में भाग लेने से बाहर कर दिया जाएगा।",
          "LOYALTY@RULESTEXT4": "यह प्रमोशन FUN88 के सामान्य नियमों के अधीन है।",
          "LOYALTY@RULESTEXT5": "प्रत्येक जमा पर 5% तक का रीफिल बोनस दिया जाता है।",
          "LOYALTY@RULESTEXT6": "साप्ताहिक नुकसान पर 5% तक का कैशबैक बोनस दिया जाता है।",
          "LOYALTY@RULESTEXT8": "",
          "TITLE@MARQUE": "Fun88 - लगाओ शर्त. ",
          "ProBonus@Sub": "388% पहली बार जमा बोनस प्राप्त करें",
          "ProBonus@Sub5": " अपने दोस्तों की जमा राशि का 24% देखें और प्राप्त करें",
          "MANUALDEPOSIT@PleaseVerify":
          "कृपया बैंक हस्तांतरण जमा करने से पहले भुगतान विवरण सत्यापित करें क्योंकि वे समय-समय पर बदल सकते हैं। Fun88 किसी पुराने/निष्क्रिय खाते से किए गए किसी भी लेनदेन के लिए जिम्मेदार नहीं होगा।",
          "Promotions@ReferralMessageTooltipThird": "हम आपके लिए 8% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की तीसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का पुनः जमा बोनस प्रदान कर रहे हैं।.",
          "Promotions@ReferralMessageTooltipSecond": "हम आपके लिए 8% रेफ़रल बोनस को सक्रिय करने के लिए आपके मित्र की दूसरी जमा राशि की प्रतीक्षा कर रहे हैं और आपके मित्र को 5% तक का रिडिपॉजिट बोनस प्रदान कर रहे हैं।.",
          "Promotions@ReferralDesc": "हम आपके 8% रेफ़रल बोनस और आपके मित्र के लिए 388% स्वागत बोनस को सक्रिय करने के लिए उनकी पहली जमा राशि की प्रतीक्षा कर रहे हैं",
          "NOTE@ELITE":'Fun88 बिना किसी पूर्व सूचना के किसी भी समय इन शर्तों को बदलने/संशोधित करने का अधिकार सुरक्षित रखता है',
          "PROMOTIONS@InstantChallengePromo":"मज़ेदार त्वरित चुनौती 2025",
          "TITLE@PREMIUM_SPORTSBOOK": "व्.से.व्",
          "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT": "व्.से.व् विवरण",
          "PROMOTIONS@LiveJackPot2025": "मजेदार जैकपॉट",
          "LOYALTY@BLUETIER1ST": "न्यूनतम टर्नओवर 0",
          "LOYALTY@BLUETIER2ND": "हर जमा पर 2% रीफिल बोनस",
          "LOYALTY@BLUETIER3RD": "साप्ताहिक नुकसान पर 2% कैशबैक बोनस",
          "LOYALTY@BLUETIER4TH": "",
          "LOYALTY@SILVERTIER1ST": "न्यूनतम टर्नओवर 25 लाख",
          "LOYALTY@SILVERTIER2ND": "हर जमा पर 3% रीफिल बोनस",
          "LOYALTY@SILVERTIER3RD": "साप्ताहिक नुकसान पर 3% कैशबैक बोनस",
          "LOYALTY@GOLDTIER1ST": "न्यूनतम टर्नओवर 70 लाख",
          "LOYALTY@GOLDTIER2ND": "हर जमा पर 4% रीफिल बोनस",
          "LOYALTY@GOLDTIER4": "साप्ताहिक नुकसान पर 4% कैशबैक बोनस",
          "LOYALTY@PLATIUNUM1ST": "न्यूनतम टर्नओवर 1 करोड़",
          "LOYALTY@PLATIUNUM2ND": "हर जमा पर 5% रीफिल बोनस",
          "LOYALTY@PLATIUNUM6": "साप्ताहिक नुकसान पर 5% कैशबैक बोनस",
        
        }
    };
  }

  return obj[brandId] || {};
}

export default withSystem;
