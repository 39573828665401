import React, { useEffect, useState } from 'react'
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import Intl from 'src/components/common/Intl';
import AndroidIcon from 'src/components/icon/AndroidIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import AppBenefits from 'src/components/appBenefits';
import DownldAndInstallStepsContent from 'src/components/appDownldAndInstallSteps';
import Footer from 'src/components/footer/footer';

//Images
import { FomoStep1Icon } from '../../components/icon/Step1Icon';
import { FomoStep2Icon } from '../../components/icon/Step2Icon';
import { FomoStep3Icon } from '../../components/icon/Step3Icon';
import { FomoStep4Icon } from '../../components/icon/Step4Icon';
import { FomoStep5Icon } from '../../components/icon/Step5Icon';
import { FomoStep6Icon } from '../../components/icon/Step6Icon';
import SEOContents from 'src/components/SEOContents';
import { SRTM } from 'src/util/sportradarEvent';
import FomoAppDownloadBg from 'src/components/icon/FomoAppDownloadBg';
import { Divider } from '../promotions/FomoWelcomeBonus';
import AccordionOpenIcon, { AccordionCloseIcon } from 'src/components/icon/AccordionIcon';
import { Helmet } from "react-helmet";

const structuredData = (brandId) => {
    let domainURL = 'https://www.fomo7.net';
    if (brandId === 40) {
        domainURL = 'https://www.fomo7.pro';
    }

    const data = {
        "@context":"https://schema.org",
        "@type":"BreadcrumbList",
        "itemListElement":[
            {
                "@type":"ListItem",
                "item":`${domainURL}`,
                "name":"Homepage","position":1
            },{
                "@type":"ListItem",
                "item":`${domainURL}/app-download`,
                "name":"Download App",
                "position":2
            }],
            "name":"Breadcrumbs"
    }

    return JSON.stringify(data)
}

function FomoAppDownload(props) {
    const { app: {
        brandId
    }, theme } = props;

    const [apkUrl, setApkUrl] = useState('')
    const [accordionItem, setAccordionItem] = useState(null);

    const downloadAndInsatallData = [
        {
            id: 1,
            imgsrc: theme.image.StepOneMobImgOne,
            icon: theme.image.BetdailyAppDownloadStepOne,
            title: <span><Intl langKey="FomoAppDwnld@ClickOnMenu" /><span className='customColor'><Intl langKey="FomoAppDwnld@Menu" /></span><Intl langKey="FomoAppDwnld@Options" /></span>,
            desc: <Intl langKey="YoloAppDwnld@ClickTheMenu" />
        },
        {
            id: 2,
            imgsrc: theme.image.StepTwoMobImg,
            icon: theme.image.BetdailyAppDownloadStepTwo,
            title: <span><Intl langKey="FomoAppDwnld@Download" /><span className='customColor'><Intl langKey="FomoAppDwnld@TheApp" /></span></span>,
            desc: <Intl langKey="YoloAppDwnld@ClickTheDownldAndroid" />
        },
        {
            id: 3,
            imgsrc: theme.image.StepThreeMobImg,
            icon: theme.image.BetdailyAppDownloadStepThree,
            title: <span><Intl langKey="FomoAppDwnld@WaitForFile" /><span className='customColor'><Intl langKey="FomoAppDwnld@ToDownload" /></span></span>,
            desc: <Intl langKey="YoloAppDwnld@OnceTheProcessIs" />
        },
        {
            id: 4,
            imgsrc: theme.image.StepFourthMobImg,
            icon: theme.image.BetdailyAppDownloadStepFour,
            title: <span><Intl langKey="FomoAppDwnld@InstallTheFomoApp" /><span className='customColor'><Intl langKey="FomoAppDwnld@SmartPhoneFomo" /></span></span>,
            desc: <Intl langKey="YoloAppDwnld@WaitForTheInsatalationprocess" />
        }
    ]

    const appBenefitData = [
        {
            id: 1,
            icon: <FomoStep1Icon />,
            titleNumber: "01",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep1" />,
        },
        {
            id: 2,
            icon: <FomoStep2Icon />,
            titleNumber: "02",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep2" />,
        },
        {
            id: 3,
            icon: <FomoStep3Icon />,
            titleNumber: "03",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep3" />,
        },
        {
            id: 4,
            icon: <FomoStep4Icon />,
            titleNumber: "04",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep4" />,
        },
        {
            id: 5,
            icon: <FomoStep6Icon />,
            titleNumber: "05",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep5" />,
        },
        {
            id: 6,
            icon: <FomoStep5Icon />,
            titleNumber: "06",
            desc: <Intl langKey="FomoAppDwnld@BenefitsStep6" />,
        }
    ]

    const faqData = [
        {
            id: 1,
            titleNumber: "1",
            question: <Intl langKey="FomoAppDwnld@FAQquestionOne" />,
            answer: <Intl langKey="FomoAppDwnld@FAQanswerOne" />
        },
        {
            id: 2,
            titleNumber: "2",
            question: <Intl langKey="FomoAppDwnld@FAQquestionTwo" />,
            answer: <Intl langKey="FomoAppDwnld@FAQanswerTwo" />
        },
        {
            id: 3,
            titleNumber: "3",
            question: <Intl langKey="FomoAppDwnld@FAQquestionThree" />,
            answer: <Intl langKey="FomoAppDwnld@FAQanswerThree" />
        }
    ]

    useEffect(() => {
        SRTM.init(brandId)
    }, [])

    const toggleAccordion = (id: any) => {
        if (accordionItem === id) {
            return setAccordionItem(null);
        }
        setAccordionItem(id);
    }


    useEffect(() => {
        switch (brandId) {
            case 39:
                setApkUrl("https://app-fomo7-prod.s3.eu-west-1.amazonaws.com/fomo7.apk");
                break;
            case 40:
                setApkUrl("https://app-fomo7club-prod.s3.eu-west-1.amazonaws.com/Fomo7club.apk");
                break;
            default:
                setApkUrl("https://app-fomo7-prod.s3.eu-west-1.amazonaws.com/fomo7.apk");
        }
    }, [brandId])

    const brandApp = (brandId === 39 || brandId === 40) ? 'Fomo app' : '';

    return (
        <>
        <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(brandId)
                    }

                </script>
            </Helmet>
            <MainWrapper>
                <SEOContents pageName="appDownload" />
                <BannerSection>
                    <BanerLeftContent>
                        <span className='customColor'><Intl langKey="FomoAppDwnld@BrandName" /></span> <Intl langKey="FomoApp@Online" /><br></br><Intl langKey="FomoApp@Betting" />
                    </BanerLeftContent>
                    <AnimateImg className="AnimateBD">
                        <AnimateImgSecond>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AnimationImgSecond}`} effect="none" placeholder={<PlaceholderImage />} alt={brandApp} />
                        </AnimateImgSecond>
                            <FomoAppDownloadBg />
                        <AnimateImgThired>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AnimationImgThired}`} effect="none" placeholder={<PlaceholderImage />} alt={brandApp} />
                        </AnimateImgThired>
                    </AnimateImg>
                    <BanerRightContent>
                        <FlexWrap><Intl langKey="FomoAppDwnld@BettingApk" /></FlexWrap>
                        <SmallText><Intl langKey="FomoAppDwnld@TheFomoAppIsAvailable" /></SmallText>

                        <DownloadBtn href={apkUrl}>
                            <AndroidIcon />
                            <Intl langKey="YoloAppDwnld@Download" />
                        </DownloadBtn>

                    </BanerRightContent>
                </BannerSection>

                <ScreenShotSection>
                    <TextCenterWrap>
                        <FlexWrap><Intl langKey="FomoAppDwnld@App" /><span className='customColor'><Intl langKey="FomoAppDwnld@Pages" /> </span></FlexWrap>
                        <SmallText><Intl langKey="FomoAppDwnld@ScreenshotTextOne" /></SmallText>
                        <SmallText className='AppText'><Intl langKey="FomoAppDwnld@ScreenshotTextTwo" /></SmallText>
                    </TextCenterWrap>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppScreenshotsImg}`} effect="none" placeholder={<PlaceholderImage />} alt={brandApp} />
                </ScreenShotSection>

                <ScanTheQRSection>
                    <ScanTheQRWrapper>
                        <BlockContent>
                            <span className='customColor'><Intl langKey="YoloAppDwnld@ScanTheQr" /></span>
                            <p><Intl langKey="YoloAppDwnld@AndDownloadTheApp" /></p>
                            <DownloadBtn href={apkUrl}>
                                <AndroidIcon />
                                <Intl langKey="YoloAppDwnld@Download" />
                            </DownloadBtn>
                        </BlockContent>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppQrCode}`} effect="none" placeholder={<PlaceholderImage />} alt={brandApp} />
                    </ScanTheQRWrapper>
                </ScanTheQRSection>

                <AppBenefitsWrapper>
                    <TextLeftWrap>
                        <span><Intl langKey="FomoAppDwnld@AppFeatures" /><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span></span>
                        <Intl langKey="FomoAppDwnld@AppOnlineApp" />
                        <SmallText><Intl langKey="FomoAppDwnld@KeyFeatureText" /></SmallText>
                    </TextLeftWrap>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MobileUserImg}`} effect="none" placeholder={<PlaceholderImage />} />
                </AppBenefitsWrapper>

                <AppBenefitsSteps>
                    {appBenefitData.map((appbenefit) => (
                        <AppBenefits
                            key={appbenefit.id}
                            id={appbenefit.id}
                            icon={appbenefit.icon}
                            titleNumber={appbenefit.titleNumber}
                            desc={appbenefit.desc}
                        />
                    ))}
                </AppBenefitsSteps>

                <AppSattaOnline>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h4><Intl langKey="FomoAppDwnld@SattaAppwhy" /><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span><Intl langKey="FomoAppDwnld@SattaApp" /></h4>
                            <h4><Intl langKey="FomoAppDwnld@SattaAppIndia" /></h4>
                        </FlexWrap>
                        <SmallText><Intl langKey="FomoAppDwnld@SattaAppText" /></SmallText>
                    </TextCenterWrap>
                    <AppSattaWrapper>
                        <BorderWrapper>
                            <BonusContent>
                                <OnlineTitle>
                                    <h1><Intl langKey="FomoAppDwnld@CasinoHeadTextOne" /></h1>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadONlineImgOneFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </OnlineTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@CasinoBodyTextOne" /></BonusBody>
                            </BonusContent>
                            <BonusContent>
                                <OnlineTitle>
                                    <h1><Intl langKey="FomoAppDwnld@CasinoHeadTextTwo" /></h1>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadONlineImgTwoFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </OnlineTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@CasinoBodyTextTwo" /></BonusBody>
                            </BonusContent>
                            <BonusContent>
                                <OnlineTitle>
                                    <h1><Intl langKey="FomoAppDwnld@CasinoHeadTextThree" /></h1>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadONlineImgThreeFomo}`} effect="none" placeholder={<PlaceholderImage />} className="lastImg" />
                                </OnlineTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@CasinoBodyTextThree" /></BonusBody>
                            </BonusContent>
                        </BorderWrapper>
                        <FlexWrap className='twoRow'>
                            <SmallText><Intl langKey="FomoAppDwnld@SattaTextOne" /></SmallText>
                            <SmallText><Intl langKey="FomoAppDwnld@SattaTextTwo" /></SmallText>
                        </FlexWrap>
                    </AppSattaWrapper>
                </AppSattaOnline>
                <Divider></Divider>
                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h2><Intl langKey="FomoAppDwnld@HowTo" /><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span><Intl langKey="FomoAppDwnld@ApkFor" /></h2>
                            <h2><Intl langKey="FomoAppDwnld@Android" /></h2>
                        </FlexWrap>
                        <SmallText><Intl langKey="FomoAppDwnld@TechnicallyFomoApkOne" /></SmallText>
                        <SmallText className='textAlignCenter'><Intl langKey="FomoAppDwnld@TechnicallyFomoApkTwo" /></SmallText>
                    </TextCenterWrap>
                    <DownStepsWrapper>
                        <DownSteps>
                            <h1><Intl langKey="FomoAppDwnld@NumberOne" /></h1>
                            <p><Intl langKey="FomoAppDwnld@NumberOneText" /></p>
                        </DownSteps>
                        <DownSteps>
                            <h1><Intl langKey="FomoAppDwnld@NumberTwo" /></h1>
                            <p><Intl langKey="FomoAppDwnld@NumberTwoText" /></p>
                        </DownSteps>
                    </DownStepsWrapper>
                </DownAndInstall>
                <DownldAndInstallStepsWrapper>
                    {downloadAndInsatallData.map((dwnldInstall) => (
                        <DownldAndInstallStepsContent
                            key={dwnldInstall.id}
                            id={dwnldInstall.id}
                            imgsrc={dwnldInstall.imgsrc}
                            icon={dwnldInstall.icon}
                            title={dwnldInstall.title}
                            desc={dwnldInstall.desc}
                            apkUrl={apkUrl}
                        />
                    ))}
                </DownldAndInstallStepsWrapper>
                <DownloadEnd>
                    <TextCenterWrap>
                        <SmallText><Intl langKey="FomoAppDwnld@DownloadTextOne" /></SmallText>
                        <SmallText className='textAlignCenter'><Intl langKey="FomoAppDwnld@DownloadTextTwo" /></SmallText>
                    </TextCenterWrap>
                    <SignUpWrapper>
                        <AppSingnUp>
                            <h1>
                                <span className='customSignUp'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span>
                                <Intl langKey="FomoAppDwnld@SignUp" />
                            </h1>
                            <p><Intl langKey="FomoAppDwnld@SignUpTextOne" /></p>
                        </AppSingnUp>
                        <AppSingnUp>
                            <h1><Intl langKey="FomoAppDwnld@DepositText" /><span className='customSignUp'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span></h1>
                            <p><Intl langKey="FomoAppDwnld@SignUpTextTwo" /></p>
                        </AppSingnUp>
                    </SignUpWrapper>
                </DownloadEnd>
                <Divider></Divider>
                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h4><Intl langKey="FomoAppDwnld@Bonus&Promo" /></h4>
                            <h4><Intl langKey="FomoAppDwnld@BonusOn" /><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span></h4>
                        </FlexWrap>
                        <FlexWrap className='twoRow'>
                            <SmallText><Intl langKey="FomoAppDwnld@PromotionTextOne" /></SmallText>
                            <SmallText><Intl langKey="FomoAppDwnld@PromotionTextTwo" /></SmallText>
                        </FlexWrap>
                    </TextCenterWrap>
                    <AppSattaWrapper className='BonusContainer'>
                        <BorderWrapper>
                            <BonusContent>
                                <BonusTitle>
                                    <h3><Intl langKey="FomoAppDwnld@BonusContentTitleOne" /></h3>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownBonusImgOneFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </BonusTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@BonusContentBodyOne" /></BonusBody>
                            </BonusContent>
                            <BonusContent>
                                <BonusTitle>
                                    <h3><Intl langKey="FomoAppDwnld@BonusContentTitleTwo" /></h3>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownBonusImgTwoFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </BonusTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@BonusContentBodyTwo" /></BonusBody>
                            </BonusContent>
                            <BonusContent>
                                <BonusTitle>
                                    <h3><Intl langKey="FomoAppDwnld@BonusContentTitleThree" /></h3>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownBonusImgThreeFomo}`} effect="none" placeholder={<PlaceholderImage />} className="lastImg" />
                                </BonusTitle>
                                <BonusBody><Intl langKey="FomoAppDwnld@BonusContentBodyThree" /></BonusBody>
                            </BonusContent>
                        </BorderWrapper>
                        <FlexWrap className='twoRow'>
                            <SmallText><Intl langKey="FomoAppDwnld@PromotionTextThree" /></SmallText>
                            <SmallText><Intl langKey="FomoAppDwnld@PromotionTextFour" /></SmallText>
                        </FlexWrap>
                    </AppSattaWrapper>
                </DownAndInstall>
                <Divider></Divider>
                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h4><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span><Intl langKey="FomoAppDwnld@CasinoApp" /></h4>
                        </FlexWrap>
                        <FlexWrap className='twoRow'>
                            <SmallText><p className='Casinotext'><Intl langKey="FomoAppDwnld@CasinoLineTextOne" /></p></SmallText>
                        </FlexWrap>
                    </TextCenterWrap>
                    <AppSattaWrapper className='CasinoContainer'>
                        <CasinoWrapper>
                            <BonusContent>
                                <CasinoTitle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadCasinoImgOneFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </CasinoTitle>
                                <CasinoBody><p><Intl langKey="FomoAppDwnld@OnlineTextOne" /></p></CasinoBody>
                            </BonusContent>
                            <BonusContent>
                                <CasinoTitle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadCasinoImgTwoFomo}`} effect="none" placeholder={<PlaceholderImage />} />
                                </CasinoTitle>
                                <CasinoBody><p><Intl langKey="FomoAppDwnld@OnlineTextTwo" /></p></CasinoBody>
                            </BonusContent>
                            <BonusContent>
                                <CasinoTitle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadCasinoImgThreeFomo}`} effect="none" placeholder={<PlaceholderImage />} className="lastImg" />
                                </CasinoTitle>
                                <CasinoBody><p><Intl langKey="FomoAppDwnld@OnlineTextThree" /></p></CasinoBody>
                            </BonusContent>
                            <BonusContent>
                                <CasinoTitle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppDownloadCasinoImgFourFomo}`} effect="none" placeholder={<PlaceholderImage />} className="lastSecondImg" />
                                </CasinoTitle>
                                <CasinoBody><p><Intl langKey="FomoAppDwnld@OnlineTextFour" /></p></CasinoBody>
                            </BonusContent>
                        </CasinoWrapper>
                        <FlexWrap className='twoRow'>
                            <SmallText><Intl langKey="FomoAppDwnld@CasinoLineTextFour" /></SmallText>
                            <SmallText><Intl langKey="FomoAppDwnld@CasinoLineTextFive" /></SmallText>
                        </FlexWrap>
                    </AppSattaWrapper>
                </DownAndInstall>
                <Divider></Divider>
                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h4><Intl langKey="FomoAppDwnld@SportsBet" /><span className='customColor'><Intl langKey="FomoAppDwnld@BrandNameFeature" /></span></h4>
                            <h4><Intl langKey="FomoAppDwnld@BettingApp" /></h4>
                        </FlexWrap>
                    </TextCenterWrap>
                    <SportsWrap>
                        <p><Intl langKey="FomoAppDwnld@SportsTextOne" /></p>
                        <p><Intl langKey="FomoAppDwnld@SportsTextTwo" /></p>
                        <p><Intl langKey="FomoAppDwnld@SportsTextThree" /></p>
                        <p><Intl langKey="FomoAppDwnld@SportsTextFour" /></p>
                    </SportsWrap>
                </DownAndInstall>

                <Divider></Divider>
                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h4><Intl langKey="FomoAppDwnld@BettingHeading" /></h4>
                        </FlexWrap>
                    </TextCenterWrap>
                    <SportsWrap>
                        <p><Intl langKey="FomoAppDwnld@BettingTextOne" /></p>
                    </SportsWrap>
                    <FAQWrapper>
                        <FaqTitle>
                            <h1><Intl langKey="FomoAppDwnld@FAQHeading" /></h1>
                        </FaqTitle>
                        <AccordionContainer>
                            {
                                faqData.map((item, id) => (
                                    <>
                                        <AccordionWrapper onClick={() => toggleAccordion(id)} key={id}>
                                            <AccordianTitle>
                                                <h3>{item.titleNumber}. {item.question}</h3>
                                                <span>{accordionItem === id ? <AccordionCloseIcon /> : <AccordionOpenIcon />}</span>
                                            </AccordianTitle>
                                            <AccordianDisc>
                                                <p className={accordionItem === id ? 'active' : 'inActive'}>{item.answer}</p>
                                            </AccordianDisc>
                                        </AccordionWrapper>
                                        <UnderLine className="lastRow"></UnderLine>
                                    </>
                                ))
                            }
                        </AccordionContainer>
                    </FAQWrapper>
                </DownAndInstall>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(FomoAppDownload))

//Styled Components
const DownldAndInstallStepsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    padding-bottom: 100px;
`;

const DownAndInstall = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 0;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    margin: 0 0;

    .BonusContainer {
        padding-bottom: 1rem;
    }

    .CasinoContainer {
        padding-bottom: 1rem;
    }
`;

const AppBenefitsSteps = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem 2rem;
    padding: 4rem 0;
`;

const AppBenefitsWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    img {
        /* max-width: 100%; */
        animation: myAnim 3s ease 0s infinite alternate forwards;
        width: 100%;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
`;

const BlockContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .customColor {
        font-size: 20px;
        font-weight: 700;
    }

    p {
        width: 190px;
        margin: 20px 0;
        margin-top: 5px;
        font-size: 18px;
        color: ${theme('palette.appDownloadPageSmallText')};
    }
`;

const ScanTheQRWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${theme('palette.appDownloadPageScanTheQRWrapperBg')};
    border: ${theme('palette.appDownloadPageScanTheQRWrapperBorder')};
    border-radius: 8px;
    padding: 3rem;
    gap: 2rem;

    img {
        width: 220px;
    }
`;

const ScanTheQRSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4rem;
`;

export const TextLeftWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    font-size: 40px;
    font-family: 'Inter-Bold';
    color: ${theme('palette.appDownloadPageSmallText')};
`;

const TextCenterWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Inter-Bold';

    .AppText {
        margin-top: -10px;
    }
`;

const ScreenShotSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 0;

    img {
        width: 80%;
        margin: 0 auto;
        display: block;
    }
`;

const AnimateImgThired = styled.div`
    width: 300px;
    position: absolute;
    right: 3rem;
    bottom: 6rem;
    animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }

    img {
        max-width: 100%;
    }
`;

export const AnimateImgSecond = styled.div`
    width: 345px;
    position: absolute;
    left: 7rem;
    top: 2rem;
    z-index: 1;
    animation: myAnim 3s ease 0s infinite alternate forwards;

    @keyframes myAnim {
        0% {
            transform: scale(0.7);
        }

        100% {
            transform: scale(0.9);
        }
    }

    img {
        max-width: 100%;
    }
`;

export const DownloadBtn = styled.a`
    width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    padding: 8px 5px;
    color: ${theme('palette.appDownloadPageDownloadBtnColor')};
    background: ${theme('palette.appDownloadPageDownloadBtnBg')};
    margin: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;

    :hover {
        background: ${theme('palette.appDownloadPageCustomColor')};
        color: ${theme('palette.appDownloadPageDownloadBtnColorHover')};

       svg {
        width: 20px;
            path {
               fill: ${theme('palette.appDownloadPageDownloadBtnColorHover')};
            } 
        }
    }

    svg {
        width: 20px;
    }
`;

const AnimateImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
        width: 700px;
        animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
    
`;

export const SmallText = styled.div`
    font-weight: 500;
    font-family: 'Inter';
    font-size: 15px;
    color: ${theme('palette.appDownloadPageSmallText')};
    text-align: justify !important;


    &.textAlignCenter {
        text-align: center;
        margin-top: -10px;
    }

    .Casinotext {
        padding: 0 3rem;
        text-align: center;
    }
`;

export const FlexWrap = styled.div`
     display: flex;
     align-items: center;
     gap: 10px;
     color: ${theme('palette.appDownloadPageSmallText')};
     font-size: 40px;

     &.twoRow {
        flex-direction: column;
        gap: unset;
     }

    h2 {
        margin: 0;
        padding: 0;
        font-size: unset;
        text-transform: capitalize;
    }
    h4 {
        margin: 0;
        padding: 0;
        font-size: 35px;
        text-transform: capitalize;
    }
`;

const BanerRightContent = styled.div`
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    color: ${theme('palette.appDownloadPageTextWhite')};
    font-family: 'Inter-Bold';
    position: absolute;
    right: 0;
    bottom: 1rem;

    @media only screen and (min-width: 1500px){
        width: 440px;
        right: 5%;
    }
`;

const BanerLeftContent = styled.div`
    color: ${theme('palette.appDownloadPageTextWhite')};
    text-align: right;
    font-family: 'Inter-Bold';
    font-size: 40px;
    position: absolute;
    left: 10%;
    top: 50px;
`;

const BannerSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;

    .AnimateBD {
        width: 700px;
        height: 700px;

        animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.8);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.AppDownloadBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem 4rem;

    .customColor {
        color: ${theme('palette.appDownloadPageCustomColor')};
    }
`;

const AppSattaOnline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AppSattaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    text-align: center;
    z-index: 1;

    img {
        width: 65%;
    }
`;

const DownStepsWrapper = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
`;

const SignUpWrapper = styled.div`
    display: flex;
    gap: 3rem;
    align-items: stretch;
    justify-content: center;
    padding-top: 3rem;
    width: 90%;
`;

const DownSteps = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    border-radius: 20px;
    border: 1px solid #C1DAFF;
    background: radial-gradient(circle at left, #112333 0%, #010406 25%);
    padding: 2rem;

    h1 {
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        font-style: italic;
        line-height: normal;
        background: linear-gradient(158deg, #FED546 26.5%, #FE7C23 102.11%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 0%.5rem;
    }

    p {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 167.5%; /* 26.8px */
    }
`;

const DownloadEnd = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 0;
    padding: 3rem;
    margin: 0 0;
`;

const AppSingnUp = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.3rem;
    border-radius: 20px;
    border: 1px solid #C1DAFF;
    background: radial-gradient(circle at left, #112333 0%, #010406 25%);
    padding: 2rem;

    h1 {
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        background-clip: text;
        font-style: italic;
        margin: 0;
    }

    p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 167.5%; /* 26.8px */
        margin: 0;
    }

    .customSignUp {
        background: linear-gradient(158deg, #FED546 26.5%, #FE7C23 102.11%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-right: 0.4rem;
    }
`;

const SportsWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
    padding: 0 5rem;

    p {
        color: white;
    }
`;

const FAQWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 90%;
    height: auto;
    padding: 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const FaqTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 2.5rem 0;
    width: 100%;

    h1 {
        background: linear-gradient(90deg, #FED546 0%, #EE8D2B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 45.466px;
        font-style: normal;
        font-weight: 700;
        line-height: 53.879px; /* 118.502% */
        text-transform: capitalize;
        margin: 0;
    }
`;



const AccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    .lastRow:last-child {
        display: none;
    }
`;

const AccordionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const UnderLine = styled.div`
    height: 2px;
    width: 85%;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
    align-self: center;
`;

const AccordianTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    h3 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 128.5%; /* 32.125px */
    }

    span {
        transition: transform 0.3s ease;
    }

    /* &:hover span {
        transform: rotate(180deg);
    } */
`;

const AccordianDisc = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-left: 1.5rem;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.3s ease;

    p {
        color: #FFF;
        font-size: 15.163px;
        font-style: normal;
        font-weight: 500;
        line-height: 128.5%; /* 25.909px */
        transition: opacity 0.3s ease;
    }

    p.active {
        display: block;
        height: auto;
        opacity: 1;
    }

    p.inActive {
        display: none;
        opacity: 0;
    }
`;

const BorderWrapper = styled.div`
    display: flex;
    width: 70%;
    justify-content: center;
    gap: 2rem;
    align-items: stretch;
`;

const CasinoWrapper = styled(BorderWrapper)`
    width: 100%;
`;

const BonusContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    height: auto;
    padding: 1.5rem;
    position: relative;
    background: radial-gradient(71.58% 74.35% at 50.18% -11.77%, #112333 0%, #010406 100%);
    background-clip: padding-box;
    border: 2px solid transparent;
    color: white;

    ::after {
        position: absolute;
        top: -1px; bottom: 0;
        left: -1px; right: -1px;
        background: linear-gradient(180deg, #666, transparent);
        content: '';
        z-index: -1;
        border-radius: 20px;
    }
`;

const BonusTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;

    h1 {
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        font-style: italic;
        padding-right: 0.5rem;
        line-height: normal;
        background: linear-gradient(158deg, #FED546 26.5%, #FE7C23 102.11%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h3 {
        background: linear-gradient(158deg, #FED546 26.5%, #FE7C23 102.11%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 20px */
        text-align: left;
        width: 45%;
    }

    img {
        position: absolute;
        width: 7rem;
        top: -3rem;
        right: 0;
    }

    .lastImg {
        position: absolute;
        width: 8rem;
        top: -4rem;
        right: -0.5rem;
    }
`;

const OnlineTitle = styled(BonusTitle)`
    img {
        position: absolute;
        width: 5rem;
        top: -3rem;
        right: 0;
    }

    .lastImg {
        position: absolute;
        width: 6.5rem;
        top: -4.5rem;
        right: 0;
    }
`;

const CasinoTitle = styled(BonusTitle)`
    height: 2rem;

    img {
        position: absolute;
        width: 6rem;
        top: -3rem;
        right: -1.5rem;
    }

    .lastImg {
        position: absolute;
        width: 5.5rem;
        top: -2.5rem;
        right: -1rem;
    }

    .lastSecondImg {
        position: absolute;
        width: 5rem;
        top: -2.5rem;
        right: -1rem;
    }
`;

const BonusBody = styled.div`
    display: flex;
    width: 100%;
    text-align: justify;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120.3%; /* 19.248px */
`;

const CasinoBody = styled(BonusBody)`
    width: 100%;
    text-align: left;
    align-items: flex-start;

    p {
        width: 75%;
        margin: 0;
    }
`;