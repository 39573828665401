// import React, { Fragment } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'

import Home from '../pages/home';
import PrivacyPolicy from "../pages/fine_print/privacy-policy";
import DisconnectionPolicy from "../pages/fine_print/disconnection-policy";
import ResponsibleGambling from "../pages/fine_print/responsible-gambling";
import RestrictedTerritories from "../pages/fine_print/restricted-territories";
import RulesRegulations from "../pages/fine_print/rules-regulations";
import SpecialMarket from "../components/eu-content/special-market/specialMarket";
import UPElection from "../components/eu-content/special-market/UPElection";
import PunjabElection from "../components/eu-content/special-market/PunjabElection";
import Teenpatti from "../components/eu-content/casino-page/teenpatti";
import MainElection from "../components/eu-content/thirdPrty/mainElections";
import CasinoPage from "../components/eu-content/casino-page/index";
import SlotGames from "../components/eu-content/casino-page/slotGames";
// import SuperNowa from "../components/eu-content/casino-page/superNowa"; 
import PopularGames from "../components/eu-content/casino-page/popularGames";
import ExchangePage from "../components/eu-content/exchange-page/index";
import InnerGameDetails from "../components/eu-content/inner-game-details/game-details";
// import SignUp from '../pages/auth/Signup/Signup';
// import MyDashboard from '../html/dashboard';
// import AccountInfo from 'src/html/accountInfo';

import CasinoStatement from "../pages/Statements/casino-statement";
// import AccountStatement from "../pages/Statements/AccountStatement";
import BalanceTransfer from "../pages/Statements/transfer";
import ChangePassword from "../pages/Statements/change-password";
import Security from '../pages/Statements/security';
import ProfitLoss from "../pages/Statements/profit-loss";
import MemberSidebar from "../pages/Statements/member-sidebar";
import MyOrder from "../pages/Statements/My-Orders";

import Dashboard from '../pages/udb/';
import AccountInfo from '../pages/udb/profile/AccountInfo';
import SocialMedia from '../pages/udb/profile/SocialMedia'
import Password from '../pages/udb/profile/ChangePassword';
import Bankdetails from '../pages/udb/profile/Bankaccountform';
import UpiDetails from 'src/pages/udb/profile/upiform'
import KYCPage from '../pages/udb/profile/KYCPage'
import Bankinfo from '../pages/udb/profile/Bankdetails';
import Deposit from '../pages/udb/finance/Deposit';
import WalletTransfer from '../pages/udb/finance/WalletTransfer'



//Finances imports
import FinancesWithdrawalPage from '../../src/pages/udb/finance/FinancesWithdrawal';
import History from '../pages/udb/finance/History';
import AccountStatement from '../pages/udb/finance/AccountStatement';

// For HTML Only Temprory Route
// import BankDetails from '../html/bankDetails';
// import AddAccount from '../html/addAccount';
// import KYCVerification from '../html/kycVerification'
// import ForgotPassword from 'src/pages/auth/Singup/forgotPassword';
// import WithdrawalMessage from 'src/pages/udb/finance/WithdrawalMessage';
//import DashboardPassword from '../html/dashboardPassword';
// import ManualDeposit from 'src/html/manualDeposit'; // temporary route delete after

// import Bonus from 'src/pages/udb/betdetails/Bonus';
// import BonusDetails from 'src/pages/udb/betdetails/BonusDetails';
import MyBets from 'src/pages/udb/betdetails/MyBets';
import ProfitLossDetails from 'src/pages/udb/betdetails/ProfitLoss';
import CasinoStatementUDB from 'src/pages/udb/betdetails/CasinoStatement';
import BTISports from 'src/components/eu-content/BTI-Sports/BTI-Sports';
import SportbookStatementUDB from 'src/pages/udb/betdetails/SportbookStatement';
import PremiumSportsbookStatementDB from "src/pages/udb/betdetails/PremiumSportsbookStatement";
import BonusMain from "src/components/Bonus";
//import { EHistoryTableType } from 'src/pages/Statements/HistoryTable';
// import BonusDetailsMain from "src/components/BonusDetails";
import BonusPopup from 'src/components/BonusPopup';
// import DepositMessage from 'src/components/udb/DepositMessage';
// import MyBetsTable from 'src/pages/udb/betdetails/MyBetsTable';
// import WithdrawalMessage from 'src/pages/udb/finance/WithdrawalMessage';
// Auth Routes ( Login , Signup)
import LoginPage from 'src/pages/auth/Login';
import SignUpPage from 'src/pages/auth/Signup/Signup';
import Termsandconditions from 'src/pages/fine_print/termsandconditions';
import Aboutus from 'src/pages/fine_print/aboutus';
import FAQ from 'src/pages/fine_print/FAQ';
import AffiliateHome from 'src/components/affiliate'
import LoyaltyInnerPage from 'src/components/Loyalty';
import Promotions from 'src/pages/udb/promotions/index';
import VirtualSportsStatement from '../pages/udb/betdetails/virtualSportsStatement';
import VirtualSports from 'src/components/eu-content/Virtual-Sports/virtualSports';
import KycPolicy from 'src/pages/fine_print/kyc-policy';
import AmlPolicy from 'src/pages/fine_print/aml-policy';
import ExclusionPolicy from "src/pages/fine_print/exclusion-policy";
import DepositStatus from 'src/pages/udb/finance/DepositStatus';
import Message from 'src/pages/udb/message';
import TurnoverStatement from 'src/pages/udb/betdetails/TurnoverStatement';
// import AviatorBonus from 'src/pages/promotions/aviatorBonusPage'
// import Catch99Component from 'src/pages/promotions/catch99Inner';
import { withTheme } from 'styled-components';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
// import FifaFeverInner from "src/pages/promotions/FifaFeverInner";
import AppDownloadPage from "src/pages/app-download-page/app-download";
import BblBonusInner from 'src/pages/promotions/BblBonusInner';
import Arjith_promo from 'src/pages/promotions/Arjith-promo';
import Jetx_promo from 'src/pages/promotions/Jetx_promo';
import CrazyCrashPromo from 'src/pages/promotions/crazyCrashPromo';
import AviatorBonusPage from 'src/pages/promotions/aviatorBonusPage';
import { withAppContext } from 'src/store/initAppContext';
import IplBettingDhamaka from 'src/pages/promotions/ipl-promo';
// import Ipl_matkaBD from '../pages/promotions/ipl-matkaBD'
import IplCasinoCarnival from 'src/pages/promotions/ipl-casino-carnival';
import InstantGames from 'src/components/eu-content/casino-page/instantGames';
import PremiumSportsbook from 'src/components/eu-content/PremiumSportsbook/PremiumSportsbook';
import Firstbet from 'src/pages/promotions/evolutionPromo';
import STPStatement from 'src/pages/udb/betdetails/STPStatement';
import Funkytimepromo from 'src/pages/promotions/Funkytimepromo';
import MajorLeagueCricketBetting from 'src/pages/sports/tournaments/iccWorldCup';
import DailyDepBonusPromo from 'src/pages/promotions/dailyDepositBonus';
import AsiaCupBonanzaPromo from 'src/pages/promotions/asiaCupBonanzaPromo';
import GalaxyStatement from 'src/pages/udb/betdetails/GalaxyStatement';
import AviatrixStatement from 'src/pages/udb/betdetails/AviatrixStatement';
// import { useEffect } from 'react';
import EvoPlayStatement from 'src/pages/udb/betdetails/EvoPlayStatement';
import JiliStatement from 'src/pages/udb/betdetails/JiliStatement';
import CaribbeanRoyalBonus from 'src/pages/promotions/CaribbeanRoyalBonus';
import TurboGameStatement from 'src/pages/udb/betdetails/TurboGameStatement';

const PageNotFound = ()=> {
    // useEffect(() => {
    //     const t = setTimeout(() => window.location.href = '/', 5000);
    //     return () => clearTimeout(t);

    // }, [])
    return (<div>
        <h1 style={{color:'red', textAlign:'center', marginTop:'10%' }}>404 Page Not Found</h1>
        {/* <p style={{color:'#fff',textAlign:'center'}}>Redirecting to Home page in 5 seconds...</p> */}
        <p style={{color:'#fff',textAlign:'center'}}>Go to<Link to="/" style={{color:'red', padding:'0 5px'}}>Home</Link> Page</p>
    </div>)
}

function PageRouter(props) {
    const { app: { brandId }, theme } = props
    const navigate = useNavigate()
    // const { theme } = props;
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const isdefPWD = JSON.parse(cookieUtil.get(ECookieName.COOKIE_IS_DEFAULT_PASSWORD) || 'false');

    if(isAuth && isdefPWD && window.location.pathname!=='/') {
        navigate('/', {state:{fpwd: isdefPWD}});
    }

    const isExternal = ['/press','/sports/tournaments','/blog','/press/','/sponsorship','/sports/tournaments/','/blog/'].findIndex(elm => window.location.pathname.includes(elm))>0;
    
    const { config: { 
        showAffiliate, 
        showtier, 
        showPromotions,
        showCatch99Bonus,
        showDBPromoLink,
        showDBMessageLink,
        showCrazyCrashPromo,
        showAviatorBonus,
        showIplPromo,
        IPLCasinoCarnivalPromotion,
        showEvolutionpromo,
        showFunkytimePromo,
        showTournaments,
        showDailyDepBonusPromo,
        showAsiaCupBonanzaPromo,
        showCaribbeanLeagueBonusPromo
    } } = theme;

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />}>
                <Route path=":promoCode" element={<SignUpPage />} />
            </Route>


            {/* old URL: still support but will be removed later */}
            {/* <Route path="/online-casino-games" element={<CasinoPage />}>
                <Route path=":id" element={<CasinoPage />} /> 
            </Route>
            <Route path="/online-casino-games/slotGames" element={<SlotGames />}>
                <Route path=":id" element={<SlotGames />} /> 
            </Route>
            <Route path="/online-casino-games/best-casino-games" element={<PopularGames />}>
                <Route path=":id" element={<PopularGames />} /> 
            </Route> */}
            {/* <Route path="/instant-games" element={<InstantGames />}>
                <Route path=":id" element={<PopularGames />} /> 
            </Route> */}


             {/* New URL */}
             <Route path="/live-casino" element={<CasinoPage />}>
                <Route path=":provider" element={<CasinoPage />} > 
                <Route path=":id" element={<CasinoPage />} ></Route>
                </Route>
            </Route>

            <Route path="/popular-games" element={<PopularGames />}>
            <Route path=":provider" element={<PopularGames />} > 
               <Route path=":id" element={<PopularGames />} /> 
            </Route>
            </Route>

            <Route path="/slots" element={<SlotGames />}>
                <Route path=":provider" element={<SlotGames />} > 
                    <Route path=":id" element={<SlotGames />} /> 
                </Route>
            </Route>

            <Route path="/instant-games" element={<InstantGames />}>
                <Route path=":provider" element={<InstantGames />} > 
                     <Route path=":id" element={<InstantGames />} /> 
                </Route>
            </Route>









            {/* <Route path="/superNowa" element={<SuperNowa />} /> */}
            <Route path="/betting-exchange" element={<ExchangePage />}>
                <Route path=":id" element={<ExchangePage />} /> 
            </Route>
            <Route path="/market/:categoryId/:competitionId/:eventId/:matchId" element={<InnerGameDetails />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<Termsandconditions />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/kyc-policy" element={<KycPolicy />} />
            <Route path="aml-policy" element={<AmlPolicy/>}/>
            <Route path="/disconnection-policy" element={<DisconnectionPolicy />} />
            <Route path="/exclusion-policy" element={<ExclusionPolicy />} />
            <Route path="/responsible-gambling" element={<ResponsibleGambling />} />
            <Route path="/restricted-territories" element={<RestrictedTerritories />} />
            <Route path="/rules-regulations" element={<RulesRegulations />} />
            <Route path="/special-market" element={<SpecialMarket />} />
            <Route path="/UPElection" element={<UPElection />} />
            <Route path="/PunjabElection" element={<PunjabElection />} />
            <Route path="/teenpatti"  element={<Teenpatti />}>
                <Route path=":id" element={<Teenpatti />} /> 
            </Route>
            <Route path="/specialMarket" element={<MainElection />} />
            <Route path="/sportsbook" element={<BTISports />} />
            <Route path="/sportsbook/:id" element={<BTISports />} />
            <Route path="/premium-sportsbook" element={<PremiumSportsbook />} />
         
            {/* <Route path="/virtualSports/:id" element={<VirtualSports />} /> */}

            <Route path="/virtualSports" element={<VirtualSports  />}>
                <Route path=":userId" element={<VirtualSports />} />
            </Route>

           {showTournaments && <Route path="/sports/tournaments/major-league-cricket-betting" element={<MajorLeagueCricketBetting />} />}

            <Route path="/sportbookstatement" element={<SportbookStatementUDB />} />

            <Route path="/membersidebar" element={<MemberSidebar />} />
            <Route path="/casinostatement" element={<CasinoStatement />} />
            <Route path="/balancetransfer" element={<BalanceTransfer />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            {/* <Route path="/signUp" element={<SignUp />} /> */}
            <Route path="/myorder" element={<MyOrder />} />
            <Route path="/profitloss" element={<ProfitLoss />} />
            <Route path="/security" element={<Security />} />
           { showPromotions && <Route path="/promotions" element={<BonusMain />} />}
            {showPromotions && <Route path="/promotions/:id" element={<BonusMain />} />}
            <Route path="/bonusPopup" element={<BonusPopup />} />
           { showAffiliate && <Route path="/affiliate" element={< AffiliateHome />} />}
            {showtier && <Route path="/loyalty" element={< LoyaltyInnerPage />} />}
            {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}
            {/* <Route path="/manualDeposit" element={<ManualDeposit />} />   */}
            {/* <Route path="/dashboard" element={<MyDashboard />} /> */}
            {/* <Route path="/catch99" element={<Catch99Component/>} /> */}
            {/* <Route path="/fifa-fever" element={<FifaFeverInner />} /> */}
            <Route path="/bbl-bonus" element={<BblBonusInner />} />
            <Route path="/Arjith_promo" element={<Arjith_promo />} />
            <Route path="/Jetx_promo" element={<Jetx_promo/>}/> 
            {showCrazyCrashPromo && <Route path="/CrazyCrash" element={<CrazyCrashPromo />} />}
            {/* <Route path="/aviator-bonus" element={<AviatorBonus/>} /> */}
            {  showAviatorBonus && <Route path="/aviator-bonus" element={<AviatorBonusPage/>}/>}
            {showCrazyCrashPromo && <Route path="/CrazyCrash" element={<CrazyCrashPromo />} />}
            {/* {showIplPromo && <Route path={brandId== 31 || brandId == 32 ? "/ipl-matka" : "ipl-satta-matka"} element={brandId==31 || brandId==32 ? <IplDhamaka/> : <Ipl_matkaBD/>}/>} */}
            {showIplPromo && <Route path= "/ipl-betting-dhamaka" element={<IplBettingDhamaka/>}/>}

            {IPLCasinoCarnivalPromotion && <Route path="/ipl-casino-carnival" element={<IplCasinoCarnival />} />}
            {showEvolutionpromo && <Route path='/evolution-first-bet' element={<Firstbet />} />}
            {showFunkytimePromo && <Route path='/funkytime' element={<Funkytimepromo/>}/>}
            {showDailyDepBonusPromo && <Route path='/daily-deposit-bonus' element={<DailyDepBonusPromo/>}/>}
            {showAsiaCupBonanzaPromo && <Route path='/asia-cup-bonanza' element={<AsiaCupBonanzaPromo/>}/>}
            {showCaribbeanLeagueBonusPromo && <Route path='/caribbean-premier-league' element={<CaribbeanRoyalBonus />}/>}
            <Route path="/app-download" element={<AppDownloadPage/>} />

            <Route path="/member" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
                <Route index element={<AccountInfo />} />
                <Route path="profile" >
                    <Route index element={<AccountInfo />} />
                    <Route path="accountinfo" element={<AccountInfo />} />
                    <Route path="linksocialmedia" element={<SocialMedia />} />
                    <Route path="password" element={<Password />} />
                    <Route path="bankaccountform" element={<Bankdetails />} />
                    <Route path="bankdetails" element={<Bankinfo />} />
                    <Route path="kycpage" element={<KYCPage />} />
                    <Route path="upiform" element={<UpiDetails/>}/>
                </Route>
                <Route path="finance" >
                    <Route index element={<Deposit />} />
                    <Route path="deposit" element={<Deposit />} />
                        <Route path="deposit/:transaction_sn" element={<DepositStatus />} />
                    <Route path="withdrawal" element={<FinancesWithdrawalPage />} />
                    <Route path="history" element={<History />} />
                    <Route path="transfer" element={<WalletTransfer />} />
                   
                </Route>
                <Route path="betDetails" >
                    <Route index element={<MyBets />} />
                    <Route path="mybets" element={<MyBets />} />
                    <Route path="profitandloss" element={<ProfitLossDetails />} />
                    <Route path="casinostatement" element={<CasinoStatementUDB />} />
                    <Route path="sportbookstatement" element={<SportbookStatementUDB />} />
                    <Route path="premiumSportsBookStatement" element={<PremiumSportsbookStatementDB />} />
                    <Route path="splitThePotStatement" element={<STPStatement />} />
                    <Route path='galaxyStatement' element={<GalaxyStatement/>}/>
                    <Route path='aviatrixStatement' element ={<AviatrixStatement/>}/>
                    <Route path='evoplayStatement' element ={<EvoPlayStatement/>}/>
                    <Route path='jiliStatement' element ={<JiliStatement/>}/>
                    <Route path='turbogamesStatement' element ={<TurboGameStatement/>}/>
                    <Route path="accountstatement" element={<AccountStatement />} />
                    <Route path="virtualSportsStatement" element={<VirtualSportsStatement />} />
                    <Route path="turnoverStatement" element={<TurnoverStatement />} />

                </Route>
               { showDBMessageLink && <Route path="message" element={<Message />} ></Route>}
                {showDBPromoLink && <Route path="promotions" element={<Promotions />} ></Route>}
            </Route>
            {/* <Route path="/passwordHTML" element={<DashboardPassword />} />
            <Route path="/bankDetails" element={<BankDetails />} />
            <Route path="/addAccount" element={<AddAccount />} />
            <Route path="/kycVerification" element={<KYCVerification />} /> */}
            {/* <Route path="/ForgotPassword" element={<ForgotPassword/>} /> */}
           {!isExternal && <Route path="/*" element={<PageNotFound />} />}
            
        </Routes>
    )
}

export default withTheme(withAppContext(PageRouter))