import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Intl from 'src/components/common/Intl';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { FlagsWrapper } from '../flags/flags';
import { MainInfoWrapper as LoyaltyMainInfoWrapper, Tittle as RulesTittle } from '../udb/commonStyle';
import LoyaltyComponent from './LoyaltyComponent';
import {SRTM} from 'src/util/sportradarEvent';
import SEOContents from '../SEOContents';
import { FlagSection } from './LoyaltyComponent';
import { Helmet } from "react-helmet";
import getSchemaData from '../schema';

const structuredData = (brandId: any) => {
  const brandUrlConfig = {
    32: 'https://www.yolo247.club',
    33: 'https://www.baaziadda.com',
    34: 'https://www.betdaily.club',
    39: 'https://www.fomo7.net',
    40: 'https://www.fomo7.pro',
    46: 'https://www.fun88.site',
    47: 'https://www.gofun88.in',
  };

  const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.co';

  const otherschemaData = getSchemaData('loyalty');

  const data = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "item": `${domainURL}`,
          "name": "Homepage",
          "position": 1
        },
        {
          "@type": "ListItem",
          "item": `${domainURL}/loyalty`,
          "name": "Loyalty",
          "position": 2
        }
      ],
      "name": "Breadcrumbs"
    },
    ...otherschemaData
  ]

  return JSON.stringify(data)
}

function LoyaltyInnerPage(props) {
  const { app: {
    brandId
  }, theme } = props

  const { showOnlyOnFun } = theme.config;

  useEffect(()=>{
    SRTM.init(brandId)
  },[]);

  return (
    <MainInfoWrapper>
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(brandId)
          }
        </script>
      </Helmet>
      <SEOContents pageName="loyalty"/>
      <BannerWrapper>
        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.loyaltyBannerImage}`} alt="Banner Image" />
      </BannerWrapper>
      <LoyaltyWrapper>
        {
          (brandId == 39 || brandId == 40) ? <LoyaltyProgramsText>Fomo7 Loyalty Programs</LoyaltyProgramsText> : (brandId == 46 || brandId == 47) ? <LoyaltyProgramsText>FUN88 Loyalty Programs</LoyaltyProgramsText> : <></>
        }
      <LoyaltyComponent />
      </LoyaltyWrapper>
      <RulesWrapper>
        <Rules>
          <Tittle>
            <Intl langKey="LOYALTY@RULESREGULATIONS" />
          </Tittle>
          {
            (brandId == 39 || brandId == 40 || brandId == 46 || brandId == 47) ? (
              <>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT1" /></>
                </RulesText>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT2" /></>
                </RulesText>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT3" /></>
                </RulesText>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT4" /></>
                </RulesText>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT5" /></>
                </RulesText>
                <RulesText>
                  <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT6" /></>
                </RulesText>
                {!showOnlyOnFun && 
                    <>
                      <RulesText>
                        <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT7" /></>
                      </RulesText>
                      <RulesText>
                        <><img src={`${config.imageV3Host}/static/cashsite/${theme.image.NoteIconFomo}`} alt="Banner Image" /><Intl langKey="LOYALTY@RULESTEXT8" /></>
                      </RulesText>
                    </>
                }
              </>
            ) : (
              <>
                <RulesText>
                  <Intl langKey="LOYALTY@RULESTEXT1" />
                </RulesText>
                <RulesText><Intl langKey="LOYALTY@RULESTEXT2" /></RulesText>
                <RulesText>
                  <Intl langKey="LOYALTY@RULESTEXT3" /></RulesText>
                <RulesText>
                  <Intl langKey="LOYALTY@RULESTEXT4" /> <br />
                  <Intl langKey="LOYALTY@RULESTEXT5" />
                </RulesText>
                <RulesText>
                  <Intl langKey="LOYALTY@RULESTEXT6" />  <br />
                  <Intl langKey="LOYALTY@RULESTEXT7" />  <br />
                  <Intl langKey="LOYALTY@RULESTEXT8" />
                </RulesText>
              </>
            )
          }
        </Rules>

        <Link className="loginSignup_logo" to="/">
          <img src={brandId == 33 || brandId == 34 ? `${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}` : (brandId == 39 || brandId == 40) ? `${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_fomo}` : `${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />
        </Link>
      </RulesWrapper>
    </MainInfoWrapper>
  )
}

export default withTheme(withAppContext(LoyaltyInnerPage));

//Styled components
const RulesText = styled.div`
    flex: 1;
    color: ${theme('palette.loyaltyRulesTextColor')};
    text-transform: capitalize;
    /* text-align: center; */
    text-align: ${theme('palette.loyaltyTextAlignment')};
    padding: 8px;
    font-weight: 400;
    font-size: 13px;
    align-items: flex-start;
    display: flex;
    gap: 5px;

    img {
      padding-top: 2px;
      height: 15px;
    }
`;

const Tittle = styled(RulesTittle)`
    flex: 1;
    color: #fff;
    color: ${theme('palette.loyaltyTittleTextColor')};
    text-transform: uppercase;
    text-transform: ${theme('palette.loyaltyTittleTextTransform')};
    text-align: center;
    font-size: ${theme('palette.loyaltyTittleTextFontSize')};
    padding-bottom: ${theme('palette.loyaltyTittleTextPaddingBtm')};
`;
const RulesWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 2em;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    /* background: linear-gradient(318deg, #102A51, #11141c 29%); */
    background: ${theme('palette.homeLoyaltyBg')};
    .loginSignup_logo {
        & img {
            width: 250px;
            opacity: 0.5;
        }
    }
`;

const Rules = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${theme('palette.homeLoyaltyRulesAlignItems')};
    background: #000000;
    background: ${theme('palette.LoyaltyRulesBg')};
    /* border: 2px solid #939BAB; */
    /* border-radius: 5px; */
    /* padding: 2em 10em; */
    border: ${theme('palette.homeLoyaltyBorder')};
    border-radius: ${theme('palette.homeLoyaltyBorderRadius')};
    padding: ${theme('palette.homeLoyaltyRandRPadding')};
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.rulesAndRegfm')});
    background-size: cover;
    background-repeat: no-repeat;
`;

const BannerWrapper = styled.div`
    width: 100%;
    display: flex;

    img {
        max-width: 100%;
    }
`;

export const MainInfoWrapper = styled(LoyaltyMainInfoWrapper)`
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.LoyaltyBgImage')});
    background-size: cover;
	  background-repeat: no-repeat;
	  background-position: top right;

    ${FlagsWrapper} {
        background-image: none;
        padding-bottom: 0;
    }
`;

const LoyaltyWrapper = styled(FlagSection)`
  margin: 0;
  padding: 0;
  background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.loyaltyCardBg')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  flex-direction: column;
`;

const LoyaltyProgramsText = styled.div `
    color: #FFF;
    color: ${theme('palette.loyaltyTittleTextColor')};
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.5px;
    margin: 0;
    padding: 3% 0 0 0;
`;