import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";

function LiveJackPot2025Promo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const settings = {
        loop: true,
        arrows: true,
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        centerPadding: '20px'
    };

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';

    return (
        <>
            <InformationWrapper>
                    <TitleText>
                        {(brandId == 31 || brandId == 32) ? <p>Get ready for <span className='boldText'>“The Live Jackpot"</span> at {domain}, with a whopping potential prize of up to <strong>7 Lakh!</strong> Each 1,000 INR wagered gives you one point in the Battle. <br />Play more, earn more points, and increase your chances to win!</p> : 
                        (brandId == 33 || brandId == 34) ? <p>Get ready for <span className='boldText'>“Live Baazi Challenge"</span> at {domain}, with a whopping potential prize of up to <strong>7 Lakh!</strong> Each 1,000 INR wagered gives you one point in the Battle.<br />Play more, earn more points, and increase your chances to win!</p> : 
                        (brandId == 39 || brandId == 40) ? <p>Get ready for <span className='boldText'>“The real deal"</span> at {domain}, with a whopping potential prize of up to <strong>7 Lakh!</strong> Each 1,000 INR wagered gives you one point in the Battle. <br />Play more, earn more points, and increase your chances to win!</p> : ""}
                        { (brandId == 39 || brandId == 40) ? <> <br /> <Divider></Divider></> : <br />}
                        {(brandId == 39 || brandId == 40) ? <div className='shadowLine'><p>Prize Pool : <strong>7 Lakh</strong></p></div> : <p>Prize Pool : <strong>7 Lakh</strong></p>}
                        
                    </TitleText>
                <ImageWrapper>
                    <Container>
                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourChipImage}`}
                                alt="Prize Pool Interest"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Prize Pool Interest:</Title>
                            <Subtitle>0.015% Of Each Bet</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourCoinImage}`}
                                alt="Promo Point"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Promo Point:</Title>
                            <Subtitle>1,000 INR Turnover <br />In Mentioned Games</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourTrophyImage}`}
                                alt="Prizes"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Prizes:</Title>
                            <Subtitle>50 Winners</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourGiftImage}`}
                                alt="Random Giveaways"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title className='textHighlight'>Random <br />Participation</Title>
                            <Subtitle>Giveaways Weekly</Subtitle>
                        </Card>
                    </Container>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/rightCardImg.png`} className='cardImg' alt="" />
                </ImageWrapper>
                <ContentWrapper>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/leftCardImg.png`} className='leftCardImg' alt="" />
                    <p>Get in on the action and boost your points!</p>
                    <p>Prizes will be credited to your Balance within 3 days after the final day of the tournament.</p>
                    <h2 className='boldText paddingTop'>Participating Games :</h2>
                    {(brandId == 39 || brandId == 40) ? <UnderLine></UnderLine> : ""}
                    <p>All Live Dealer Games :</p>
                    <div className='contntImg'>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/brand/6/image/providerNameImgs.png`}
                            alt="Random Giveaways"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </div>
                    <p>Event Dates : <span className='boldText'>Starting 15th January 2025 to 15th March 2025</span></p>
                </ContentWrapper>

                <PriceBreakDown>
                    <h2 className='boldText'>Prize Breakdown : Place | Prize</h2>
                    {(brandId == 39 || brandId == 40) ? <UnderLine className='paddingTopBtm'></UnderLine> : ""}
                    <SliderWrapper>
                        <Slider {...settings}>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP1}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP2}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP3}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP4}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP5}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP6}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP7}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP8}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP9}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP10}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP11}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP12}`}/></div>
                        </Slider>
                    </SliderWrapper>
                </PriceBreakDown>
                
                <LiveJackPotTermsAndCondition>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/allDiceImg.png`} className='leftCardTermsImg' alt="allDiceImg" />
                    {(brandId == 39 || brandId == 40) ? 
                        <div className='termsBorder'>
                            <h4>Terms & Conditions</h4>
                            <UnderLine className='marginTop'></UnderLine>
                            <ul>
                                <li>This promotion is valid for all new and existing players.</li>
                                <li>Winners will be announced on {domain}'s social media handles (Instagram & Telegram) as well as in your respective {domain} inbox.</li>
                                <li>This promotion applies <span className='boldTextLite'>only to live dealer games</span> from <strong>Evolution, Ezugi, Vivo, Supernova, and AgTeenPatti</strong>.</li>
                                <li>Winners will be determined by those with the <span className='boldTextLite'>highest wager</span> every month.</li>
                                <li>Random Participation giveaways weekly.</li>
                                <li>Void, canceled, or draw bets, as well as cashed-out bets, do not count towards deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved 
                                in collusion or any foul play related to this promotion.</li>
                                <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>General Terms & Conditions of {domain} apply.</li>
                            </ul>
                        </div> : 
                        <>
                        <h4>Terms & Conditions</h4>
                            <ul>
                                <li>This promotion is valid for all new and existing players.</li>
                                <li>Winners will be announced on {domain}'s social media handles (Instagram & Telegram) as well as in your respective {domain} inbox.</li>
                                <li>This promotion applies <span className='boldText'>only to live dealer games</span> from <strong>Evolution, Ezugi, Vivo, Supernova, and AgTeenPatti</strong>.</li>
                                <li>Winners will be determined by those with the <span className='boldText'>highest wager</span> every month.</li>
                                <li>Random Participation giveaways weekly.</li>
                                <li>Void, canceled, or draw bets, as well as cashed-out bets, do not count towards deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved 
                                in collusion or any foul play related to this promotion.</li>
                                <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>General Terms & Conditions of {domain} apply.</li>
                            </ul>
                        </>
                    }
                </LiveJackPotTermsAndCondition>
                <FotterWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/brownDiceImg.png`} className='fotterWrapCardLeftImg' alt="DiceImg" />
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/greenDiceImg.png`} className='fotterWrapCardRightImg' alt="DiceImg" />
                </FotterWrap>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(LiveJackPot2025Promo));

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);

    &.paddingTopBtm {
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    &.marginTop {
        margin: 0 auto;
        margin-top: 1rem;
    }
`;

const FotterWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .fotterWrapCardLeftImg {
        width: 68px;
        position: absolute;
        left: 4%;
        top: -40px;
    }

    .fotterWrapCardRightImg {
        width: 68px;
        position: absolute;
        right: 15%;
        top: -2px;
    }

    :before {
        content: "";
        width: 46px;
        height: 43px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/footrDiceImg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 32%;
        top: 40%;
    }
`;

const SliderWrapper = styled.div`
    width: 85%;
    margin: 0 auto;

    .slick-slide {
        padding: 0 15px;
    }

    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */

        :hover {
            transform: scale(0.8);
            border: 3px solid #ffffff;
            border-radius: 1rem;
        }

        :focus-visible {
            outline: none;
        }
        :focus {
            outline: none;
        }
    }

    .slick-prev {
        left: -65px;
        top: 43%;
    }

    .slick-next {
        right: -25px;
        top: 43%;
    }

    .slick-prev:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/prevSlideJackpot.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-next:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/nextSlideJackpot.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: #fff;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: ${theme('palette.slickActiveButtonClr')};
        }
    }
`;

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 0 8rem 0;
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;  
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.LiveJackPot2025PromoBgImg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .boldText {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 700;
    }

    .boldTextLite {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 400;
    }

    .shadowLine {
        background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
        -webkit-backdrop-filter: blur(6.849999904632568px);
        backdrop-filter: blur(6.849999904632568px);
        width: 100%;
        padding-top: 2rem;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        background: ${theme('palette.LiveJackPot2025InformationWrapperH2Text')};
        background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2TextClip')};
        -webkit-background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2WebkitClip')};
        -webkit-text-fill-color: ${theme('palette.LiveJackPot2025InformationWrapperH2TextWebkitFill')};
    }
`;

const PriceBreakDown = styled.div`
    width: 100%;
    padding: 2rem 0;
    position: relative;

    :before {
        content: "";
        width: 36px;
        height: 33px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/sliderDiceLeft.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 2%;
        top: 40%;
    }

    :after {
        content: "";
        width: 36px;
        height: 33px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/sliderDiceRight.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 1%;
        bottom: 25%;
    }
`;

const Card = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
`;

const Title = styled.div`
  font-size: 14px;
  margin-top: 10px;

  &.textHighlight {
    font-size: 16px;
    font-weight: bold;
    color: ${theme('palette.informationWrapperPromoBoldText')};
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  /* margin-top: 5px; */
  color: ${theme('palette.informationWrapperPromoBoldText')};
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${theme('palette.liveJackpotTittleTextPadding')};
    position: relative;

    :before {
        content: "";
        width: 39px;
        height: 34px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/topLeftDiceImg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 18%;
        top: 50%;
        z-index: 1;
    }

    :after {
        content: "";
        width: 42px;
        height: 36px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/topRightDiceImg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 11%;
        bottom: 1%;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WaveTypeBgImg")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: ${theme('palette.liveJackpotImageWrapperHeight')};
    margin-bottom: 2rem;
    position: relative;

    .cardImg {
        width: 352px;
        /* height: 266px; */
        position: absolute;
        right: -125px;
        top: 40px;
    }

    :before {
        content: "";
        width: 67px;
        height: 70px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/blueDiceImg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 10%;
        bottom: 30%;
    }

    img {
        width: 93px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding-top: 5rem; */
    padding-top: 0rem;
    color: #fff;
    position: relative;

    .leftCardImg {
        width: 270px;
        position: absolute;
        left: -105px;
        bottom: 0;
    }

    p {
        margin: 1em 0 0 0;
        text-transform: capitalize;
    }

    .contntImg {
        width: 885px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 0;
        position: relative;

        img {
            max-width: 100%;
        }

        :before {
            content: "";
            width: 68px;
            height: 70px;
            background: url(${config.imageV3Host}/static/cashsite/brand/6/image/redDiceImg.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: -30%;
            bottom: -10%;
        }
    }

    .paddingTop {
        padding-top: 1rem;
    }
`;

const Container = styled.div`
    /* max-width: 70%; */
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
`;

const LiveJackPotTermsAndCondition = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    padding-left: 9rem;
    position: relative;

    :after {
        content: "";
        width: 68px;
        height: 70px;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/whiteDiceImg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 4%;
        top: 19%;
    }

    .leftCardTermsImg {
        width: 149px;
        position: absolute;
        left: 1%;
        top: 15%;
    }


    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 10px 0;
    }

    & h4 {
        color: #E6FF00;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
        background: ${theme('palette.LiveJackPot2025InformationWrapperH2Text')};
        background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2TextClip')};
        -webkit-background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2WebkitClip')};
        -webkit-text-fill-color: ${theme('palette.LiveJackPot2025InformationWrapperH2TextWebkitFill')};
        text-align: ${theme('palette.LiveJackPot2025TermsTittleAlign')};
    }

    .termsBorder {
        width: 90%;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        position: relative;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        padding: 3rem;
        margin: 0 auto;
        overflow: hidden;
        z-index: 1;
        border: 2px solid transparent;
        background-clip: padding-box;
    }

    .termsBorder::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 30px;
        margin: 1px;
        background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    }

`;