import { getBrandId } from "src/store/initAppContext";

const schemaData: any = {
    31: {

    },
    32: {

    },
    33: {

    },
    34: {

    },
    39: {

    },
    40: {

    },
    46: {
        "default": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Fun88 Online Betting ID",
                "description": "Get your Fun88 online betting ID instantly through WhatsApp in just one click! Fast, secure, and easy. Get yours now!",
                "url": "https://www.fun88.site"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "live-casino": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Live Casino with Instant Betting ID | Fun88",
                "description": "Play your favourite live caisno games with instant Betting ID through Whatsapp",
                "url": "https://www.fun88.site/live-casino"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "online-cricket-betting": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Get your Online Cricket Betting ID with 1 Click | Fun88",
                "description": "Are you looking to bet on Cricket? Get your online cricket betting ID today and start placing your bets. With our secure platform, you can bet safely. Don't wait any longer-start winning today!",
                "url": "https://www.fun88.site/live-casino"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What does it mean to be an online cricket betting ID provider? ",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "An online cricket betting ID provider like Fun88 is a betting website that provides users with an identification number or other information that is used to place bets on cricket games. Fun88 as an online betting ID provider also provides services such as betting odds, sports statistics, news, and tips to help users make informed decisions when placing bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Why do I need an online cricket ID for betting? ",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Online cricket betting requires identification for two main reasons. Firstly, it is required to ensure that you are of legal age to place a bet. Secondly, it is needed to protect you from fraud and to help verify your identity. Many online cricket betting sites also use online ID for Cricket betting to help comply with anti-money laundering legislation."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the difference between online betting ID & cricket betting ID ?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Online betting ID is a generic term used to refer to any type of identification number that is associated with placing a bet. This could be an account number, username, or any other unique identifier. Cricket betting ID is a specific type of betting ID that is used to place bets on cricket games. This type of betting ID will include information specific to cricket betting, such as the types of bets available, the teams involved, and other details."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What types of cricket bets can be placed using an online cricket ID?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You have a free choice of placing any bets, but the popular bets you can place while betting on cricket are as follows: 1. Match bets: These are bets placed on the outcome of a match, such as who will win the game or whether the game will end in a draw. 2. Series bets: These are bets placed on the outcome of a series, such as which team will win the series or which team will be the top scorer. 3. Player performance bets: These are bets placed on specific players' performances, such as which player will score the most runs or take the most wickets. 4. Prop bets: These are bets placed on specific events during a match, such as the first wicket to fall or the number of runs scored in an over. 5. Futures bets: These are bets placed on future events, such as who will win a tournament or which team will be the champion of a league. "
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is online cricket betting ID legal for other sports betting?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No, online cricket betting ID is not legal for other sports betting. Each country has its own laws regarding online sports betting, and online cricket betting is only legal in countries where it is permitted."
                        }
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "login": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fun88 Club Login | Get Your Betting ID Instantly",
                "description": "Log in to your Fun88 Club account or register for a new one to enjoy instant Online Betting ID",
                "url": "https://www.fun88.site/login"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "forgot-password": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Forgot Password | Reset Your Fun88 Club Password",
                "description": "Forget your Fun88 Club account password? No worries! Reset your account password securely and regain access to your Online Betting ID",
                "url": "https://www.fun88.site/forgot-password"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "refill-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Redeposit Bonus up to 5% | Fun88 promotions India",
                "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit. T&C apply.",
                "url": "https://www.fun88.site/promotions/refill-bonus"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "welcome-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Get 388% massive bonus on your 1st deposit | Fun88 India",
                "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!. T&C apply.",
                "url": "https://www.fun88.site/promotions/welcome-bonus"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "referral-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Refer Your Friend & Get 24%/- FREE Bonus | Fun88",
                "description": "Want to earn Rs.1000 free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
                "url": "https://www.fun88.site/promotions/referral-bonus"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ],
        "cashback-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Cashback up to 5% on your Losses | Fun88 Official India",
                "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%. T&C apply.",
                "url": "https://www.fun88.site/promotions/cashback-bonus"
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88 ID",
                    "Live Casino ID",
                    "Cricket Betting ID",
                    "Login"
                ],
                "url": [
                    "https://www.fun88.site",
                    "https://www.fun88.site/live-casino",
                    "https://www.fun88.site/betting-exchange/online-cricket-betting",
                    "https://www.fun88.site/login"
                ]
            }
        ]
    },
    47: {
        "default": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Fun88",
                "description": "Fun88 is a trusted online betting site in India with 100+ online casino games, slots games, & 10K different sports with the best betting odds. Join now for exciting betting online!",
                "url": "https://www.gofun88.in"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How to do online betting on Fun88 India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Betting online at Fun88 is very easy. Just register on our website or download our app. Youíd find multiple sports on Fun88ís website to bet on, including cricket, tennis, football, b aseball, volleyball, kabaddi, and many more. Youíd be able to find a number of matches currently happening or scheduled sometime in near future. Select the appropriate betting option/market you want to bet on and Bingo youíll start betting at Fun88 ñ one of the top betting sites in India."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is betting online in Indian Rupees possible at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "One of the best online betting sites in India, Fun88, allows you to bet in Indian Rupees. So, Indian bettors donít have the hassle of converting their local currency to place their bets. Just visit Fun88ís portal and start betting online in Indian Rupees."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are online betting sites in India safe?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Fun88 is the safest online betting site in India. It’s wise to only use an accredited online betting site that is licensed and independently regulated."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the other ways of online betting in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can also bet at online casinos on Fun88 to play games like slots, blackjack and roulette."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What do you need for betting online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Online betting sites in India need the below proofs from you when you need to sign up. - A valid address, email address and mobile number - A valid form of Indian identification - Legitimate payment method to deposit and withdraw funds from your account"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the payment methods for online betting in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Generally, the best online betting sites in India offer the following modes of payment: - UPI apps - PhonePe - Paytm - Google Pay (G Pay) - Cryptocurrency - Skrill - Neteller - Payz - Debit cards/credits cards (Visa & Mastercard)"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to claim the Fun88 betting bonus on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "1.Click the button to claim the Fun88 bonus now. 2.Create a new Fun88 account by typing in your details. 3.Deposit Fun88 using any of our payment methods. 4. Go to the My Promotions page and activate the welcome bonus."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are minimum online betting odds?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Betting sites will often set minimum odds on viable bets, for example, minimum odds may be fixed at (2.00) which will require you to place winning bets at 2.00 or above to qualify."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum deposit & withdrawal amount in INR?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Minimum deposit and withdrawal amounts differ between online betting sites using Indian rupees. Some bookmakers offer minimum deposits as little as 100 INR, while the minimum and maximum withdrawal amount depend on the market, along with the payment method selected."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "about-us": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "About Us | Online Gambling Company in India | Fun88 India",
                "description": "Learn all about Fun88 India, one of the country’s biggest and fastest growing online gambling companies. Check out the About Us page today!",
                "url": "https://www.gofun88.in/about-us"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "app-download": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Best Betting App For Cricket, Football, & Other Sports In India | Fun88",
                "description": "Fun88 is an ideal online betting (satta) app for punters willing to bet on cricket, football, kabaddi, etc. in India. Download Fun88 to win prizes and rewards!",
                "url": "https://www.gofun88.in/app-download"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Does Fun88 have a betting app for both Android and iOS platforms?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we do have a mobile app for both Android and iOS platforms. That takes care of the majority of the tech ecosystem. Whether you have a Samsung, OnePlus, Motorola, Oppo, Vivo, or Apple mobile phone, you can download our app and enjoy a seamless betting experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I download Fun88’s mobile betting app on a tablet?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can download our app on a tablet. Most tablets display betting apps the way mobile phones do. So, the interface of a betting app will almost look similar on a tablet compared to a mobile phone."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I know which is the best betting app for me?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are certain features you must always check in an app before downloading it. For example, whether it is owned by a legitimate business. How would you know that? Check if the app has a license or certification from a reputed institution. You can also check for reviews online. Ease of use, secure payment methods, and availability of betting options are other features you should check before downloading a betting app."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I bet on multiple sports and online casino games with Fun88’s mobile app?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, on Fun88’s app, you can bet on multiple sports, like cricket, soccer, tennis, and kabaddi, at the same time. You can also place multiple bets on the same sport. Moreover, you can bet on multiple casino games as well."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do I have to mandatorily make a deposit right after downloading Fun88’s mobile app?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No, you don’t have to compulsorily make a deposit the moment you download our app. Downloading and installing Fun88’s app is free. However, to start betting, you’ve to make a deposit. Even for that, we offer a number of sign-up schemes, which give you a bonus on your deposit. Check out the offers and promotions page of our website."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Fun88 Online Sports Betting",
                "description": "Fun88 is a trusted online betting App in India with 100+ online casino games, slots games, & 10K different sports with the best betting odds. Join now for exciting betting online!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Person",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/app-download",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "betting-exchange": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Best Online Sports Betting Exchange Site In India For Cricket, Football, Kabaddi, & A Lot More | Fun88",
                "description": "Fun88 is a top online sports betting exchange (gambling) site in India, which offers punters more than 10,000 games, bonuses, promotions, and a lot more. Visit Fun88 now!",
                "url": "https://www.gofun88.in//betting-exchange"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "football-betting": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Football Bet App: Enjoy Sports Betting Online | Download Now | Fun88",
                "description": "Kick-off your betting journey with the best betting app for Football. Download the Fun88 Football bet app and win big with the best betting offers.",
                "url": "https://www.gofun88.in/betting-exchange/football-betting"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How to bet on Football?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The easiest way to bet on Football is through the Match bet market. Choose from a range of wagers, including total goals, match winners, or even number of red cards or corners on Fun88. Simply navigate to the football betting section and make your selection."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What football leagues and tournam",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 covers Europe’s top leagues, domestic leagues, South American football and the Australian A-League. Browse our full range of football leagues, tournaments and international competitions to start betting o"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is betting on football in India allowed?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can bet on football matches in India. Fun88 is a popular betting site in India that has odds listed for all the major leagues including Premier League, La Liga, Serie A, Bundesliga, ISL, and others."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What do the signs +/- mean in football betting?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The +/- signs are usually the way odds are expressed in terms of money. In terms of the spread, the (-) refers to the favourite and the (+) always refers to the underdog."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there the safest market to bet on in Football?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is no such proven betting market that gives you 100% profits. However, successful bettors bet on straight wins, goal line or Asian handicaps, as these three markets are considered to be most easy to take advantage of."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "online-cricket-betting": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Online Cricket Betting Site: Bet on Cricket Satta at Fun88",
                "description": "Cricket betting online at Fun88! Bet on cricket tournaments ✓ Best Cricket Satta Rates ✓Secure Payment ✓Bonus for thrilling live betting in India.",
                "url": "https://www.gofun88.in/betting-exchange/online-cricket-betting"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is it legal to bet on cricket online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is legal to bet on online cricket. But, it varies from region to region, so it’s crucial to verify your local laws to ensure compliance prior to diving into such online betting."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I bet on live cricket matches?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, live betting options for cricket matches are available on many betting platforms."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What happens if a match is cancelled or abandoned?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Different betting sites have different rules regarding cancelled or abandoned cricket matches. In general, it frequently leads to refunds or void bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a mobile app for betting on cricket?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely. Many trustworthy betting platforms offer dedicated mobile apps for easy cricket betting. These apps provide mobile betting together with an easy-to-use UI."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I create an account on the betting platform?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Go to the platform's website or download the app, then follow the registration instructions to create an account. These instructions usually include submitting personal information, confirming identity, and setting up payment options."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to do cricket betting online in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Follow the steps and process of betting on cricket online:\nStep 1 - Choose a reputable online cricket betting website like Fun88.\nStep 2 - Sign up and create your betting account with the Sportsbook.\nStep 3 - Choose the payment options and deposit money into your betting account.\nStep 4 - Choose a match/series/tournament you wish to bet on.\nStep 5 - Choose from the different betting markets like a top bowler, top batsman, Match Winner, Man of the Match etc.\nStep 6 - Pick your bet, enter the amount of stake (money) in the bet slip generated and hit the 'Place Bet' button to confirm your bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where to watch live cricket matches on mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Different websites and mobile apps have made it easy to stream live cricket matches for on-the-go betting. Say, if you want to watch the live streaming of IPL, you can sign-up on Fun88 to watch the live streaming of each IPL match."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are my cricket betting winnings taxable?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, in India, winnings from gambling are treated as ‘income from other sources and are taxed at a flat 30% + 4% cess."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I sign up with more than one cricket betting online site?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can sign up on more than one cricket betting site. You are free to analyze and check the right betting website that offers the most competitive cricket betting odds online."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How much should I spend on a cricket bet?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It is advised to spend up to 2% of your funds for any bet on cricket. For example, if you deposit ₹2,000, you should bet a maximum of ₹40 per bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How is Cricket Betting Odds calculated?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Sportsbooks have a hand in deciding what odds they will offer punters. However, these odds are not just numbers, they are calculated very carefully."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How long does it take to withdraw cricket betting winnings?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It all depends on the payment method you choose. If you choose e-wallets like EcoPayz or GPay, then you can withdraw instantly. On the other hand, a bank transfer could take 3-5 days to reflect the money in your account."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Will online cricket betting sites in India ask for ID documents?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, cricket betting sites will ask for ID documents as they need to comply with laws and regulations in the country and also to stop money laundering, and other illegal activities."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I win money betting on Cricket?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can! Your winnings from your betting will be reflected in your bank account."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "faq": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Frequently Asked Questions (FAQ) On Online Betting & Gambling | Fun88",
                "description": "Visit Fun88's FAQ page, where we'll help you if you have any doubts about online betting and gambling. Read about how to sign up or open an account, deposit and withdraw money, etc.",
                "url": "https://www.gofun88.in/faq"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "instant-games": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Instant Games Online and Win Real Cash Prizes at Fun88",
                "description": "Play instant card games like Aviator, Mini Roulette, Dice & More in India! Try your best in instant games and compete with your friends!",
                "url": "https://www.gofun88.in/instant-games"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What are instant play games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Instant play games are the online version of traditional games that can be played on reliable online casinos like Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can I earn by playing instant games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In general, you can earn money by playing instant games through a few different methods, such as participating in paid tournaments or contests, receiving rewards for completing certain in-game tasks or earning virtual currency that can be exchanged for real money or prizes."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are some popular genres of instant games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are many popular genres of instant games that are widely enjoyed by players of all ages. Some of the most common genres include puzzle games, arcade games, sports games, casino games, simulation games and more."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are instant games, and how do they differ from traditional video games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Instant games differ from traditional video games in several ways: They are usually played on mobile devices or computers. They are designed to be played in short bursts, often lasting only a few minutes. Instant games typically have simpler graphics and gameplay mechanics compared to traditional video games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do game developers ensure the quality and security of instant games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Game developers ensure the quality and security of instant games through various strategies. They subject games to rigorous testing, security audits, and user reviews to identify and fix issues. Compliance with relevant regulations and laws is also important. These practices help ensure that instant games are of high quality and provide players with a secure and enjoyable experience."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "smartsoft": [
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "spribe": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Spribe - Leading Dealer of Online Instant Games",
                "description": "Discover the best dealer of instant games with Spribe. Enjoy exciting online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/instant-games/spribe"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What does Spribe do?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Spribe is a well-known online casino game company that creates high-quality games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Spribe registered and regulated?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Spribe is licensed and regulated by the UKGC, MGA, GCC and various other European gaming regulatory bodies."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the most popular casino games on Spribe?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Spribe has developed popular casino games such as Mini Roulette, Blackjack and Russian Poker."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "aviator": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Aviator Game Online and Earn Real Money at Fun88",
                "description": "Ready for an 🚀 Aviator game online experience? Fly through thrilling missions in ✈️ Spribe Plane Game with fair gameplay, winning strategies and device compatibility. ",
                "url": "https://www.gofun88.in/instant-games/spribe/aviator"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is the online aviator game real or fake?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The Aviator bet game is legitimate and real, treating all its players equally. The game uses a random multiplier technology that makes each round fair for everyone."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is an aviator game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Aviator game casino is a small plane game that needs you to place a bet and withdraw before the plane takes off in the distance. It is an easy-to-control game based on its plane and whether you can withdraw before it takes off."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many Aviator's betting options available in the game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are only two betting options in the Aviator game casino. One is to make a single bet at the game's beginning or two bets simultaneously. Pick the kind of bet you want to make according to your preference."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is the aviator game legal to play in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It is legal to play Aviator online in India. The game satisfies every specific standard to be deemed legal in most Indian states."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do you make real money with the aviator game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Making money with the Aviator game is possible when withdrawing your bet. Each round of this game needs you to make a bet at the beginning and withdraw before the plane takes off. Withdraw a higher amount of money by playing several rounds of the game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it possible to predict the aviator game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Mostly, it is challenging to make predictions in the Aviator game. It can be tempting to find patterns in its statistics, but the game is controlled by a random multiplier, which makes it challenging for the players to find any connection between the outcome of each round."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Aviator Game",
                "description": "Experience the excitement of Aviator and other Spribe games at Fun88. Join now for a thrilling gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/aviator",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/aviator",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "dice": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Dice Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Dice by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/dice"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can one win money playing the dice game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! Once you register with Fun88 and create your online wallet, you can start placing bets. If you win your dice game, all your winnings will be directly saved in your wallet. Just transfer the money to your bank account, and it's all yours!"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a strategy for winning in Dice?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Not really! Dice is the easiest and probably the oldest game in the world, and winning it depends on your luck."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a best place to play a dice game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Well, yes! Online casinos offer a variety of dice games. Most importantly, you can play these dice games to win money. Login to Fun88 and start playing the dice game by Spribe to try your luck!"
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Dice Game",
                "description": "Roll the virtual dice and explore other exciting Spribe games at Fun88. Join now for a thrilling gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/dice",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/dice",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "goal": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Goal Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Goal by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/goal"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Where can I play Goal by Spribe?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Go to the online casino at Fun88 and search for the game Goal under the instant game section. You will find it quickly since they always use modern software!"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Goal on devices other than a PC or laptop?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! This online casino game was created in a specific way, and it can be played on any mobile device compatible with Android phones and Apple software."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a strategy to learn before I play Goal?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The goal is a luck-based game. Apart from managing your bets and keeping track of your investment amount so that you never overspend, there is no strategy you need to understand."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Goal Game",
                "description": "Immerse yourself in the Goal game and other exciting Spribe games at Fun88. Join now for a thrilling gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/goal",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/goal",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "hotline": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Hotline Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Hotline by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/hotline"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Hotline Game",
                "description": "Experience the excitement of the Hotline game and discover other captivating Spribe games at Fun88. Join now for a thrilling gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/hotline",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/hotline",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "keno": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Live Keno Instant Game By Spribe | Fun88",
                "description": "Experience the thrill of instant games with Keno by Spribe Gaming at Fun88! Play Keno instant game and win big.",
                "url": "https://www.gofun88.in/instant-games/spribe/keno"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Keno an interesting game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely yes! If you are fond of the lottery, you will love playing Keno. The thing is, unlike the lottery, the Keno results are instant so you immediately know whether you won."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Keno on my phone?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can play Keno by Spribe on your smartphone because it is compatible with both Android and Apple systems."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How much money can I win from Keno?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "That depends on how many of your selected numbers match the game numbers. The use of multipliers is another factor that will affect your winnings and you may win a large amount if many numbers match."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Keno Game",
                "description": "Try your luck with the Keno game and explore other thrilling Spribe games at Fun88. Join now for an exciting gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/keno",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/keno",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "mines": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Mines Game Online By Spribe & Win Real Money at Fun88",
                "description": "Play the Mines game online by Spribe at Fun88 and get a chance to win real money by strategically discovering hidden treasures.",
                "url": "https://www.gofun88.in/instant-games/spribe/mines"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is the Mines game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The Mines game by Spribe on Fun88 requires you to check a boxed field and avoid mines. This is a brilliantly classic and simple game. The basis of the game is that the more areas with stars you open, the more money you will receive when you click Cash Out. With each new safe box, the multiplier increases. However, if you click a box with a mine, you lose. The game boasts a 97% RTP. The minimum multiplier here starts at 1.01x."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it legal to play the Mines game in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is legal to play Mines in India. You can play it on Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is the Mines game similar to Minesweeper?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Mines is an instant game where players aim to demine as many boxes as they can and win a higher payout."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What platforms can I play the Mines game on?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 is your most convenient and secure platform to play Mines. You can play it on your phone, laptop, or other devices."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there different variations of the Mines game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers 28 variations of this game, but Mines from Spribe is the most popular."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can you play the Mines game for real money?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The answer is yes. You need to register on Fun88, deposit funds, select the game, place a bet, and if you win, you can withdraw your winnings instantly."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What strategies can I use to win the Mines game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A few simple strategies that you can use are: Make the best of the Auto feature: This is the highlight of the game; you can automate the process of betting by increasing or decreasing the bet size if you win or lose; you also have the option to cancel autoplay if the balance is above or below a particular level; and so on. Change the number of bombs per round: By increasing the number of mines, your risks become higher, but you can win more after opening each new star. Manage your bankroll: Keep an eye on your funds. This way, you can play more relaxed and focus better on the game. Play Mines for free: With Fun88, you can try a demo version to play for free and get a hold of the game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is the Mines game safe to play online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "On Fun88, you can play the game securely; it complies with all the mandatory guidelines and is secure to play."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can I improve my skills in the Mines game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Mines has very simple and straightforward rules. Play as much as you can and follow the above-mentioned strategies. Practice is the key."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it free to play Mines games online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is. You can also play for real money and win big."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Mines Game",
                "description": "Delve into the Mines game and explore other captivating Spribe games at Fun88. Join now for a thrilling gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/mines",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/mines",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "plinko": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Plinko Online - Pachinko Real Money Casino Game India",
                "description": "Plinko game online excitement awaits! Explore Pachinko, the real money casino game in India. Join the fun, play now, and elevate your gaming with thrilling wins!",
                "url": "https://www.gofun88.in/instant-games/spribe/plinko"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How do I play the Plinko game online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Play the Plinko game online by registering yourself at Fun88. Fill in all your information in the registration section and get your account verified. Make your first deposit, take the welcome bonus and head to the “Instant Games” section to start playing Plinko. Once you reach the next page, click on the game and place your bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the objective of the Plinko game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The Plinko game’s objective is to thoroughly entertain you during your gameplay. It’s a game of chance, so it does not have a lot of room for complex strategies. However, your winning streak also depends on the number of balls you launch and the bets you make. Ensure you get these two parts right and pick the best colour to turn the game in your favour."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How are points or rewards earned in the Plinko game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The Plinko board game has many slots at the bottom, with a different reward or point value. You win a reward whenever the ball lands in a slot. But your reward will differ depending on the slot it landed in."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies to increase my chances of winning in Plinko?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are a few strategies that can boost your chances of winning Plinko. One of them is that you can pick 16 ball launches in red to have the game in your favour, and you can also reduce the risk of losing in the game by picking three colours at once or in a combination."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What happens if I encounter technical issues while playing Plinko?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88’s more than capable support team resolves technical issues while playing Plinko on the platform. You need to connect with the team through a call, email, WhatsApp, or Telegram to resolve your issue immediately. Mail them at cs@fun88india.com or call +91 2250323347."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What age group is the Plinko game suitable for?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The Plinko game generally can be played by people of all ages since it's simple to understand. However, you must be at least 18 years old to play the game online since it needs you to wager money to win rewards in return."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Plinko Game",
                "description": "Enjoy the Plinko game and other thrilling Spribe games at Fun88. Join now for a captivating gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/instant-games/spribe/plinko",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/instant-games/spribe/plinko",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "live-casino": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Live Casinos India: Play Best Online Casino Games at Fun88",
                "description": "Fun88 offers you the best live casino games. Play casino games online like Blackjack, Rolultte, Baccarat, Andar Bahar, and more today. Join and win real money.",
                "url": "https://www.gofun88.in/live-casino"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is playing casino live online in India safe?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, playing casino live online in India is safe, especially on trusted platforms like Fun88. Always ensure the platform has appropriate licences and follows standard safety protocols."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I play live casino games on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To play casino live games on Fun88, sign up for an account, deposit funds, choose a live casino game, and start playing. It is straightforward."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the most popular online live casino games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The most popular online casino live games include blackjack, roulette, and baccarat. These classic games attract a vast number of players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do I have to pay taxes on my online casino live winnings?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Tax regulations vary by country. In India, for instance, you might be required to pay tax on gambling winnings. Always consult a local tax advisor for clarity."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the system requirements to play live casino games online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Most modern computers and smartphones can run live casino games. Ensure you have a stable internet connection for uninterrupted play."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the difference between online and live casino games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In regular casino games, live dealers are not involved, also not played in real-time. However, that’s not the case with live dealer casino games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "When playing live casino games online, can anyone else see my game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the participants of the game can see your online live casino game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum and maximum bet for live casino games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The minimum and maximum bet limits for live casino games can vary from platform to platform and the casino table you choose to play. However, online casino platforms let you know the betting limits before players indulge in the game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are the live casino games fair?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It completely depends on the platform where you play online casino. However, reputed platforms like FUN88 and a few others offer fair slot games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How long does it take to withdraw my winnings?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It usually takes 2 to 5 business days to withdraw winnings. However, the withdrawal time may vary from platform to platform."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do I need to download any software to play live casino games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You don’t necessarily have to download any software to play live casino games. You can visit the FUN88 website to enjoy the gameplay online."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Online Live Casino",
                "description": "Fun88 is a trusted online betting site in India with 100+ online casino games, slots games, & 10K different sports with the best betting odds. Join now for exciting betting online!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Person",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/app-download",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "ag": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Amazing Gaming Live Casino Games at Fun88",
                "description": "Experience the thrill of live casino games by Amazing Gaming at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
                "url": "https://www.gofun88.in/live-casino/ag"
            },
            {
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Fun88",
                        "item": "https://www.gofun88.in/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "Live Casino",
                        "item": "https://www.gofun88.in/live-casino/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "name": "Amazing Gaming",
                        "item": "https://www.gofun88.in/live-casino/amazing-gaming/"
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "andar-bahar": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Andar Bahar Games Online | Best Andar Bahar Sites In India | Fun88",
                "description": "Love playing Andar Bahar games online? Now enjoy Free online Andar Bahar or play online Andar Bahar with friends on Fun88. Register now to play Andar Bahar with real money!",
                "url": "https://www.gofun88.in/live-casino/andar-bahar"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is it legal to play Andar Bahar?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is absolutely legal to play Andar Bahar online in India. There are no such laws that explicitly say playing Andar Bahar online is illegal. Many sports betting websites in India have Andar Bahar in their games catalogue. Be sure to choose a safe and regulated platform bound by international laws on protecting your data and privacy."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Andar Bahar for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Why not? Yes, you can play Andar Bahar for free. However, you can't win any real money when you are playing the game for free."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I get an Andar Bahar bonus?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can get an Andar Bahar online or sports betting online bonus. Check out our guide to get the best possible rewards."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there any viable strategy to increase my chance of winning?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Andar Bahar comes under the category of the game of chance. So, winning at the Andar Bahar card game is mainly depends on your luck. Though it's a simple and entertaining card game, ensure your awareness about the rules and betting before you get into the play."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I cheat online at Andar Bahar?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely not. Though many scammers or software claims to help you win, cheating is impossible. Don't fall for such false promises. It's not true. It's an entirely random card game that you get every time you start a new game, and there are no strategy guides or skills that will improve the odds."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "betgames": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Betgames - Leading Online TV Games Dealer",
                "description": "Discover the best online dealer TV games with Betgames. Enjoy exciting online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/live-casino/betgames"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I use the Fun88 App to play TV games on my mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the Fun88 TV games are totally responsive."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What games can I play online on my Smart TV?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Betgames, our official TV games partner, provides 12 games that you can play on your Smart TV. These include card games using cards, wheels, and dice, as well as lottery-style games like Dice Duel, Andar Bahar, War of Bets, Speedy 7, and many more."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the best online TV game on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "War of Bets is the best online TV game since it has the best odds and makes it easy to observe who wins and who loses. The TV game may be played 24 hours a day, and the game rounds are quick for betting sessions."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Betgames TV Games",
                "description": "Explore a variety of exciting games with Betgames at Fun88. Join now for an immersive gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/betgames",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "dice-duel": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Dice Duel Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Dice Duel by Bet games on Fun88! Play online and win big with Dice Duel. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/dice-duel"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play Dice Duel on my mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is possible to play Dice Duel on a mobile device and also on desktops with the same quality. Fun88 has created a seamless mobile platform easily accessible to all players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a way to play Dice Duel free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Dice Duel normally requires real money bets before any bet can be placed. However, new entrants could have access to promotions or welcome bonuses that come with free bets that can be used in playing Dice Duel."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How long does it take for winnings to be credited?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 ensures that winnings are processed as fast as they can. Depending on your payment method, the rate of transactional speed may differ though it is usually very swift and efficient."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there strategies that will make me win every time I play Dice Duel?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is no single strategy guaranteed to win at Dice Duel because of its unpredictable nature. However, learning how odds function and managing bets effectively can enhance your playing experience."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "lucky-7": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Lucky 7 Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Lucky 7 by Bet games on Fun88! Play online and win big with Lucky 7. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/lucky-7"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play for free on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Most games on Fun88 require real-money bets, so Lucky 7 is no exception. However, keep checking back with us because sometimes we run promos where players can enjoy some risk-free action without making any deposits!"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can I win every time in this game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Unfortunately there aren’t definite strategies that guarantee wins every round. But if one manages his/her finances wisely while choosing strategic stakes, chances of success will be higher than those opting for blind luck."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What times best suit my luckiest plays at Fun88 Lucky 7?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "This particular slot machine operates all day, every day, throughout the year, giving anyone willing to try out their lucky stars a chance whenever they feel like it."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are my winnings from Lucky 7 liable for taxes?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Winnings from online gaming fall under the Indian government's taxation policies, which vary by local state jurisdiction. Players are advised to consult with a tax professional for guidance and to make informed decisions."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Lucky 7 on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Lucky 7 is developed for mobile play. You can enjoy the game on Fun88's mobile platform whenever and from wherever you want."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Lucky 7 TV Game",
                "description": "Immerse yourself in the excitement of Lucky 7 TV Game at Fun88. Join now for an exhilarating gaming experience with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/betgames/lucky-7",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/betgames/lucky-7",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "dragon-tiger": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Online Dragon Tiger Real Cash Games | Play & Win at Fun88",
                "description": "Experience the thrill of Dragon Tiger with real cash games at Fun88. Play dragon tiger game from top vendors like Evolution and Ezugi for seamless gameplay, and enjoy a secure gaming environment. ",
                "url": "https://www.gofun88.in/live-casino/dragon-tiger"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What are the card values in Dragon Tiger Casino Game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In Dragon Tiger, the cards are ranked from highest to lowest as follows: Ace, King, Queen, Jack, 10, 9, 8, 7, 6, 5, 4, 3, 2. "
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Dragon Tiger's real cash game online on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the Dragon Tiger casino online game is available on Fun88. Create a real money account on Fun88, download the app, and you can start playing in minutes. You can get exciting bonuses and promotions while playing on Fun88. "
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the highest card in Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The highest card in the Dragon Tiger casino game is the King."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the odds of winning in Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The odds of winning in Dragon Tiger depend on the bet made. Betting on either the Dragon or the Tiger carries a 1:1 payout and has a 3.73% house edge. Betting on a tie has a payout of 10:1 but carries a much higher house edge of 13.16%."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Live Casino: Dragon Tiger",
                "description": "Dive into the captivating world of Dragon Tiger at Fun88. Join now for live casino games and exclusive bonuses. Experience the thrill of an exhilarating gaming adventure!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/dragon-tiger",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "evolution": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Discover Evolution's Live Casino Games Online in India | Fun88",
                "description": "Level up your gaming experience with Evolution's live casino. Experience real-time games, interactive dealers & immersive excitement. Don't miss out, join now!",
                "url": "https://www.gofun88.in/live-casino/evolution"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "american-roulette": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "American Roulette - Play Online Roulette by Evolution Gaming",
                "description": "Play American Roulette online with Evolution Gaming ✓ Experience best roulette action ✓ Sign-Up Not Required ✓ Game Demo Version Available ✓ Get 100% welcome bonuses.",
                "url": "https://www.gofun88.in/live-casino/evolution/american-roulette"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What makes American Roulette different from other types?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "One key difference between European or French Roulette is that the wheel in an American version has both single zero pockets as well as double zeros (00), making a total number of 38 compartments altogether."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How does the house edge differ in American Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In comparison with European or French variations, there exists a greater house advantage in terms of one extra pocket – 00."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is American Roulette mobile compatible?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It is possible to play Evolution Gaming’s American Roulette on Fun88 website either through a computer or any other portable device."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution American Roulette",
                "description": "Play the classic with Evolution American Roulette at Fun88. Join now for an authentic live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/american-roulette",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/american-roulette",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "baccarat": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Live Baccarat Online By Evolution Gaming | Fun88",
                "description": "Play Live Baccarat Online by Evolution Gaming at Fun88: Experience the thrill of real-time gaming and win big with our exciting live casino!",
                "url": "https://www.gofun88.in/live-casino/evolution/baccarat"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What's the best bet to make in Baccarat by Evolution?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Based on stats, choosing a bet would be the smartest selection as it offers the highest probability of winnings."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is any strategy foolproof in Baccarat?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Betting strategies only advise what bets to make while avoiding others. Still, none is completely sure due to its unpredictability, as pure luck decides everything here."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Baccarat by Evolution on mobile devices?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. It is optimised for mobile gaming; thus, you can play Baccarat anywhere."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are live dealers present in online Baccarat games on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Of course, Evolution’s Baccarat at Fun88 is manned by professional dealers, making it as close to a real-life casino as possible."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What tools can I use to improve my Baccarat gameplay?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "So, to perfect your gaming decisions, consider practising and using tools like bet and hand trackers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it safe to play Baccarat online on Indian casino sites?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Several measures have been put in place on platforms like Fun88 that guarantee secure gambling."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Baccarat",
                "description": "Play the classic card game at Fun88. Join now for an authentic live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/baccarat",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/baccarat",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "blackjack": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Live Blackjack Game Online for Real Money by Evolution",
                "description": "Play Blackjack online for real money with Evolution's top-tier games. Enjoy live dealers and seamless gameplay. Start winning big and join now Fun88!",
                "url": "https://www.gofun88.in/live-casino/evolution/blackjack/"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How many players can play Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Usually, a table of two to seven people play Blackjack. A standard deck of 52 cards is used to play this fascinating card game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the values of the cards in Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Number cards (2-10) hold their face value. Face cards (King, Queen, Jack) are worth 10. Aces are worth either 1 or 11."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the common actions a player can take during their turn?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are four actions a player can take during their turn: Hit, where you take another card; Stand, where you keep your current hand; Double Down, where you double the initial bet and take one more card; and Split, where you separate two cards of the same value into two hands."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is 'insurance' in Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "An insurance bet in Blackjack is a side bet you can make when the dealer's face-up card is an Ace. In an insurance bet, you are betting that the dealer has a Blackjack, and if that happens to be the case, the payout is 2:1."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is card counting, and is it legal?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Card counting is a popular blackjack strategy used to determine if the dealer or the player will have an advantage on the next hand. By keeping a running count of high- and low-valued cards dealt, players try to overcome the house edge. While card counting is not illegal, it is considered cheating in the gambling world. Casinos do not favor players who use this strategy."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many decks are used in online Blackjack at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 hosts different types of Blackjack games, similar to those found at offline casinos, that use between one and eight decks."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the safest bet in Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Usually, the safest bet in Blackjack is considered even money; if you win, you will keep your original stake plus another equal amount."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Blackjack",
                "description": "Play the iconic card game at Fun88. Join now for an authentic live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/blackjack",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/blackjack",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "crazy-time": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Crazy Time Live Casino Game by Evolution: Bet on Fun88",
                "description": "Discover the excitement of Crazy Time casino game at Fun88. Sign up today and enter the world of live crazy fun to win real money!",
                "url": "https://www.gofun88.in/live-casino/evolution/crazy-time"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Where can I play Crazy Time casino in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can play Crazy Time online at the Fun88 casino! Just register on Fun88 and start playing."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to play Crazy time casino games at Fun88 casino?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "All you have to do is register on the Fun88 website or download our app, available in both Android and iOS variants. Visit the 'Live Casino' tab on our website to play Crazy Time live."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum bet in Crazy Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The minimum bet varies but typically starts from as low as £0.10, depending on the platform."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the maximum bet in Crazy Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Based on the casino's terms, the maximum bet can go up to £1,000 or more."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "When do I get a bonus when playing the Crazy Time game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "According to Evolution Gaming, a bonus round will pop up approximately once every sixth spin of the wheel, providing numerous chances to win. The game also offers Crazy Time results to help players make informed decisions."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a Crazy Time live casino dealer on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the live dealers in the Crazy Time casino game are known as Game Presenters. They entertain and maintain a high energy level during the game, differing from conventional live casino dealers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I track the results of the Crazy Time game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can track Crazy Time evolution by examining the summary results of the Crazy Time tracker's last spins to establish stats and upcoming results."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the betting time for Crazy Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The betting time is 13 seconds, so you should bet instantly."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What's the bonus prize payout of Crazy Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Most bonus prizes will be paid out between 10x and 25x."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is a Crazy Time history tracker?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Crazy Time live trackers show the outcome and insights into how the game is progressing. The history tracker displays game statistics distinctly, including Crazy Time results, the number of winners, and the total reward."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Who is the provider of live Crazy Time casino?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Evolution Gaming is a leading live dealer casino gaming company that created and operates the Crazy Time live casino game. The game is only available through online casinos like Fun88, which have a partnership with Evolution Gaming."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many bonus games are there in Crazy Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Crazy-Time features four exciting bonus games: Cash Hunt, Pachinko, Coin Flip, and Crazy-Time."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "evo-dragon-tiger": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Dragon Tiger Real Cash Game Online by Evolution at Fun88",
                "description": "Play dragon tiger casino game online for real money at Fun88 and experience the excitement of 🐲 dragon vs. tiger 🐯 showdown! Download the evolution game app now.",
                "url": "https://www.gofun88.in/live-casino/evolution/dragon-tiger"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is Live Dragon Tiger by Evolution?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Live Dragon Tiger by Evolution is an exciting live casino card game where players bet on which side, the dragon or the tiger, will receive a higher-ranking card. It offers rapid gameplay and the potential to win real cash rewards."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I play Dragon Tiger for real cash?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Playing Dragon Tiger for real cash is simple: Place your bets on the dragon, tiger, or a tie. Two cards are drawn, one for the dragon and one for the tiger. The side with the higher card wins, and you receive a payout based on your bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Dragon Tiger suitable for beginners?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Dragon Tiger is an ideal game for beginners due to its straightforward rules and rapid pace. You don't need complex strategies to enjoy the game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the betting options and payouts in Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Dragon Tiger offers several betting options: Dragon or Tiger: 1:1 payout. Tie Bet: 11:1 payout. Dragon or Tiger Suited Tie: 50:1 payout."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies for playing Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While Dragon Tiger is primarily luck-based, here are some strategies to enhance your experience: Manage your bankroll responsibly. Consider avoiding the tie bet due to its higher house edge. Look for patterns, although results are ultimately random."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I predict card outcomes in Dragon Tiger based on patterns?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Predicting card outcomes in Dragon Tiger based on patterns can be challenging due to the game's randomness. Each card draw is independent of previous results, ensuring fairness and unpredictability."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Dragon Tiger fair and reliable?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Dragon Tiger by Evolution uses reliable and fair shuffling methods to ensure the randomness of card outcomes. Evolution Gaming is a trusted provider known for its fairness and integrity."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Live Dragon Tiger on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, many online casinos offer the option to play Live Dragon Tiger on mobile devices. You can enjoy the game on your smartphone or tablet for added convenience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I play Live Dragon Tiger by Evolution?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can play Live Dragon Tiger by Evolution at Fun88, a reputable online casino that offers Evolution Gaming's live casino games. Fun88 is a licensed and trusted casino platform to enjoy this exciting card game."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Dragon Tiger",
                "description": "Experience the excitement of Evolution Dragon Tiger at Fun88. Join now for a fast-paced live gaming adventure with top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/dragon-tiger",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/dragon-tiger",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "dream-catcher": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Dream Catcher Game | Live Casino Evolution Game | Fun88",
                "description": "Play Live Dream Catcher at Fun88 with Evolution! Explore dream catcher rules, play guidelines, and win real money online. Start your winning journey now!",
                "url": "https://www.gofun88.in/live-casino/evolution/dream-catcher"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is Live Dream Catcher?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Live Dream Catcher is an interactive online casino game at Fun88, combining elements of a money wheel and a game show for an exciting gaming experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I play Live Dream Catcher?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Place bets on numbers (1, 2, 5, 10, 20, or 40) on the wheel. The live dealer spins the wheel, and if it lands on your number, you win!"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the betting options?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Bet on numbers for different payouts, including 1, 2, 5, 10, 20, and 40. You can place multiple bets in one round."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Live Dream Catcher fair?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it's fair. Fun88 uses certified random number generators to ensure randomness and fairness."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I interact with the live dealer and players?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, there's a live chat feature for real-time interaction with the dealer and other players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the RTP for Live Dream Catcher?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "RTP varies: 1 (96.58%), 2 (95.18%), 5 (91.08%), 10 (96.21%), 20 (92.23%), 40 (90.57%)."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play on mobile devices?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Live Dream Catcher is mobile-optimized for gaming on smartphones and tablets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there winning strategies?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No guaranteed strategies; it's a game of chance. Play responsibly within your budget."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I make deposits and withdrawals?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Use secure payment methods like cards, e-wallets, and bank transfers at Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Live Dream Catcher available 24/7?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can play it anytime, 24/7, at Fun88."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Dream Catcher",
                "description": "Catch your dreams with Evolution Dream Catcher at Fun88. Join now for a dreamy live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/dream-catcher",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/dream-catcher",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "emperor-sic-bo": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Emperor Sic Bo A Casino Game by Evolution Gaming | Fun88",
                "description": "Enjoy the excitement of Emperor Sic Bo, a thrilling casino game by Evolution Gaming. Play now and experience the ultimate gaming experience!",
                "url": "https://www.gofun88.in/live-casino/evolution/emperor-sic-bo"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is Emperor Sic Bo A?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Evolution Gaming developed Emperor Sic Bo A as a live casino game where players roll three dice in another version of traditional Sic Bo. This version offers a live dealer experience and the possibility of winning real money."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I start playing Emperor Sic Bo A on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To start playing, create an account on Fun88 or log into your existing one, deposit funds, visit the live casino section, choose Emperor Sic Bo A, and place your bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there strategies for winning at Emperor Sic Bo A?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Though Sic Bo is largely a game of chance, understanding the betting options and managing your bankroll can increase your chances of winning."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Emperor Sic Bo A on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Emperor Sic Bo A is fully optimised for mobile gaming so you can enjoy it via the Fun88 website on your mobile browser or through the Fun88 app."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Emperor Sic Bo",
                "description": "Enjoy the excitement of Emperor Sic Bo at Fun88. Join now for an authentic live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/emperor-sic-bo",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/emperor-sic-bo",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "first-person-deal-or-no-deal": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play First Person Deal or No Deal by Evolution at Fun88",
                "description": "Explore First Person Deal or No Deal by Evolution Gaming at Fun88 for thrilling gameplay and the chance to win big rewards!",
                "url": "https://www.gofun88.in/live-casino/evolution/first-person-deal-or-no-deal"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is First Person Deal or No Deal available on mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the game is mobile-optimised, so you can play it on the go using the browser of your smartphone or tablet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum bet amount?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The bet amount is regulated by a casino, so it can vary. Check the information of each casino on Fun88 for more details."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play First Person Deal or No Deal for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Some casinos offer a demo version for practice. To win, you have to play for real money."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a time limit to make decisions?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You have a limited amount of time to decide on eliminating cases or accepting the Banker’s offer."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a skill involved in First Person Deal or No Deal?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Of course, in addition to luck, you need to understand the game mechanics, analyse coincidences, and eliminate cases in the order that seems more appropriate to you."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "funky-time": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Funky Time Live Casino Game by Evolution Gaming at Fun88",
                "description": "Play Funky Time exciting live casino game show by Evolution with Epic multiplier real money prize. Bet today and win big rewards!",
                "url": "https://www.gofun88.in/live-casino/evolution/funky-time"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is the difference between the regular Disco Bonus and the VIP Disco Bonus?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The main difference lies in the potential payouts. The VIP Disco Bonus features a larger board with a wider range of multipliers, allowing for potentially bigger wins. Additionally, the VIP Disco Bonus is exclusive to players who place higher bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What's the most exciting part of Funky Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "This depends on your preference! The vibrant atmosphere, funky music, and live dealer interaction are all big draws. However, many players find the bonus games to be the most exciting part due to their unique features and the potential for big wins."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Funky Time a good game for beginners?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! Funky Time's clear interface and engaging gameplay make it a great choice for both seasoned players and newcomers to the world of live casino games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I play Funky Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Funky Time is available online casinos offering Evolution Gaming live dealer games. Check with your preferred online casino to see if they offer this groovy game."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I find a casino that offers Funky Time?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Many online casinos offer Funky Time Evolution Games. Do some research to find a reputable online casino with a good selection of live dealer games, including Funky Time. Remember to check for any welcome bonuses or promotions they might offer for new players."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Funky Time",
                "description": "Indulge in the fun with Evolution Funky Time at Fun88. Join now for an entertaining live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/funky-time",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/funky-time",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "hindi-lightning-roulette": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Online Hindi Lightning Roulette by Evolution at Fun88",
                "description": "Experience the excitement of playing Play Online Hindi Lightning Roulette by Evolution Gaming at Fun88. Immerse yourself in thrilling gameplay and win big rewards!",
                "url": "https://www.gofun88.in/live-casino/evolution/hindi-lightning-roulette"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play Hindi Lightning Roulette with real money?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! Most online casinos offer players the option to play with real money. If you want to play with real money and earn a significant amount through the games, go to Fun88 and log in to start playing."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Hindi Lightning Roulette for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Some casinos, including Fun88, offer a demo mode so the players can practice the game before they start playing with real money. However, Hindi Lightning Roulette is a live game, so you can’t play the full version for free."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Hindi Lightning Roulette on my mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, of course! All Fun88 games can be played on any mobile phone since they have mobile-compatible versions of online casino games, including Hindi Lightning Roulette."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "lightning-dice": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Lightning Dice Casino Game by Evolution Gaming | Fun88",
                "description": "Experience the thrill of a live casino game with Lightning Dice by Evolution Gaming at Fun88! Play casino online and win big with Lightning Dice.",
                "url": "https://www.gofun88.in/live-casino/evolution/lightning-dice"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Lightning Dice a game of skill or luck?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Lightning Dice is primarily a game of luck. The outcome of the dice rolls and the \"Lightning Strikes\" are random events. However, there's a slight element of strategy involved. Players can choose their bets based on risk tolerance and utilise strategies like varying stake sizes or focusing on specific bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the house edge in Lightning Dice?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The house edge in Lightning Dice varies slightly depending on the casino and the specific bets offered. In general, it falls within the range of 2-3%. This means the casino has a built-in advantage in the long run."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the best bets in Lightning Dice?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There's no single \"best\" bet in Lightning Dice. The optimal choice depends on your playing style and risk tolerance. For those seeking frequent smaller wins, side bets like \"High/Low\" can be a good option. Players looking for the potential of high payouts with lower win frequency might prefer specific dice totals."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I win big by playing Lightning Dice?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! The \"Lightning Strikes\" multipliers offer the potential for significant wins. Landing on a lucky number with a high multiplier can lead to substantial payouts. However, remember that winning relies heavily on chance, and responsible bankroll management is crucial."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I play Lightning Dice online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Lightning Dice is available at many reputable online casinos that offer live dealer games. Fun88 is one of the reputed platforms you can choose to play."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Lightning Dice",
                "description": "Feel the thrill with Evolution Lightning Dice at Fun88. Join now for an electrifying live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/lightning-dice",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/lightning-dice",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "lightning-roulette": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Lightning Roulette Live Casino Game by Evolution Gaming",
                "description": "Get ready for non-stop excitement with Lightning Roulette at Fun88! Attempt to win real money online by playing live roulette casino games.",
                "url": "https://www.gofun88.in/live-casino/evolution/lightning-roulette"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What makes Lightning Roulette different from traditional roulette games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The best part of Lightning Roulette is that it has more chances of winning. Another significant difference is the inclusion of lucky numbers and multipliers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Lightning Roulette on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the Fun88 mobile app allows players to access this game anywhere on their smartphones or tablets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a strategy to win at Lightning Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The game is mainly determined by luck, although managing the bankroll professionally and learning more about it can enhance the experience, increasing the chances of winning."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are the Lightning Strikes in Lightning Roulette truly random?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely yes because they are generated by RNG (Random Number Generator) thus providing fairness in gambling."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the maximum multipliers I can hit in Lightning Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The maximum multipliers from Lightning Roulette can reach 500x, an enormous chance for the luckiest."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Lightning Roulette",
                "description": "Indulge in the excitement of Evolution Lightning Roulette at Fun88. Join now for a thrilling live gaming experience with lightning-fast action and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/lightning-roulette",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/lightning-roulette",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "monopoly-big-baller": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play MONOPOLY Big Baller Live Casino Game by Evolution",
                "description": "Join the action in MONOPOLY Big Baller by Evolution. Play live, roll the dice, and enjoy big wins and bonuses!",
                "url": "https://www.gofun88.in/live-casino/evolution/monopoly-big-baller"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is the bonus game in Monopoly Big Baller?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In the bonus game, Mr. Monopoly walks across the board in an augmented reality 3D bonus round. The bonus triggers primary features like chance and community chest, tax square, go to jail, and multiplier."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I play Monopoly Big Baller?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Play Monopoly Big Baller on Fun88 for the best rewards and perks. Enjoy a wide range of thrilling games, utilize bonuses, and experience a user-friendly interface for an engaging gaming experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the maximum multiplier?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is no maximum limit on the multiplier, but there is a limit to a single-player payout."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a strategy for Monopoly Big Baller?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Monopoly Big Baller has several strategies, including betting on two of each card and two bonus cards together. Implement the chance strategy to increase your chances of winning lucrative multipliers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many Bingo Balls are drawn each game round?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Twenty bingo balls are drawn in each round of Big Baller out of a total of sixty balls."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the probability of Monopoly Big Baller?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The probability of winning with Big Baller is 96.10%, increasing your chances of acing the game and earning great rewards."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "monopoly-live": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play MONOPOLY Live Casino Game by Evolution Gaming at Fun88",
                "description": "Experience the fun of MONOPOLY Live Casino Game by Evolution Gaming. Play now and win real money with live casino games at Fun88.",
                "url": "https://www.gofun88.in/live-casino/evolution/monopoly-live"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Monopoly Live rigged?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It may depend on the platform you choose to play. Reliable platforms like Fun88 offer purely random and luck-based gameplay. The platform adheres to strict fair play and transparency standards, ensuring an honest and reliable gaming experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play monopoly game on the Fun88 app/apk?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can play the Monopoly Live game on the Fun88 App! Create a real money account on Fun88, download the App, and you can start playing in minutes."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Does the Monopoly Live casino game have a live dealer?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Monopoly Live Evolution gaming's another exciting game features a live dealer who hosts the game, spins the wheel, and interacts with players through the chat feature."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the maximum win for a Monopoly Live game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The biggest win varies, but players can achieve multipliers of up to 10,000x in the bonus rounds, leading to substantial payouts."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the best colours in the Monopoly Live game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In Monopoly Live, there isn't a \"best\" colour as success depends on predicting the wheel's outcome. However, the wheel segments coloured yellow ('1' and '10'), blue ('2'), green ('5'), and red ('Chance') are significant for their respective payouts and bonus features, offering varied opportunities for players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can anyone cheat on a Monopoly Live game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No, a player cannot cheat while playing a Monopoly Live game. The software provider, Evolution Gaming, is a leading gaming developer licensed by UKGC. The results of the game are random and fair."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are Monopoly Live odds?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The odds are dependent on the bets you choose. If you bet on “1,” there is a 40% chance of winning, and this bet's payout value would be 1:1 (or 2.00)."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the RTP for the Monopoly Live game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The RTP (return to the player) of the Monopoly Live game is 96.23%, but it could vary from 91.30% to 96.23%, depending on your chosen bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Where can I play Monopoly Live?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Monopoly Live is available on many online casinos, including Fun88, Betway, and LeoVegas. Ensure you choose a licensed and regulated platform for a safe gaming experience."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Monopoly Live",
                "description": "Embark on a captivating live gaming journey with Evolution Monopoly Live at Fun88. Join now for an interactive experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/monopoly-live",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/monopoly-live",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "roulette": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Live Roulette Casino Game by Evolution Gaming at Fun88",
                "description": "Play Live Roulette by Evolution Gaming at Fun88 and enjoy an immersive casino experience with real-time gameplay. Spin the wheel and win real money today!",
                "url": "https://www.gofun88.in/live-casino/evolution/roulette"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is it legal to play online Roulette in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While specific gambling laws vary across Indian states, many offshore online casinos legally allow Indian players to enjoy roulette games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I access Fun88’s online roulette from my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, our website is mobile-friendly and allows you to access the complete website, including our guides, reviews, and strategy sections."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many types of Roulette are there?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Primarily, there are three main types of Roulette. These are European, American, and French Roulette. Online platforms have introduced several other variants."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I use software to win at online Roulette India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Using third-party software to win is not essential. While there may be software that claims to aid in winning, there is no guarantee that such a tool will be successful."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to win at Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While Roulette is primarily a game of chance, understanding the odds, payouts, and a good betting strategy can enhance your chances of winning."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What does 00 mean in online Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The '00' or double zero is a feature of American Roulette. It is an extra slot on the roulette wheel, making the total number of slots 38 instead of 37 in European Roulette."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the safest bet in online Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is no certain way to guarantee a win. The bets with the highest probability of success are the 1:1 bets, such as red/black and odd/even. However, even these bets are still subject to luck and have no guarantees."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the five-number bet in Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The five-number bet, available only in American Roulette, involves betting on 0, 00, 1, 2, and 3. It has the highest house edge of all bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How is the European/French wheel different from the American wheel?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The European and French version of Roulette has 37 pockets in total: 36 numbers and a single zero. The American version, however, has 38 pockets, which includes the double-zero. This additional pocket also allows for extra betting options."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum bet for roulette on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The minimum bet in roulette can vary depending on the casino and the specific roulette game you're playing. It's essential to check the game's betting limits before playing. In most cases, online casinos offer a range of betting options to accommodate both low and high-stakes players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are live dealer versions of roulette available on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Surely, we have live dealer roulettes where you can get the same kind of thrill experienced in live casinos right from your device."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I withdraw my roulette winnings from Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Withdrawals are done through the cashier section at Fun88 following withdrawal methods plus terms stated therein – generally including basic verification processes aiming at ensuring security."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Roulette",
                "description": "Explore the classic charm of Evolution Roulette at Fun88. Join now for an authentic live gaming experience with timeless gameplay and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/roulette",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/roulette",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "super-andar-bahar": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Super Andar Bahar Live Casino Game by Evolution Gaming",
                "description": "Experience the thrill of a live casino game with Super Andar Bahar by Evolution Gaming at Fun88! Play casino online and win big with Super Andar Bahar.",
                "url": "https://www.gofun88.in/live-casino/evolution/super-andar-bahar"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Super Andar Bahar",
                "description": "Experience the super version of Andar Bahar with Evolution Super Andar Bahar at Fun88. Join now for an extraordinary live gaming adventure and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/super-andar-bahar",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/super-andar-bahar",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "evo-teen-patti": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Teen Patti Live Casino Game by Evolution Gaming | Fun88",
                "description": "Enjoy the excitement of a live casino with Teen Patti by Evolution Gaming on Fun88! Play online and win big with Teen Patti.",
                "url": "https://www.gofun88.in/live-casino/evolution/teen-patti"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How does Evolution's Teen Patti on Fun88 differ from others?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers an enhanced and optimised online experience for this popular Indian card game by partnering with Evolution Gaming while infusing it with modern elements."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Free games before risking money in Teen Patti at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Many casinos have free versions that allow people to practice their skills."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are some tips for my first time playing Teen Patti at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Beginners should go easy with small amounts, learn the rules and practice bankroll management."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there fairness and safety in playing Teen Patti in Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun 88 provides a reliable and fair environment to ensure players' trustworthiness."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies for winning Teen Patti on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Tips like being aggressive when having good hands and bluffing less can help increase your chances of winning, although there is no guarantee of winning."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution Teen Patti",
                "description": "Play the traditional game with a twist at Fun88. Join now for an interactive live gaming experience and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/teen-patti",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/teen-patti",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "xxxtreme-lightning-roulette": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play XXXtreme Lightning Roulette Online Game by Evolution",
                "description": "Enter the world of XXXtreme Lightning Roulette by Evolution. Play online and win big with this amazing casino game.",
                "url": "https://www.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play XXXtreme Lightning Roulette on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, at Fun88, we offer optimised mobile play for XXXtreme lighting roulette, which means you can spin wherever and whenever you like."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is online roulette at Fun88 fair and regulated?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely yes! Fun88 holds valid licences, and its offerings, such as XXXtreme lighting roulette, are regularly audited for fair gameplay."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the minimum bet for XXXtreme Lightning Roulette on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It’s pretty much variable, though Fun888 caters to both high-stakes rollers and those who wish to keep things low-key."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there strategies to guarantee a win in XXXtreme Lightning Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No strategies could promise winnings since this game is very much based on chance, but knowing the rules and tips could improve your gaming experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I withdraw my winnings from XXXtreme Lightning Roulette on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To withdraw your winnings, go to the cashier section on Fun88, choose a method of payment you are most comfortable with, and follow simple directions."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Evolution XXXtreme Lightning Roulette",
                "description": "Take your live gaming experience to the extreme with Evolution XXXtreme Lightning Roulette at Fun88. Join now for high-stakes action and top-notch betting odds!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "ezugi": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Ezugi Live Casino Games at Fun88",
                "description": "Join Fun88 to enjoy live casino games by Ezugi Games. Dive into the action and win big with our thrilling games! Play Now!",
                "url": "https://www.gofun88.in/live-casino/ezugi"
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Ezugi",
                "description": "Join Fun88 and enjoy Ezugi live casino games, a trusted online betting site in India. Explore a variety of gaming options now!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/mobile-app/",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in/"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/ezugi/",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "ezugi-dragon-tiger": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Dragon Tiger Live Casino Game by Ezugi | Fun88",
                "description": "Enjoy the excitement of a live casino with Dragon Tiger by Ezugi Gaming on Fun88! Play online and win big with Dragon Tiger.",
                "url": "https://www.gofun88.in/live-casino/ezugi/dragon-tiger"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is there a mobile version of Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Of course! You may play Dragon Tiger on your tablet or smartphone at most respectable online casinos. Most of those casinos optimized their systems for mobile users to draw new customers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I get a free game of Dragon Tiger?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! You may get a feel for Dragon Tiger in a demo or free-play mode. A lot of online casinos, including Fun88, offer this option before you start making a real money wager."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I win at Dragon Tiger by using a strategy?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! You may use specific methods to improve your performance! People think that Dragon Tiger is mostly a luck-based game, but it is also critical to keep in mind that there is always a card-counting strategy."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any Dragon Tiger variant games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While the fundamentals of Dragon Tiger stay the same, certain casinos could make minor tweaks in the game to make it seem different. They also use side bets to up the ante on the action. By using random number generators (RNGs) to generate card results, Dragon Tiger assures fairness when played at trustworthy and regulated online casinos."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Dragon Tiger",
                "description": "Join Fun88 and play Dragon Tiger, a thrilling Ezugi live casino game. Enjoy a trusted online betting experience in India!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/ezugi/dragon-tiger",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "one-day-teen-patti": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Live One Day Teen Patti Online By Ezugi Gaming | Fun88",
                "description": "Play Live One Day Teen Patti Online by Ezugi Gaming at Fun88: Experience the thrill of real-time gaming and win big with our exciting live casino!",
                "url": "https://www.gofun88.in/live-casino/ezugi/one-day-teen-patti"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is One Day Teen Patti different from regular Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! While the hand rankings are the same, One Day Teen Patti is a live dealer game where you bet on Player A or B winning, not on your own hand. Additionally, the back-and-lay betting system allows you to place bets on both winning and losing outcomes."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What's the advantage of back and lay betting in One Day Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Back-and-lay betting allows for strategic gameplay. You can hedge your bets, lock in some profit early, or even profit even if your initial prediction of the winner is wrong."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I know which player to bet on in One Day Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There's no guaranteed way to predict the winner. However, you can analyse the revealed cards and the dynamic odds to make informed bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is One Day Teen Patti available on mobile at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! Fun88 offers a mobile-friendly platform so, you can experience the thrill of One Day Teen Patti from anywhere with a stable internet connection."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are some responsible gambling practices for One Day Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Always set a budget and stick to it. Never chase losses, and take breaks to avoid gaming fatigue. Fun88 also offers resources and tools to promote responsible playing."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "7-up-7-down": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play 7 Up 7 Down Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of 7 Up 7 Down by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/7-up-7-down"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "k-andar-bahar": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Andar Bahar Card Game By Kingmaker | Fun88",
                "description": "Enjoy the excitement of a card game with Andar Bahar by Kingmaker on Fun88! Play online and win big with Andar Bahar. Enjoy boundless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/andar-bahar"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play Andar Bahar online legally while in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely yes. Playing Andar Bahar online is legal in India on platforms registered and hosted overseas like Fun88 which follow regulatory standards."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the minimum and maximum bets on Fun88’s Andar Bahar?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The minimum and maximum amounts one can bet in any given Andar Bahar game room on Fun88 may differ accordingly. Before you start playing, check out the rules and regulations at Fun88.com."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How long does a game of Andar Bahar take on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A single round of Andar Bahar is very fast, usually taking just a few minutes. This makes it perfect for people who want quick and exhilarating gameplay."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies to win in Andar Bahar?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Although largely based on luck, players suggest choosing either side (Andar or Bahar) to play out their rounds. Manage your bankroll prudently while utilising patterns but always play responsibly."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "bonus-dice": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Bonus Dice Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Bonus Dice by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/bonus-dice"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Bonus Dice by Kingmaker appropriate for beginners?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, because it has a direct mode of play that makes the game accessible to new players while incorporating the element of live dealers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I deposit money to play?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Various money deposit options exist, including UPI, e-wallets, and bank transfers. Fun88 has several safe deposit methods suitable for the Indian market."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Bonus Dice by Kingmaker on mobile?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! The Fun88 platform is mobile-friendly so that you can enjoy an uninterrupted gaming experience on any device."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I employ any tips and tricks to win at Bonus Dice?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Though this is largely a chance-based game, educating yourself on the rules and diversifying your bets can possibly improve your winning odds."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "coin-toss": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Coin Toss Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Coin Toss by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/coin-toss"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Fun88 safe for playing Coin Toss by Kingmaker?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Fun88 is a trustworthy platform that protects its patrons from any kind of danger, including Coin Toss by Kingmaker."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Coin Toss on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Certainly, Fun88 is adapted for mobile devices, making it possible to play various games at once from the phone."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies for winning at Coin Toss?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Coin Toss is a game of pure luck; strategies are more about managing finances than influencing the game's outcome."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I use bonus funds to play Coin Toss?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, bonus funds from specific promotions and bonuses provided by Fun88 can be used in Coin Toss."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What should I do if I encounter issues while playing?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 provides a strong customer support system. Contact us by email or phone for assistance with any gaming-related problem."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "color-game": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Color Game Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Color Game by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/color-game"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play Color Game by Kingmaker on my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Fun88 is compatible with mobile devices, thus enabling you to play Color Game by Kingmaker anywhere."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a strategy to win in this game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Although mostly depending on luck, playing in a disciplined manner may be helpful."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I deposit money to play?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 provides many ways for users to fund their accounts such as credit cards or debit cards, e-wallets, UPI and bank transfers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are my winnings from Color Game by Kingmaker taxable?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, winnings from online games are subject to tax according to the laws of India."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "jhandi-munda": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Jhandi Munda (Langur Burja) Card Game By Kingmidas",
                "description": "Play Jhandi Munda by Kingmidas, a thrilling card game of chance. Explore Langur Burja game rules, strategies, and start to win real money today!",
                "url": "https://www.gofun88.in/live-casino/kingmaker/jhandi-munda"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Jhandi Munda a game of skill or chance?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Jhandi Munda is primarily a game of chance, as the outcome relies on the roll of the dice."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the different bet amounts on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers a range of betting options to suit various bankrolls. Explore the platform to see the specific minimum and maximum bet limits for Jhandi Munda."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I withdraw my winnings?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 provides various secure withdrawal methods. Check their website for detailed instructions on their withdrawal process."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is there a live dealer Jhandi Munda option?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Currently, Fun88 offers a standard online version of Jhandi Munda. Live dealer options may be available for other casino games on the platform."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What other Indian games can I play on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers a diverse selection of Indian casino games, potentially including Teen Patti, Andar Bahar, and variations of Roulette and Poker with an Indian theme."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Jhandi Munda",
                "description": "Discover the excitement of Jhandi Munda at Fun88, a reputable online betting site in India. Enjoy live card games, claim exclusive bonuses, and join now for an immersive gaming experience!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/kingmaker/jhandi-munda",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "thai-fish-prawn-crab": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Thai Fish Prawn Crab Card Game By Kingmaker | Fun88",
                "description": "Take your gaming to the next level with Thai Fish Prawn Crab by Kingmaker on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/thai-fish-prawn-crab"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "triple-chance": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Triple Chance Card Game By Kingmaker | Fun88",
                "description": "Enjoy the excitement of a card game with Triple Chance by Kingmaker on Fun88! Play online and win big with Triple Chance. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/triple-chance"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is there a strategy for winning at Triple Chance?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The fact that this game’s outcome relies heavily on chance means there is no guarantee of victory. Understanding betting odds as well as adopting disciplined bankroll management could boost your chances of winning to a great extent."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How often can I play Triple Chance on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Triple Chance is accessible 24/7 on Fun88 and you may enjoy it any time."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do I have to pay taxes on my winnings?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Per the Indian tax laws, any winnings over a set threshold are liable for taxes. Users are advised to check with a tax expert to understand the taxation."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "live-baccarat": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Live Baccarat Casino Game and Win Real Money at Fun88",
                "description": "Learn more about the online baccarat card game and its thrilling gameplay. Dive into our detailed live baccarat casino to uncover everything about it at Fun88 only.",
                "url": "https://www.gofun88.in/live-casino/baccarat"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Q1. Is Baccarat a game of skill?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Baccarat is primarily a game of chance, but strategies can influence betting decisions, optimizing the chances of winning."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q2. Where can I play Baccarat online for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Many online casinos offer free demo versions of Baccarat, allowing players to practice without risking real money."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q3. Are there any bonuses or promotions for real money Baccarat players?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, several online casinos provide bonuses or promotions for Baccarat players. Always check a casino's offers and terms."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q4. How can I improve my Baccarat game strategy?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Study the game rules, understand betting odds, practice regularly, and stick to a consistent strategy for best results."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q5. What are the different versions of Baccarat?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Popular Baccarat versions include Punto Banco, Chemin de Fer, Baccarat Banque, and Mini Baccarat, each with unique rules."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "live-blackjack": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Blackjack Game Online and Win Real Money at Fun88",
                "description": "Ready to play blackjack online for real money? Discover objectives, strategies, tips, bonuses, and put your skills to the test in the ultimate casino card game.",
                "url": "https://www.gofun88.in/live-casino/blackjack"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Q1. How can I play Blackjack for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Most online casinos offer free demo versions of the blackjack game. Register and choose the demo or free play option."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q2. Is online Blackjack legal in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Laws vary by state. Some states allow online blackjack, while others don't. Always consult local regulations."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q3. How do I play live Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Live blackjack involves a real dealer streamed online. Choose the live blackjack option in your online casino, place your bets, and play as you would in a physical casino."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q4. Can I practice Blackjack for free online?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, many online platforms offer free demo versions for practice."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q5. Is card counting legal in Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Card counting is legal but frowned upon by casinos. Online blackjack games often use multiple decks, making card counting difficult."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q6. Can I play free Blackjack games for real money?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Free games do not offer real money wins. Some casinos offer bonuses or promotions that allow you to earn real money."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q7. What's the best way to win at Blackjack?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Adopting a consistent strategy, understanding game rules, and managing your bankroll is key to improving your odds."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "live-roulette": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Play Roulette Game Online and Win Real Money at Fun88",
                "description": "Get ready for an adventure with our thrilling roulette games. Join the fun and test your luck at the roulette wheel game and win real cash. Your jackpot awaits!",
                "url": "https://www.gofun88.in/live-casino/roulette"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Q1. Is it legal to play online Roulette in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While specific gambling laws vary across Indian states, many offshore online casinos legally allow Indian players to enjoy roulette games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q2. Can I access Fun88’s online roulette from my mobile device?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, our website is mobile-friendly and allows you to access the complete website, including our guides, reviews, and strategy sections."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q3. How many types of Roulette are there?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Primarily, there are three main types of Roulette. These are European, American, and French Roulette. Online platforms have introduced several other variants."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q4. Can I use software to win at online Roulette India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Using third-party software to win is not essential. While there may be software that claims to aid in winning, there is no guarantee that such a tool will be successful."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q5. How to win at Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "While Roulette is primarily a game of chance, understanding the odds, payouts, and a good betting strategy can enhance your chances of winning."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q6. What does 00 mean in online Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The '00' or double zero is a feature of American Roulette. It is an extra slot on the roulette wheel, making the total number of slots 38 instead of 37 in European Roulette."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q7. What is the safest bet in online Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is no certain way to guarantee a win. The bets with the highest probability of success are the 1:1 bets, such as red/black and odd/even. However, even these bets are still subject to luck and have no guarantees."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q8. What is the five-number bet in Roulette?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The five-number bet, available only in American Roulette, involves betting on 0, 00, 1, 2, and 3. It has the highest house edge of all bets."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Q9. How European/French wheel is different from the American wheel?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The European and French version of Roulette has 37 pockets in total: 36 numbers and a single zero. The American version, however, has 38 pockets, which includes the double-zero. This additional pocket also allows for extra betting options."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "playtech": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Playtech Live Casino Games at Fun88",
                "description": "Experience the thrill of live casino games by Playtech at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
                "url": "https://www.gofun88.in/live-casino/playtech"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What games are they responsible for?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Playtech games include poker, particularly its iPoker system, sports betting possibilities, live gaming, and a plethora of other casino games. They have a number of entertainment companies under their belt, like Marvel and HBO, the creators of Game of Thrones, thus they provide a wide variety of themed casino games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Which ones are popular?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Their slot games are undoubtedly their most popular online games, as the field of online slot gambling has such a large following! Their iPoker and Sports Betting are also very popular, but the Slot games are the main attraction. Their most popular slots include White King, Foxy Fortunes, Cat Queen, Ice Run, and Secrets of the Amazon."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Playtech",
                "description": "Explore Playtech live casino games on Fun88, a trusted online betting site in India. Join now for a diverse gaming experience!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/playtech",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "super-spade": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Super Spade Live Casino Games at Fun88",
                "description": "Get ready for non-stop fun with Fun88's Super Spade live casino games. Join us now and enjoy the thrill of gaming!",
                "url": "https://www.gofun88.in/live-casino/super-spade"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What do I need to start playing the Super Spade games on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "All that is required of you is a stable internet connection and an account at Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do Super Spade games play fair?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The games of Super Spade use RNG (Random Number Generator) technology to ensure the fairness of all the results."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do Super Spade games work on mobiles?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Fun88 is a mobile-friendly site so that players can play Super Spade games on the go."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can you win in Super Spade games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Luck plays a significant role in gambling, but knowing the rules and responsible gambling behavior can make your fun better."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Super Spade",
                "description": "Enjoy Super Spade live casino games on Fun88, a trusted online betting site in India. Join now for an exciting gaming journey!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/super-spade",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "teen-patti-20-20": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Teen Patti 20-20 Live Casino By Super Spade | Fun88",
                "description": "Enjoy the excitement of a live casino with Teen Patti 20-20 by Super Spade on Fun88! Play online and win big with Teen Patti 20-20.",
                "url": "https://www.gofun88.in/live-casino/super-spade/teen-patti-20-20"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Is Teenpatti 20 - 20 on Fun88 suitable for all skill levels?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it has different tables with various stakes, so everyone can participate even if you are a beginner or an experienced player."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play Teenpatti 20 - 20 on mobile devices?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Certainly, Fun88 ensures that their games, like Teenpatti 20-20, are mobile optimised."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies specific to Teenpatti 20 - 20 on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are many strategies one can employ. However, it is best to start by betting cautiously and steadily increase the stakes as your understanding of the game improves."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I ensure I’m playing Teenpatti 20 - 20 responsibly on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Set yourself monetary limits or time boundaries and use responsible gaming tools provided by Fun88 if necessary."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "https://schema.org/",
                "@type": "SoftwareApplication",
                "name": "Fun88 - Teen Patti 20-20",
                "description": "Play Teen Patti 20-20 on Fun88, a trusted online betting site in India. Join now for an exciting Super Spade live casino gaming thrill!",
                "screenshot": [
                    "https://www.gofun88.in/fimg/i202309243cc47069e449b7b333f2e536bc5af1.jpg"
                ],
                "operatingSystem": "ANDROID, iOS",
                "applicationCategory": "GameApplication",
                "image": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "installUrl": "https://www.gofun88.in/app-download",
                "author": {
                    "@type": "Organization",
                    "name": "Fun88",
                    "url": "https://www.gofun88.in"
                },
                "contentRating": "Mature 17+",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "ratingCount": "96734"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.gofun88.in/live-casino/super-spade/teen-patti-20-20",
                    "price": "0",
                    "priceCurrency": "INR",
                    "availability": "https://schema.org/InStock"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "teen-patti": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Teen Patti Real Cash: Play 3 Patti Money Games Online",
                "description": "Play teen patti online game and win real cash daily. Choose rooms, compete with real 3 patti players and boost your popular card games earnings! ",
                "url": "https://www.gofun88.in/live-casino/teen-patti"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is Ante in online Teen patti real cash game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Ante is where all players place their bets at the beginning of a round. Only after placing their initial bets, the game begins."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Trail a higher hand than Sequence in Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. Trail is the highest hand in Teen Patti. Second is the Pure Sequence, followed by Run Sequence. Then, colour comes as fourth highest in the rules chart. The last two spots are for Pair and High Card."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do Poker and 3 Patti have similar rules?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Although the gameplay of Teen Patti and Poker is similar, they are not the same. Five cards are used in a standard Poker. However, Teen Patti, often known as Indian Poker, is played with just three cards."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the different hand rankings in Teen Patti?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The hand rankings in Teen Patti are: Trail or Trio Pure Sequence Sequence Color or Flush Pair High Card"
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "login": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fun88 Login - Online Sports Betting and Casino",
                "description": "Log in to your Fun88 account or register for a new one to enjoy a wide range of online sports betting and casino games. Join the excitement today!",
                "url": "https://www.gofun88.in/login"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "loyalty": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fun88 VIP Club",
                "description": "At Fun88 we believe that all the time you spend online should be rewarding and entertaining. We will treat you like royalty. What we want to do is offer you the best VIP service. ",
                "url": "https://www.gofun88.in/loyalty"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "premium-sportsbook": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Bet on B2B Sports Betting: Trusted Source for Sports Wagering",
                "description": "Enjoy the excitement of B2B online betting in India with Fun88! Bet on your favourite B2B teams with ease and win big with Fun88.",
                "url": "https://www.gofun88.in/premium-sportsbook"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What is B2B Sports Gaming at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "B2B Sports Gaming on Fun88 is an online sports provider that lets players bet on various sports and events."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can I start using B2B Sports on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To get started, go to the Fun88 website or download the Fun88 app (available on Android and iOS). Create an account, make a deposit, and you'll be ready to begin betting on your favourite sports."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Which sports and events can I bet on with B2B Sports on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "B2B Sports on Fun88 offers over 182 sports, including popular ones like cricket, football, tennis, and eSports. You may place bets on many events."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "privacy-policy": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Learn Everything About Our Privacy Policy | Fun88 India",
                "description": "At Fun88 India, we are dedicated to keeping your details safe and secure at all times. Check out our Privacy Policy page for more information.",
                "url": "https://www.gofun88.in/help/privacy-policy"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "promotions": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Claim Casino Bonus: Avail 388% Welcome & Free Sign-Up Offers",
                "description": "Discover top casino and sports betting bonuses in India! Grab exclusive casino signup and first-time deposit bonuses for an unbeatable gaming journey.",
                "url": "https://www.gofun88.in/promotions"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How do I claim the casino sign-up bonus?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To claim the casino sign-up bonus, simply register on the Fun88 platform and follow the bonus withdrawal steps."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the eligibility criteria for the free sign-up bonus?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Eligibility criteria may include age and location verification. Ensure you meet these requirements during the registration process."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is the casino bonus applicable to all games on the site?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The bonus may apply to specific games. Review the terms to see which games are eligible for the sign-up bonus."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I combine the sign-up bonus with other promotions?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The ability to combine bonuses varies. Refer to the terms for each promotion to determine if they can be used together."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is a welcome bonus?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A 'welcome bonus' is offered to new bettors who have just registered on the website of a betting platform. It is a reward to attract new punters and encourage them to bet."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the other common types of bonuses offered by sports and casino sites?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Common types of bonuses include daily reload bonus, cashback bonus, and lucky draw prizes. These are offered to various kinds of punters for different purposes."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is a rollover requirement?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A rollover requirement means that you need to bet a certain minimum number of times to become eligible for withdrawing your funds. The specific requirement varies for each offer."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the process of claiming a bonus and rewards?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The process to claim a bonus or reward may differ from offer to offer. Read the terms and conditions of the specific offer to understand how to claim the bonus or reward."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is the most I can win by participating in the lucky draw contests of Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "At Fun88, you can win exciting lucky draw prizes, including a BMW – X7, Suzuki Hayabusa bike, and iPhone 13 Pro, by participating in T20 matches and live casino games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What will happen if I end up accidentally withdrawing my bonus before fulfilling its conditions?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If you accidentally withdraw your bonus before fulfilling its conditions, you'll receive an error message from Fun88's website/app. Contact customer care to discuss options for withdrawing your money without the bonus and related winnings."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What if I don’t receive a bonus when I’m entitled to?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If you don't receive a bonus when entitled to, contact Fun88's customer care 24/7 via Whatsapp, Email, Call, or Telegram for assistance."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I avail of an offer both from Fun88’s website and its mobile app?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can avail of all Fun88 offers either through the website or the mobile app. The terms and conditions remain the same."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "cashback-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Cashback up to 5% on your Losses | Fun88 Official India",
                "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%. T&C apply.",
                "url": "https://www.gofun88.in/promotions/cashback-bonus"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "referral-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Refer Your Friend & Get Rs.1000/- FREE Bonus | Fun88",
                "description": "Want to earn Rs.1000 free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
                "url": "https://www.gofun88.in/promotions/referral-bonus"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "refill-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Redeposit Bonus up to 5% | Fun88 promotions India",
                "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit. T&C apply.",
                "url": "https://www.gofun88.in/promotions/refill-bonus"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "welcome-bonus": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Get 388% massive bonus on your 1st deposit | Fun88 India",
                "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!. T&C apply.",
                "url": "https://www.gofun88.in/promotions/welcome-bonus"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "responsible-gambling": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Learn All About Wagering & Responsible Gaming | Fun88 India",
                "description": "Read the Fun88 India’s responsible gaming section to learn how to gamble responsibly with features such as time outs, deposit limits, and self-exclusions. Know more about the negative effects of gambling and wagering, online and offline.",
                "url": "https://www.gofun88.in/help/responsible-gaming"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "signup": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fun88 India Registration | Sign Up Today!",
                "description": "Sign up on Fun88 official website, the best online sports betting market, an action-packed online casino, and a host of big promotions and bonuses. Enjoy more such features with Fun88 online registration.",
                "url": "https://www.gofun88.in/signup"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "slots": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Online Slots Jili Casino and Win a Jackpot 🎰 with Fun88",
                "description": "Play Slots Game online in India for real money ➡️ Browse the list of the best slots machine by Jili. Join now at Fun88 & let the games begin!",
                "url": "https://www.gofun88.in/slots"
            },
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Are online slot games legal?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, online slot games are legal to play. You don't have to worry about doing something illegal when playing for real money."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are slots safe to play in India?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, playing slot games in India is very safe. Just choose safe platforms like Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is an online slot game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Slot games have random number generators that determine your chances of winning and the amount of winning. The results are, in a way, very instant."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I play online slot games for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Some games do offer free trials. To gain more clarity, head on to Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I win at online slot games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Keep practising and trying your luck."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any strategies to win at online slot games?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Although slots are very luck-based, you should always play financially responsibly. It will help you stay in the game longer and win more."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Which slot games offer the best bonuses?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers you the best bonus on slots."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What are the odds of winning online slots?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There are no such particular odds. In slot games, the winning chances are luck-dependent."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it possible to win real money by playing online slots?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, it is. Platforms like Fun88 let you play for real money, and you can even win jackpots."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What is RTP in Slots?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "RTP in slots is the payout percentage you could get if you win."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "jili-slot": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Best JILI Slots Game Online at Fun88",
                "description": "Take your Jili Slot game to the next level with tricks, tips, and other important strategies that can help you win real money. Play jili slot games today.",
                "url": "https://www.gofun88.in/slots/jili-slot"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How much bonus does Fun88 offer for Jili Slot players?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 offers about a 388% bonus along with extra free spins on deposits made for Jili Slot games."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many Jili Slot variations are there on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You will come across more than 50 slot game variations on Fun88."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to choose the correct variation of the game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You should play demo games to understand how each slot works for you and avoid getting by just the graphic."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "crazy777": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Crazy777 JILI Slots Game Online by Fun88",
                "description": "Play Crazy777 by JILI, a fun slots game full of exciting features. Learn winning tips and start spinning to win real money today on Fun88!",
                "url": "https://www.gofun88.in/slots/jili-slot/crazy777"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "fortune-gems": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Fortune Gems Slots JILI Games Online by Fun88",
                "description": "Spin and win with Fortune Gems Slots from JILI Games at Fun88. Experience exciting features, big rewards, and endless fun.",
                "url": "https://www.gofun88.in/slots/jili-slot/fortune-gems"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "money-coming": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Money Coming JILI Slot Game Online by Fun88",
                "description": "Play Money Coming Jili Slots at Fun88 & win real money. Enjoy exciting spins, big rewards, and top-notch gameplay. Start winning today!",
                "url": "https://www.gofun88.in/slots/jili-slot/money-coming"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "netent": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "NetEnt - Leading Provider of Online Slot Games",
                "description": "Discover top slot games by NetEnt. Enjoy thrilling online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/slots/netent"
            },
            {
                "@context": "http://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Why Should You Play Netent on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Netent on Fun88 offers an amazing range of graphically captivating slot variations with interesting gameplay."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How to Win on Netent at Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The only way is to choose the slot variation correctly and practice on the demo game before making the deposit."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How many games NetEnt has launched?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Till now, the developer has launched as many as 200 online casino games."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "sportsbook": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "BTi Sports Betting: Trusted Source for Sports Wagering",
                "description": "Enjoy the excitement of BTi online betting in India with Fun88! Bet on your favourite BTi teams with ease and win big with Fun88.",
                "url": "https://www.gofun88.in/sportsbook"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "terms-conditions": [
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Terms And Conditions About Betting & Gambling | Fun88 India",
                "description": "Before joining Fun88 India, make sure you read our terms and conditions carefully. Check out the detailed terms and conditions about betting, payments, promotions, bonuses, and more.",
                "url": "https://www.gofun88.in/help/terms-and-conditions"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "virtualSports": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Bet on Virtual Sports Games Online with Fun88",
                "description": "Bet on virtual sports online with Fun88! Enjoy realistic gameplay and seamless betting. Join now for an immersive virtual sports experience!",
                "url": "https://www.gofun88.in/virtualSports"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "vci": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Bet on Virtual Cricket Online with Fun88",
                "description": "Bet on virtual Cricket online with Fun88! Enjoy realistic gameplay and seamless betting. Join now for an immersive virtual Cricket experience!",
                "url": "https://www.gofun88.in/virtualSports/vci"
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "skyward": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Skyward Game: Betgames Crash Adventure | Fun88",
                "description": "Experience the thrill of Skyward Game – an instant crash game by Betgames. Play for real money excitement at Fun88. Dive into the action now!",
                "url": "https://www.gofun88.in/live-casino/betgames/skyward "
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How do I start playing Skyward Game on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To begin your Skyward Game experience, simply register on the Fun88 platform, navigate to the game section, and launch Skyward. Choose your bet amount and take off for an adrenaline-pumping adventure!"
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Skyward Game available on mobile devices?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! You can enjoy Skyward Game on the go by downloading the Fun88 mobile app. It's compatible with both iOS and Android devices, providing a seamless gaming experience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there any special rewards for Skyward Game players on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes! Fun88 offers exclusive rewards for Skyward Game enthusiasts. Keep an eye on the promotions page for special bonuses, free spins, and other exciting offers tailored for Skyward players."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What benefits does the Fun88 VIP program offer for Skyward Game players?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88's VIP program brings a host of benefits for Skyward Game players, including personalized assistance, higher betting limits, and exclusive promotions. Elevate your gaming experience by becoming a Fun88 VIP member."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I register on Fun88 to play Skyward Game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Registering on Fun88 is a breeze. Visit the Fun88 website, click on the 'Register' button, fill in the required details, and you'll be ready to explore the world of Skyward Game and other exciting offerings."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "speedy-7": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Play Speedy 7 Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Speedy 7 by Bet games on Fun88! Play online and win big with Speedy 7. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/speedy-7"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can I play Speedy 7 for free?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Speedy 7 is generally meant for real money players looking for an exciting experience and potential winnings."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Speedy 7 mobile compatible?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can play Speedy 7 using your Fun88 on different devices such as tablets or smartphones."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I win at Speedy 7?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Even though it is a game of luck, many people employ various wagering strategies like Martingale or Fibonacci by which they handle their bets even though no method ensures victory."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is it safe to play Speedy 7 on Fun88?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Fun88 provides a secure environment that employs modern security measures to safeguard players’ personal information and transactions."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Does Speedy 7 produce random results?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, since the game uses certified RNG (random number generator) in every card drawn, ensuring fairness and impartiality in the results obtained."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ],
        "jili-fishing": [
            {
                "@context": "https://schema.org/",
                "@type": "WebPage",
                "name": "Jili Fishing - Leading Provider of Online Slot Games",
                "description": "Dive into the world of Jili Fishing's captivating slot games. Experience thrilling online gaming at Fun88 for an immersive adventure.",
                "url": "https://www.gofun88.in/slots/jili-fishing"
            },
            {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "Can you play Jili Fishing on your smartphone?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can download the Fun88 application on your smartphone to play the game at your convenience."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Are there tournaments available in the game?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Fun88 organizes tournaments and competitions, Which offer Jili Fishing players a chance to compete with others and often reward them with great prizes."
                        }
                    }
                ]
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fun88",
                "url": "https://www.gofun88.in",
                "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
                "sameAs": [
                    "https://www.facebook.com/Fun88India",
                    "https://www.instagram.com/fun88indofficial/",
                    "https://twitter.com/Fun88India"
                ],
                "contactPoint": [
                    {
                        "@type": "ContactPoint",
                        "telephone": "+91 22 50323347",
                        "contactType": "customer service",
                        "email": "cs@fun88india.com",
                        "areaServed": "IN"
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "SiteNavigationElement",
                "name": [
                    "Fun88",
                    "Promotions",
                    "Live Casino",
                    "Instant Games",
                    "Virtual Sports",
                    "Slots",
                    "Sponsorships",
                    "Refer A Friend",
                    "VIP",
                    "Rewards",
                    "Contact Us",
                    "Fun88 App",
                    "Affiliate",
                    "FAQ",
                    "Blog",
                    "Hindi Blog",
                    "Telugu Blog"
                ],
                "url": [
                    "https://www.gofun88.in",
                    "https://www.gofun88.in/promotions",
                    "https://www.gofun88.in/live-casino",
                    "https://www.gofun88.in/instant-games",
                    "https://www.gofun88.in/sports",
                    "https://www.gofun88.in/virtualSports",
                    "https://www.gofun88.in/slots",
                    "https://www.gofun88.in/promotions/referral-bonus",
                    "https://www.gofun88.in/loyalty",
                    "https://www.gofun88.in/rewards",
                    "https://www.gofun88.in/contact-us",
                    "https://www.gofun88.in/app-download",
                    "https://www.gofun88.in/affiliates",
                    "https://www.gofun88.in/faq",
                    "https://www.gofun88.in/blog/",
                    "https://www.gofun88.in/hi/blog/",
                    "https://www.gofun88.in/te/blog/"
                ]
            }
        ]
    }
}


const getSchemaData = (pagename: string) => {
    const _brandId = getBrandId();
    const _brandData = schemaData[_brandId] || {};
    const _schemaData = _brandData[pagename];
    if (_schemaData) {
        return _schemaData
    }
    return [];
}
export default getSchemaData;